export default {
    // 中
    zhCN: {
        vin: 'VIN',
        claim_information: '索赔信息',
        marketing_activity_list: '营销活动列表',
        basic_information: '基础信息',
        warranty_block: '保修块',
        repair_block_code: '保修块代码',
        claim_cycle_list: '索赔周期列表',
        WTY_Indicates_the_cycle_type: 'WTY周期类型',
        start_time: '开始时间',
        end_time: '结束时间',
        WTY_mileage: 'WTY里程',
        List_of_claims: '索赔单列表',
        customer: '顾客',
        warranty_claim: '保修索赔单',
        work_order: '工单',
        date_of_receipt: '接收日期',
        maintenance_technical_date: '维修技术日期',
        mileage: '里程',
        state: '状态',
        main_component: '主要部件',
        part_name: '零件名称',
        Recall_and_service_activities: '召回和服务活动',
        recall_and_service_activity_code: '召回和服务活动代码',
        recall_and_service_activity_names: '召回和服务活动名称',
        brand: '品牌',
        recall_type_code: '服务类型代码',
        recall_type_name: '服务类型名称',
        release_date: '发布日期',
        closing_date: '关闭日期',
        complete: '完成',
        warranty_claim_status: '保修索赔状态',
        end_of_maintenance_date: '维修结束日期',
        marketing_activity_code: '市场活动代码',
        marketing_activity_name: '市场活动名称',
        vehicle_warranty_period: '车辆质保期',
        marketing_activities: '市场活动',
        prohibition_of_claims: '禁止索赔',
        prohibited_claim_code: '禁止索赔代码',
        type_of_warranty_period: '质保期类型',
        repair_end_date: '维修结束日期',
        reception_date: '接待日期',
        main_station_code: '主工位代码',
        main_workstation_name: '主工位名称',
    },
    // 英
    enUS: {
        vin: 'VIN',
        claim_information: 'Claim information',
        marketing_activity_list: 'Marketing campaign',
        basic_information: 'Basic information',
        warranty_block: 'Warranty block',
        repair_block_code: 'Warranty block code',
        claim_cycle_list: 'WTY period list',
        WTY_Indicates_the_cycle_type: 'WTY period type',
        start_time: 'Start date',
        end_time: 'End date',
        WTY_mileage: 'WTY mileage',
        List_of_claims: 'WTY history',
        customer: 'Customer',
        warranty_claim: 'Warranty claim',
        work_order: 'Work order',
        date_of_receipt: 'Reception date',
        maintenance_technical_date: 'Repair date',
        mileage: 'Mileage',
        state: 'Status',
        main_component: 'Main part',
        part_name: 'Part name_en',
        Recall_and_service_activities: 'Recall&service campaign',
        recall_and_service_activity_code: 'Recall&service campaign code',
        recall_and_service_activity_names: 'Recall&service campaign name',
        brand: 'Brand',
        recall_type_code: 'Recall type code',
        recall_type_name: 'Recall type name',
        release_date: 'Release date',
        closing_date: 'Close date',
        complete: 'Completed',
        warranty_claim_status: 'Warranty claim status',
        end_of_maintenance_date: 'End date of last repair',
        marketing_activity_code: 'Marketing campaign code',
        marketing_activity_name: 'Marketing campaign name',
        vehicle_warranty_period: 'Vehicle warranty period',
        marketing_activities: 'Marketing activities',
        prohibition_of_claims: 'Prohibition of claims',
        prohibited_claim_code: 'Prohibited claim code',
        type_of_warranty_period: 'Type of warranty period',
        repair_end_date: 'Repair end date',
        reception_date: 'Reception date',
        main_station_code: 'Main station code',
        main_workstation_name: 'Main workstation name',
    },
    // 德
    deDE: {
        vin: 'VIN',
        claim_information: 'Schadensinformation',
        marketing_activity_list: 'Marketing-Kampagnenliste',
        basic_information: 'Grundinformationen',
        warranty_block: 'Garantieblock',
        repair_block_code: 'Garantie-Blockcode',
        claim_cycle_list: 'WTY-Zeitraum Liste',
        WTY_Indicates_the_cycle_type: 'WTY-Zeitraumtyp',
        start_time: 'Startzeit',
        end_time: 'Endzeit',
        WTY_mileage: 'WTY-Kilometerstand',
        List_of_claims: 'WTY-Verlauf',
        customer: 'Kunde',
        warranty_claim: 'Garantieanspruch',
        work_order: 'Arbeitsauftrag',
        date_of_receipt: 'Empfangsdatum',
        maintenance_technical_date: 'Wartungsdatum',
        mileage: 'Kilometerstand',
        state: 'Status',
        main_component: 'Hauptkomponente',
        part_name: 'Teilname',
        Recall_and_service_activities: 'Rückruf & Service-Kampagne',
        recall_and_service_activity_code: 'Rückruf & Service-Kampagnencode',
        recall_and_service_activity_names: 'Rückruf & Service-Kampagnenname',
        brand: 'Marke',
        recall_type_code: 'Diensttypcode',
        recall_type_name: 'Name des Diensttyps',
        release_date: 'Veröffentlichungsdatum',
        closing_date: 'Abschlussdatum',
        complete: 'vollständig',
        warranty_claim_status: 'Garantieanspruchstatus',
        end_of_maintenance_date: 'Enddatum der letzten Reparatur',
        marketing_activity_code: 'Kampagnencode',
        marketing_activity_name: 'Kampagnenname',
        vehicle_warranty_period: 'Garantiezeit für Fahrzeuge',
        marketing_activities: 'Marketingaktivitäten',
        prohibition_of_claims: 'Verbot von Ansprüchen',
        prohibited_claim_code: 'Code für verbotene Ansprüche',
        type_of_warranty_period: 'Art der Garantiezeit',
        repair_end_date: 'Enddatum der Reparatur',
        reception_date: 'Empfangsdatum',
        main_station_code: 'Hauptbahnhofcode',
        main_workstation_name: 'Name des Hauptplatzes',
    },
    // 法
    frFR: {
        vin: 'VIN',
        claim_information: 'Informations sur les réclamations',
        marketing_activity_list: 'Campagne de marketing',
        basic_information: 'Informations de base',
        warranty_block: 'Bloc garantie',
        repair_block_code: 'Code de bloc garantie',
        claim_cycle_list: 'Liste des périodes de garantie',
        WTY_Indicates_the_cycle_type: 'Types de périodes de garantie',
        start_time: 'Date de début',
        end_time: 'Date de fin',
        WTY_mileage: 'kilométrage garantie',
        List_of_claims: 'Histortique garantie',
        customer: 'Client',
        warranty_claim: 'Demande de garantie',
        work_order: 'Ordre de réparation',
        date_of_receipt: 'Date de réception',
        maintenance_technical_date: 'Date de fin de travaux',
        mileage: 'Kilométrage',
        state: 'Statut',
        main_component: 'Pièce principale',
        part_name: 'Nom de pièce',
        Recall_and_service_activities: 'Campagnes de rappel de service',
        recall_and_service_activity_code: 'Code campagne',
        recall_and_service_activity_names: 'Nom campagne',
        brand: 'Marque',
        recall_type_code: 'Code du type de service',
        recall_type_name: 'Nom du type de service',
        release_date: 'Date d\'ouverture',
        closing_date: 'Date de fermeture',
        complete: 'Effectuée',
        warranty_claim_status: 'Statut de la demande de garantie',
        end_of_maintenance_date: 'Date de fin de travaux',
        marketing_activity_code: 'Code de campagne marketing',
        marketing_activity_name: 'Nom de l’activité marketing',
    },
    // 意大利
    itIT: {
        vin: 'VIN',
        claim_information: 'Informazioni reclamo',
        marketing_activity_list: 'Campagna di marketing',
        basic_information: 'Informazioni di base',
        warranty_block: 'Blocco garanzia',
        repair_block_code: 'Codice blocco garanzia',
        claim_cycle_list: 'Elenco periodi garanzia',
        WTY_Indicates_the_cycle_type: 'Tipo di periodo garanzia',
        start_time: 'Ora di inizio',
        end_time: 'Ora di fine',
        WTY_mileage: 'Kilometraggio garanzia',
        List_of_claims: 'Storico garanzia',
        customer: 'Cliente',
        warranty_claim: 'Reclamo garanzia',
        work_order: 'Ordine di lavoro',
        date_of_receipt: 'Data di ricezione',
        maintenance_technical_date: 'Data di riparazione',
        mileage: 'Chilometraggio',
        state: 'Stato',
        main_component: 'Componente principale',
        part_name: 'Nome parte',
        Recall_and_service_activities: 'Campagna richiami e servizio',
        recall_and_service_activity_code: 'Codice campagna richiami e servizio',
        recall_and_service_activity_names: 'Nome campagna richiami e servizio',
        brand: 'Marchio',
        recall_type_code: 'Codice tipo di servizio',
        recall_type_name: 'Nome del tipo di servizio',
        release_date: 'Data di rilascio',
        closing_date: 'Data di chiusura',
        complete: 'Completa',
        warranty_claim_status: 'Stato reclamo garanzia',
        end_of_maintenance_date: 'Data di fine dell\'ultima riparazione',
        marketing_activity_code: 'Codici delle attività di mercato',
        marketing_activity_name: 'Nome dell’attività commerciale',
        vehicle_warranty_period: 'Periodo di garanzia del veicolo',
        marketing_activities: 'Attività di marketing',
        prohibition_of_claims: 'Divieto di rivendicazioni',
        prohibited_claim_code: 'Codice delle richieste vietate',
        type_of_warranty_period: 'Tipo di periodo di garanzia',
        repair_end_date: 'Data di fine riparazione',
        reception_date: 'Data di ricevimento',
        main_station_code: 'Codice della stazione centrale',
        main_workstation_name: 'Nome della stazione di lavoro principale',
    },
    // 荷兰
    nlNL: {
        vin: 'VIN',
        claim_information: 'Claim informatie',
        marketing_activity_list: 'Marketingcampagne',
        basic_information: 'Basisinformatie',
        warranty_block: 'Garantieblokkering',
        repair_block_code: 'Code garantieblokkering',
        claim_cycle_list: 'Garantieperiodelijst',
        WTY_Indicates_the_cycle_type: 'WTY-periode type',
        start_time: 'Starttijd',
        end_time: 'Eindtijd',
        WTY_mileage: 'WTY kilometerstand',
        List_of_claims: 'WTY-geschiedenis',
        customer: 'Klant',
        warranty_claim: 'Garantieclaim',
        work_order: 'Werkorder',
        date_of_receipt: 'Ontvangst datum',
        maintenance_technical_date: 'Reparatiedatum',
        mileage: 'kilometerstand',
        state: 'Status',
        main_component: 'Hoofdonderdeel',
        part_name: 'Onderdeelnaam',
        Recall_and_service_activities: 'Terugroep- & servicecampagne',
        recall_and_service_activity_code: 'Terugroep- & servicecampagnecode',
        recall_and_service_activity_names: 'Terugroep- & servicecampagnenaam',
        brand: 'Merk',
        recall_type_code: 'Code van het diensttype',
        recall_type_name: 'Diensttype naam',
        release_date: 'Releasedatum',
        closing_date: 'Sluitingsdatum',
        complete: 'voltooid',
        warranty_claim_status: 'Garantieclaim status',
        end_of_maintenance_date: 'Einddatum van laatste reparatie',
        marketing_activity_code: 'Code voor marktactiviteiten',
        marketing_activity_name: 'Naam van de marktactiviteiten',
        vehicle_warranty_period: 'Garantieperiode voor voertuigen',
        marketing_activities: 'Marketingactiviteiten',
        prohibition_of_claims: 'Verbod op vorderingen',
        prohibited_claim_code: 'Code verboden claims',
        type_of_warranty_period: 'Soort garantieperiode',
        repair_end_date: 'inddatum herstellen',
        reception_date: 'Ontvangstdatum',
        main_station_code: 'Code van het hoofdstation',
        main_workstation_name: 'Naam hoofdwerkstation',
    },
    // 越南
    viVN: {
        vin: 'Số khung',
        claim_information: 'Thông tin đòi lại',
        marketing_activity_list: 'Chiến dịch marketing',
        basic_information: 'Thông tin cơ bản',
        warranty_block: 'Khối bảo hành',
        repair_block_code: 'Mã khối bảo hành',
        claim_cycle_list: 'Danh sách thời gian bảo hành',
        WTY_Indicates_the_cycle_type: 'Loại thời gian bảo hành',
        start_time: 'Ngày bắt đầu',
        end_time: 'Ngày kết thúc',
        WTY_mileage: 'Số km bảo hành',
        List_of_claims: 'Lịch sử bảo hành',
        customer: 'Khách hàng',
        warranty_claim: 'Yêu cầu bảo hành',
        work_order: 'Lệnh sửa chữa',
        date_of_receipt: 'Ngày tiếp nhận',
        maintenance_technical_date: 'Ngày sửa chữa',
        mileage: 'Số km',
        state: 'Tình trạng',
        main_component: 'Phụ tùng chính',
        part_name: 'Tên phụ tùng _ Tiếng Anh',
        Recall_and_service_activities: 'Chiến dịch triệu hồi và dịch vụ',
        recall_and_service_activity_code: 'Mã chiến dịch triệu hồi và dịch vụ',
        recall_and_service_activity_names: 'Tên chiến dịch triệu hồi và dịch vụ',
        brand: 'Thương hiệu',
        recall_type_code: 'Mã loại triệu hồi',
        recall_type_name: 'Tên loại triệu hồi',
        release_date: 'Ngày phát hành',
        closing_date: 'Ngày đóng',
        complete: 'Đã hoàn thành',
        warranty_claim_status: 'Tình trạng yêu cầu bảo hành',
        end_of_maintenance_date: 'Ngày sửa chữa gần nhất',
        marketing_activity_code: 'Mã chiến dịch marketing',
        marketing_activity_name: 'Tên chiến dịch marketing',
        vehicle_warranty_period: 'Kỳ hạn bảo hành xe',
        marketing_activities: 'Hoạt động marketing',
        prohibition_of_claims: 'Cấm đòi lại',
        prohibited_claim_code: 'Mã cấm đòi lại',
        type_of_warranty_period: 'Loại kỳ hạn bảo hành',
        repair_end_date: 'Ngày kết thúc sửa chữa',
        reception_date: 'Ngày tiếp nhận',
        main_station_code: 'Mã trạm chính',
        main_workstation_name: 'Tên trạm làm việc chính',
    },
    // 西班牙
    esES: {
        vin: 'VIN',
        claim_information: 'Información de reclamación',
        marketing_activity_list: 'Lista de actividades de marketing',
        basic_information: 'Información básica',
        warranty_block: 'Bloque de garantía',
        repair_block_code: 'Código de bloque de garantía',
        claim_cycle_list: 'Lista de períodos de reclamación',
        WTY_Indicates_the_cycle_type: 'Tipo de período de garantía',
        start_time: 'Fecha de inicio',
        end_time: 'Fecha de finalización',
        WTY_mileage: 'Kilometraje de garantía',
        List_of_claims: 'Lista de formularios de reclamación',
        customer: 'Cliente',
        warranty_claim: 'Formulario de reclamación de garantía',
        work_order: 'Orden de trabajo',
        date_of_receipt: 'Fecha de recepción',
        maintenance_technical_date: 'Fecha de reparación técnica',
        mileage: 'Kilometraje',
        state: 'Estado',
        main_component: 'Componente principal',
        part_name: 'Nombre de la pieza',
        Recall_and_service_activities: 'Recall y actividades de servicio',
        recall_and_service_activity_code: 'Código de recall y actividades de servicio',
        recall_and_service_activity_names: 'Nombre de recall y actividades de servicio',
        brand: 'Marca',
        recall_type_code: 'Código de tipo de servicio',
        recall_type_name: 'Nombre de tipo de servicio',
        release_date: 'Fecha de publicación',
        closing_date: 'Fecha de cierre',
        complete: 'Completado',
        warranty_claim_status: 'Estado de reclamación de garantía',
        end_of_maintenance_date: 'Fecha de finalización de la reparación',
        marketing_activity_code: 'Código de actividad de mercado',
        marketing_activity_name: 'Nombre de actividad de mercado',
        vehicle_warranty_period: 'Período de garantía del vehículo',
        marketing_activities: 'Actividad de mercado',
        prohibition_of_claims: 'Prohibición de reclamación',
        prohibited_claim_code: 'Código de prohibición de reclamación',
        type_of_warranty_period: 'Tipo de período de garantía',
        repair_end_date: 'Fecha de finalización de la reparación',
        reception_date: 'Fecha de recepción',
        main_station_code: 'Código de estación de servicio principal',
        main_workstation_name: 'Nombre de estación de servicio principal',
    },
    // 葡萄牙
    ptBR: {
        vin: 'VIN',
        claim_information: 'Informação de reivindicação',
        marketing_activity_list: 'Lista de atividades de marketing',
        basic_information: 'Informação básica',
        warranty_block: 'Bloqueio da garantia',
        repair_block_code: 'Código do bloco de reparo',
        claim_cycle_list: 'Lista de ciclos de reivindicação',
        WTY_Indicates_the_cycle_type: 'WTY Indica o tipo de ciclo',
        start_time: 'Hora de início',
        end_time: 'Hora de término',
        WTY_mileage: 'WTY Quilometragem',
        List_of_claims: 'Lista de reivindicações',
        customer: 'Cliente',
        warranty_claim: 'Reivindicação de garantia',
        work_order: 'Ordem de trabalho',
        date_of_receipt: 'Data de recebimento',
        maintenance_technical_date: 'Data técnica de manutenção',
        mileage: 'Quilometragem',
        state: 'Estado',
        main_component: 'Componente principal',
        part_name: 'Nome da peça',
        Recall_and_service_activities: 'Atividades de recall e serviço',
        recall_and_service_activity_code: 'Código da atividade de recall e serviço',
        recall_and_service_activity_names: 'Nomes das atividades de recall e serviço',
        brand: 'Marca',
        recall_type_code: 'Código do tipo de recall',
        recall_type_name: 'Nome do tipo de recall',
        release_date: 'Data de lançamento',
        closing_date: 'Data de encerramento',
        complete: 'Completo',
        warranty_claim_status: 'Status da reivindicação de garantia',
        end_of_maintenance_date: 'Data de término da manutenção',
        marketing_activity_code: 'Código da atividade de marketing',
        marketing_activity_name: 'Nome da atividade de marketing',
        vehicle_warranty_period: 'Período de garantia do veículo',
        marketing_activities: 'Atividades de marketing',
        prohibition_of_claims: 'Proibição de reivindicações',
        prohibited_claim_code: 'Código de reivindicação proibida',
        type_of_warranty_period: 'Tipo de período de garantia',
        repair_end_date: 'Data de término do reparo',
        reception_date: 'Data de recepção',
        main_station_code: 'Código da estação principal',
        main_workstation_name: 'Nome da estação de trabalho principal',
    },
    // 波兰
    plPL: {
        vin: 'VIN',
        claim_information: 'Informacje o reklamacji',
        marketing_activity_list: 'Kampania marketingowa',
        basic_information: 'Informacje podstawowe',
        warranty_block: 'Blok gwarancyjny',
        repair_block_code: 'Kod bloku gwarancyjnego',
        claim_cycle_list: 'Lista okresowa reklamacji',
        WTY_Indicates_the_cycle_type: 'Typ okresu reklamacji',
        start_time: 'Data rozpoczęcia',
        end_time: 'Data zakończenia',
        WTY_mileage: 'Przebieg',
        List_of_claims: 'Historia reklamacji',
        customer: 'Klient',
        warranty_claim: 'Roszczenie gwarancyjne',
        work_order: 'Zlecenie prac',
        date_of_receipt: 'Data przyjęcia',
        maintenance_technical_date: 'Data naprawy',
        mileage: 'Przebieg',
        state: 'Status',
        main_component: 'Główna część',
        part_name: 'Nazwa części_pl',
        Recall_and_service_activities: 'Akcja',
        recall_and_service_activity_code: 'Kod akcji recall&service',
        recall_and_service_activity_names: 'Nazwa akcji recall&service',
        brand: 'Marka',
        recall_type_code: 'Kod typu akcji',
        recall_type_name: 'Nazwa typu akcji',
        release_date: 'Data ogłoszenia',
        closing_date: 'Data zamknięcia',
        complete: 'Zakończone',
        warranty_claim_status: 'Status roszczenia gwarancyjnego',
        end_of_maintenance_date: 'Data zakończenia ostatniej naprawy',
        marketing_activity_code: 'Kod kampanii marketingowej',
        marketing_activity_name: 'Nazwa kampanii marketingowej',
        vehicle_warranty_period: 'Okres gwarancji pojazdu',
        marketing_activities: 'Działalność marktingowa',
        prohibition_of_claims: 'Zakaz roszczeń',
        prohibited_claim_code: 'Kod zakazanego roszczenia',
        type_of_warranty_period: 'Typ okresu gwarancji',
        repair_end_date: 'Data zakończenia naprawy',
        reception_date: 'Data przyjęcia',
        main_station_code: 'Kod głównej stacji obługi',
        main_workstation_name: 'Nazwa glównej stacji obsługi',
    },
    // 土耳其
    trTR: {
        vin: 'VIN',
        claim_information: 'Kleym bilgileriİstem bilgileri',
        marketing_activity_list: 'Pazarlama kampanyası',
        basic_information: 'Temel bilgi',
        warranty_block: 'Garanti Grubu',
        repair_block_code: 'Garanti Blok Kodu',
        claim_cycle_list: 'WTY dönem listesi',
        WTY_Indicates_the_cycle_type: 'WTY dönem türü',
        start_time: 'Başlangıç tarihi',
        end_time: 'Bitiş Tarihi',
        WTY_mileage: 'Km si',
        List_of_claims: 'WTY geçmişi',
        customer: 'Müşterisi',
        warranty_claim: 'Garanti  kleymi',
        work_order: 'İş emri',
        date_of_receipt: 'Kabul Tarihi',
        maintenance_technical_date: 'Onarım tarihi',
        mileage: 'Km si',
        state: 'Durum',
        main_component: 'Ana parça',
        part_name: 'Parça adı',
        Recall_and_service_activities: 'Geri çağırma kampanyası',
        recall_and_service_activity_code: 'Geri çağırma kampanya adı',
        recall_and_service_activity_names: 'Geri çağırma kampanya kodu',
        brand: 'Marka',
        recall_type_code: 'Geri çağırma tipi kodu',
        recall_type_name: 'Geri çağırma tipi adı',
        release_date: 'Yayınlanma tarihi',
        closing_date: 'Kapanma tarihi',
        complete: 'Tamamlandı',
        warranty_claim_status: 'Garanti kleym durumu',
        end_of_maintenance_date: 'Son onarma tarihi',
        marketing_activity_code: 'Pazarlama kampanya kodu',
        marketing_activity_name: 'Pazarlama kampanya adı',
        vehicle_warranty_period: 'Araç garanti dönemi',
        marketing_activities: 'Pazarlama etkinlikleri',
        prohibition_of_claims: 'Kleym yapılamaz',
        prohibited_claim_code: 'Yapılamaz kley kodu',
        type_of_warranty_period: 'Garanti peryotye türü',
        repair_end_date: 'Onarım bitiş tarihi',
        reception_date: 'Kabul tarihi',
        main_station_code: 'Ana istasyon kodu',
        main_workstation_name: 'Ana çalışma istasyonu ismi',
    },
    // 捷克语
    csCZ: {
        vin: 'VIN',
        claim_information: 'Informace o reklamaci',
        marketing_activity_list: 'Marketingová kampaň',
        basic_information: 'Základní informace',
        warranty_block: 'Warranty block',
        repair_block_code: 'Kód záručního bloku',
        claim_cycle_list: 'Seznam období záruky',
        WTY_Indicates_the_cycle_type: 'Typ období záruky',
        start_time: 'Datum zahájení',
        end_time: 'Datum ukončení',
        WTY_mileage: 'WTY mileage',
        List_of_claims: 'historie záruky',
        customer: 'Zákazník',
        warranty_claim: 'Záruční reklamace',
        work_order: 'Zakázka',
        date_of_receipt: 'Datum přijetí',
        maintenance_technical_date: 'Termín opravy',
        mileage: 'Počet najetých kilometrů',
        state: 'Status',
        main_component: 'Hlavní část',
        part_name: 'Název dílu_en',
        Recall_and_service_activities: 'Svolávací a servisní kampaň',
        recall_and_service_activity_code: 'Kód kampaně a svolávací akce',
        recall_and_service_activity_names: 'Jméno kampaně a svolávací akce',
        brand: 'Značka',
        recall_type_code: 'Typ kódu svolávací kampaně',
        recall_type_name: 'Název svolávací kampaně',
        release_date: 'Datum vydání',
        closing_date: 'Datum uzavření',
        complete: 'Dokončeno',
        warranty_claim_status: 'Stav reklamace',
        end_of_maintenance_date: 'Datum ukončení poslední opravy',
        marketing_activity_code: 'Kód marketingové kampaně',
        marketing_activity_name: 'Název marketingové kampaně',
        vehicle_warranty_period: 'Záruční doba vozidla',
        marketing_activities: 'Marketingové aktivity',
        prohibition_of_claims: 'Zákaz reklamací',
        prohibited_claim_code: 'Kód zakázaných reklamací',
        type_of_warranty_period: 'Typ záruční doby',
        repair_end_date: 'Datum ukončení opravy',
        reception_date: 'Datum příjmu',
        main_station_code: 'Main station code',
        main_workstation_name: 'Main workstation name',
    },
    // 匈牙利语
    huHU: {
        vin: 'Alvázszám',
        claim_information: 'Igényinformáció',
        marketing_activity_list: 'Marketingkampány',
        basic_information: 'Alapvető információ',
        warranty_block: 'Garanciablokk',
        repair_block_code: 'Garanciablokk kódja',
        claim_cycle_list: 'Gar. időszak lista',
        WTY_Indicates_the_cycle_type: 'Gar. időszak típusa',
        start_time: 'Kezdő dátum',
        end_time: 'Befejező dátum',
        WTY_mileage: 'Gar. futásteljesítmény',
        List_of_claims: 'Gar. előzmények',
        customer: 'Ügyfél',
        warranty_claim: 'Garanciális igény',
        work_order: 'Munkautasítás',
        date_of_receipt: 'Átvétel dátuma',
        maintenance_technical_date: 'Javítás dátuma',
        mileage: 'Futásteljesítmény',
        state: 'Állapot',
        main_component: 'Fődarab',
        part_name: 'Alkatrésznév',
        Recall_and_service_activities: 'Visszahívási és szervizkampány',
        recall_and_service_activity_code: 'Visszahívási és szervizkampány kódja',
        recall_and_service_activity_names: 'Visszahívási és szervizkampány neve',
        brand: 'Márka',
        recall_type_code: 'Visszahívási típus kódja',
        recall_type_name: 'Visszahívási típus neve',
        release_date: 'Kiadási dátum',
        closing_date: 'Lezárás dátuma',
        complete: 'Kész',
        warranty_claim_status: 'Garanciális igény állapota',
        end_of_maintenance_date: 'Legutóbbi javítás befejező dátuma',
        marketing_activity_code: 'Marketingkampány kódja',
        marketing_activity_name: 'Marketingkampány neve',
        vehicle_warranty_period: 'Jármű garanciális időszaka',
        marketing_activities: 'Marketingtevékenységek',
        prohibition_of_claims: 'Igények megtiltása',
        prohibited_claim_code: 'Tiltott igény kódja',
        type_of_warranty_period: 'Garanciális időszak típusa',
        repair_end_date: 'Javítás befejező dátuma',
        reception_date: 'Átvétel dátuma',
        main_station_code: 'Fő állomás kódja',
        main_workstation_name: 'Fő műhely kódja',
    },
    // 俄罗斯语
    ruRU: {
        vin: 'Идентификационный номер автомобиля',
        claim_information: 'Информация о претензии',
        marketing_activity_list: 'Список маркетинговых мероприятий',
        basic_information: 'Основная информация',
        warranty_block: 'Гарантийный блок',
        repair_block_code: 'Код гарантийного блока',
        claim_cycle_list: 'Список циклов претензий',
        WTY_Indicates_the_cycle_type: 'Тип цикла гарантии',
        start_time: 'Время начала',
        end_time: 'Время окончания',
        WTY_mileage: 'Пробег гарантии',
        List_of_claims: 'Список претензий',
        customer: 'Клиент',
        warranty_claim: 'Гарантийная претензия',
        work_order: 'Рабочий заказ',
        date_of_receipt: 'Дата получения',
        maintenance_technical_date: 'Дата ремонта',
        mileage: 'Пробег',
        state: 'Статус',
        main_component: 'Основная деталь',
        part_name: 'Название детали',
        Recall_and_service_activities: 'Программа отзывов и сервисные мероприятия',
        recall_and_service_activity_code: 'Код программы отзыва и сервисной активности',
        recall_and_service_activity_names: 'Название программы отзыва и сервисной активности',
        brand: 'Бренд',
        recall_type_code: 'Код типа отзыва',
        recall_type_name: 'Название типа отзыва',
        release_date: 'Дата выпуска',
        closing_date: 'Дата закрытия',
        complete: 'Завершено',
        warranty_claim_status: 'Статус гарантийной претензии',
        end_of_maintenance_date: 'Дата завершения последнего ремонта',
        marketing_activity_code: 'Код маркетинговой кампании',
        marketing_activity_name: 'Название маркетинговой кампании',
        vehicle_warranty_period: 'Период гарантии на транспортное средство',
        marketing_activities: 'Маркетинговые мероприятия',
        prohibition_of_claims: 'Запрет на претензии',
        prohibited_claim_code: 'Код запрещенных претензий',
        type_of_warranty_period: 'Тип гарантии',
        repair_end_date: 'Дата завершения ремонта',
        reception_date: 'Дата приема',
        main_station_code: 'Код основного рабочего места',
        main_workstation_name: 'Название основного рабочего места',
    },
};

import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 意大利
export default {
    license: {
        powered_by: 'Alimentato da SMIL',
    },
    auth: {
        login: 'Accesso',
        username: 'Nome utente',
        username_placeholder: 'Inserisci nome utente',
        password: 'Password',
        password_placeholder: 'Inserisci password',
        code: 'Codice di verifica',
        code_placeholder: 'Inserire il codice di verifica',
        logout: 'Disconnettersi',
        logout_success: 'Logout effettuato con successo',
        day: 'giorno',
        password_expiration: 'La password è scaduta, per favore modificala',
        expire: 'Rimanere fino alla scadenza della password',
        conseils:
            'La password deve contenere lettere maiuscole, minuscole, numeri e caratteri speciali ({msg}), e la lunghezza della password deve essere compresa tra 8 e 16 caratteri',
        brand_Selection: 'Selezione del marchio',
        unread_notices: 'Avvisi non letti',
        new_cases: 'Nuovi casi',
        unanswered_cases: 'Casi non risposti',
        overdue_cases: 'Casi scaduti',
    },
    button: {
        editing_position: 'Modifica posizione',
        query: 'Richiesta',
        old_password: 'Vecchia password',
        new_password: 'Nuova password',
        confirm_password: 'Conferma password',
        change_password: 'Cambia password',
        show_theme_editor: 'Mostra/Nascondi editor di tema',
        button: 'Pulsante',
        about: 'Informazioni',
        back: 'Indietro',
        go: 'VAI',
        home: 'Home',
        confirm: 'Conferma',
        modify: 'Modifica',
        edit: 'Modifica',
        new: 'Nuovo',
        cancel: 'Annulla',
        close: 'Chiuso',
        search: 'Cerca',
        reset: 'Reimposta',
        delete: 'Elimina',
        add: 'Aggiungi',
        collapse: 'Comprimi',
        expand: 'Altro',
        download_template: 'Scarica modello',
        batch_import: 'Importazione batch',
        import: 'Importa',
        export: 'Esporta',
        export_fail: 'Esportazione fallita',
        detailed: 'Dettaglio',
        details: 'dettagli',
        download: 'Scarica',
        toView: 'Controlla',
        toViews: 'Visualizza immagine',
        uploadAttachments: 'Carica',
        selectFile: 'Seleziona file',
        fillingInstructions: 'Istruzioni di compilazione',
        release: 'Rilascio',
        release_recall: 'Richiamo del rilascio',
        save: 'Salva',
        send: 'Invia',
        tips: 'Consigli',
        send_back: 'Ritorna',
        examine: 'Esamina',
        void: 'Annulla',
        more: 'Altro',
        refresh: 'Aggiorna',
        selects: 'Seleziona',
        serial_number: 'No',
        upload_progress: 'Progresso caricamento',
        invalid: 'Non valido',
        daily_record: 'Registro quotidiano',
        operation_type: 'Tipo di operazione',
        field_name: 'Nome del campo',
        edit_role: 'Modifica ruolo',
        user_role: 'Ruolo utente',
        assignment_role: 'Ruolo',
        post: 'Posta',
        sending: 'Inviando',
        reset_password: 'Reimposta password',
        display_or_not: 'Mostra o non mostra',
        document: 'Documento',
        specificTemplates: 'Modelli specifici',
        transferOrder: 'Ordine di trasferimento',
        forwarding: 'Inoltro',
        one_click_notification: 'Notifica con un clic',
    },
    radio: {
        enabled: 'Abilitato',
        disabled: 'Disabilitato',
        catalogue: 'Catalogo',
        individual: 'Individuale',
        all: 'Tutto',
        yes: 'Sì',
        no: 'No',
    },
    dropdown: {
        close_others: 'Chiudi Altri',
        close_right: 'Chiudi a Destra',
        close_left: 'Chiudi a Sinistra',
    },
    table: {
        action: 'Operazione',
        explain: 'Spiegazione',
        fileName: 'Nome dell\'allegato',
        size: 'Dimensione(MB)',
        uploader: 'Caricatore',
        uploadTime: 'Ora di Caricamento',
        system: 'Sistema',
        forwarding_time: 'Tempo di inoltro',
        forwarder: 'Spedizioniere',
        send_to: 'Invia a',
        mode: 'Modalità',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/pagina`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} articolo dati`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) =>
            `${named('number')} veicoli in totale`,
    },
    confirm: {
        confirm_deletion: 'Conferma la cancellazione?',
        confirm_void: 'Conferma vuoto?',
        confirm_release: 'Conferma rilascio?',
        ack: 'Conferma',
        cancel: 'Annulla',
        non_records: 'Seleziona almeno un pezzo di dati',
    },
    request: {
        failed_placeholder: 'Richiesta fallita, errore del backend',
        success_placeholder: 'Richiesta riuscita',
        auth: {
            login_success: 'Accesso riuscito',
        },
        error: {
            '404': 'URL Richiesta non trovato',
            '403': 'Permesso negato',
        },
    },
    page: {
        global: {
            page_search: 'Ricerca Pagina',
        },
        error: {
            not_found: 'Non trovato',
            not_found_des: 'Non c\'è niente qui',
            auth_failed: 'Autenticazione fallita',
            auth_failed_des: 'Questa pagina non è raggiungibile',
            file_format_error: 'Formato del file anomalo!',
            preview_failed: 'Anteprima fallita! File anomalo!',
        },
        homepage: {
            homepage: homepage.homepage.itIT,
        },
        system: {
            menu: system.menu.itIT,
            dict: system.dict.itIT,
            dept: system.dept.itIT,
            role: system.role.itIT,
            user: system.user.itIT,
        },
        info: {
            announcement: Info.announcement.announcement.itIT,
            document: Info.document.document.itIT,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.itIT,
                recallActivity: tech.quality.recallActivity.itIT,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.itIT,
            },
            management: {
                inquiryOd: tech.management.inquiry.itIT,
                permission: tech.management.permission.itIT,
                onSiteSupportHq: tech.management.onSiteSupport.itIT,
                progHistoryHq: tech.management.progHistory.itIT,
                salesRecordHq: tech.management.salesRecord.itIT,
                vehicleInfoHq: tech.management.vehicleInfo.itIT,
                claimHq: tech.management.claim.itIT,
                filterHq: tech.management.filter.itIT,
                newCarlssue: tech.management.newCarIssue.itIT,
                newCarDefinition: tech.management.newCarDefinition.itIT,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.itIT,
                programmingHistoryInformationQuery: tech.management.programmingHistoryInformationQuery.itIT,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.itIT,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.itIT,
        },
        statistics: statistics.inquiryStatistics.itIT,
        systemClassifyA: statistics.systemClassifyA.itIT,
        systemClassifyB: statistics.systemClassifyB.itIT,
        engineerStatistics: statistics.engineerStatistics.itIT,
        defectivePart: statistics.defectivePart.itIT,
        replyInquiryStatistics: statistics.replyInquiryStatistics.itIT,
        passRateStatistics: statistics.passRateStatistics.itIT,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.itIT,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.itIT,
        customsClearance135Rate: statistics.customsClearance135Rate.itIT,
        accumulatedClosureRate: statistics.accumulatedClosureRate.itIT,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.itIT,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.itIT,
    },
    validation: {
        common: {
            required: 'Questo elemento è richiesto',
            numeric: 'Deve essere un numero intero',
            decimal: 'Deve essere un numero',
            positive_interger: 'Deve essere un numero intero positivo',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Fino a ${named('number')} cifre`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Più di ${named('number')} caratteri`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Superare byte max (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Meno di ${named('number')} caratteri`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Deve essere ${named('number')} caratteri`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Maggiore di ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) => `Meno di ${named('number')}`,
            up_to_2_decimal_places: 'Fino a 2 decimali',
            retain_up: 'Inserisci fino a 10 numeri interi positivi e mantieni fino a 2 decimali',
            retain_ups: 'Inserisci fino a 11 numeri interi positivi e mantieni fino a 4 decimali',
            input_number: 'Inserisci un numero',
            only_number_a: 'Possono essere inseriti solo lettere o numeri',
            translate: 'Possono essere solo lettere',
            positive_integer: 'Inserisci almeno un campo',
            at_least_one: 'Compilare almeno uno',
        },
        tip: {
            incomplete_information: 'Informazioni incomplete compilate',
        },
    },
    import: {
        template_file: 'File di modello',
        browse: 'Sfoglia',
        download_template: 'Scarica il modello',
        no_file_selected: 'Nessun file selezionato',
        incorrect_file_type: 'Tipo di file errato',
        exceed: 'Andare oltre',
        upload_success: 'Caricamento riuscito',
        upload_fail: 'Caricamento fallito',
        again_upload: 'È possibile caricare solo file di immagine in formato jpg, png, bmp o gif.',
        attachmentUpload: 'Caricamento Allegato',
        supportedFormats: 'Formati Supportati',
        preview: 'Anteprima',
    },
    export: {
        noDataToExport: 'Nessun dato da esportare',
    },
    select: {
        all: 'Intero',
    },
    checkbox: {
        select_all: 'Seleziona tutto',
    },
    dialog: {
        error_message: 'Messaggio di errore',
    },
    ymd: {
        day: 'giorno',
        month: 'mese',
        year: 'anno',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    },
};

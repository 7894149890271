export default {
    // 中
    zhCN: {
        vin: 'VIN',
        productionTimeList: '生产时间清单',
        vehicle_configuration_list: '车辆配置清单',
        accurately_traceable_parts_list: '精确追溯零件清单',
        order_number: '订单号',
        order_type: '订单类型',
        platform: '平台',
        model_code: '车型代码',
        date_of_production: '生产日期',
        certificate_of_conformity: '合格证号',
        certificate_printing_time: '合格证打印时间',
        vehicle_material_number: '车辆物料号',
        description: '描述',
        time: '时间',
        feature_ID: '特征ID',
        feature_description: '特征描述',
        feature_code: '特征码',
        eigenvalue_description: '特征值描述',
        chinese_description: '中文描述',
        supplier: '供应商',
        bar_code_number: '条码编号',
        scanning_time: '扫描时间',
        key_component_type_code: '关键件类型编码',
        part_number: '零件号',
        traceability_number: '追溯号',
        sub_key_type: '子关键件类型',
        software: '软件',
        hardware: '硬件',
        calibration_information: '标定信息',
        supplier_name: '供应商号',
        pn: 'PIN码',
    },
    // 英
    enUS: {
        vin: 'VIN',
        productionTimeList: 'Production date list',
        vehicle_configuration_list: 'Vehicle configuration list',
        accurately_traceable_parts_list: 'Traceable parts list',
        order_number: 'Order #',
        order_type: 'Order type',
        platform: 'Platform',
        model_code: 'Model code',
        date_of_production: 'Date of production',
        certificate_of_conformity: 'COC #',
        certificate_printing_time: 'COC print timee',
        vehicle_material_number: 'Vehicle material code',
        description: 'Description',
        time: 'Time',
        feature_ID: 'Feature ID',
        feature_description: 'Feature description',
        feature_code: 'Feature code',
        eigenvalue_description: 'Feature description',
        chinese_description: 'Chinese description',
        supplier: 'Supplier',
        bar_code_number: 'Bar code number',
        scanning_time: 'Scanning time',
        key_component_type_code: 'Key component type code',
        part_number: 'Part number',
        traceability_number: 'Traceability number',
        sub_key_type: 'Sub key type',
        software: 'Software',
        hardware: 'Hardware',
        calibration_information: 'Calibration information',
        supplier_name: 'Supplier name',
        pn: 'PIN code',
    },
    // 德
    deDE: {
        vin: 'VIN',
        productionTimeList: 'Produktionszeitliste',
        vehicle_configuration_list: 'Fahrzeugkonfigurationsliste',
        accurately_traceable_parts_list: 'Genau nachverfolgbare Teileliste',
        order_number: 'Bestellnummer',
        order_type: 'Bestelltyp',
        platform: 'Plattform',
        model_code: 'Modellcode',
        date_of_production: 'Produktionsdatum',
        certificate_of_conformity: 'Konformitätsbescheinigung',
        certificate_printing_time: 'Zeitpunkt des Zertifikatsdrucks',
        vehicle_material_number: 'Fahrzeugmaterialnummer',
        description: 'Beschreibung',
        time: 'Zeit',
        feature_ID: 'Merkmals-ID',
        feature_description: 'Merkmalsbeschreibung',
        feature_code: 'Merkmalcode',
        eigenvalue_description: 'Eigenwertbeschreibung',
        chinese_description: 'Chinesische Beschreibung',
        supplier: 'Lieferant',
        bar_code_number: 'Barcode-Nummer',
        scanning_time: 'Scanzeit',
        key_component_type_code: 'Code des Schlüsselkomponententyps',
        part_number: 'Teilenummer',
        traceability_number: 'Rückverfolgbarkeitsnummer',
        sub_key_type: 'Untertyp des Schlüssels',
        software: 'Software',
        hardware: 'Hardware',
        calibration_information: 'Kalibrierungsinformationen',
        supplier_name: 'Lieferantenname',
        pn: 'PIN-Code',
    },
    // 法
    frFR: {
        vin: 'VIN',
        productionTimeList: 'Date de production',
        vehicle_configuration_list: 'Configuration véhicule',
        accurately_traceable_parts_list: 'Suivi des pièces',
        order_number: 'Numéro de commande',
        order_type: 'Type de commande',
        platform: 'Plateforme',
        model_code: 'Code modèle',
        date_of_production: 'Date de production',
        certificate_of_conformity: 'Numéro de COC',
        certificate_printing_time: 'Date d\'impression du COC',
        vehicle_material_number: 'Code du matériel véhicule',
        description: 'Description',
        time: 'Date',
        feature_ID: 'Nom de la fonctionnalité',
        feature_description: 'Description de la fonctionnalité',
        feature_code: 'Code de la fonctionnalité',
        eigenvalue_description: 'Description de valeur propre',
        chinese_description: 'Description',
        supplier: 'Fournisseur',
        bar_code_number: 'Numéro de code barre',
        scanning_time: 'Date de scan',
        key_component_type_code: 'Code de type de composant clé',
        part_number: 'Numéro de pièce',
        traceability_number: 'Numéro de traçabilité',
        sub_key_type: 'Sous-type de clé',
        software: 'Logiciel',
        hardware: 'Matériel',
        calibration_information: 'Informations de calibration',
        supplier_name: 'Nom du fournisseur',
        pn: 'Code PIN',
    },
    // 意大利
    itIT: {
        vin: 'VIN',
        productionTimeList: 'Elenco tempi di produzione',
        vehicle_configuration_list: 'Elenco configurazioni veicolo',
        accurately_traceable_parts_list: 'Elenco parti accuratamente rintracciabili',
        order_number: 'Numero d\'ordine',
        order_type: 'Tipo d\'ordine',
        platform: 'Piattaforma',
        model_code: 'Codice modello',
        date_of_production: 'Data di produzione',
        certificate_of_conformity: 'Certificato di conformità',
        certificate_printing_time: 'Tempo di stampa del certificato',
        vehicle_material_number: 'Numero materiale veicolo',
        description: 'Descrizione',
        time: 'Tempo',
        feature_ID: 'ID funzione',
        feature_description: 'Descrizione funzione',
        feature_code: 'Codice funzione',
        eigenvalue_description: 'Descrizione valore caratteristico',
        chinese_description: 'Descrizione cinese',
        supplier: 'Fornitore',
        bar_code_number: 'Numero codice a barre',
        scanning_time: 'Tempo di scansione',
        key_component_type_code: 'Codice tipo componente chiave',
        part_number: 'Numero parte',
        traceability_number: 'Numero tracciabilità',
        sub_key_type: 'Sotto-tipo chiave',
        software: 'Software',
        hardware: 'Hardware',
        calibration_information: 'Informazioni di calibrazione',
        supplier_name: 'Nome fornitore',
        pn: 'Codice PIN',
    },
    // 荷兰
    nlNL: {
        vin: 'VIN',
        productionTimeList: 'Productietijdlijst',
        vehicle_configuration_list: 'Voertuigconfiguratielijst',
        accurately_traceable_parts_list: 'Nauwkeurig traceerbare onderdelenlijst',
        order_number: 'Bestelnummer',
        order_type: 'Besteltype',
        platform: 'Platform',
        model_code: 'Modelcode',
        date_of_production: 'Productiedatum',
        certificate_of_conformity: 'Certificaat van overeenstemming',
        certificate_printing_time: 'Tijd van afdrukken certificaat',
        vehicle_material_number: 'Voertuigmateriaalnummer',
        description: 'Beschrijving',
        time: 'Tijd',
        feature_ID: 'Kenmerk ID',
        feature_description: 'Kenmerkbeschrijving',
        feature_code: 'Kenmerkcode',
        eigenvalue_description: 'Eigenwaarde beschrijving',
        chinese_description: 'Chinese beschrijving',
        supplier: 'Leverancier',
        bar_code_number: 'Barcode nummer',
        scanning_time: 'Scan tijd',
        key_component_type_code: 'Code van sleutelcomponenttype',
        part_number: 'Onderdeelnummer',
        traceability_number: 'Traceerbaarheidsnummer',
        sub_key_type: 'Subsleuteltype',
        software: 'Software',
        hardware: 'Hardware',
        calibration_information: 'Kalibratie informatie',
        supplier_name: 'Leveranciersnaam',
        pn: 'PIN-code',
    },
    // 越南
    viVN: {
        vin: 'Số khung',
        productionTimeList: 'Danh sách ngày sản xuất',
        vehicle_configuration_list: 'Danh sách thông tin kỹ thuật của xe',
        accurately_traceable_parts_list: 'Danh sách các phụ tùng có thể truy xuất nguồn gốc',
        order_number: 'Số đơn hàng',
        order_type: 'Loại đơn hàng',
        platform: 'Nền tảng',
        model_code: 'Mã loại',
        date_of_production: 'Ngày sản xuất',
        certificate_of_conformity: 'Số tờ khai nguồn gốc COC',
        certificate_printing_time: 'Thời gian in tờ khai nguồn gốc COC',
        vehicle_material_number: 'Mã vật liệu xe',
        description: 'Mô tả',
        time: 'Thời gian',
        feature_ID: 'Mã số tính năng',
        feature_description: 'Mô tả tính năng',
        feature_code: 'Mã tính năng',
        eigenvalue_description: 'Mô ta tính năng',
        chinese_description: 'Mô tả bằng tiếng Trung Quốc',
        supplier: 'Nhà cung cấp',
        bar_code_number: 'Số mã vạch',
        scanning_time: 'Thời gian quét',
        key_component_type_code: 'Mã loại thành phần chính',
        part_number: 'Số lượng linh kiện',
        traceability_number: 'Số theo dõi',
        sub_key_type: 'Loại thành phần chính phụ',
        software: 'Phần mềm',
        hardware: 'Phần cứng',
        calibration_information: 'Thông tin hiệu chuẩn',
        supplier_name: 'Tên nhà cung cấp',
        pn: 'Mã PIN',
    },
    // 西班牙
    esES: {
        vin: 'VIN',
        productionTimeList: 'Lista de Tiempo de Producción',
        vehicle_configuration_list: 'Lista de Configuración del Vehículo',
        accurately_traceable_parts_list: 'Lista de Piezas con Trazabilidad Exacta',
        order_number: 'Número de Orden',
        order_type: 'Tipo de Orden',
        platform: 'Plataforma',
        model_code: 'Código de Modelo de Vehículo',
        date_of_production: 'Fecha de Producción',
        certificate_of_conformity: 'Número de Certificado de Calidad',
        certificate_printing_time: 'Fecha de Impresión del Certificado de Calidad',
        vehicle_material_number: 'Número de Material del Vehículo',
        description: 'Descripción',
        time: 'Tiempo',
        feature_ID: 'ID de Característica',
        feature_description: 'Descripción de Característica',
        feature_code: 'Código de Característica',
        eigenvalue_description: 'Descripción de Valor de Característica',
        chinese_description: 'Descripción en Chino',
        supplier: 'Proveedor',
        bar_code_number: 'Número de Código de Barras',
        scanning_time: 'Fecha de Escaneo',
        key_component_type_code: 'Código de Tipo de Pieza Clave',
        part_number: 'Número de Pieza',
        traceability_number: 'Número de Rastreo',
        sub_key_type: 'Subtipo de Pieza Clave',
        software: 'Software',
        hardware: 'Hardware',
        calibration_information: 'Información de Calibración',
        supplier_name: 'Número de Proveedor',
        pn: 'Código PIN',
    },
    // 葡萄牙
    ptBR: {
        vin: 'VIN',
        productionTimeList: 'Lista de tempo de produção',
        vehicle_configuration_list: 'Lista de configuração do veículo',
        accurately_traceable_parts_list: 'Lista de peças rastreáveis com precisão',
        order_number: 'Número do pedido',
        order_type: 'Tipo de pedido',
        platform: 'Plataforma',
        model_code: 'Código do modelo',
        date_of_production: 'Data de produção',
        certificate_of_conformity: 'Certificado de conformidade',
        certificate_printing_time: 'Tempo de impressão do certificado',
        vehicle_material_number: 'Número de material do veículo',
        description: 'Descrição',
        time: 'Tempo',
        feature_ID: 'ID da característica',
        feature_description: 'Descrição da característica',
        feature_code: 'Código da característica',
        eigenvalue_description: 'Descrição do valor característico',
        chinese_description: 'Descrição em chinês',
        supplier: 'Fornecedor',
        bar_code_number: 'Número de código de barras',
        scanning_time: 'Tempo de digitalização',
        key_component_type_code: 'Código do tipo de componente chave',
        part_number: 'Número da peça',
        traceability_number: 'Número de rastreabilidade',
        sub_key_type: 'Subtipo de componente chave',
        software: 'Software',
        hardware: 'Hardware',
        calibration_information: 'Informação de calibração',
        supplier_name: 'Nome do fornecedor',
        pn: 'Código PIN',
    },
    // 波兰
    plPL: {
        vin: 'VIN',
        productionTimeList: 'Lista czasu produkcji',
        vehicle_configuration_list: 'Lista konfiguracji pojazdu',
        accurately_traceable_parts_list: 'Identyfikowalna lista części',
        order_number: 'Zamówienie #',
        order_type: 'Typ zamówienia',
        platform: 'Platforma',
        model_code: 'Kod modelu',
        date_of_production: 'Data produkcji',
        certificate_of_conformity: 'COC #',
        certificate_printing_time: 'Data druku COC',
        vehicle_material_number: 'Kod materiałowy pojazdu',
        description: 'Opis',
        time: 'Czas',
        feature_ID: 'Identyfikator właściwości',
        feature_description: 'Opis właściwości',
        feature_code: 'Kod właściwości',
        eigenvalue_description: 'Opis właściwości',
        chinese_description: 'Opis chiński',
        supplier: 'Dostawca',
        bar_code_number: 'Numer kodu kreskowego',
        scanning_time: 'Data skanowania',
        key_component_type_code: 'Kod typu kluczowego komponentu',
        part_number: 'Numer części',
        traceability_number: 'Numer identyfikacyjny',
        sub_key_type: 'Typ klucza podrzędnego',
        software: 'Oprogramowanie',
        hardware: 'Sprzęt',
        calibration_information: 'Informacjie o kalibracji',
        supplier_name: 'Nazwa dostawcy',
        pn: 'Kod PIN',
    },
    // 土耳其
    trTR: {
        vin: 'VIN',
        productionTimeList: 'Üretim Tarih Listesi',
        vehicle_configuration_list: 'Araç  donanım listesi',
        accurately_traceable_parts_list: 'İzlenebilen parçalar listesi',
        order_number: 'Sipariş',
        order_type: 'Sipariş tipi',
        platform: 'Platform',
        model_code: 'Model kodu',
        date_of_production: 'Üretim tarihi',
        certificate_of_conformity: 'COC #',
        certificate_printing_time: 'COC yazdırma zamanı',
        vehicle_material_number: 'Araç malzemel kodu',
        description: 'Açıklama',
        time: 'Zaman',
        feature_ID: 'Özellik Kimliği',
        feature_description: 'Özellik  açıklaması',
        feature_code: 'Özellik kodu',
        eigenvalue_description: 'Özellik tanıtımı',
        chinese_description: 'Çin\'ce açıklama',
        supplier: 'Sağlayıcı',
        bar_code_number: 'Bar kodu numarası',
        scanning_time: 'Tarama zamanı',
        key_component_type_code: 'Anahtar komponent tipi kodu',
        part_number: 'Bölüm Numarası',
        traceability_number: 'İzlenebileceği Numarası',
        sub_key_type: 'Alt Anahtar Türü',
        software: 'Yazılım',
        hardware: 'Hardware',
        calibration_information: 'Kalibrasyon bilgisi',
        supplier_name: 'Sağlayıcı ismi',
        pn: 'PIN kodu',
    },
    // 捷克语
    csCZ: {
        vin: 'VIN',
        productionTimeList: 'Seznam dat výroby',
        vehicle_configuration_list: 'Seznam konfigurace vozidla',
        accurately_traceable_parts_list: 'Vysledovatelný seznam dílů',
        order_number: 'Objednat #',
        order_type: 'Typ objednávky',
        platform: 'Platforma',
        model_code: 'Kód modelu',
        date_of_production: 'Datum výroby',
        certificate_of_conformity: 'COC #',
        certificate_printing_time: 'Doba tisku COC',
        vehicle_material_number: 'Kód materiálu vozidla',
        description: 'Popis',
        time: 'Čas',
        feature_ID: 'ID funkce',
        feature_description: 'Popis funkce',
        feature_code: 'Kód funkce',
        eigenvalue_description: 'Popis funkce',
        chinese_description: 'Čínský popis',
        supplier: 'Dodavatel',
        bar_code_number: 'Číslo čárového kódu',
        scanning_time: 'Doba skenování',
        key_component_type_code: 'Typový kód klíčové součásti',
        part_number: 'Číslo dílu',
        traceability_number: 'Číslo sledovatelnosti',
        sub_key_type: 'Dílčí typ klíče',
        software: 'Software',
        hardware: 'Hardware',
        calibration_information: 'Informace o kalibraci',
        supplier_name: 'Jméno dodavatele',
        pn: 'PIN',
    },
    // 匈牙利语
    huHU: {
        vin: 'Alvázszám',
        productionTimeList: 'Gyártási dátumlista',
        vehicle_configuration_list: 'Járműkonfigurációs lista',
        accurately_traceable_parts_list: 'Nyomon követhető alkatrészek listája',
        order_number: 'Rendeléssz.',
        order_type: 'Rendeléstípus',
        platform: 'Platform',
        model_code: 'Modellkód',
        date_of_production: 'Gyártási dátum',
        certificate_of_conformity: 'Megfelelőségi nyilatkozat sz.',
        certificate_printing_time: 'Megfelelőségi nyilatkozat nyomtatási ideje',
        vehicle_material_number: 'Jármű anyagkód',
        description: 'Leírás',
        time: 'Idő',
        feature_ID: 'Funkcióazonosító',
        feature_description: 'Funkcióleírás',
        feature_code: 'Funkciókód',
        eigenvalue_description: 'Funkcióleírás',
        chinese_description: 'Kínai leírás',
        supplier: 'Beszállító',
        bar_code_number: 'Vonalkód száma',
        scanning_time: 'Beolvasás ideje',
        key_component_type_code: 'Fődarab típuskódja',
        part_number: 'Alkatrészszám',
        traceability_number: 'Nyomkövetési szám',
        sub_key_type: 'Alegység típusa',
        software: 'Szoftver',
        hardware: 'Hardver',
        calibration_information: 'Kalibrációs információ',
        supplier_name: 'Beszállító neve',
        pn: 'PIN-kód',
    },
    // 俄罗斯语
    ruRU: {
        vin: 'VIN',
        productionTimeList: 'Список дат производства',
        vehicle_configuration_list: 'Список конфигураций автомобиля',
        accurately_traceable_parts_list: 'Список отслеживаемых деталей',
        order_number: 'Номер заказа',
        order_type: 'Тип заказа',
        platform: 'Платформа',
        model_code: 'Код модели',
        date_of_production: 'Дата производства',
        certificate_of_conformity: 'Номер COC',
        certificate_printing_time: 'Время печати COC',
        vehicle_material_number: 'Код материала автомобиля',
        description: 'Описание',
        time: 'Время',
        feature_ID: 'ID функции',
        feature_description: 'Описание функции',
        feature_code: 'Код функции',
        eigenvalue_description: 'Описание функции',
        chinese_description: 'Описание на китайском',
        supplier: 'Поставщик',
        bar_code_number: 'Номер штрих-кода',
        scanning_time: 'Время сканирования',
        key_component_type_code: 'Код типа ключевого компонента',
        part_number: 'Номер детали',
        traceability_number: 'Номер отслеживания',
        sub_key_type: 'Подтип ключа',
        software: 'Программное обеспечение',
        hardware: 'Аппаратное обеспечение',
        calibration_information: 'Информация о калибровке',
        supplier_name: 'Имя поставщика',
        pn: 'PIN код',
    },
};

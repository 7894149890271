export default {
    // 中文
    zhCN: {
        part_number: '零件号',
        ECU_name: 'ECU名称(中文)',
        ECU_name_en: 'ECU名称(英文)',
        modelID: '模块ID',
    },
    // 英语
    enUS: {
        part_number: 'Part Number',
        ECU_name: 'ECU Name (Chinese)',
        ECU_name_en: 'ECU Name (English)',
        modelID: 'Module ID',
    },
    // 德语
    deDE: {
        part_number: 'Teilenummer',
        ECU_name: 'ECU Name (Chinesisch)',
        ECU_name_en: 'ECU Name (Englisch)',
        modelID: 'Modul-ID',
    },
    // 法语
    frFR: {
        part_number: 'Numéro de Pièce',
        ECU_name: 'Nom de l\'ECU (Chinois)',
        ECU_name_en: 'Nom de l\'ECU (Anglais)',
        modelID: 'ID du Module',
    },
    // 意大利语
    itIT: {
        part_number: 'Numero di Parte',
        ECU_name: 'Nome ECU (Cinese)',
        ECU_name_en: 'Nome ECU (Inglese)',
        modelID: 'ID Modulo',
    },
    // 荷兰语
    nlNL: {
        part_number: 'Onderdeelnummer',
        ECU_name: 'ECU Naam (Chinees)',
        ECU_name_en: 'ECU Naam (Engels)',
        modelID: 'Module ID',
    },
    // 越南语
    viVN: {
        part_number: 'Số Phần',
        ECU_name: 'Tên ECU (Tiếng Trung)',
        ECU_name_en: 'Tên ECU (Tiếng Anh)',
        modelID: 'ID Mô-đun',
    },
    // 西班牙语
    esES: {
        part_number: 'Número de Parte',
        ECU_name: 'Nombre del ECU (Chino)',
        ECU_name_en: 'Nombre del ECU (Inglés)',
        modelID: 'ID del Módulo',
    },
    // 葡萄牙语
    ptBR: {
        part_number: 'Número da Peça',
        ECU_name: 'Nome do ECU (Chinês)',
        ECU_name_en: 'Nome do ECU (Inglês)',
        modelID: 'ID do Módulo',
    },
    // 波兰语
    plPL: {
        part_number: 'Numer Części',
        ECU_name: 'Nazwa ECU (Chiński)',
        ECU_name_en: 'Nazwa ECU (Angielski)',
        modelID: 'ID Modułu',
    },
    // 土耳其语
    trTR: {
        part_number: 'Parça Numarası',
        ECU_name: 'ECU Adı (Çince)',
        ECU_name_en: 'ECU Adı (İngilizce)',
        modelID: 'Modül Kimliği',
    },
    // 捷克语
    csCZ: {
        part_number: 'Číslo dílu',
        ECU_name: 'Název ECU (čínsky)',
        ECU_name_en: 'Název ECU (anglicky)',
        modelID: 'ID modulu',
    },
    // 匈牙利语
    huHU: {
        part_number: 'Alkatrész szám',
        ECU_name: 'ECU neve (kínai)',
        ECU_name_en: 'ECU neve (angol)',
        modelID: 'Modul azonosító',
    },
    // 俄罗斯语
    ruRU: {
        part_number: 'Номер детали',
        ECU_name: 'Название ECU (китайский)',
        ECU_name_en: 'Название ECU (английский)',
        modelID: 'ID модуля',
    },
};

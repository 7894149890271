export default {
    // 中
    zhCN: {
        customs_declaration_unit: '报关单位',
        theme: '主题',
        model: '型号',
        problem_state: '问题状态',
        TAC_submit_date: 'TAC提交日期',
        level: '级别',
        type: '类型',
        vehicle_involved: '涉及活动',
        activity_code: '活动代码',
        activity_name: '活动名称',
        connected_activities: '关联活动',
        connected_activities_remarks: '关联活动说明',
        system_problems: '系统问题',
        quantity: '数量',
        engine_type: '发动机类型',
        gearbox_type: '变速箱类型',
        reporting_country: '上报国家',
        region: '上报地区',
        date_of_feedback_to_QA: '反馈到QA日期',
        required_part_return_date: '要求零件返回日期',
        date_of_parts_delivery_to_QA: '零件交付到QA日期',
        solution_release_date: '解决方案发布日期',
        solution_target_date: '解决方案目标日期',
        end_date: '结束日期',
        difference: '差异',
        QA_weekday: 'QA工作日',
        fault_description: '故障描述',
        root_cause: '根本原因',
        production_line_measures_Breakpoint: '产线措施/断点',
        breakpoint: '售后措施/断点',
        process: '最新进展',
        technical_document_attachments: '技术文档附件',
        connected_activities_required: '请选择关联活动',
        fault_desc_placeholder:
            'a、道路情况、驾驶工况、客户操作、仪表提示、车辆表现 \n' +
            'b、检查过程、故障是否复现、互换及结果、车辆修复情况等',
        production_line_measures_Breakpoint_placeholder: '产线改进方案及产线/零件断点信息',
        breakpoint_placeholder: '售后维修方案，售后方案释放时间',
        process_placeholder:
            'a、案例上升情况 \n' +
            'b、零件回运情况 \n' +
            'c、原因分析计划 \n' +
            'd、解决措施 \n' +
            'e、活动开展情况 \n' +
            'f、案例关闭情况等',
        historical_information: '历史信息',
        is_intensive_monitoring: '是否重点跟踪',
        partOrSoftwareNum: '零件号/软件版本号',
        closeDate: '关闭时间',
        original_content: '修改前内容',
        modified_time: '修改时间',
        modified_by: '修改人',
    },
    // 英
    enUS: {
        customs_declaration_unit: 'Customs declaration unit',
        theme: 'Theme',
        model: 'Model',
        problem_state: 'Problem state',
        TAC_submit_date: 'TAC submit date',
        level: 'Level',
        type: 'Type',
        vehicle_involved: 'Involved in activities',
        activity_code: 'Activity code',
        activity_name: 'Activity name',
        connected_activities: 'Connected activities',
        connected_activities_remarks: 'Connected activities description',
        system_problems: 'System problems',
        quantity: 'Quantity',
        engine_type: 'Engine type',
        gearbox_type: 'Gearbox type',
        reporting_country: 'Reporting country',
        region: 'Reporting Region',
        date_of_feedback_to_QA: 'Date of feedback to QA',
        required_part_return_date: 'Required part return date',
        date_of_parts_delivery_to_QA: 'Date of select delivery to QA',
        solution_release_date: 'Solution release date',
        solution_target_date: 'Solution target date',
        end_date: 'End date',
        difference: 'Difference',
        QA_weekday: 'QA weekday',
        fault_description: 'Fault description',
        root_cause: 'Root Cause',
        production_line_measures_Breakpoint: 'Production Line Measures/Breakpoint',
        breakpoint: 'After-Sales Measures/Breakpoint',
        process: 'Latest progress',
        technical_document_attachments: 'Technical document attachments',
        connected_activities_required: 'Please select an associated activity',
        fault_desc_placeholder:
            'a. Road conditions, Driving conditions, Customer operations, Instrument prompts, Vehicle performance. \n' +
            'b. Inspection process, Reproduction of faults, Interchange and results, Vehicle repair situation, etc.',
        production_line_measures_Breakpoint_placeholder:
            'Production Line Improvement Plan && Production Line/Part Breakpoint Information',
        breakpoint_placeholder: 'After-Sales Maintenance Plan , After-Sales Plan Release Time',
        process_placeholder:
            'a、Case Escalation Status \n' +
            'b、Parts Return Status \n' +
            'c、Root Cause Analysis Plan \n' +
            'd、Solution Measures \n' +
            'e、Activity Progress \n' +
            'f、Case Closure Status',
        historical_information: 'Historical Information',
        is_intensive_monitoring: 'Is intensive monitoring',
        partOrSoftwareNum: 'Part Number/Software Version',
        closeDate: 'Closure Time',
        original_content: 'Original Content',
        modified_time: 'Modified Time',
        modified_by: 'Modified By',
    },
    // 德
    deDE: {
        customs_declaration_unit: 'Zolldeklarationseinheit',
        theme: 'Thema',
        model: 'Modell',
        problem_state: 'Problemzustand',
        TAC_submit_date: 'TAC-Einreichungsdatum',
        level: 'Ebene',
        type: 'Typ',
        vehicle_involved: 'An Tätigkeiten beteiligt',
        activity_code: 'Aktivitätscode',
        activity_name: 'Aktivitätsname',
        connected_activities: 'verbundene Aktivitäten',
        connected_activities_remarks: 'Beschreibung der verwandten Aktivität',
        system_problems: 'Systemprobleme',
        quantity: 'Menge',
        engine_type: 'Motortyp',
        gearbox_type: 'Getriebetyp',
        reporting_country: 'Berichterstattendes Land',
        region: 'Meldegebiet',
        date_of_feedback_to_QA: 'Datum der Rückmeldung an QA',
        required_part_return_date: 'Erforderliches Rücksendedatum des Teils',
        date_of_parts_delivery_to_QA: 'Datum der Auslieferung an QA',
        solution_release_date: 'Lösungsveröffentlichungsdatum',
        solution_target_date: 'Zieldatum für Lösung',
        end_date: 'Enddatum',
        difference: 'Unterschied',
        QA_weekday: 'QA-Wochentag',
        fault_description: 'Fehlerbeschreibung',
        root_cause: 'Grundursache',
        production_line_measures_Breakpoint:
            'Maßnahmen in der Produktionslinie/Unterbrechungspunkt',
        breakpoint: 'Maßnahmen nach dem Verkauf/Unterbrechungspunkt',
        process: 'Prozess',
        technical_document_attachments: 'Anlagen technischer Dokumente',
        connected_activities_required: 'Bitte wählen Sie eine zugehörige Aktivität aus',
        fault_desc_placeholder:
            'a. Straßenverhältnisse, Fahrbedingungen, Kundenbedienung, Instrumentenanzeigen, Fahrzeugleistung. \n' +
            'b. Prüfprozess, Wiederholung von Fehlern, Austausch und Ergebnisse, Fahrzeugreparaturstatus, etc.',
        production_line_measures_Breakpoint_placeholder:
            'Produktionslinienverbesserungsplan && Produktionslinien-/Teileunterbrechungsinformationen',
        breakpoint_placeholder:
            'Kundendienstwartungsplan,Zeitpunkt der Freigabe des Kundendienstplans',
        process_placeholder:
            'a、Fallsteigerungsstatus \n' +
            'b、Status der Teile-Rücksendung \n' +
            'c、Plan für die Ursachenanalyse \n' +
            'd、Lösungsmaßnahmen \n' +
            'e、Aktivitätsfortschritt \n' +
            'f、Fallabschlussstatus',
        historical_information: 'Historische Informationen',
        is_intensive_monitoring: 'Wird es genau überwacht',
        partOrSoftwareNum: 'Teilenummer/Softwareversion',
        closeDate: 'Abschlusszeit',
        original_content: 'Ursprünglicher Inhalt',
        modified_time: 'Änderungszeit',
        modified_by: 'Geändert von',
    },
    // 法
    frFR: {
        customs_declaration_unit: 'Unité de déclaration en douane',
        theme: 'Thème',
        model: 'Modèle',
        problem_state: 'État du problème',
        TAC_submit_date: 'Date de soumission TAC',
        level: 'Niveau',
        type: 'Type',
        vehicle_involved: 'Impliquant des activités',
        activity_code: 'Code d\'activité',
        activity_name: 'Nom de l\'activité',
        connected_activities: 'Activités associées',
        connected_activities_remarks: 'Description des activités associées',
        system_problems: 'Problèmes système',
        quantity: 'Quantité',
        engine_type: 'Type de moteur',
        gearbox_type: 'Type de boîte de vitesses',
        reporting_country: 'Pays déclarant',
        region: 'Région de déclaration',
        date_of_feedback_to_QA: 'Date de rétroaction à QA',
        required_part_return_date: 'Date de retour de pièce requise',
        date_of_parts_delivery_to_QA: 'Pièces livrées à la date QA',
        solution_release_date: 'Date de publication de la solution',
        solution_target_date: 'Date cible de la solution',
        end_date: 'Date de fin',
        difference: 'Différence',
        QA_weekday: 'Jour de la semaine QA',
        fault_description: 'Description de la faute',
        root_cause: 'Cause fondamentale',
        production_line_measures_Breakpoint: 'Mesures de la ligne de production/Point d\'arrêt',
        breakpoint: 'Mesures après-vente/Point d\'arrêt',
        process: 'Processus',
        technical_document_attachments: 'Pièces jointes de document technique',
        connected_activities_required: 'Veuillez sélectionner une activité associée',
        fault_desc_placeholder:
            'a. Conditions routières, Conditions de conduite, Opérations client, Indications du tableau de bord, Performance du véhicule. \n' +
            'b. Processus d\'inspection, Reproduction des pannes, Échange et résultats, État de réparation du véhicule, etc.',
        production_line_measures_Breakpoint_placeholder:
            'Plan d\'amélioration de la ligne de production && Informations sur le point de rupture de la ligne de production/partie',
        breakpoint_placeholder:
            'Plan de maintenance après-vente,Heure de libération du plan après-vente',
        process_placeholder:
            'a、État de l\'escalade des cas \n' +
            'b、État des retours de pièces \n' +
            'c、Plan d\'analyse des causes \n' +
            'd、Mesures de solution \n' +
            'e、Progrès des activités \n' +
            'f、État de clôture des cas',
        historical_information: 'Informations historiques',
        is_intensive_monitoring: 'Est-ce suivi de près',
        partOrSoftwareNum: 'Numéro de pièce/Version du logiciel',
        closeDate: 'Temps de fermeture',
        original_content: 'Contenu d\'origine',
        modified_time: 'Temps de modification',
        modified_by: 'Modifié par',
    },
    // 意大利
    itIT: {
        customs_declaration_unit: 'Unità dichiarazione doganale',
        theme: 'Tema',
        model: 'Modello',
        problem_state: 'Stato del problema',
        TAC_submit_date: 'Data di invio TAC',
        level: 'Livello',
        type: 'Tipo',
        vehicle_involved: 'Coinvolto in attività',
        activity_code: 'Codice attività',
        activity_name: 'Nome attività',
        connected_activities: 'Attività correlate',
        connected_activities_remarks: 'Descrizione delle attività correlate',
        system_problems: 'Problemi di sistema',
        quantity: 'Quantità',
        engine_type: 'Tipo di motore',
        gearbox_type: 'Tipo di cambio',
        reporting_country: 'Paese dichiarante',
        region: 'Regione di segnalazione',
        date_of_feedback_to_QA: 'Data di feedback a QA',
        required_part_return_date: 'Data di restituzione della parte richiesta',
        date_of_parts_delivery_to_QA: 'Data di selezione della consegna a QA',
        solution_release_date: 'Data di rilascio della soluzione',
        solution_target_date: 'Data prevista per la soluzione',
        end_date: 'Data di fine',
        difference: 'Differenza',
        QA_weekday: 'Giorno lavorativo QA',
        fault_description: 'Descrizione del difetto',
        root_cause: 'Causa radicale',
        production_line_measures_Breakpoint:
            'Misure della linea di produzione/Punto di interruzione',
        breakpoint: 'Misure post-vendita/Punto di interruzione',
        process: 'Processo',
        technical_document_attachments: 'Allegati documento tecnico',
        connected_activities_required: 'Seleziona un\'attività associata',
        fault_desc_placeholder:
            'a. Condizioni stradali, Condizioni di guida, Operazioni del cliente, Promemoria strumenti, Prestazioni del veicolo. \n' +
            'b. Processo di ispezione, Riproduzione dei guasti, Scambio e risultati, Stato di riparazione del veicolo, ecc.',
        production_line_measures_Breakpoint_placeholder:
            'production_line_measures_Breakpoint_placeholder && Informazioni sul punto di interruzione della linea di produzione/parti',
        breakpoint_placeholder:
            'Piano di manutenzione post-vendita,Ora di rilascio del piano post-vendita',
        process_placeholder:
            'a、Stato dell\'escalation del caso \n' +
            'b、Stato del ritorno delle parti \n' +
            'c、Piano di analisi delle cause \n' +
            'd、Misure di soluzione \n' +
            'e、Progresso delle attività \n' +
            'f、Stato della chiusura del caso',
        historical_information: 'Informazioni storiche',
        is_intensive_monitoring: 'Viene monitorato attentamente',
        partOrSoftwareNum: 'Numero di parte/Versione del software',
        closeDate: 'Orario di chiusura',
        original_content: 'Contenuto originale',
        modified_time: 'Orario di modifica',
        modified_by: 'Modificato da',
    },
    // 荷兰
    nlNL: {
        customs_declaration_unit: 'Douane-aangifte-eenheid',
        theme: 'Thema',
        model: 'Model',
        problem_state: 'Probleemstatus',
        TAC_submit_date: 'TAC indieningsdatum',
        level: 'Niveau',
        type: 'Type',
        vehicle_involved: 'Betrokken bij activiteiten',
        activity_code: 'Activiteitencode',
        activity_name: 'Activiteitsnaam',
        connected_activities: 'Aanhangende activiteiten',
        connected_activities_remarks: 'Beschrijving verwante activiteit',
        system_problems: 'Systeemproblemen',
        quantity: 'Hoeveelheid',
        engine_type: 'Motortype',
        gearbox_type: 'Versnellingsbaktype',
        reporting_country: 'Rapporterend land',
        region: 'Rapporteringsregio',
        date_of_feedback_to_QA: 'Datum van feedback aan QA',
        required_part_return_date: 'Datum vereiste onderdeelretourzending',
        date_of_parts_delivery_to_QA: 'Datum van levering van onderdelen aan QA',
        solution_release_date: 'Oplossing vrijgavedatum',
        solution_target_date: 'Oplossingsdoelstelling datum',
        end_date: 'Einddatum',
        difference: 'Verschil',
        QA_weekday: 'QA weekdag',
        fault_description: 'Foutomschrijving',
        root_cause: 'Kernoorzaak',
        production_line_measures_Breakpoint: 'Maatregelen in de productielijn/Breekpunt',
        breakpoint: 'Maatregelen na verkoop/Breekpunt',
        process: 'Proces',
        technical_document_attachments: 'Technische documentbijlagen',
        connected_activities_required: 'Selecteer een bijbehorende activiteit',
        fault_desc_placeholder:
            'a. Wegomstandigheden, Rijomstandigheden, Klantbediening, Instrumentaanwijzingen, Voertuigprestaties. \n' +
            'b. Inspectieproces, Reproductie van fouten, Uitwisseling en resultaten, Voertuigherstelsituatie, etc.',
        production_line_measures_Breakpoint_placeholder:
            'Productielijnverbeteringsplan && Productielijn-/Onderdeelonderbrekingsinformatie',
        breakpoint_placeholder: 'Nazorg Onderhoudsplan,Vrijgavetijd van nazorgplan',
        process_placeholder:
            'a、Status van de casusescalatie \n' +
            'b、Status van onderdelenretour \n' +
            'c、Plan voor root cause-analyse \n' +
            'd、Oplossingsmaatregelen \n' +
            'e、Voortgang van activiteiten \n' +
            'f、Status van de casusafsluiting',
        historical_information: 'Historische informatie',
        is_intensive_monitoring: 'Wordt het nauwlettend gevolgd',
        partOrSoftwareNum: 'Onderdeelnummer/Softwareversie',
        closeDate: 'Sluitingstijd',
        original_content: 'Oorspronkelijke inhoud',
        modified_time: 'Wijzigingstijd',
        modified_by: 'Gewijzigd door',
    },
    // 越南
    viVN: {
        customs_declaration_unit: 'Đơn vị khai báo hải quan',
        theme: 'Chủ đề',
        model: 'Mô hình',
        problem_state: 'Trạng thái vấn đề',
        TAC_submit_date: 'Ngày nộp TAC',
        level: 'Cấp độ',
        type: 'Loại',
        vehicle_involved: 'Hoạt động liên quan',
        activity_code: 'Mã hoạt động',
        activity_name: 'Tên hoạt động',
        connected_activities: 'Hoạt động liên quan',
        connected_activities_remarks: 'Mô tả hoạt động liên kết',
        system_problems: 'Vấn đề hệ thống',
        quantity: 'Số lượng',
        engine_type: 'Loại động cơ',
        gearbox_type: 'Loại hộp số',
        reporting_country: 'Quốc gia báo cáo',
        region: 'Vùng báo cáo',
        date_of_feedback_to_QA: 'Ngày phản hồi cho QA',
        required_part_return_date: 'Ngày yêu cầu trả linh kiện',
        date_of_parts_delivery_to_QA: 'Ngày giao linh kiện cho QA',
        solution_release_date: 'Ngày phát hành giải pháp',
        solution_target_date: 'Ngày mục tiêu giải pháp',
        end_date: 'Ngày kết thúc',
        difference: 'Sự khác biệt',
        QA_weekday: 'Ngày làm việc của QA',
        fault_description: 'Miêu tả lỗi',
        root_cause: 'Nguyên nhân cơ bản',
        production_line_measures_Breakpoint: 'Biện pháp trên dây chuyền sản xuất/Điểm ngắt',
        breakpoint: 'Biện pháp sau bán hàng/Điểm ngắt',
        process: 'Tiến trình mới nhất',
        technical_document_attachments: 'Tài liệu kỹ thuật đính kèm',
        connected_activities_required: 'Vui lòng chọn hoạt động liên kết',
        fault_desc_placeholder:
            'a. Điều kiện đường, Điều kiện lái xe, Thao tác của khách hàng, Hướng dẫn bảng đồng hồ, Hiệu suất của xe. \n' +
            'b. Quy trình kiểm tra, Tái tạo lỗi, Trao đổi và kết quả, Tình trạng sửa chữa xe, vv.',
        production_line_measures_Breakpoint_placeholder:
            'Kế hoạch cải thiện dây chuyền sản xuất && Thông tin về điểm ngắt dây chuyền sản xuất/Phần',
        breakpoint_placeholder:
            'Kế hoạch bảo dưỡng sau bán hàng,Thời gian phát hành kế hoạch sau bán hàng',
        process_placeholder:
            'a、Tình trạng nâng cao vụ án \n' +
            'b、Tình trạng trả lại linh kiện \n' +
            'c、Kế hoạch phân tích nguyên nhân \n' +
            'd、Biện pháp giải quyết \n' +
            'e、Tiến triển của hoạt động \n' +
            'f、Tình trạng đóng vụ án',
        historical_information: 'Thông tin lịch sử',
        is_intensive_monitoring: 'Có đang được theo dõi chặt chẽ không',
        partOrSoftwareNum: 'Số phần/Phiên bản phần mềm',
        closeDate: 'Thời gian đóng cửa',
        original_content: 'Nội dung gốc',
        modified_time: 'Thời gian sửa đổi',
        modified_by: 'Người sửa đổi',
    },
    // 西班牙
    esES: {
        customs_declaration_unit: 'Agente de despacho de aduana',
        theme: 'Tema',
        model: 'Modelo',
        problem_state: 'Estado del Problema',
        TAC_submit_date: 'Fecha de envío a TAC',
        level: 'Nivel',
        type: 'Tipo',
        vehicle_involved: 'Actividades relacionadas',
        activity_code: 'Código de actividad',
        activity_name: 'Nombre de actividad',
        connected_activities: 'Actividad relacionada',
        connected_activities_remarks: 'Descripción de las actividades relacionadas',
        system_problems: 'Problema de sistema',
        quantity: 'Cantidad',
        engine_type: 'Tipo de motor',
        gearbox_type: 'Tipo de caja de cambios',
        reporting_country: 'País informante',
        region: 'Región de informe',
        date_of_feedback_to_QA: 'Fecha de retroalimentación a QA',
        required_part_return_date: 'Fecha de devolución de piezas requeridas',
        date_of_parts_delivery_to_QA: 'Fecha de entrega de piezas a QA',
        solution_release_date: 'Fecha de publicación de la solución',
        solution_target_date: 'Fecha objetivo de la solución',
        end_date: 'Fecha de finalización',
        difference: 'Diferencia',
        QA_weekday: 'Días laborables de QA',
        fault_description: 'Descripción de la falla',
        root_cause: 'Causa raíz',
        production_line_measures_Breakpoint:
            'Medidas en la línea de producción/Punto de interrupción',
        breakpoint: 'Medidas postventa/Punto de interrupción',
        process: 'Últimos avances',
        technical_document_attachments: 'Adjunto de documento técnico',
        connected_activities_required: 'Por favor, seleccione la actividad asociada',
        fault_desc_placeholder:
            'a. Condiciones de la carretera, Condiciones de conducción, Operaciones del cliente, Indicaciones del panel de instrumentos, Rendimiento del vehículo. \n' +
            'b. Proceso de inspección, Reproducción de fallas, Intercambio y resultados, Estado de reparación del vehículo, etc.',
        production_line_measures_Breakpoint_placeholder:
            'Plan de mejora de la línea de producción &&  Información de punto de ruptura de línea de producción/parte',
        breakpoint_placeholder:
            'Plan de mantenimiento postventa,Hora de liberación del plan postventa',
        process_placeholder:
            'a、Estado de escalada del caso \n' +
            'b、Estado de devolución de piezas \n' +
            'c、Plan de análisis de causas \n' +
            'd、Medidas de solución \n' +
            'e、Progreso de actividades \n' +
            'f、Estado de cierre del caso',
        historical_information: 'Información histórica',
        is_intensive_monitoring: '¿Se está siguiendo de cerca',
        partOrSoftwareNum: 'Número de pieza/Versión de software',
        closeDate: 'Hora de cierre',
        original_content: 'Contenido original',
        modified_time: 'Hora de modificación',
        modified_by: 'Modificado por',
    },
    // 葡萄牙
    ptBR: {
        customs_declaration_unit: 'Unidade de declaração aduaneira',
        theme: 'Tema',
        model: 'Modelo',
        problem_state: 'Estado do problema',
        TAC_submit_date: 'Data de envio do TAC',
        level: 'Nível',
        type: 'Tipo',
        vehicle_involved: 'Atividades envolvidas',
        activity_code: 'Código de atividade',
        activity_name: 'Nome da atividade',
        connected_activities: 'Atividades conectadas',
        connected_activities_remarks: 'Observações das atividades conectadas',
        system_problems: 'Problemas do sistema',
        quantity: 'Quantidade',
        engine_type: 'Tipo de motor',
        gearbox_type: 'Tipo de caixa de câmbio',
        reporting_country: 'País de relato',
        region: 'Região de relatório',
        date_of_feedback_to_QA: 'Data de feedback para QA',
        required_part_return_date: 'Data de devolução da peça requerida',
        date_of_parts_delivery_to_QA: 'Data de entrega das peças para QA',
        solution_release_date: 'Data de lançamento da solução',
        solution_target_date: 'Data de conclusão desejada da solução',
        end_date: 'Data de término',
        difference: 'Diferença',
        QA_weekday: 'Dias úteis do QA',
        fault_description: 'Descrição da falha',
        root_cause: 'Causa raiz',
        production_line_measures_Breakpoint: 'Medidas na linha de produção/Ponto de interrupção',
        breakpoint: 'Medidas pós-venda/Ponto de interrupção',
        process: 'Últimas etapas',
        technical_document_attachments: 'Anexos de documentos técnicos',
        connected_activities_required: 'Por favor, selecione atividades conectadas',
        fault_desc_placeholder:
            'a. Condições da estrada, Condições de condução, Operações do cliente, Indicações do painel de instrumentos, Desempenho do veículo. \n' +
            'b. Processo de inspeção, Reprodução de falhas, Troca e resultados, Estado de reparo do veículo, etc.',
        production_line_measures_Breakpoint_placeholder:
            'Plano de Melhoria da Linha de Produção && Informações de ponto de interrupção da linha de produção/peça',
        breakpoint_placeholder:
            'Plano de Manutenção Pós-Venda,Hora de liberação do plano pós-venda',
        process_placeholder:
            'a、Estado de escalonamento de casos \n' +
            'b、Estado de retorno de peças \n' +
            'c、Plano de análise de causas \n' +
            'd、Medidas de solução \n' +
            'e、Progresso das atividades \n' +
            'f、Estado de encerramento do caso',
        historical_information: 'Informações históricas',
        is_intensive_monitoring: 'Está sendo monitorado de perto',
        partOrSoftwareNum: 'Número da peça/Versão do software',
        closeDate: 'Hora de fechamento',
        original_content: 'Conteúdo original',
        modified_time: 'Tempo de modificação',
        modified_by: 'Modificado por',
    },
    // 波兰
    plPL: {
        customs_declaration_unit: 'Jednostka zgłoszenia celnego',
        theme: 'Temat',
        model: 'Model',
        problem_state: 'Status sprawy',
        TAC_submit_date: 'Data zgłoszenia',
        level: 'Poziom',
        type: 'Rodzaj',
        vehicle_involved: 'Zaangażowany w działania',
        activity_code: 'Kod działania',
        activity_name: 'Nazwa działania',
        connected_activities: 'Działania powiązane',
        connected_activities_remarks: 'Uwagi dotyczące powiązanych aktywności',
        system_problems: 'Błąd systemu',
        quantity: 'Ilość',
        engine_type: 'Typ silnika',
        gearbox_type: 'Typ skrzyni biegów',
        reporting_country: 'Kraj raportujący',
        region: 'Region raportowania',
        date_of_feedback_to_QA: 'Data przekazania informacji zwrotnej',
        required_part_return_date: 'Data zwrotu potrzebnej częsci',
        date_of_parts_delivery_to_QA: 'Data dostarczenia części',
        solution_release_date: 'Data wydania rozwiązania',
        solution_target_date: 'Docelowa data rozwiązania sprawy',
        end_date: 'Data zakończenia',
        difference: 'Różnica',
        QA_weekday: 'Dni pracy',
        fault_description: 'Opis błędu',
        root_cause: 'Przyczyna podstawowa',
        production_line_measures_Breakpoint: 'Środki na linii produkcyjnej/Punkt przerwania',
        breakpoint: 'Środki posprzedażowe/Punkt przerwania',
        process: 'Postęp',
        technical_document_attachments: 'Załączone dokumenty techniczne',
        connected_activities_required: 'Proszę wybrać powiązane aktywności',
        fault_desc_placeholder:
            'a. Warunki drogowe, Warunki jazdy, Obsługa klienta, Wskazówki deski rozdzielczej, Wydajność pojazdu. \n' +
            'b. Proces inspekcji, Reprodukcja usterek, Wymiana i wyniki, Stan naprawy pojazdu, itp.',
        production_line_measures_Breakpoint_placeholder:
            'Plan poprawy linii produkcyjnej && Informacje o punkcie przerwania linii produkcyjnej/części',
        breakpoint_placeholder:
            'Plan konserwacji posprzedażowej,Czas wydania planu posprzedażowego',
        process_placeholder:
            'a、Status eskalacji przypadku \n' +
            'b、Status zwrotu części \n' +
            'c、Plan analizy przyczyn \n' +
            'd、Środki rozwiązania \n' +
            'e、Postęp w działaniach \n' +
            'f、Status zamknięcia przypadku',
        historical_information: 'Informacje historyczne',
        is_intensive_monitoring: 'Czy jest śledzone dokładnie',
        partOrSoftwareNum: 'Numer części/Wersja oprogramowania',
        closeDate: 'Czas zamknięcia',
        original_content: 'Oryginalna zawartość',
        modified_time: 'Czas modyfikacji',
        modified_by: 'Zmodyfikowane przez',
    },
    // 土耳其
    trTR: {
        customs_declaration_unit: 'Özel deklarasyon birimi',
        theme: 'Tema',
        model: 'Model',
        problem_state: 'Sorun durumu',
        TAC_submit_date: 'TAC dağıt',
        level: 'Seviye',
        type: 'Türü',
        vehicle_involved: 'Etkinliğe dahil edildi',
        activity_code: 'Etkinlik kodu',
        activity_name: 'Etkinlik adı',
        connected_activities: 'Bağlanmış etkinlikler',
        connected_activities_remarks: 'Bağlantı etkinlik açıklayın',
        system_problems: 'Sistem sorunları',
        quantity: 'Adet',
        engine_type: 'Motor tipi',
        gearbox_type: 'Gearbox tipi',
        reporting_country: 'Bildiren ülke',
        region: 'Bildirilen Bölge',
        date_of_feedback_to_QA: 'QA\'ye geri dönüş tarihi',
        required_part_return_date: 'Gerekli parça dönüş tarihi',
        date_of_parts_delivery_to_QA: 'QA\'ye teslim edilmesi tarihic',
        solution_release_date: 'Çözümün yayınlama tarihi',
        solution_target_date: 'Çözüm hedef tarihi',
        end_date: 'Bitiş tarihi',
        difference: 'Farklılık',
        QA_weekday: 'QA çalışma günü',
        fault_description: 'Hata tanımı',
        root_cause: 'Temel Neden',
        production_line_measures_Breakpoint: 'Üretim hattındaki önlemler/Kesme noktası',
        breakpoint: 'Satış Sonrası Önlemler/Kesme Noktası',
        process: 'İşlem',
        technical_document_attachments: 'Tehnik belge ekleri',
        connected_activities_required: 'seçenek İlişkili etkinlikler',
        fault_desc_placeholder:
            'a. Yol koşulları, Sürüş koşulları, Müşteri operasyonları, Gösterge tablosu uyarıları, Araç performansı. \n' +
            'b. İnceleme süreci, Arızaların tekrarı, Değişim ve sonuçlar, Araç tamir durumu, vb.',
        production_line_measures_Breakpoint_placeholder:
            'Üretim Hattı İyileştirme Planı && Üretim Hattı/Parça Kesme Noktası Bilgisi',
        breakpoint_placeholder:
            'Satış Sonrası Bakım Planı,Satış sonrası planın serbest bırakılma zamanı',
        process_placeholder:
            'a、Vaka Yükseltme Durumu \n' +
            'b、Parça İade Durumu \n' +
            'c、Kök Neden Analizi Planı \n' +
            'd、Çözüm Tedbirleri \n' +
            'e、Faaliyet İlerleme Durumu \n' +
            'f、Vaka Kapanış Durumu',
        historical_information: 'Tarihsel Bilgiler',
        is_intensive_monitoring: 'Yakından izleniyor mu',
        partOrSoftwareNum: 'Parça Numarası/Yazılım Sürümü',
        closeDate: 'Kapanış Zamanı',
        original_content: 'Orijinal İçerik',
        modified_time: 'Değiştirme Zamanı',
        modified_by: 'Tarafından değiştirildi',
    },
    // 捷克语
    csCZ: {
        customs_declaration_unit: 'Celní deklarační jednotka',
        theme: 'Téma',
        model: 'Model',
        problem_state: 'Stav problému',
        TAC_submit_date: 'Datum odeslání TAC',
        level: 'Úroveň',
        type: 'Typ',
        vehicle_involved: 'Podílí se na aktivitách',
        activity_code: 'Kód aktivity',
        activity_name: 'Název aktivity',
        connected_activities: 'Související aktivity',
        connected_activities_remarks: 'Popis souvisejících aktivit',
        system_problems: 'Systémové problémy',
        quantity: 'Množství',
        engine_type: 'Typ motoru',
        gearbox_type: 'Typ převodovky',
        reporting_country: 'Oznamující země',
        region: 'Oblast hlášení',
        date_of_feedback_to_QA: 'Datum zpětné vazby na QA',
        required_part_return_date: 'Požadované datum vrácení dílu',
        date_of_parts_delivery_to_QA: 'Datum vybraného doručení do QA',
        solution_release_date: 'Datum vydání řešení',
        solution_target_date: 'Cílové datum řešení',
        end_date: 'Datum ukončení',
        difference: 'Rozdíl',
        QA_weekday: 'Dotazy/odpovědi všední den',
        fault_description: 'Popis závady',
        root_cause: 'Příčina',
        production_line_measures_Breakpoint: 'Opatření na výrobní lince /Breakpoint',
        breakpoint: 'Poprodejní opatření /Breakpoint',
        process: 'Poslední pokrok',
        technical_document_attachments: 'Přílohy technických dokumentů',
        connected_activities_required: 'Vyberte přidruženou aktivitu',
        fault_desc_placeholder:
            '„a. Stav vozovky, Jízdní podmínky, jízdní styl zákazníka, informace na přístroji, Výkon vozidla. \nb. Postup kontroly, Reprodukce závad, Záměny a výsledky, Stav opravy vozidla atd..',
        production_line_measures_Breakpoint_placeholder:
            'Production Line Improvement Plan && Production Line/Part Breakpoint Information',
        breakpoint_placeholder: 'After-Sales Maintenance Plan , After-Sales Plan Release Time',
        process_placeholder:
            'a、Stav eskalace případu \n' +
            'b、Stav vrácení dílů \n' +
            'c、Plán analýzy hlavních příčin \n' +
            'd、Opatření při řešení \n' +
            'e、Pokrok v činnosti \n' +
            'f、Stav uzavření případu',
        historical_information: 'historické informace',
        is_intensive_monitoring: 'Je intenzivně sledováno',
        partOrSoftwareNum: 'Číslo dílu/verze softwaru',
        closeDate: 'Čas uzavření',
        original_content: 'Původní obsah',
        modified_time: 'Upravený čas',
        modified_by: 'Upraveno',
    },
    // 匈牙利语
    huHU: {
        customs_declaration_unit: 'Vámkezelési egység',
        theme: 'Téma',
        model: 'Modell',
        problem_state: 'Probléma állapota',
        TAC_submit_date: 'TAC-beküldés dátuma',
        level: 'Szint',
        type: 'Típus',
        vehicle_involved: 'Részt vesz tevékenységekben',
        activity_code: 'Tevékenységkód',
        activity_name: 'Tevékenységnév',
        connected_activities: 'Kapcsolódó tevékenységek',
        connected_activities_remarks: 'Kapcsolódó tevékenységek leírása',
        system_problems: 'Rendszerproblémák',
        quantity: 'Mennyiség',
        engine_type: 'Motor típusa',
        gearbox_type: 'Váltómű típusa',
        reporting_country: 'Jelentő ország',
        region: 'Jelentő régió',
        date_of_feedback_to_QA: 'Visszajelzés dátuma MB felé',
        required_part_return_date: 'Szükséges alkatrész-visszaküldés dátuma',
        date_of_parts_delivery_to_QA: 'MB felé kiválasztott szállítmány dátuma',
        solution_release_date: 'Megoldás kiadási dátuma',
        solution_target_date: 'Megoldás céldátuma',
        end_date: 'Befejező dátum',
        difference: 'Eltérés',
        QA_weekday: 'MB hétköznap',
        fault_description: 'Hibaleírás',
        root_cause: 'Kiváltó ok',
        production_line_measures_Breakpoint: 'Gyártósori intézkedések/meghibásodás',
        breakpoint: 'Ügyfélszolgálati intézkedések/meghibásodás',
        process: 'Legutóbbi előrelépések',
        technical_document_attachments: 'Műszaki dokumentum mellékletek',
        connected_activities_required: 'Válasszon társított tevékenységet',
        fault_desc_placeholder:
            'a. Útviszonyok, menetkörülmények, ügyfél általi kezelés, műszerek jelzései, járműteljesítmény. \n' +
            'b. Átvizsgálási folyamat, hibák reprodukálása, csere és eredmények, járműjavítás helyzete stb.',
        production_line_measures_Breakpoint_placeholder:
            'Gyártósor-fejlesztési terv és gyártósor/alkatrész meghibásodási adatok',
        breakpoint_placeholder:
            'Ügyfélszolgálati karbantartási terv, ügyfélszolgálati terv kiadási ideje',
        process_placeholder:
            'a. Eset eszkalációs állapota \n' +
            'b. Alkatrész-visszaküldések állapota \n' +
            'c. Kiváltó okok elemzési terve \n' +
            'd. Megoldási intézkedések \n' +
            'e. Tevékenység előrehaladta \n' +
            'f. Esetlezárási állapot',
        historical_information: 'Előzményadatok',
        is_intensive_monitoring: 'Intenzív felügyeletre szorul-e',
        partOrSoftwareNum: 'Alkatrészszám/szoftververzió',
        closeDate: 'Lezárás ideje',
        original_content: 'Eredeti tartalom',
        modified_time: 'Módosítás ideje',
        modified_by: 'Módosította',
    },
    // 俄罗斯语
    ruRU: {
        customs_declaration_unit: 'Таможенная декларация',
        theme: 'Тема',
        model: 'Модель',
        problem_state: 'Состояние проблемы',
        TAC_submit_date: 'Дата подачи TAC',
        level: 'Уровень',
        type: 'Тип',
        vehicle_involved: 'Участвует в мероприятиях',
        activity_code: 'Код мероприятия',
        activity_name: 'Название мероприятия',
        connected_activities: 'Связанные мероприятия',
        connected_activities_remarks: 'Описание связанных мероприятий',
        system_problems: 'Проблемы системы',
        quantity: 'Количество',
        engine_type: 'Тип двигателя',
        gearbox_type: 'Тип трансмиссии',
        reporting_country: 'Страна отчета',
        region: 'Регион отчета',
        date_of_feedback_to_QA: 'Дата ответа QA',
        required_part_return_date: 'Дата возврата необходимых частей',
        date_of_parts_delivery_to_QA: 'Дата выбора доставки в QA',
        solution_release_date: 'Дата выпуска решения',
        solution_target_date: 'Целевая дата решения',
        end_date: 'Дата окончания',
        difference: 'Разница',
        QA_weekday: 'QA неделя',
        fault_description: 'Описание неисправности',
        root_cause: 'Коренная причина',
        production_line_measures_Breakpoint: 'Меры на производственной линии/Перерыв',
        breakpoint: 'Меры после продаж/Перерыв',
        process: 'Последний прогресс',
        technical_document_attachments: 'Вложения технических документов',
        connected_activities_required: 'Пожалуйста, выберите связанное мероприятие',
        fault_desc_placeholder:
            'a. Дорожные условия, Условия вождения, Операции клиента, Подсказки приборной панели, Производительность автомобиля и т.д. \n' +
            'b. Процесс инспекции, Воспроизведение неисправностей, Замена и результаты, Ситуация с ремонтом автомобиля и т.д.',
        production_line_measures_Breakpoint_placeholder:
            'План улучшения производственной линии && Информация о прерывании производственной линии/части',
        breakpoint_placeholder:
            'План обслуживания после продаж, Время выпуска плана обслуживания после продаж',
        process_placeholder:
            'a. Статус эскалации дела \n' +
            'b. Статус возврата частей \n' +
            'c. План анализа коренной причины \n' +
            'd. Меры по решению \ne. Прогресс мероприятия \n' +
            'f. Статус закрытия дела',
        historical_information: 'Историческая информация',
        is_intensive_monitoring: 'Является ли интенсивным мониторингом',
        partOrSoftwareNum: 'Номер детали/Версия программного обеспечения',
        closeDate: 'Время закрытия',
        original_content: 'Оригинальное содержание',
        modified_time: 'Время модификации',
        modified_by: 'Модифицировал',
    },
};

export default {
    // 中
    zhCN: {
        menu_maintenance: '菜单维护',
        role_name: '角色名称',
        role_key: '角色代码',
        role_id: '显示顺序',
        status: '状态',
        create_date: '创建时间',
        role_sort: '排序',
        role_new: '角色新增',
        role_edit: '角色编辑',
        role_bleak: '菜单',
    },
    // 英
    enUS: {
        menu_maintenance: 'Menu',
        role_name: 'Role name',
        role_key: 'Role code',
        role_id: 'Display order',
        status: 'status',
        create_date: 'Create time',
        role_sort: 'Sort',
        role_new: 'New role',
        role_edit: 'Edit role',
        role_bleak: 'Bleak',
    },
    // 德
    deDE: {
        menu_maintenance: 'Menüpflege',
        role_name: 'Rollenname',
        role_key: 'Berechtigungszeichen',
        role_id: 'Anzeigereihenfolge',
        status: 'Status',
        create_date: 'Erstellungszeit',
        role_sort: 'Sortieren',
        role_new: 'Neue Rolle',
        role_edit: 'Rolle bearbeiten',
        role_bleak: 'Düster',
    },
    // 法
    frFR: {
        menu_maintenance: 'Maintenance du menu',
        role_name: 'Nom de rôle',
        role_key: 'Caractère d\'autorisation',
        role_id: 'Ordre d\'affichage',
        status: 'Statut',
        create_date: 'Heure de création',
        role_sort: 'Trier',
        role_new: 'Nouveau rôle',
        role_edit: 'Modifier le rôle',
        role_bleak: 'Sombre',
    },
    // 意大利
    itIT: {
        menu_maintenance: 'Menu',
        role_name: 'Nome del Ruolo',
        role_key: 'Codice del Ruolo',
        role_id: 'Ordine di Visualizzazione',
        status: 'Stato',
        create_date: 'Data di Creazione',
        role_sort: 'Ordinamento',
        role_new: 'Nuovo Ruolo',
        role_edit: 'Modifica Ruolo',
        role_bleak: 'Menu',
    },
    // 荷兰
    nlNL: {
        menu_maintenance: 'Menu',
        role_name: 'Rollen Naam',
        role_key: 'Rechten Karakter',
        role_id: 'Weergave volgorde',
        status: 'Status',
        create_date: 'Aanmaak datum',
        role_sort: 'Sorteren',
        role_new: 'Nieuwe Rol',
        role_edit: 'Rol bewerken',
        role_bleak: 'Leegmaken',
    },
    // 越南
    viVN: {
        menu_maintenance: 'Bảo trì menu',
        role_name: 'Tên vai trò',
        role_key: 'Mã vai trò',
        role_id: 'Thứ tự hiển thị',
        status: 'Trạng thái',
        create_date: 'Thời gian tạo',
        role_sort: 'Sắp xếp vai trò',
        role_new: 'Thêm mới vai trò',
        role_edit: 'Chỉnh sửa vai trò',
        role_bleak: 'Menu',
    },
    // 西班牙
    esES: {
        menu_maintenance: 'Mantenimiento de menús',
        role_name: 'Nombre del rol',
        role_key: 'Código del rol',
        role_id: 'Orden de visualización',
        status: 'Estado',
        create_date: 'Fecha de creación',
        role_sort: 'Ordenar',
        role_new: 'Añadir rol',
        role_edit: 'Editar rol',
        role_bleak: 'Menú',
    },
    // 葡萄牙
    ptBR: {
        menu_maintenance: 'Manutenção do menu',
        role_name: 'Nome do papel',
        role_key: 'Código do papel',
        role_id: 'Ordem de exibição',
        status: 'Status',
        create_date: 'Data de criação',
        role_sort: 'Ordenação do papel',
        role_new: 'Novo papel',
        role_edit: 'Editar papel',
        role_bleak: 'Menu',
    },
    // 波兰
    plPL: {
        menu_maintenance: 'Menu',
        role_name: 'Nazwa roli',
        role_key: 'Kod roli',
        role_id: 'Kolejność wyświetlania',
        status: 'Status',
        create_date: 'Czas założenia',
        role_sort: 'Sortowanie',
        role_new: 'Nowa rola',
        role_edit: 'Edytuj rolę',
        role_bleak: 'Menu',
    },
    // 土耳其
    trTR: {
        menu_maintenance: 'Menü koruması',
        role_name: 'Rol İsmi',
        role_key: 'Rol Kodu',
        role_id: 'Gösterim Sırası',
        status: 'Durum',
        create_date: 'Zaman Oluşturma',
        role_sort: 'Sırala',
        role_new: 'Yeni Rol',
        role_edit: 'Rol Düzenle',
        role_bleak: 'Menü',
    },
    // 捷克语
    csCZ: {
        menu_maintenance: 'Nabídka',
        role_name: 'Název role',
        role_key: 'Kód role',
        role_id: 'Zobrazení objednávky',
        status: 'status',
        create_date: 'Čas vytvoření',
        role_sort: 'Seřadit',
        role_new: 'Nová role',
        role_edit: 'Upravit roli',
        role_bleak: 'Nabídka',
    },
    // 匈牙利语
    huHU: {
        menu_maintenance: 'Menü',
        role_name: 'Szerepnév',
        role_key: 'Szerepkód',
        role_id: 'Megjelenítési sorrend',
        status: 'Állapot',
        create_date: 'Létrehozás ideje',
        role_sort: 'Rendezés',
        role_new: 'Új szerep',
        role_edit: 'Szerep módosítása',
        role_bleak: 'Üres',
    },
    // 俄罗斯语
    ruRU: {
        menu_maintenance: 'Управление меню',
        role_name: 'Название роли',
        role_key: 'Код роли',
        role_id: 'Порядок отображения',
        status: 'Статус',
        create_date: 'Дата создания',
        role_sort: 'Сортировка',
        role_new: 'Новая роль',
        role_edit: 'Редактирование роли',
        role_bleak: 'Меню',
    },
};

export default {
    // 中
    zhCN: {
        filter_information: '筛选器信息',
        filter_name: '筛选器名称',
        user_name: '用户名',
        creation_time: '创建时间',
        splicing_description: '拼接描述',
        enable_or_not: '是否启用',
        association_symbol: '关联符号',
        screening_condition: '筛选条件',
        conditional_value: '条件值',
        not_included: '不包含',
        and: '并且',
        perhaps: '或者',
        not: '否',
        enable: '是',
        judgement_logic: '判断逻辑',
        please_add_judgment_logic: '请添加判断逻辑',
        model_platform_is: '车型平台：',
        subject_of_the_case_is: '案例主题：',
        overseas_branches_are: '海外分公司：',
        overseas_subsidiaries_are: '海外子公司：',
        general_agent_are: '总代：',
        fault_symptom_is: '故障现象：',
        cause_of_the_fault_is: '故障原因：',
        vehicle_status_is: '车辆状态：',
        case_type_is: '案例类型：',
        case_status_is: '案例状态：',
        dealer: '经销商：',
    },
    // 英
    enUS: {
        filter_information: 'Filter information',
        filter_name: 'Filter name',
        user_name: 'User name',
        creation_time: 'Creation time',
        splicing_description: 'Splicing description',
        enable_or_not: 'Enabled or disabled',
        association_symbol: 'Relation symbol',
        screening_condition: 'Filtering condition',
        conditional_value: 'Filtering condition value',
        not_included: 'Not included',
        and: 'And',
        perhaps: 'Perhaps',
        not: 'Not',
        enable: 'Enable',
        judgement_logic: 'Judgement logic',
        relational_symbol: 'Relational symbol',
        filter_criteria: 'Filter criteria',
        please_add_judgment_logic: 'Please add judgment logic',
        model_platform_is: 'Platform：',
        subject_of_the_case_is: 'Case subject：',
        overseas_branches_are: 'Overseas company：',
        overseas_subsidiaries_are: 'Overseas subsidiaries are：',
        general_agent_are: 'Distributor：',
        fault_symptom_is: 'Fault phenomenon：',
        cause_of_the_fault_is: 'Fault cause：',
        vehicle_status_is: 'Vehicle status：',
        case_type_is: 'Case type：',
        case_status_is: 'Case status：',
        dealer: 'Dealer：',
    },
    // 德
    deDE: {
        filter_information: 'Filterinformationen',
        filter_name: 'Filtername',
        user_name: 'Benutzername',
        creation_time: 'Erstellungszeit',
        splicing_description: 'Verbindungsbeschreibung',
        enable_or_not: 'Aktiviert oder deaktiviert',
        association_symbol: 'Beziehungssymbol',
        screening_condition: 'Filterbedingung',
        conditional_value: 'Filterbedingungswert',
        not_included: 'Nicht enthalten',
        and: 'Und',
        perhaps: 'Vielleicht',
        not: 'Nicht',
        enable: 'Aktiveren',
        judgement_logic: 'Beurteilungslogik',
        relational_symbol: 'Relationssymbol',
        filter_criteria: 'Filterkriterien',
        please_add_judgment_logic: 'Bitte Beurteilungslogik hinzufügen',
        model_platform_is: 'Fahrzeugplattform：',
        subject_of_the_case_is: 'Groß- und Kleinschreibung：',
        overseas_branches_are: 'Tochtergesellschaft im Ausland：',
        overseas_subsidiaries_are: 'ausländische Tochtergesellschaft：',
        general_agent_are: 'Generalbevollmächtigter：',
        fault_symptom_is: 'Fehlerphänomen：',
        cause_of_the_fault_is: 'Fehlerursache：',
        vehicle_status_is: 'Fahrzeugstatus：',
        case_type_is: 'Fallart：',
        case_status_is: 'Fallstatus：',
        dealer: 'Händler：',
    },
    // 法
    frFR: {
        filter_information: 'Informations de filtre',
        filter_name: 'Nom du filtre',
        user_name: 'Nom d\'utilisateur',
        creation_time: 'Heure de création',
        splicing_description: 'Description',
        enable_or_not: 'Activé ou désactivé',
        association_symbol: 'Symbole de relation',
        screening_condition: 'Condition de filtrage',
        conditional_value: 'Valeur de la condition de filtrage ',
        not_included: 'Non inclus',
        and: 'Et',
        perhaps: 'Peut-être',
        not: 'Ne pas',
        enable: 'Activer',
        judgement_logic: 'Logique de jugement',
        relational_symbol: 'Symbole relationnel',
        filter_criteria: 'Critères de filtrage',
        please_add_judgment_logic: 'Veuillez ajouter une logique de jugement',
        model_platform_is: 'Plateforme：',
        subject_of_the_case_is: 'Sujet du cas：',
        overseas_branches_are: 'Succursales à l’étranger：',
        overseas_subsidiaries_are: 'Filiales à l’étranger：',
        general_agent_are: 'Distributeur：',
        fault_symptom_is: 'Phénomène de défaillance：',
        cause_of_the_fault_is: 'Cause de la panne：',
        vehicle_status_is: 'Status du véhicule：',
        case_type_is: 'Type de cas：',
        case_status_is: 'Status du cas：',
        dealer: 'Distributeurs：',
    },
    // 意大利
    itIT: {
        filter_information: 'Informazioni sul filtro',
        filter_name: 'Nome del filtro',
        user_name: 'Username',
        creation_time: 'Data di creazione',
        splicing_description: 'Descrizione di giunzione',
        enable_or_not: 'Abilitato o disabilitato',
        association_symbol: 'Simbolo di associazione',
        screening_condition: 'Condizione di filtraggio',
        conditional_value: 'Valore di condizionamento di filtraggio',
        not_included: 'Non incluso',
        and: 'E',
        perhaps: 'Forse',
        not: 'Non',
        enable: 'Abilitare',
        judgement_logic: 'Logica di giudizio',
        relational_symbol: 'Simbolo relazionale',
        filter_criteria: 'Criteri di filtro',
        please_add_judgment_logic: 'Si prega di aggiungere la logica di giudizio',
        model_platform_is: 'Piattaforma del veicolo：',
        subject_of_the_case_is: 'Tema case：',
        overseas_branches_are: 'filiale all\'estero：',
        overseas_subsidiaries_are: 'filiale estera：',
        general_agent_are: 'Agente generale：',
        fault_symptom_is: 'Fenomeno di guasto：',
        cause_of_the_fault_is: 'Causa del guasto：',
        vehicle_status_is: 'Stato del veicolo：',
        case_type_is: 'Tipo di caso：',
        case_status_is: 'Stato del caso：',
        dealer: 'Rivenditore：',
    },
    // 荷兰
    nlNL: {
        filter_information: 'Filterinformatie',
        filter_name: 'Filternaam',
        user_name: 'Gebruikersnaam',
        creation_time: 'Creatietijd',
        splicing_description: 'Samenstellingsbeschrijving',
        enable_or_not: 'Inschakelen of niet',
        association_symbol: 'Verbonden symbool',
        screening_condition: 'Filtervoorwaarde',
        conditional_value: 'Filtervoorwaarde waarde',
        not_included: 'Niet inbegrepen',
        and: 'En',
        perhaps: 'Misschien',
        not: 'Niet',
        enable: 'Inschakelen',
        judgement_logic: 'Oordeelslogica',
        relational_symbol: 'Relatie symbool',
        filter_criteria: 'Filtercriteria',
        please_add_judgment_logic: 'Voeg alstublieft oordeelslogica toe',
        model_platform_is: 'Voertuigplatform：',
        subject_of_the_case_is: 'Hoofdthema：',
        overseas_branches_are: 'buitenlandse dochteronderneming：',
        overseas_subsidiaries_are: 'buitenlandse dochteronderneming：',
        general_agent_are: 'Algemeen gemachtigde：',
        fault_symptom_is: 'Foutfenomeen：',
        cause_of_the_fault_is: 'Foutoorzaak：',
        vehicle_status_is: 'Voertuigstatus：',
        case_type_is: 'Zaaktype：',
        case_status_is: 'Zaakstatus：',
        dealer: 'Dealer：',
    },
    // 越南
    viVN: {
        filter_information: 'Thông tin bộ lọc',
        filter_name: 'Tên bộ lọc',
        user_name: 'Tên người dùng',
        creation_time: 'Thời gian tạo',
        splicing_description: 'Mô tả mối nối',
        enable_or_not: 'Đã kích hoạt hoặc vô hiệu hoá',
        association_symbol: 'Biểu tượng mối quan hệ',
        screening_condition: 'Điều kiện lọc',
        conditional_value: 'Giá trị điều kiện lọc',
        not_included: 'Không bao gồm',
        and: 'Và',
        perhaps: 'Hoặc',
        not: 'Không',
        enable: 'Bật',
        judgement_logic: 'Logic đánh giá',
        please_add_judgment_logic: 'Vui lòng thêm logic đánh giá',
        model_platform_is: 'Nền tảng：',
        subject_of_the_case_is: 'Chủ đề vụ việc：',
        overseas_branches_are: 'Chi nhánh nước ngoài：',
        overseas_subsidiaries_are: 'Các công ty con nước ngoài là：',
        general_agent_are: 'Nhà phân phối：',
        fault_symptom_is: 'Hiện tượng lỗi：',
        cause_of_the_fault_is: 'Nguyên nhân lỗi：',
        vehicle_status_is: 'Tình trạng xe：',
        case_type_is: 'Loại vụ việc：',
        case_status_is: 'Trạng thái trường hợp：',
        dealer: 'Trang chủ：',
    },
    // 西班牙
    esES: {
        filter_information: 'Información del filtro',
        filter_name: 'Nombre del filtro',
        user_name: 'Usuario',
        creation_time: 'Fecha de creación',
        splicing_description: 'Descripción concatenada',
        enable_or_not: 'Habilitado o Deshabilitado',
        association_symbol: 'Símbolo asociado',
        screening_condition: 'Condición de filtro',
        conditional_value: 'Valor de condición',
        not_included: 'No incluido',
        and: 'Y',
        perhaps: 'O',
        not: 'No',
        enable: 'Sí',
        judgement_logic: 'Lógica de evaluación',
        please_add_judgment_logic: 'Por favor, agregue lógica de evaluación',
        model_platform_is: 'Plataforma del modelo de vehículo：',
        subject_of_the_case_is: 'Tema del caso：',
        overseas_branches_are: 'Sucursal en el extranjero：',
        overseas_subsidiaries_are: 'Subsidiaria en el extranjero：',
        general_agent_are: 'Distribuidor principal：',
        fault_symptom_is: 'Síntoma de falla：',
        cause_of_the_fault_is: 'Causa de falla：',
        vehicle_status_is: 'Estado del vehículo：',
        case_type_is: 'Tipo de caso：',
        case_status_is: 'Estado del caso：',
        dealer: 'Distribuidores：',
    },
    // 葡萄牙
    ptBR: {
        filter_information: 'Informações do filtro',
        filter_name: 'Nome do filtro',
        user_name: 'Nome de usuário',
        creation_time: 'Tempo de criação',
        splicing_description: 'Descrição de junção',
        enable_or_not: 'Ativar ou não',
        association_symbol: 'Símbolo de associação',
        screening_condition: 'Condição de triagem',
        conditional_value: 'Valor condicional',
        not_included: 'Não incluído',
        and: 'E',
        perhaps: 'Ou',
        not: 'Não',
        enable: 'Sim',
        judgement_logic: 'Lógica de julgamento',
        please_add_judgment_logic: 'Por favor, adicione a lógica de julgamento',
        model_platform_is: 'Plataforma do modelo:',
        subject_of_the_case_is: 'Tema do caso:',
        overseas_branches_are: 'Filiais no exterior:',
        overseas_subsidiaries_are: 'Subsidiárias no exterior:',
        general_agent_are: 'Agente geral:',
        fault_symptom_is: 'Sintoma da falha:',
        cause_of_the_fault_is: 'Causa da falha:',
        vehicle_status_is: 'Status do veículo:',
        case_type_is: 'Tipo de caso:',
        case_status_is: 'Status do caso:',
        dealer: 'Revendedor:',
    },
    // 波兰
    plPL: {
        filter_information: 'Informacje o filtrze',
        filter_name: 'Nazwa filtra',
        user_name: 'Nazwa użytkownika',
        creation_time: 'Czas założenia',
        splicing_description: 'Opis połączenia',
        enable_or_not: 'Włączony czy wyłączony',
        association_symbol: 'Symbol relacji',
        screening_condition: 'Warunki filtrowania',
        conditional_value: 'Wartość warunku filtrowania',
        not_included: 'Nie zawiera',
        and: 'I',
        perhaps: 'Lub',
        not: 'Mie',
        enable: 'Włączony',
        judgement_logic: 'Logika oceny',
        please_add_judgment_logic: 'Proszę dodać logikę oceny',
        model_platform_is: 'Platforma pojazdu:',
        subject_of_the_case_is: 'Temat sprawy:',
        overseas_branches_are: 'Oddział zagraniczny:',
        overseas_subsidiaries_are: 'Zagraniczna spółka zależna:',
        general_agent_are: 'Dystrybutor:',
        fault_symptom_is: 'Zjawisko usterki:',
        cause_of_the_fault_is: 'Przyczyna usterki:',
        vehicle_status_is: 'Status pojazdu:',
        case_type_is: 'Typ sprawy:',
        case_status_is: 'Status sprawy:',
        dealer: 'Dealer:',
    },
    // 土耳其
    trTR: {
        filter_information: 'Filtreleme bilgisi',
        filter_name: 'Filtreleme adı',
        user_name: 'Kullanıcı adı',
        creation_time: 'Oluşturma zamanı',
        splicing_description: 'Ayrılan tanımlama',
        enable_or_not: 'Etkinleştirildi ya da etkinleştirilmedi',
        association_symbol: 'İlişkisi sembolü',
        screening_condition: 'Filtreleme durumu',
        conditional_value: 'Filtreleme durum değeri',
        not_included: 'İçermiyor',
        and: 'Ve',
        perhaps: 'Belki de',
        not: 'Hayır.',
        enable: 'Etkinleştir',
        judgement_logic: 'Karar verme mantığı',
        please_add_judgment_logic: 'Lütfen karar verme mantığını ekleyin',
        model_platform_is: 'Platform：',
        subject_of_the_case_is: 'Dosya konusu：',
        overseas_branches_are: 'Deniz aşırı şirket：',
        overseas_subsidiaries_are: 'Deniz aşırı tali şirket：',
        general_agent_are: 'Dağıtıcı：',
        fault_symptom_is: 'Arıza semptomu：',
        cause_of_the_fault_is: 'Hata sebebi：',
        vehicle_status_is: 'Araç durumu：',
        case_type_is: 'Dosya türü：',
        case_status_is: 'Dosya durumu：',
        dealer: 'distribütörler：',
    },
    // 捷克语
    csCZ: {
        filter_information: 'Filtrovat informace',
        filter_name: 'Název filtru',
        user_name: 'Uživatelské jméno',
        creation_time: 'Čas vytvoření',
        splicing_description: 'Splicing description',
        enable_or_not: 'Povoleno nebo zakázáno',
        association_symbol: 'Vztahový symbol',
        screening_condition: 'Podmínky filtrování',
        conditional_value: 'Hodnota podmínky filtrování',
        not_included: 'Nezahrnuje',
        and: 'A',
        perhaps: 'Možná',
        not: 'Ne',
        enable: 'Umožnit',
        judgement_logic: 'Logika posouzení',
        please_add_judgment_logic: 'Přidejte prosím logiku posouzení',
        model_platform_is: 'Platforma:',
        subject_of_the_case_is: 'Předmět případu:',
        overseas_branches_are: 'Zahraniční společnost:',
        overseas_subsidiaries_are: 'Zahraniční dceřiné společnosti jsou:',
        general_agent_are: 'Distributor:',
        fault_symptom_is: 'Poruchový jev:',
        cause_of_the_fault_is: 'Příčina závady:',
        vehicle_status_is: 'Stav vozidla:',
        case_type_is: 'Typ případu:',
        case_status_is: 'Stav případu:',
        dealer: 'Dealer:',
    },
    // 匈牙利语
    huHU: {
        filter_information: 'Szűrőinformáció',
        filter_name: 'Szűrőnév',
        user_name: 'Felhasználónév',
        creation_time: 'Létrehozás ideje',
        splicing_description: 'Összeillesztési leírás',
        enable_or_not: 'Bekapcsolt vagy kikapcsolt',
        association_symbol: 'Relációs jel',
        screening_condition: 'Szűrési feltétel',
        conditional_value: 'Szűrési feltétel értéke',
        not_included: 'Nem része',
        and: 'És',
        perhaps: 'Talán',
        not: 'Nem',
        enable: 'Engedélyezés',
        judgement_logic: 'Elbírálási logika',
        please_add_judgment_logic: 'Adjon hozzá elbírálási logikát',
        model_platform_is: 'Platform:',
        subject_of_the_case_is: 'Eset tárgya:',
        overseas_branches_are: 'Tengerentúli vállalat:',
        overseas_subsidiaries_are: 'Tengerentúli leányvállalatok:',
        general_agent_are: 'Forgalmazó:',
        fault_symptom_is: 'Hibajelenség:',
        cause_of_the_fault_is: 'Hiba oka:',
        vehicle_status_is: 'Jármű állapota:',
        case_type_is: 'Eset típusa:',
        case_status_is: 'Eset állapota:',
        dealer: 'Kereskedő:',
    },
    // 俄罗斯语
    ruRU: {
        filter_information: 'Информация о фильтре',
        filter_name: 'Название фильтра',
        user_name: 'Имя пользователя',
        creation_time: 'Время создания',
        splicing_description: 'Описание соединения',
        enable_or_not: 'Активирован или деактивирован',
        association_symbol: 'Символ связи',
        screening_condition: 'Условие фильтрации',
        conditional_value: 'Значение условия фильтрации',
        not_included: 'Не включается',
        and: 'И',
        perhaps: 'Или',
        not: 'Нет',
        enable: 'Активировать',
        judgement_logic: 'Логика проверки',
        please_add_judgment_logic: 'Добавьте логику проверки',
        model_platform_is: 'Платформа:',
        subject_of_the_case_is: 'Тема случая:',
        overseas_branches_are: 'Зарубежная компания:',
        overseas_subsidiaries_are: 'Зарубежные дочерние предприятия:',
        general_agent_are: 'Дистрибьютор:',
        fault_symptom_is: 'Симптом неисправности:',
        cause_of_the_fault_is: 'Причина неисправности:',
        vehicle_status_is: 'Состояние автомобиля:',
        case_type_is: 'Тип случая:',
        case_status_is: 'Статус случая:',
        dealer: 'Дилер:',
    },
};

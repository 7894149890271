export default {
    // 中
    zhCN: {
        vin: 'VIN',
        inquiry_no: '案例单号',
        case_theme: '案例主题',
        department_name: '部门名称',
        department_type: '部门类型',
        document_status: '单据状态',
        inquiry_date: '问询日期',
        organization_name: '组织名称',
        name: '提案方名称',
        sap_code: 'SAP编码',
        failed_part: '失效零件',
        case_type: '案例类型',
        vehicle_status: '维修状态',
        case_owner: '责任人',
        country: '国家',
        fault_cause: '故障原因',
        symptoms: '故障现象',
        is_the_document_qualified: '单据是否合格',
        is_the_document_replied_in_a_timely_manner: '单据是否及时答复',
        filter: '筛选器',
        system_classification_I: '系统分类I',
        system_classification_II: '系统分类II',
        full_query: '全量查询',
        fuzzy_query: '模糊查询',
        whether_belongs_new_car_market: '是否新车上市',
        caseStatus: '案例状态',
        correct: '是',
        deny: '否',
        not_closed: '未结案',
        no_reply: '未答复',
        replied: '已答复',
        closed: '已结案',
        drafts: '草稿箱',
        create_time: '创建时间',
        create_user: '创建人',
        parent_of_document: '单据所属上级',
        document_owner: '单据负责人',
        case_subject: '案例主题',
        receipt_info: '单据信息',
        superior_of_case: '案例所属上级',
        inquiry_time: '问询时间',
        status: '状态',
        overseas_company: '海外公司名称',
        general_agent_name: '总代名称',
        dealer_code: '经销商编码',
        dealer_name: '经销商名称',
        name_of_subsidiary: '子公司名称',
        contacts: '联系人',
        contact_information: '联系方式',
        training_certification: '培训认证',
        driving_mileage: '行驶里程',
        plate_number: '车牌号',
        material_no: '物料号',
        material_desc: '物料描述',
        offline_date: '下线日期',
        model_platform: '车型平台',
        engine_number: '发动机号',
        transmission_type: '变速箱型号',
        assembly_no: '总装号',
        fault_info_desc: '故障信息描述',
        fault_handle_process: '故障处理经过',
        solution: '解决方案',
        remark: '备注',
        caseRemark: '案例备注',
        document_information: '单据信息',
        basic_information_of_service_center: '服务中心基本信息',
        basic_vehicle_information: '车辆基本信息',
        problem_description: '问题描述',
        common_links: '常用链接',
        claim_information: '索赔信息',
        diagnostic_report_record: '诊断报告记录',
        vehicle_history: '车辆历史问询单据',
        programming_history: '编程历史',
        vehicle_sales_logistics_information: '车辆销售及物流信息',
        new_vehicle_information: '新增车辆信息',
        attachment_upload: '附件上传',
        diagnostic_report: '诊断报告',
        bulk_download: '批量下载',
        document_View: '文档查看',
        inquiry_content: '问询内容',
        inquiry_entry: '问询回复',
        subordinate_record: '下级问答记录',
        superior_record: '上级问答记录',
        send_content: '发送内容',
        send_time: '发送时间',
        sent_username: '发送人',
        receive_name: '发送至',
        dept_name: '级别',
        enclosure: '附件',
        wind_case: '结案',
        close_order: '关单',
        case_reporting: '案例上报',
        pdf_preview: 'PDF预览',
        SIS_maintenance: '重大事故维护',
        Please_fill_in_the_inquiry_content: '请填写询问内容',
        sending_an_email: '发送邮件',
        copy: '复制',
        repeat: 'Vin 重复',
        inconsistent: '车型平台不一致',
        sendTo: '发送至',
        reply: '输入内容进行问询/回复',
        please_check_the_exported_document: '请勾选导出的单据',
        please_upload_diagnostic_report: '请上传诊断报告',
        please_enter_the_vin: '请输入VIN',
        placeholderDTC: '示例：P080800_P087D00_------',
        placeholderFaultInfoDesc: '1.核实客户关注的问题(只是为了确保出现问题)',
        placeholderFaultHandleProcess:
            '2.确定相关症状(查找并记录可能与问题相关的所有症状)\n' +
            '3.分析症状(仔细观察相关症状，找出常见原因)\n' +
            '4.确认问题(缩小你的结果范围，以确保你解决了正确的问题)',
        placeholderSolution:
            '5.修复问题(进行维修)\n' + '6.验证车辆运行情况(检查以确保您所做的修复已解决问题)',
        placeholderRemark: '例如：转向机-201806，FL减震器-2010101',
        placeholderContact: '请输入操作者姓名',
        close_time: '结案时间',
        one_touch: '单据重建',
        confirm_generating_new_case: '确定生成新的单据？',
        closing_statement: '结案说明',
        number_of_cases: '单据数量',
        number_of_vehicles: '车辆数量',
        pleaseEnterContent: '请输入内容',
        case_age: '案龄',
        last_response_time: '上次响应时间',
        time_since_last_response: '自上次响应以来的时间',
        warranty_start_date: '保修开始日期',
        submit_reason: '提交原因',
        synchronize_PQCP: '同步PQCP',
        FPR_export: 'FPR导出',
        level_selection: '级别选择',
        category_selection: '分类选择',
        brand_selection: '品牌选择',
        code: '代码',
        description: '描述',
        major_complaints: '重大抱怨',
        difficult_miscellaneous_diseases: '疑难杂症',
        emerging_issues: '新发问题',
        breakpoint_failure: '断点失效',
        general_complaints: '一般抱怨',
        other: '其他',
        roewe: '荣威',
        mg: 'MG',
        serious: '严重',
        secondary: '次要',
        generally: '一般',
        complete: '完成',
        please_select_level: '请选择级别',
        please_select_category: '请选择分类',
        please_select_brand: '请选择品牌',
        placeholder_auto: '自动带出',
        translate: '翻译',
        isTranslate: '是否翻译',
        day: '天',
        one_click_order: '一键关单',
        greater_than: '大于',
        less_than: '小于',
        between: '之间',
        document_label: '单据标签',
        receipts_first_modification_time: '单据首次修改时间',
        Selection_of_Document_Owner: '单据责任人选择',
        responsiblePerson: '责任人：',
        functional_Domain: '功能域',
        Category: '类别',
        property: '属性',
        reRepair: '重复维修',
        pullInNum: '进站次数',
        perception: '感知类',
        onSupport: '是否现场支持',
        batteryNo: '电池编号',
        normalStartDate: '保修日期',
        qmotorNo: '前电机编号',
        hmotorNo: '后电机编号',
        fault_cause_type: '故障原因类型',
        measure_name: '措施名称',
        part_name: '零件名称',
        close_order_notification: '关单需要答复提案方',
        TIS_Case_Data: 'TIS案例数据',
        case_File: '案例档案',
        responsibility_Data: '分责数据',
        longterm_Measures: '长期措施',
        tisCaseNo: 'TIS案例编号',
        vehGlobalFwVer: '整车软件版本号',
        vehGlobalFwPn: '整车软件零件号',
        archivesNo: '档案编号',
        archivesTitle: '档案标题',
        archivesSource: '档案来源',
        proposer: '提出人员',
        proposedTime: '提出日期',
        archivesManagementPersonnel: '管理人员',
        functionalMode: '功能模块',
        supplier: '供应商',
        accessoriesInvolved: '是否涉及配件',
        functionalDomain: '功能域',
        invalidPartId: '失效件',
        archivesType: '档案类型',
        archivesGrade: '档案等级',
        problemDescription: '问题描述',
        analysisProgress: '分析进展',
        qaRemark: 'QA备注',
        tacRemark: 'TAC备注',
        responsibleDepartment: '责任部门',
        coordinator: '协调人',
        principal: '责任人',
        containmentAction: '遏制措施',
        issueStatus: '问题状态',
        softwareProblems: '软件问题',
        vehGlobalFwVer_j: '基线版本号',
        vehGlobalFwPn_j: '基线零件号',
        ewo: 'EWO号',
        rootCause: '根本原因',
        marketBehavior: '市场行为',
        shortTermMeasures: '短期措施',
        shortTermBreakpointDescription: '短措断点说明',
        measuresResult: '措施审批结果',
        measuresConfirmation: '措施确认说明',
        longTermMeasures: '长期措施',
        longTermMeasuresBreak: '长措预估断点说明',
        implementation: '是否实施',
        longMeasuresTime: '长措实际断点日期',
        vinBreak: '长措断点VIN',
        breakpointImplementationPhase: '断点实施阶段',
        archive_Information: '档案信息',
        reason_incomplete_closure: '未完工关闭原因',
        entry_Date: '进站日期',
        departure_date: '离站日期',
        work_order_number: '工单号',
        questionnaire_usage: '问询单用途',
        classification_of_technical_enquiries: '技术问询分类',
    },
    // 英
    enUS: {
        vin: 'VIN',
        inquiry_no: 'Case no.',
        case_theme: 'Case subject',
        department_name: 'Department name',
        department_type: 'Department type',
        document_status: 'Document status',
        inquiry_date: 'Inquiry date',
        organization_name: 'Organization name',
        name: 'Name of proposer',
        sap_code: 'SAP code',
        failed_part: 'System-part',
        case_type: 'Case type',
        vehicle_status: 'Repair status',
        case_owner: 'Person responsible',
        country: 'Country',
        fault_cause: 'Fault cause',
        symptoms: 'Fault phenomenon',
        is_the_document_qualified: 'Is the case qualified',
        is_the_document_replied_in_a_timely_manner: 'Is the case replied in a timely manner',
        filter: 'Filter',
        system_classification_I: 'System-group',
        system_classification_II: 'System-assembly',
        full_query: 'Full query',
        fuzzy_query: 'Fuzzy query',
        whether_belongs_new_car_market: 'New car launched',
        caseStatus: 'Case status',
        correct: 'Correct',
        deny: 'Deny',
        not_closed: 'Open',
        no_reply: 'Wait for reply',
        replied: 'Replied',
        closed: 'Closed',
        drafts: 'Draft box',
        create_time: 'Create time',
        create_user: 'Created by',
        parent_of_document: 'Parent of the document',
        document_owner: 'Case owner',
        case_subject: 'Subject',
        receipt_info: 'Case information',
        superior_of_case: 'Case superior',
        inquiry_time: 'Inquiry time',
        status: 'Status',
        overseas_company: 'Sales company name',
        general_agent_name: 'Distributor name',
        dealer_code: 'Dealer code',
        dealer_name: 'Dealer name',
        name_of_subsidiary: 'Name of subsidiary',
        contacts: 'Contact person',
        contact_information: 'Contact information',
        training_certification: 'Training certification',
        driving_mileage: 'Mileage',
        plate_number: 'License plate #',
        material_no: 'Vehicle Material code',
        material_desc: 'Material description',
        offline_date: 'Off-line date',
        model_platform: 'Platform',
        engine_number: 'Engine number',
        transmission_type: 'Transmission',
        assembly_no: 'CSN no',
        fault_info_desc: 'Customer reported concern',
        fault_handle_process: 'Diagnosis/Work carried out',
        solution: 'Solution',
        remark: 'Remark',
        caseRemark: 'Case remark',
        document_information: 'Case information',
        basic_information_of_service_center: 'Service center information',
        basic_vehicle_information: 'Vehicle basic information',
        problem_description: 'Problem description',
        common_links: 'Useful link',
        claim_information: 'Claim information',
        diagnostic_report_record: 'Diagnostic report',
        vehicle_history: 'Technical inquiry history',
        programming_history: 'Programming history',
        vehicle_sales_logistics_information: 'Vehicle sales and logistics information',
        new_vehicle_information: 'New vehicle information',
        attachment_upload: 'Attachment upload',
        diagnostic_report: 'Diagnostic report',
        bulk_download: 'Bulk download',
        document_View: 'Document view',
        inquiry_content: 'Case response detail',
        inquiry_entry: 'Case response entry',
        subordinate_record: 'Subordinate record',
        superior_record: 'Superior record',
        send_content: 'Send content',
        send_time: 'Send time',
        sent_username: 'Sender',
        receive_name: 'Send to',
        dept_name: 'Level',
        enclosure: 'Attachment',
        wind_case: 'Closed',
        close_order: 'Close case',
        case_reporting: 'Case submit',
        pdf_preview: 'PDF preview',
        SIS_maintenance: 'Major accident maintenance',
        Please_fill_in_the_inquiry_content: 'Please fill in the inquiry content',
        sending_an_email: 'Sending an email',
        copy: 'Copy',
        repeat: 'Vin repeat',
        inconsistent: 'Inconsistent vehicle platform',
        sendTo: 'Send to',
        reply: 'Input for inquiry/reply',
        please_check_the_exported_document: 'Please check the exported document',
        please_upload_diagnostic_report: 'Please upload diagnostic report',
        please_enter_the_vin: 'Please enter the VIN',
        placeholderDTC: 'Example：P080800_P087D00_------',
        placeholderFaultInfoDesc: '1.Verify customer concerns (just to ensure issues arise)',
        placeholderFaultHandleProcess:
            '2.Identify relevant symptoms (identify and record all symptoms that may be related to the problem)\n' +
            '3.Analyze symptoms (carefully observe relevant symptoms to identify common causes)\n' +
            '4.Confirm the problem (narrow down the scope of your results to ensure you have solved the correct problem)',
        placeholderSolution:
            '5.Fix the problem (carry out repairs)\n' +
            '6.Verify vehicle operation (check to ensure that your repairs have resolved the issue)',
        placeholderRemark: 'Example: Steering gear-201806, FL Shock absorber-2010101',
        placeholderContact: 'Please enter operator name',
        close_time: 'Close time',
        one_touch: 'Case rebuild',
        confirm_generating_new_case: 'Confirm generating new case？',
        closing_statement: 'Closing statement',
        number_of_cases: 'Number of cases',
        number_of_vehicles: 'Number of vehicles',
        pleaseEnterContent: 'Please enter content',
        case_age: 'Case age',
        last_response_time: 'Last response time',
        time_since_last_response: 'Time since last response',
        warranty_start_date: 'Warranty start date',
        submit_reason: 'Reason for submission',
        synchronize_PQCP: 'Synchronize PQCP',
        FPR_export: 'FPR export',
        level_selection: 'Level selection',
        category_selection: 'Category selection',
        brand_selection: 'Brand selection',
        code: 'Code',
        description: 'Description',
        major_complaints: 'Major complaints',
        difficult_miscellaneous_diseases: 'Difficult miscellaneous diseases',
        emerging_issues: 'Emerging issues',
        breakpoint_failure: 'Breakpoint failure',
        general_complaints: 'General complaints',
        other: 'Other',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Serious',
        secondary: 'Secondary',
        generally: 'Generally',
        complete: 'complete',
        please_select_level: 'Please select level',
        please_select_category: 'Please select category',
        please_select_brand: 'Please select brand',
        placeholder_auto: 'Auto Populated',
        translate: 'Translate',
        isTranslate: 'Is translate',
        day: 'day',
        one_click_order: 'One-click case',
        greater_than: 'Greater than',
        less_than: 'Less than',
        between: 'Between',
        document_label: 'Document label',
        receipts_first_modification_time: 'Receipts first modification time',
        Selection_of_Document_Owner: 'Document Responsible Person Selection',
        responsiblePerson: 'responsible Person：',
        functional_Domain: 'Functional Domain',
        Category: 'Category',
        property: 'Causality',
        reRepair: 'Repeat Repairs',
        pullInNum: 'Number of Entries',
        perception: 'Perception Class',
        onSupport: 'On-Site Support',
        batteryNo: 'Battery Number',
        normalStartDate: 'Warranty Date',
        qmotorNo: 'Front Motor Number',
        hmotorNo: 'Rear Motor Number',
        fault_cause_type: 'Fault Cause Type',
        measure_name: 'Measure Name',
        part_name: 'Part Name',
        close_order_notification: 'Closing the order requires a response from the proposer.',
        TIS_Case_Data: 'TIS Case Data',
        case_File: 'Case File',
        responsibility_Data: 'Responsibility Data',
        longterm_Measures: 'Long-term Measures',
        tisCaseNo: 'TIS Case Number',
        vehGlobalFwVer: 'Vehicle Software Version Number',
        vehGlobalFwPn: 'Vehicle Software Part Number',
        archivesNo: 'File Number',
        archivesTitle: 'File Title',
        archivesSource: 'File Source',
        proposer: 'Proposer',
        proposedTime: 'Proposed Date',
        archivesManagementPersonnel: 'Management Personnel',
        functionalMode: 'Functional Module',
        supplier: 'Supplier',
        accessoriesInvolved: 'Involves Accessories',
        functionalDomain: 'Functional Domain',
        invalidPartId: 'Invalid Part ID',
        archivesType: 'File Type',
        archivesGrade: 'File Grade',
        problemDescription: 'Problem Description',
        analysisProgress: 'Analysis Progress',
        qaRemark: 'QA Remark',
        tacRemark: 'TAC Remark',
        responsibleDepartment: 'Responsible Department',
        coordinator: 'Coordinator',
        principal: 'Principal',
        containmentAction: 'Containment Action',
        issueStatus: 'Issue Status',
        softwareProblems: 'Software Problems',
        vehGlobalFwVer_j: 'Baseline Version Number',
        vehGlobalFwPn_j: 'Baseline Part Number',
        ewo: 'EWO Number',
        rootCause: 'Root Cause',
        marketBehavior: 'Market Behavior',
        shortTermMeasures: 'Short-term Measures',
        shortTermBreakpointDescription: 'Short-term Breakpoint Description',
        measuresResult: 'Measures Approval Result',
        measuresConfirmation: 'Measures Confirmation',
        longTermMeasures: 'Long-term Measures',
        longTermMeasuresBreak: 'Long-term Measures Estimated Breakpoint Description',
        implementation: 'Implementation',
        longMeasuresTime: 'Long-term Measures Actual Breakpoint Date',
        vinBreak: 'Long-term Measures Breakpoint VIN',
        breakpointImplementationPhase: 'Breakpoint Implementation Phase',
        archive_Information: 'Archive Information',
        reason_incomplete_closure: 'Reason for Incomplete Closure',
        entry_Date: 'Entry Date',
        departure_date: 'Leave Time',
        work_order_number: 'Work Order Number',
        questionnaire_usage: 'Questionnaire Usage',
        classification_of_technical_enquiries: 'Classification Of Technical Enquiries',
    },
    // 德
    deDE: {
        vin: 'VIN',
        inquiry_no: 'Anfragenummer',
        case_theme: 'Ein fall für sich.',
        department_name: 'Abteilungsname',
        department_type: 'Abteilungstyp',
        document_status: 'Dokumentenstatus',
        inquiry_date: 'Datum der Anfrage',
        organization_name: 'Organisationsname',
        name: 'Name des Antragstellers',
        sap_code: 'SAP-Code',
        failed_part: 'Defekte Teile',
        case_type: 'Falltyp',
        vehicle_status: 'Wartungsstatus',
        case_owner: 'Haftpflichtige Person',
        country: 'Land',
        fault_cause: 'Fehlerursache',
        symptoms: 'Fehlerphänomen',
        is_the_document_qualified: 'Ist das Dokument qualifiziert',
        is_the_document_replied_in_a_timely_manner: 'Wird das Dokument rechtzeitig beantwortet',
        filter: 'Filter',
        system_classification_I: 'Systematik I',
        system_classification_II: 'Systematik II',
        full_query: 'vollständige Abfrage',
        fuzzy_query: 'fuzzy-Abfrage',
        whether_belongs_new_car_market: 'Neues Auto gestartet',
        caseStatus: 'Fallstatus',
        correct: 'Richtig',
        deny: 'Ablehnen',
        not_closed: 'Offen',
        no_reply: 'Auf Antwort warten',
        replied: 'Antwort',
        closed: 'Geschlossen',
        drafts: 'Entwurfskasten',
        create_time: 'Erstellungszeit',
        create_user: 'Erstellt von',
        parent_of_document: 'Übergeordneter Dokumententyp',
        document_owner: 'Dokumenteneigentümer',
        case_subject: 'Ein fall für sich',
        receipt_info: 'Empfangsinformationen',
        superior_of_case: 'Er hat den fall aufgeklärt',
        inquiry_time: 'Anfragezeit',
        status: 'Status',
        overseas_company: 'Verkaufsfirmenname',
        general_agent_name: 'Distributeur-Name',
        dealer_code: 'Händlercode',
        dealer_name: 'Händlername',
        name_of_subsidiary: 'Name der Tochtergesellschaft',
        contacts: 'Kontaktperson',
        contact_information: 'Kontaktinformationen',
        training_certification: 'Schulungszertifizierung',
        driving_mileage: 'Fahrleistung',
        plate_number: 'Kennzeichen',
        material_no: 'Fahrzeugmaterialcode',
        material_desc: 'Materialbeschreibung',
        offline_date: 'Off-line Datum',
        model_platform: 'Plattform',
        engine_number: 'Motornummer',
        transmission_type: 'Getriebe',
        assembly_no: 'CSN-Nr.',
        fault_info_desc: 'Fehlerinfo-Beschreibung',
        fault_handle_process: 'Fehlerbehandlungsprozess',
        solution: 'Lösung',
        remark: 'Bemerkung',
        caseRemark: 'Fall Bemerkungen',
        document_information: 'Fallinformationen',
        basic_information_of_service_center: 'Servicecenter-Informationen',
        basic_vehicle_information: 'Fahrzeuggrundinformationen',
        problem_description: 'Problem Beschreibung',
        common_links: 'Allgemeine Links',
        claim_information: 'Reklamationsinformationen',
        diagnostic_report_record: 'Diagnosebericht',
        vehicle_history: 'Technische Anfragengeschichte',
        programming_history: 'Programmierungsverlauf',
        vehicle_sales_logistics_information: 'Fahrzeugverkaufs- und Logistikinformationen',
        new_vehicle_information: 'Informationen für neue Fahrzeuge',
        attachment_upload: 'Anhang hochladen',
        diagnostic_report: 'Diagnosebericht',
        bulk_download: 'Massendownload',
        document_View: 'Dokument anzeigen',
        inquiry_content: 'Anfrageinhalt',
        inquiry_entry: 'Beantwortung der Anfrage',
        subordinate_record: 'Untergeordneter Eintrag',
        superior_record: 'Übergeordneter Eintrag',
        send_content: 'Senden Inhalt',
        send_time: 'Sendezeit',
        sent_username: 'Absender',
        receive_name: 'Empfänger',
        dept_name: 'Ebene',
        enclosure: 'Anlage',
        wind_case: 'Windkoffer',
        close_order: 'Liste schließen',
        case_reporting: 'Fallberichterstattung',
        pdf_preview: 'PDF-Vorschau',
        SIS_maintenance: 'Hauptunfallinstandhaltung',
        Please_fill_in_the_inquiry_content: 'Bitte füllen Sie den Inhalt der Anfrage aus',
        sending_an_email: 'Senden einer E-Mail',
        copy: 'Kopie',
        repeat: 'Vin Wiederholen',
        inconsistent: 'Inkonsistente Fahrzeugplattform',
        sendTo: 'Senden an',
        reply: 'Inhalt für Anfrage/Antwort eingeben',
        please_check_the_exported_document: 'Bitte überprüfen Sie das exportierte Dokument',
        please_upload_diagnostic_report: 'Bitte laden Sie einen Diagnosebericht hoch',
        please_enter_the_vin: 'Bitte geben Sie die VIN ein',
        placeholderDTC: 'Beispiel：P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1.Überprüfen Sie Kundenanliegen (nur um sicherzustellen, dass Probleme auftreten)',
        placeholderFaultHandleProcess:
            '2.Relevante Symptome identifizieren (alle Symptome identifizieren und aufzeichnen, die mit dem Problem zusammenhängen können)\n' +
            '3.Symptome analysieren (relevante Symptome sorgfältig beobachten, um häufige Ursachen zu identifizieren)\n' +
            '4.Bestätigen Sie das Problem (schränken Sie den Umfang Ihrer Ergebnisse ein, um sicherzustellen, dass Sie das richtige Problem gelöst haben)',
        placeholderSolution:
            '5.Beheben Sie das Problem (führen Sie Reparaturen durch)\n' +
            '6.Überprüfen Sie den Fahrzeugbetrieb (überprüfen Sie, ob Ihre Reparaturen das Problem behoben haben)',
        placeholderRemark: 'Zum Beispiel：Lenkgetriebe-201806，FL Stoßdämpfer-2010101',
        placeholderContact: 'Alle fünf minuten',
        close_time: 'Schließzeit',
        one_touch: 'Fall Rekonstruktion',
        confirm_generating_new_case: 'Generierung neuer Dokumente bestätigen？',
        closing_statement: 'Akte geschlossen',
        number_of_cases: 'Anzahl der Fälle',
        number_of_vehicles: 'Anzahl der Fahrzeuge',
        pleaseEnterContent: 'Bitte Inhalt eingeben',
        case_age: 'Fallalter',
        last_response_time: 'Letzte Antwortzeit',
        time_since_last_response: 'Zeit seit der letzten Antwort',
        warranty_start_date: 'Beginn der Garantie',
        submit_reason: 'Grund für die Einreichung',
        synchronize_PQCP: 'PQCP synchronisieren',
        FPR_export: 'FPR export',
        level_selection: 'Ebenenauswahl',
        category_selection: 'Klassifikationsauswahl',
        brand_selection: 'Markenauswahl',
        code: 'Code',
        description: 'Beschreibung',
        major_complaints: 'Wichtige Beschwerden',
        difficult_miscellaneous_diseases: 'Ein Knotenproblem',
        emerging_issues: 'Neue Probleme',
        breakpoint_failure: 'Bruchpunktfehler',
        general_complaints: 'Allgemeine Beschwerden',
        other: 'Andere',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Schwerwiegend',
        secondary: 'Sekundär',
        generally: 'Häufig',
        complete: 'Komplett',
        please_select_level: 'Bitte wählen Sie eine Ebene',
        please_select_category: 'Bitte wählen Sie eine Klassifizierung',
        please_select_brand: 'Bitte wählen Sie eine Marke',
        placeholder_auto: 'Automatisch hervorbringen',
        translate: 'übersetzen',
        isTranslate: 'Übersetzen oder nicht',
        day: 'tag',
        one_click_order: 'Drück den knopf',
        greater_than: 'Größer als',
        less_than: 'Weniger als',
        between: 'Zwischen',
        document_label: 'Dokumentenbezeichnung',
        receipts_first_modification_time: 'Quittungen erste Änderungszeit',
        Selection_of_Document_Owner: 'Auswahl des Dokumentverantwortlichen',
        responsiblePerson: 'Verantwortliche Person：',
        functional_Domain: 'Funktionale Domäne',
        Category: 'Kategorie',
        property: 'Kausalität',
        reRepair: 'Wiederholte Reparaturen',
        pullInNum: 'Anzahl der Einträge',
        perception: 'Wahrnehmungsklasse',
        onSupport: 'Vor-Ort-Unterstützung',
        batteryNo: 'Hintere Motornummer',
        normalStartDate: 'Vordere Motornummer',
        qmotorNo: 'Front Motor Number',
        hmotorNo: 'Rear Motor Number',
        fault_cause_type: 'Fehlerursachentyp',
        measure_name: 'Maßnahmenname',
        part_name: 'Teilname',
        close_order_notification:
            'Das Schließen der Bestellung erfordert eine Antwort vom Antragsteller.',
        TIS_Case_Data: 'TIS Falldaten',
        case_File: 'Falldatei',
        responsibility_Data: 'Verantwortungsdaten',
        longterm_Measures: 'Langfristige Maßnahmen',
        tisCaseNo: 'TIS Fallnummer',
        vehGlobalFwVer: 'Fahrzeugsoftware-Versionsnummer',
        vehGlobalFwPn: 'Fahrzeugsoftware-Teilenummer',
        archivesNo: 'Archivnummer',
        archivesTitle: 'Archivtitel',
        archivesSource: 'Archivquelle',
        proposer: 'Antragsteller',
        proposedTime: 'Antragsdatum',
        archivesManagementPersonnel: 'Verwaltungspersonal',
        functionalMode: 'Funktionsmodul',
        supplier: 'Lieferant',
        accessoriesInvolved: 'Zubehör involviert',
        functionalDomain: 'Funktionsdomäne',
        invalidPartId: 'Ungültige Teil-ID',
        archivesType: 'Archivtyp',
        archivesGrade: 'Archivbewertung',
        problemDescription: 'Problembeschreibung',
        analysisProgress: 'Analysefortschritt',
        qaRemark: 'QA-Bemerkung',
        tacRemark: 'TAC-Bemerkung',
        responsibleDepartment: 'Verantwortliche Abteilung',
        coordinator: 'Koordinator',
        principal: 'Verantwortlicher',
        containmentAction: 'Eindämmungsmaßnahme',
        issueStatus: 'Problemstatus',
        softwareProblems: 'Softwareprobleme',
        vehGlobalFwVer_j: 'Basisversionsnummer',
        vehGlobalFwPn_j: 'Basisteilenummer',
        ewo: 'EWO-Nummer',
        rootCause: 'Grundursache',
        marketBehavior: 'Marktverhalten',
        shortTermMeasures: 'Kurzfristige Maßnahmen',
        shortTermBreakpointDescription: 'Kurzfristige Breakpoint-Beschreibung',
        measuresResult: 'Maßnahmen Genehmigungsergebnis',
        measuresConfirmation: 'Maßnahmen Bestätigung',
        longTermMeasures: 'Langfristige Maßnahmen',
        longTermMeasuresBreak: 'Langfristige Maßnahmen geschätzte Breakpoint-Beschreibung',
        implementation: 'Implementierung',
        longMeasuresTime: 'Langfristige Maßnahmen tatsächliches Breakpoint-Datum',
        vinBreak: 'Langfristige Maßnahmen Breakpoint VIN',
        breakpointImplementationPhase: 'Breakpoint Implementierungsphase',
        archive_Information: 'Archivinformationen',
        reason_incomplete_closure: 'Grund für unvollständige Schließung',
        entry_Date: 'Eingangsdatum',
        departure_date: 'Abfahrtsdatum',
        work_order_number: 'Auftragsnummer',
        questionnaire_usage: 'Verwendungszweck des Fragebogens',
        classification_of_technical_enquiries: 'Klassifikation technischer Anfragen',
    },
    // 法
    frFR: {
        vin: 'VIN',
        inquiry_no: 'Numéro du cas',
        case_theme: 'Sujet du cas',
        department_name: 'Nom du service',
        department_type: 'Type de service',
        document_status: 'Statut du document',
        inquiry_date: 'Date de l\'enquête',
        organization_name: 'Nom de l\'organisation',
        name: 'Nom du promoteur',
        sap_code: 'Code SAP',
        failed_part: 'Pièces affectées',
        case_type: 'Type de cas',
        vehicle_status: 'État des réparations',
        case_owner: 'Personnes responsables',
        country: 'Pays',
        fault_cause: 'Cause du défaut',
        symptoms: 'Défaut constaté',
        is_the_document_qualified: 'Le document est - il conforme',
        is_the_document_replied_in_a_timely_manner:
            'Les documents répondent - ils en temps opportun',
        filter: 'Filtre',
        system_classification_I: 'Système 1',
        system_classification_II: 'Système 2',
        full_query: 'Recherche complète',
        fuzzy_query: 'Recherche floue',
        whether_belongs_new_car_market: 'Lancement d\'un nouveau modèle',
        caseStatus: 'État du cas',
        correct: 'Correct',
        deny: 'Refuser',
        not_closed: 'Ouvert',
        no_reply: 'En attente de réponse',
        replied: 'Répondu',
        closed: 'Fermé',
        drafts: 'Brouillons',
        create_time: 'Heure de création ',
        create_user: 'Créé par',
        parent_of_document: 'Cas supérieur',
        document_owner: 'Responsable du cas',
        case_subject: 'Sujet du cas',
        receipt_info: 'Information sur le cas ',
        superior_of_case: 'Appartenance du cas supérieur',
        inquiry_time: 'Temps d\'enquête',
        status: 'Statut',
        overseas_company: 'Nom de la société',
        general_agent_name: 'Nom du distributeur',
        dealer_code: 'Code réparateur',
        dealer_name: 'Nom du distributeur',
        name_of_subsidiary: 'Nom de la filiale',
        contacts: 'Personne à contacter',
        contact_information: 'Information de contact',
        training_certification: 'Niveau de formation',
        driving_mileage: 'Kilométrage',
        plate_number: 'Plaque d\'immatriculation',
        material_no: 'Code modèle',
        material_desc: 'Description',
        offline_date: 'Date de fabrication',
        model_platform: 'Platforme',
        engine_number: 'Numéro moteur',
        transmission_type: 'Transmission',
        assembly_no: 'Numéro CSN',
        fault_info_desc: 'Description du problème',
        fault_handle_process: 'Action corrective',
        solution: 'Solution',
        remark: 'Remarque',
        caseRemark: 'CAS Remarques',
        document_information: 'Information sur le cas ',
        basic_information_of_service_center: 'Information sur le Réparateur ',
        basic_vehicle_information: 'Informations de base sur le véhicule',
        problem_description: 'Déscription du problème',
        common_links: 'Lien utile',
        claim_information: 'Informations sur la réclamation',
        diagnostic_report_record: 'Rapport de diagnostic',
        vehicle_history: 'Historique technique',
        programming_history: 'Historique de programmation',
        vehicle_sales_logistics_information: 'Informations sur la logistique et sur la vente',
        new_vehicle_information: 'Informations sur les nouveaux véhicules',
        attachment_upload: 'Téléchargement de pièces jointes',
        diagnostic_report: 'Rapport de diagnostic',
        bulk_download: 'Téléchargement en masse',
        document_View: 'Affichage du document',
        inquiry_content: 'Détails de la recherche',
        inquiry_entry: 'Demande de réponse',
        subordinate_record: 'Enregistrement subordonné',
        superior_record: 'Enregistrement supérieur',
        send_content: 'Envoyer du contenue',
        send_time: 'Envoyer le',
        sent_username: 'Expéditeur',
        receive_name: 'Envoyer à',
        dept_name: 'Niveau',
        enclosure: 'Pièces jointes',
        wind_case: 'Cas de vent',
        close_order: 'Fermer la monade',
        case_reporting: 'Cas soumis',
        pdf_preview: 'Aperçu PDF',
        SIS_maintenance: 'Entretien des accidents majeurs',
        sending_an_email: 'Envoyer un email',
        copy: 'Copier',
        repeat: 'Répéter le VIN',
        inconsistent: 'Plate-forme de modèle incohérente',
        sendTo: 'Envoyer à',
        reply: 'Contribution à la recherche',
        please_check_the_exported_document: 'Veuillez cocher le document exporté',
        please_upload_diagnostic_report: 'Veuillez télécharger le rapport de diagnostic',
        please_enter_the_vin: 'Veuillez entrer le VIN',
        placeholderDTC: 'Exemples：P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1.Vérifiez les préoccupations des clients (juste pour vous assurer que quelque chose ne va pas)',
        placeholderFaultHandleProcess:
            '2.Identifier les symptômes associés (trouver et documenter tous les symptômes qui peuvent être associés au problème)\n' +
            '3.Analyser les symptômes (Regardez attentivement les symptômes pertinents pour trouver les causes communes)\n' +
            '4.Confirmer le problème (rétrécissez vos résultats pour vous assurer que vous avez résolu le bon problème)',
        placeholderSolution:
            '5.Résoudre les problèmes (effectuer des réparations)\n' +
            '6.Vérifiez le fonctionnement du véhicule (Vérifiez que les correctifs que vous avez effectués ont résolu le problème)',
        placeholderRemark:
            'Par exemple：Machine de direction-201806，FL Amortisseur de chocs-2010101',
        placeholderContact: 'Veuillez entrer le nom de l’opérateur',
        close_time: 'Temps de fermeture',
        one_touch: 'CAS Reconstruction',
        confirm_generating_new_case: 'Déterminer la génération d\'un nouveau document？',
        closing_statement: 'Note de clôture',
        number_of_cases: 'Nombre de cas',
        number_of_vehicles: 'Nombre de véhicules',
        pleaseEnterContent: 'Veuillez entrer le contenu',
        case_age: 'Âge du cas',
        last_response_time: 'Dernier temps de réponse',
        time_since_last_response: 'Temps écoulé depuis la dernière réponse',
        warranty_start_date: 'Date de début de la garantie',
        submit_reason: 'Raison de la soumission',
        synchronize_PQCP: 'Synchronisation PQCP',
        FPR_export: 'FPR exportation',
        level_selection: 'Sélection des niveaux',
        category_selection: 'Sélection de catégories',
        brand_selection: 'Sélection de marques',
        code: 'Code',
        description: 'Description',
        major_complaints: 'Plaintes majeures',
        difficult_miscellaneous_diseases: 'Trouble suspect',
        emerging_issues: 'Nouveaux problèmes',
        breakpoint_failure: 'Défaillance du point d\'arrêt',
        general_complaints: 'Plaintes générales',
        other: 'Autres',
        roewe: 'Ronway',
        mg: 'MG',
        serious: 'Sérieux',
        secondary: 'Secondaire',
        generally: 'Général',
        complete: 'Terminé',
        please_select_level: 'Veuillez sélectionner un niveau',
        please_select_category: 'Veuillez sélectionner une classification',
        please_select_brand: 'Veuillez sélectionner une marque',
        placeholder_auto: 'Sortir automatiquement',
        translate: 'Traduction',
        isTranslate: 'Traduire ou non',
        day: 'jours',
        one_click_order: 'Une clé pour fermer la commande',
        greater_than: 'Supérieure à',
        less_than: 'Moins que',
        between: 'Entre',
        document_label: 'Étiquettes des documents',
        receipts_first_modification_time: 'Reçus première heure de modification',
        Selection_of_Document_Owner: 'Sélection du Responsable du Document',
        responsiblePerson: 'Personne Responsable：',
        functional_Domain: 'Domaine Fonctionnel',
        Category: 'Catégorie',
        property: 'Causalité',
        reRepair: 'Réparations répétées',
        pullInNum: 'Nombre d\'entrées',
        perception: 'Classe de perception',
        onSupport: 'Support sur site',
        batteryNo: 'Numéro de batterie',
        normalStartDate: 'Date de garantie',
        qmotorNo: 'Numéro de moteur avant',
        hmotorNo: 'Numéro de moteur arrière',
        fault_cause_type: 'Type de cause de panne',
        measure_name: 'Nom de la mesure',
        part_name: 'Nom de la pièce',
        close_order_notification:
            'La fermeture de la commande nécessite une réponse de la partie proposante.',
        TIS_Case_Data: 'Données de cas TIS',
        case_File: 'Fichier de cas',
        responsibility_Data: 'Données de responsabilité',
        longterm_Measures: 'Mesures à long terme',

        tisCaseNo: 'Numéro de cas TIS',
        vehGlobalFwVer: 'Numéro de version du logiciel du véhicule',
        vehGlobalFwPn: 'Numéro de pièce du logiciel du véhicule',
        archivesNo: 'Numéro de dossier',
        archivesTitle: 'Titre du dossier',
        archivesSource: 'Source du dossier',
        proposer: 'Proposant',
        proposedTime: 'Date de proposition',
        archivesManagementPersonnel: 'Personnel de gestion',
        functionalMode: 'Module fonctionnel',
        supplier: 'Fournisseur',
        accessoriesInvolved: 'Accessoires impliqués',
        functionalDomain: 'Domaine fonctionnel',
        invalidPartId: 'ID de pièce invalide',
        archivesType: 'Type de dossier',
        archivesGrade: 'Évaluation du dossier',
        problemDescription: 'Description du problème',
        analysisProgress: 'Progrès de l\'analyse',
        qaRemark: 'Remarque QA',
        tacRemark: 'Remarque TAC',
        responsibleDepartment: 'Département responsable',
        coordinator: 'Coordinateur',
        principal: 'Responsable',
        containmentAction: 'Action de confinement',
        issueStatus: 'Statut du problème',
        softwareProblems: 'Problèmes logiciels',
        vehGlobalFwVer_j: 'Numéro de version de base',
        vehGlobalFwPn_j: 'Numéro de pièce de base',
        ewo: 'Numéro EWO',
        rootCause: 'Cause principale',
        marketBehavior: 'Comportement du marché',
        shortTermMeasures: 'Mesures à court terme',
        shortTermBreakpointDescription: 'Description du Breakpoint à court terme',
        measuresResult: 'Résultat de l\'approbation des mesures',
        measuresConfirmation: 'Confirmation des mesures',
        longTermMeasures: 'Mesures à long terme',
        longTermMeasuresBreak: 'Description estimée du Breakpoint des mesures à long terme',
        implementation: 'Implémentation',
        longMeasuresTime: 'Date réelle du Breakpoint des mesures à long terme',
        vinBreak: 'VIN du Breakpoint des mesures à long terme',
        breakpointImplementationPhase: 'Phase d\'implémentation du Breakpoint',
        archive_Information: 'Informations d\'archive',
        reason_incomplete_closure: 'Raison de la fermeture incomplète',
        entry_Date: 'Date d\'entrée',
        departure_date: 'Date de départ',
        work_order_number: 'Numéro de commande',
        questionnaire_usage: 'Utilisation du questionnaire',
        classification_of_technical_enquiries: 'Classification des demandes techniques',
    },
    // 意大利
    itIT: {
        vin: 'Vin',
        inquiry_no: 'Numero di richiesta',
        case_theme: 'Tema case',
        department_name: 'Nome del dipartimento',
        department_type: 'Tipo di dipartimento',
        document_status: 'Stato del documento',
        inquiry_date: 'Data della richiesta',
        organization_name: 'Nome dell\'organizzazione',
        name: 'Nome del proponente',
        sap_code: 'Codice SAP',
        failed_part: 'Parte difettosa',
        case_type: 'Tipo di caso',
        vehicle_status: 'Stato di manutenzione',
        case_owner: 'Persona responsabile',
        country: 'Paese',
        fault_cause: 'Causa del malfunzionamento',
        symptoms: 'Sintomi del malfunzionamento',
        is_the_document_qualified: 'Il documento è qualificato',
        is_the_document_replied_in_a_timely_manner: 'Il documento risponde tempestivamente',
        filter: 'Filtro',
        system_classification_I: 'Classificazione del sistema I',
        system_classification_II: 'Classificazione del sistema II',
        full_query: 'Query completa',
        fuzzy_query: 'Query sfuocata',
        whether_belongs_new_car_market: 'Appartiene al nuovo mercato dell\'auto',
        caseStatus: 'Stato del caso',
        correct: 'Si',
        deny: 'No',
        not_closed: 'Non chiuso',
        no_reply: 'Attendere la risposta',
        replied: 'Risposta',
        closed: 'Chiuso',
        drafts: 'Bozze',
        create_time: 'Data di creazione',
        create_user: 'Creatore',
        parent_of_document: 'Documento genitore',
        document_owner: 'Proprietario del documento',
        case_subject: 'Oggetto della causa',
        receipt_info: 'Informazioni di ricevimento',
        superior_of_case: 'Superiore gerarchico',
        inquiry_time: 'Orario della richiesta',
        status: 'Stato',
        overseas_company: 'Nome dell\'azienda estera',
        general_agent_name: 'Nome dell\'agente generale',
        dealer_code: 'Codice del concessionario',
        dealer_name: 'Nome del concessionario',
        name_of_subsidiary: 'Nome della sussidiaria',
        contacts: 'Contatti',
        contact_information: 'Informazioni di contatto',
        training_certification: 'Certificazione di formazione',
        driving_mileage: 'Chilometraggio di guida',
        plate_number: 'Numero di targa',
        material_no: 'Numero di materiale',
        material_desc: 'Descrizione del materiale',
        offline_date: 'Data offline',
        model_platform: 'Piattaforma del modello',
        engine_number: 'Numero del motore',
        transmission_type: 'Tipo di trasmissione',
        assembly_no: 'Numero di assemblaggio',
        fault_info_desc: 'Descrizione informazioni malfunzionamento',
        fault_handle_process: 'Processo di gestione di malfunzionamento',
        solution: 'Soluzione',
        remark: 'Nota',
        caseRemark: 'caso Nota',
        document_information: 'Informazioni del documento',
        basic_information_of_service_center: 'Informazioni di base del centro assistenza',
        basic_vehicle_information: 'Informazioni di base del veicolo',
        problem_description: 'Descrizione del problema',
        common_links: 'Link comuni',
        claim_information: 'Informazioni sulla richiesta',
        diagnostic_report_record: 'Record di rapporto diagnostico',
        vehicle_history: 'Documento di richiesta storica del veicolo',
        programming_history: 'Storia della programmazione',
        vehicle_sales_logistics_information:
            'Informazioni sulle vendite e sulla logistica del veicolo',
        new_vehicle_information: 'Informazioni sui veicoli nuovi',
        attachment_upload: 'Upload degli allegati',
        diagnostic_report: 'Rapporto diagnostico',
        bulk_download: 'Download in blocco',
        document_View: 'Visualizza documento',
        inquiry_content: 'Contenuto della richiesta',
        inquiry_entry: 'Risposta all\'inchiesta',
        subordinate_record: 'Record delle risposte subordinate',
        superior_record: 'Record delle risposte superiori',
        send_content: 'Invia contenuto',
        send_time: 'Orario di invio',
        sent_username: 'Mittente',
        receive_name: 'Destinatario',
        dept_name: 'Livello',
        enclosure: 'Allegato',
        wind_case: 'Chiudere il caso',
        close_order: 'Lista chiusa',
        case_reporting: 'Segnalazione dei casi',
        pdf_preview: 'Anteprima PDF',
        SIS_maintenance: 'Manutenzione di incidenti significativi',
        Please_fill_in_the_inquiry_content: 'Si prega di compilare il contenuto della richiesta',
        sending_an_email: 'Invio di una email',
        copy: 'Copia',
        repeat: 'Vin ripeti',
        inconsistent: 'Piattaforma del veicolo incoerente',
        sendTo: 'Invia a',
        reply: 'Inserisci il contenuto per la richiesta/risposta',
        please_check_the_exported_document: 'Controlla il documento esportato',
        please_upload_diagnostic_report: 'Carica un rapporto diagnostico',
        please_enter_the_vin: 'Inserire il VIN',
        placeholderDTC: 'Esempio：P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1.Verificare le preoccupazioni dei clienti (solo per assicurarsi che sorgano problemi)',
        placeholderFaultHandleProcess:
            '2.Identificare i sintomi rilevanti (identificare e registrare tutti i sintomi che possono essere correlati al problema)\n' +
            '3.Analizzare i sintomi (osservare attentamente i sintomi rilevanti per identificare le cause comuni)\n' +
            '4.Confermare il problema (restringere la portata dei risultati per assicurarsi di aver risolto il problema corretto)',
        placeholderSolution:
            '5.Risolvere il problema (effettuare riparazioni)\n' +
            '6.Verificare il funzionamento del veicolo (verificare che le riparazioni abbiano risolto il problema)',
        placeholderRemark: 'Per esempio：Servosterzo-201806，FL Ammortizzatore-2010101',
        placeholderContact: 'Digitare il nome dell’operatore',
        close_time: 'Orario di chiusura',
        one_touch: 'caso ricostruzione',
        confirm_generating_new_case: 'Conferma la generazione di nuovi documenti？',
        closing_statement: 'Dichiarazione di chiusura',
        number_of_cases: 'Numero di casi',
        number_of_vehicles: 'Numero di veicoli',
        pleaseEnterContent: 'Inserisci il contenuto',
        case_age: 'Età del caso',
        last_response_time: 'Tempo di risposta ultima',
        time_since_last_response: 'Tempo dall\'ultima risposta',
        warranty_start_date: 'Data di inizio della garanzia',
        submit_reason: 'Motivo della presentazione',
        synchronize_PQCP: 'Sincronizza PQCP',
        FPR_export: 'FPR Esportazione',
        level_selection: 'Selezione del livello',
        category_selection: 'Selezione della classificazione',
        brand_selection: 'Selezione del marchio',
        code: 'Codice',
        description: 'Descrivere',
        major_complaints: 'Principali reclami',
        difficult_miscellaneous_diseases: 'Un problema nodoso',
        emerging_issues: 'Questioni emergenti',
        breakpoint_failure: 'Guasto al punto di rottura',
        general_complaints: 'Reclami generali',
        other: 'Altro',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Sérieux',
        secondary: 'Secondaire',
        generally: 'Général',
        complete: 'completo',
        please_select_level: 'Seleziona un livello',
        please_select_category: 'Seleziona una classificazione',
        please_select_brand: 'Seleziona una marca',
        placeholder_auto: 'Estrarre automaticamente',
        translate: 'Traduci',
        isTranslate: 'Traduci o no',
        day: 'giorno',
        one_click_order: 'Una barra di sicurezza',
        greater_than: 'Maggiore di',
        less_than: 'Inferiore a',
        between: 'Tra',
        document_label: 'Etichetta del documento',
        receipts_first_modification_time: 'Ricevute tempo di prima modifica',
        Selection_of_Document_Owner: 'Selezione del Responsabile del Documento',
        responsiblePerson: 'Persona Responsabile：',
        functional_Domain: 'Dominio Funzionale',
        Category: 'Categoria',
        property: 'causalità',
        reRepair: 'Riparazioni ripetute',
        pullInNum: 'Numero di ingressi',
        perception: 'Classe di percezione',
        onSupport: 'Supporto in loco',
        batteryNo: 'Numero della batteria',
        normalStartDate: 'Data di garanzia',
        qmotorNo: 'Numero motore anteriore',
        hmotorNo: 'Numero motore posteriore',
        fault_cause_type: 'Tipo di causa del guasto',
        measure_name: 'Nome della misura',
        part_name: 'Nome del pezzo',
        close_order_notification: 'La chiusura dell\'ordine richiede una risposta dal proponente.',
        TIS_Case_Data: 'Dati del caso TIS',
        case_File: 'File del caso',
        responsibility_Data: 'Dati di responsabilità',
        longterm_Measures: 'Misure a lungo termine',

        tisCaseNo: 'Numero del caso TIS',
        vehGlobalFwVer: 'Numero di versione del software del veicolo',
        vehGlobalFwPn: 'Numero di parte del software del veicolo',
        archivesNo: 'Numero di archivio',
        archivesTitle: 'Titolo dell\'archivio',
        archivesSource: 'Fonte dell\'archivio',
        proposer: 'Proponente',
        proposedTime: 'Data di proposta',
        archivesManagementPersonnel: 'Personale di gestione',
        functionalMode: 'Modulo funzionale',
        supplier: 'Fornitore',
        accessoriesInvolved: 'Accessori coinvolti',
        functionalDomain: 'Dominio funzionale',
        invalidPartId: 'ID parte non valida',
        archivesType: 'Tipo di archivio',
        archivesGrade: 'Valutazione dell\'archivio',
        problemDescription: 'Descrizione del problema',
        analysisProgress: 'Progresso dell\'analisi',
        qaRemark: 'Osservazione QA',
        tacRemark: 'Osservazione TAC',
        responsibleDepartment: 'Dipartimento responsabile',
        coordinator: 'Coordinatore',
        principal: 'Responsabile',
        containmentAction: 'Azione di contenimento',
        issueStatus: 'Stato del problema',
        softwareProblems: 'Problemi software',
        vehGlobalFwVer_j: 'Numero di versione di base',
        vehGlobalFwPn_j: 'Numero di parte di base',
        ewo: 'Numero EWO',
        rootCause: 'Causa principale',
        marketBehavior: 'Comportamento di mercato',
        shortTermMeasures: 'Misure a breve termine',
        shortTermBreakpointDescription: 'Descrizione del Breakpoint a breve termine',
        measuresResult: 'Risultato dell\'approvazione delle misure',
        measuresConfirmation: 'Conferma delle misure',
        longTermMeasures: 'Misure a lungo termine',
        longTermMeasuresBreak: 'Descrizione stimata del Breakpoint delle misure a lungo termine',
        implementation: 'Implementazione',
        longMeasuresTime: 'Data effettiva del Breakpoint delle misure a lungo termine',
        vinBreak: 'VIN del Breakpoint delle misure a lungo termine',
        breakpointImplementationPhase: 'Fase di implementazione del Breakpoint',
        archive_Information: 'Informazioni sull\'archivio',
        reason_incomplete_closure: 'Motivo della chiusura incompleta',
        entry_Date: 'Data di ingresso',
        departure_date: 'Data di partenza',
        work_order_number: 'Numero dell\'ordine di lavoro',
        questionnaire_usage: 'Uso del questionario',
        classification_of_technical_enquiries: 'Classificazione delle richieste tecniche',
    },
    // 荷兰
    nlNL: {
        vin: 'VIN',
        inquiry_no: 'Geval Nr',
        case_theme: 'Hoofdthema',
        department_name: 'Afdelingsnaam',
        department_type: 'Afdelingstype',
        document_status: 'Documentstatus',
        inquiry_date: 'Datum van onderzoek',
        organization_name: 'Organisatienaam',
        name: 'Naam van de indiener',
        sap_code: 'SAP-code',
        failed_part: 'Getroffen onderdeel',
        case_type: 'Gevaltype',
        vehicle_status: 'Staat van onderhoud',
        case_owner: 'Aansprakelijk persoon',
        country: 'Land',
        fault_cause: 'Foutoorzaak',
        symptoms: 'Foutverschijnselen',
        is_the_document_qualified: 'Is het document gekwalificeerd',
        is_the_document_replied_in_a_timely_manner: 'Is het document tijdig beantwoord?',
        filter: 'Filter',
        system_classification_I: 'Systeemclassificatie I',
        system_classification_II: 'Systeemclassificatie II',
        full_query: 'Volledige zoekopdracht',
        fuzzy_query: 'Zoeken op overeenkomsten',
        whether_belongs_new_car_market: 'Nieuw voertuig geïntroduceerd',
        caseStatus: 'Case status',
        correct: 'Correct',
        deny: 'Ontkennen',
        not_closed: 'Open',
        no_reply: 'Wachten op antwoord',
        replied: 'Beantwoord',
        closed: 'Gesloten',
        drafts: 'Concepten',
        create_time: 'Aanmaaktijd',
        create_user: 'Gemaakt door',
        parent_of_document: 'Ouder van het document',
        document_owner: 'Documenteigenaar',
        case_subject: 'Hoofdthema',
        receipt_info: 'Ontvangstinfo',
        superior_of_case: 'De hierarchieke meerdere van de zaak',
        inquiry_time: 'Onderzoekstijd',
        status: 'Status',
        overseas_company: 'Verkoopmaatschappij naam',
        general_agent_name: 'Distributeur naam',
        dealer_code: 'Dealer code',
        dealer_name: 'Dealer naam',
        name_of_subsidiary: 'Naam van dochteronderneming',
        contacts: 'Contactpersoon',
        contact_information: 'Contactgegevens',
        training_certification: 'Training certificering',
        driving_mileage: 'Kilometerstand',
        plate_number: 'Kenteken',
        material_no: 'Voertuigmateriaalcode',
        material_desc: 'Materiaalomschrijving',
        offline_date: 'Offline datum',
        model_platform: 'Modelplatform',
        engine_number: 'Motornummer',
        transmission_type: 'Transmissie',
        assembly_no: 'CSN nr.',
        fault_info_desc: 'Foutinformatie beschrijving',
        fault_handle_process: 'Foutafhandelingsproces',
        solution: 'Oplossing',
        remark: 'Opmerking',
        caseRemark: 'geval Opmerkingen',
        document_information: 'Dossier informatie',
        basic_information_of_service_center: 'Basisinformatie van servicecentrum',
        basic_vehicle_information: 'Basisvoertuiginformatie',
        problem_description: 'Probleemomschrijving',
        common_links: 'Veelvoorkomende links',
        claim_information: 'Claim informatie',
        diagnostic_report_record: 'Diagnostisch rapport',
        vehicle_history: 'Technische onderzoekshistorie',
        programming_history: 'Programmeerhistorie',
        vehicle_sales_logistics_information: 'Voertuig verkoop- en logistieke informatie',
        new_vehicle_information: 'Nieuwe voertuiginformatie',
        attachment_upload: 'Bijlage uploaden',
        diagnostic_report: 'Diagnostisch rapport',
        bulk_download: 'Bulk download',
        document_View: 'Documentweergave',
        inquiry_content: 'Onderzoeksdetails',
        inquiry_entry: 'Antwoord op het onderzoek',
        subordinate_record: 'Ondergeschikte records',
        superior_record: 'Superieure records',
        send_content: 'Verstuur inhoud',
        send_time: 'Verstuurtijd',
        sent_username: 'Verzender',
        receive_name: 'Ontvanger',
        dept_name: 'Niveau',
        enclosure: 'Bijlage',
        wind_case: 'Wind case',
        close_order: 'Gesloten lijst',
        case_reporting: 'Rapportage van zaken',
        pdf_preview: 'PDF Voorbeeld',
        SIS_maintenance: 'Belangrijk onderhoud na een ongeval',
        Please_fill_in_the_inquiry_content: 'Vul de onderzoeksinhoud in',
        sending_an_email: 'Een e-mail versturen',
        copy: 'Kopie',
        repeat: 'Vin herhalen',
        inconsistent: 'Onconsistent voertuigplatform',
        sendTo: 'Verzenden naar',
        reply: 'Inhoud invoeren voor onderzoek/antwoord',
        please_check_the_exported_document: 'Controleer het geëxporteerde document',
        please_upload_diagnostic_report: 'Upload een diagnoserapport',
        please_enter_the_vin: 'Voer het VIN in',
        placeholderDTC: 'Voorbeeld：P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1.Controleer de zorgen van klanten (alleen om ervoor te zorgen dat er problemen ontstaan)',
        placeholderFaultHandleProcess:
            '2.Identificeer relevante symptomen (identificeer en registreer alle symptomen die gerelateerd kunnen zijn aan het probleem)\n' +
            '3.Symptomen analyseren (relevante symptomen zorgvuldig observeren om veelvoorkomende oorzaken te identificeren)\n' +
            '4.Bevestig het probleem (beperk de reikwijdte van uw resultaten om ervoor te zorgen dat u het juiste probleem hebt opgelost)',
        placeholderSolution:
            '5.Het probleem oplossen (reparaties uitvoeren)\n' +
            '6.Controleer de werking van het voertuig (controleer of uw reparaties het probleem hebben opgelost)',
        placeholderRemark: 'Bijvoorbeeld：Stuurwerk-201806，FL Schokdemper-2010101',
        placeholderContact: 'Voer de naam van de operator in',
        close_time: 'Sluitijd',
        one_touch: 'geval reconstructie',
        confirm_generating_new_case: 'Bevestig het genereren van nieuwe documenten？',
        closing_statement: 'Omschrijving van de zaak',
        number_of_cases: 'Aantal gevallen',
        number_of_vehicles: 'Aantal voertuigen',
        pleaseEnterContent: 'Voer inhoud in',
        case_age: 'Leeftijd van het geval',
        last_response_time: 'Laatste responstijd',
        time_since_last_response: 'Tijd sinds laatste reactie',
        warranty_start_date: 'Beginddatum van de garantie',
        submit_reason: 'Reden voor indiening',
        synchronize_PQCP: 'PQCP synchroniseren',
        FPR_export: 'FPR export',
        level_selection: 'Niveauselectie',
        category_selection: 'Classificatie selectie',
        brand_selection: 'Merkselectie',
        code: 'Code',
        description: 'Beschrijven',
        major_complaints: 'Belangrijke klachten',
        difficult_miscellaneous_diseases: 'Een knoopprobleem',
        emerging_issues: 'Opkomende kwesties',
        breakpoint_failure: 'Breekpunt mislukt',
        general_complaints: 'Algemene klachten',
        other: 'Andere',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Ernstig',
        secondary: 'Secundair',
        generally: 'Vaak',
        complete: 'Compleet',
        please_select_level: 'Selecteer een niveau',
        please_select_category: 'Selecteer een classificatie',
        please_select_brand: 'Selecteer een merk',
        placeholder_auto: 'Automatisch naar buiten brengen',
        translate: 'Vertalen',
        isTranslate: 'Vertalen of niet',
        day: 'dag',
        one_click_order: 'Eén sleutel',
        greater_than: 'Groter dan',
        less_than: 'Minder dan',
        between: 'Tussen',
        document_label: 'Documentlabel',
        receipts_first_modification_time: 'Ontvangsten eerste wijzigingstijd',
        Selection_of_Document_Owner: 'Selectie van de Documentverantwoordelijke',
        responsiblePerson: 'Verantwoordelijke Persoon：',
        functional_Domain: 'Functioneel Domein',
        Category: 'Categorie',
        property: 'causaliteit',
        reRepair: 'Herhaalde reparaties',
        pullInNum: 'Aantal entries',
        perception: 'Waarnemingsklasse',
        onSupport: 'On-site ondersteuning',
        batteryNo: 'Batterijnummer',
        normalStartDate: 'Garantiedatum',
        qmotorNo: 'Voorste motornummer',
        hmotorNo: 'Achterste motornummer',
        fault_cause_type: 'Foutoorzaaktype',
        measure_name: 'Maatregelnaam',
        part_name: 'Onderdeelsnaam',
        close_order_notification:
            'Het sluiten van de bestelling vereist een reactie van de voorsteller.',
        TIS_Case_Data: 'TIS Zaakgegevens',
        case_File: 'Zaakbestand',
        responsibility_Data: 'Verantwoordelijkheidsgegevens',
        longterm_Measures: 'Langetermijnmaatregelen',

        tisCaseNo: 'TIS Zaaknummer',
        vehGlobalFwVer: 'Voertuigsoftware Versienummer',
        vehGlobalFwPn: 'Voertuigsoftware Onderdeelnummer',
        archivesNo: 'Dossiernummer',
        archivesTitle: 'Dossiertitel',
        archivesSource: 'Dossierbron',
        proposer: 'Voorsteller',
        proposedTime: 'Voorgestelde Datum',
        archivesManagementPersonnel: 'Beheerders',
        functionalMode: 'Functioneel Module',
        supplier: 'Leverancier',
        accessoriesInvolved: 'Betrokken Accessoires',
        functionalDomain: 'Functioneel Domein',
        invalidPartId: 'Ongeldig Onderdeel ID',
        archivesType: 'Dossiertype',
        archivesGrade: 'Dossierbeoordeling',
        problemDescription: 'Probleembeschrijving',
        analysisProgress: 'Analysevoortgang',
        qaRemark: 'QA Opmerking',
        tacRemark: 'TAC Opmerking',
        responsibleDepartment: 'Verantwoordelijke Afdeling',
        coordinator: 'Coördinator',
        principal: 'Verantwoordelijke',
        containmentAction: 'Beheersmaatregel',
        issueStatus: 'Probleemstatus',
        softwareProblems: 'Softwareproblemen',
        vehGlobalFwVer_j: 'Baseline Versienummer',
        vehGlobalFwPn_j: 'Baseline Onderdeelnummer',
        ewo: 'EWO Nummer',
        rootCause: 'Hoofdoorzaak',
        marketBehavior: 'Marktgedrag',
        shortTermMeasures: 'Korte Termijn Maatregelen',
        shortTermBreakpointDescription: 'Korte Termijn Breakpoint Beschrijving',
        measuresResult: 'Maatregelen Goedkeuringsresultaat',
        measuresConfirmation: 'Maatregelen Bevestiging',
        longTermMeasures: 'Lange Termijn Maatregelen',
        longTermMeasuresBreak: 'Geschatte Breakpoint Beschrijving van Lange Termijn Maatregelen',
        implementation: 'Implementatie',
        longMeasuresTime: 'Werkelijke Breakpoint Datum van Lange Termijn Maatregelen',
        vinBreak: 'Break VIN van Lange Termijn Maatregelen',
        breakpointImplementationPhase: 'Implementatiefase van Breakpoint',
        archive_Information: 'Archiefinformatie',
        reason_incomplete_closure: 'Reden voor onvolledige sluiting',
        entry_Date: 'Ingangsdatum',
        departure_date: 'Vertrekdatum',
        work_order_number: 'Werkordernummer',
        questionnaire_usage: 'Gebruik van de vragenlijst',
        classification_of_technical_enquiries: 'Classificatie van technische vragen',
    },
    // 越南
    viVN: {
        vin: 'Số khung',
        inquiry_no: 'Mã số vụ việc',
        case_theme: 'Chủ đề vụ việc',
        department_name: 'Tên phòng ban',
        department_type: 'Loại phòng ban',
        document_status: 'Trạng thái tài liệu',
        inquiry_date: 'Ngày hỏi',
        organization_name: 'Tên tổ chức',
        name: 'Tên đề xuất',
        sap_code: 'Mã SAP',
        failed_part: 'Ảnh hưởng',
        case_type: 'Loại trường hợp',
        vehicle_status: 'Bảo trì tình trạng',
        case_owner: 'Người chịu trách nhiệm',
        country: 'Quốc gia',
        fault_cause: 'Nguyên nhân lỗi',
        symptoms: 'Hiện tượng lỗi',
        is_the_document_qualified: 'Tài liệu có đủ điều kiện',
        is_the_document_replied_in_a_timely_manner: 'Tài liệu đã được trả lời kịp thời',
        filter: 'Bộ lọc',
        system_classification_I: 'Hệ thống I',
        system_classification_II: 'Hệ thống II',
        full_query: 'Truy vấn đầy đủ',
        fuzzy_query: 'Truy vấn mờ',
        whether_belongs_new_car_market: 'Xe đã được giới thiệu ra thị trường',
        caseStatus: 'Trạng thái vụ án',
        correct: 'Đúng',
        deny: 'Không',
        not_closed: 'Trạng thái mở',
        no_reply: 'Chờ trả lời',
        replied: 'Trả lời',
        closed: 'Đóng',
        drafts: 'Thư mục tạm',
        create_time: 'Thời gian tạo',
        create_user: 'Tạo bởi',
        parent_of_document: 'Tài liệu cấp trên',
        document_owner: 'Người sở hữu vụ việc',
        case_subject: 'Chủ đề vụ án',
        receipt_info: 'Thông tin vụ việc',
        superior_of_case: 'Người quản lý vụ việc',
        inquiry_time: 'Thời gian truy vấn',
        status: 'Trạng thái',
        overseas_company: 'Tên công ty bán hàng',
        general_agent_name: 'Tên nhà phân phối',
        dealer_code: 'Mã đại ký',
        dealer_name: 'Tên đại lý',
        name_of_subsidiary: 'Tên công ty con',
        contacts: 'Người liên hệ',
        contact_information: 'Thông tin liên hệ',
        training_certification: 'Chứng chỉ đào tạo',
        driving_mileage: 'Số km đã đi được',
        plate_number: 'Biển số #',
        material_no: 'Mã vật liệu xe',
        material_desc: 'Mô tả vật liệu',
        offline_date: 'Ngày off-line',
        model_platform: 'Nền tảng',
        engine_number: 'Số động cơ',
        transmission_type: 'Hộp số',
        assembly_no: 'Số CSN',
        fault_info_desc: 'Mô tả lỗi',
        fault_handle_process: 'Thực hiện khắc phục',
        solution: 'Giải pháp',
        remark: 'Ghi chú',
        caseRemark: 'Ghi chú vụ án',
        document_information: 'Thông tin tài liệu',
        basic_information_of_service_center: 'Thông tin trung tâm dịch vụ',
        basic_vehicle_information: 'Thông tin cơ bản về xe',
        problem_description: 'Mô tả sự cố',
        common_links: 'Đường dẫn hữu ích',
        claim_information: 'Thông tin phản ánh',
        diagnostic_report_record: 'Bảng lỗi',
        vehicle_history: 'Lịch sử truy vấn kỹ thuật',
        programming_history: 'Lịch sử cài đặt chương trình',
        vehicle_sales_logistics_information: 'Thông tin bán hàng và vận chuyển',
        new_vehicle_information: 'Thông tin xe mới',
        attachment_upload: 'Tải tệp đính kèm',
        diagnostic_report: 'Bảng lỗi',
        bulk_download: 'Tải xuống hàng loạt',
        document_View: 'Xem tài liệu',
        inquiry_content: 'Truy vấn chi tiết',
        inquiry_entry: 'Hỏi đáp',
        subordinate_record: 'Ghi chép câu hỏi dưới',
        superior_record: 'Ghi chú câu trả lời trên',
        send_content: 'Nội dung gửi',
        send_time: 'Thời gian gửi',
        sent_username: 'Nguời gửi',
        receive_name: 'Gửi tới',
        dept_name: 'Cấp độ',
        enclosure: 'Tệp đính kèm',
        wind_case: 'Đóng',
        close_order: 'Đóng danh sách',
        case_reporting: 'Nộp hồ sơ',
        pdf_preview: 'Xem trước PDF',
        SIS_maintenance: 'Bảo trì sự cố nghiêm trọng',
        Please_fill_in_the_inquiry_content: 'Vui lòng điền nội dung yêu cầu',
        sending_an_email: 'Gửi email',
        copy: 'Sao chép',
        repeat: 'VIN đã bị trùng',
        inconsistent: 'Không nhất quán về nền tảng xe',
        sendTo: 'Gửi đến',
        reply: 'Nhập cho truy vấn/phản hồi',
        please_check_the_exported_document: 'Vui lòng chọn tài liệu đã xuất',
        please_upload_diagnostic_report: 'Vui lòng tải lên báo cáo chẩn đoán',
        please_enter_the_vin: 'Vui lòng nhập VIN',
        placeholderDTC: 'Ví dụ: P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1. Xác minh vấn đề khách hàng quan tâm (chỉ để đảm bảo có vấn đề xảy ra)',
        placeholderFaultHandleProcess:
            '2. Xác định các triệu chứng liên quan (tìm kiếm và ghi lại tất cả các triệu chứng có thể liên quan đến vấn đề)\n' +
            '3. Phân tích các triệu chứng (xem xét cẩn thận các triệu chứng liên quan để tìm ra nguyên nhân phổ biến)\n' +
            '4. Xác nhận vấn đề (thu hẹp kết quả của bạn để đảm bảo bạn đã giải quyết đúng vấn đề)',
        placeholderSolution:
            '5. Sửa chữa (sửa chữa)\n' +
            '6. Xác minh hoạt động của xe (kiểm tra để đảm bảo rằng các bản sửa lỗi bạn đã thực hiện đã được giải quyết)',
        placeholderRemark: 'Ví dụ: Hộp lái-201806, Giảm chấn trước-2010101',
        placeholderContact: 'Vui lòng nhập tên người thực hiện',
        close_time: 'Thời gian kết thúc',
        one_touch: 'Trường hợp Xây dựng lại',
        confirm_generating_new_case: 'Xác nhận tạo vụ án mới?',
        closing_statement: 'Lời giải thích kết thúc',
        number_of_cases: 'Số trường hợp',
        number_of_vehicles: 'Số lượng xe',
        pleaseEnterContent: 'Vui lòng nhập nội dung',
        case_age: 'Tuổi vụ án',
        last_response_time: 'Thời gian đáp ứng cuối cùng',
        time_since_last_response: 'Thời gian kể từ lần trả lời cuối cùng',
        warranty_start_date: 'Ngày bắt đầu bảo hành',
        submit_reason: 'Lý do đệ trình',
        synchronize_PQCP: 'Đồng bộ PQCP',
        FPR_export: 'FPR Xuất khẩu',
        level_selection: 'Chọn cấp độ',
        category_selection: 'Lựa chọn phân loại',
        brand_selection: 'Lựa chọn thương hiệu',
        code: 'Mã số',
        description: 'Mô tả',
        major_complaints: 'Khiếu nại lớn',
        difficult_miscellaneous_diseases: 'Bệnh nan y',
        emerging_issues: 'Câu hỏi mới',
        breakpoint_failure: 'Lỗi breakpoint',
        general_complaints: 'Phàn nàn chung',
        other: 'Khác',
        roewe: 'Vinh Uy.',
        mg: 'MG',
        serious: 'Nghiêm trọng',
        secondary: 'Nhỏ',
        generally: 'Tổng quan',
        complete: 'Hoàn thành',
        please_select_level: 'Vui lòng chọn cấp độ',
        please_select_category: 'Vui lòng chọn',
        please_select_brand: 'Vui lòng chọn thương hiệu',
        placeholder_auto: 'Tự động mang ra',
        translate: 'Dịch',
        isTranslate: 'Dịch hay không',
        day: 'ngày',
        one_click_order: 'Một đĩa đơn quan trọng',
        greater_than: 'Lớn hơn',
        less_than: 'Nhỏ hơn',
        between: 'Giữa',
        document_label: 'Nhãn tài liệu',
        receipts_first_modification_time: 'Thời gian thay đổi biên lai đầu tiên',
        Selection_of_Document_Owner: 'Lựa chọn Người Chịu trách nhiệm về Tài liệu',
        responsiblePerson: 'Người chịu trách nhiệm：',
        functional_Domain: 'Lĩnh Vực Chức Năng',
        Category: 'Danh Mục',
        property: 'thuộc tính',
        reRepair: 'Sửa chữa lặp lại',
        pullInNum: 'Số lần nhập',
        perception: 'Lớp nhận thức',
        onSupport: 'Hỗ trợ tại chỗ',
        batteryNo: 'Số pin',
        normalStartDate: 'Ngày bảo hành',
        qmotorNo: 'Số máy trước',
        hmotorNo: 'Số máy sau',
        fault_cause_type: 'Loại Nguyên Nhân Sự Cố',
        measure_name: 'Tên bộ phận',
        part_name: 'Tên biện pháp',
        close_order_notification: 'Đóng đơn đặt hàng đòi hỏi phải có phản hồi từ người đề xuất.',
        TIS_Case_Data: 'Dữ liệu trường hợp TIS',
        case_File: 'Hồ sơ trường hợp',
        responsibility_Data: 'Dữ liệu trách nhiệm',
        longterm_Measures: 'Các biện pháp dài hạn',

        tisCaseNo: 'Số vụ TIS',
        vehGlobalFwVer: 'Số phiên bản phần mềm xe',
        vehGlobalFwPn: 'Số phần mềm phụ tùng xe',
        archivesNo: 'Số hồ sơ',
        archivesTitle: 'Tiêu đề hồ sơ',
        archivesSource: 'Nguồn hồ sơ',
        proposer: 'Người đề xuất',
        proposedTime: 'Ngày đề xuất',
        archivesManagementPersonnel: 'Nhân viên quản lý',
        functionalMode: 'Mô-đun chức năng',
        supplier: 'Nhà cung cấp',
        accessoriesInvolved: 'Phụ kiện liên quan',
        functionalDomain: 'Miền chức năng',
        invalidPartId: 'ID phần không hợp lệ',
        archivesType: 'Loại hồ sơ',
        archivesGrade: 'Đánh giá hồ sơ',
        problemDescription: 'Mô tả vấn đề',
        analysisProgress: 'Tiến độ phân tích',
        qaRemark: 'Nhận xét QA',
        tacRemark: 'Nhận xét TAC',
        responsibleDepartment: 'Bộ phận chịu trách nhiệm',
        coordinator: 'Điều phối viên',
        principal: 'Chủ nhiệm',
        containmentAction: 'Hành động kiểm soát',
        issueStatus: 'Trạng thái vấn đề',
        softwareProblems: 'Vấn đề phần mềm',
        vehGlobalFwVer_j: 'Số phiên bản cơ sở',
        vehGlobalFwPn_j: 'Số phần cơ sở',
        ewo: 'Số EWO',
        rootCause: 'Nguyên nhân gốc rễ',
        marketBehavior: 'Hành vi thị trường',
        shortTermMeasures: 'Biện pháp ngắn hạn',
        shortTermBreakpointDescription: 'Mô tả Breakpoint ngắn hạn',
        measuresResult: 'Kết quả phê duyệt biện pháp',
        measuresConfirmation: 'Xác nhận biện pháp',
        longTermMeasures: 'Biện pháp dài hạn',
        longTermMeasuresBreak: 'Mô tả dự đoán Breakpoint của biện pháp dài hạn',
        implementation: 'Thực hiện',
        longMeasuresTime: 'Ngày Breakpoint thực tế của biện pháp dài hạn',
        vinBreak: 'VIN của Breakpoint biện pháp dài hạn',
        breakpointImplementationPhase: 'Giai đoạn thực hiện Breakpoint',
        archive_Information: 'Thông tin lưu trữ',
        reason_incomplete_closure: 'Lý do đóng cửa không hoàn tất',
        entry_Date: 'Ngày vào',
        departure_date: 'Ngày rời',
        work_order_number: 'Số lệnh công việc',
        questionnaire_usage: 'Mục đích sử dụng bảng hỏi',
        classification_of_technical_enquiries: 'Phân loại các yêu cầu kỹ thuật',
    },
    // 西班牙
    esES: {
        vin: 'VIN',
        inquiry_no: 'Número de consulta',
        case_theme: 'Tema del caso',
        department_name: 'Nombre del departamento',
        department_type: 'Tipo de departamento',
        document_status: 'Estado del documento',
        inquiry_date: 'Fecha de consulta',
        organization_name: 'Nombre de la organización',
        name: 'Nombre del proponente',
        sap_code: 'Código SAP',
        failed_part: 'Piezas defectuosas',
        case_type: 'Tipo de caso',
        vehicle_status: 'Estado de mantenimiento',
        case_owner: 'Responsable',
        country: 'País',
        fault_cause: 'Causa de falla',
        symptoms: 'Síntoma de falla',
        is_the_document_qualified: '¿Documento calificado?',
        is_the_document_replied_in_a_timely_manner: '¿Respuesta oportuna al documento?',
        filter: 'Filtro',
        system_classification_I: 'Clasificación del sistema I',
        system_classification_II: 'Clasificación del sistema II',
        full_query: 'Consulta completa',
        fuzzy_query: 'Consulta difusa',
        whether_belongs_new_car_market: '¿Es un nuevo lanzamiento de vehículo?',
        caseStatus: 'Estado del caso',
        correct: 'Sí',
        deny: 'No',
        not_closed: 'No resuelto',
        no_reply: 'Esperando una respuesta',
        replied: 'Ha respondido',
        closed: 'Resuelto',
        drafts: 'Borrador',
        create_time: 'Fecha de creación',
        create_user: 'Creador',
        parent_of_document: 'Superior del documento',
        document_owner: 'Responsable del documento',
        case_subject: 'Tema del caso',
        receipt_info: 'Información del documento',
        superior_of_case: 'Superior del caso',
        inquiry_time: 'Hora de consulta',
        status: 'Estado',
        overseas_company: 'Nombre de la empresa en el extranjero',
        general_agent_name: 'Nombre del distribuidor principal',
        dealer_code: 'Código del distribuidor',
        dealer_name: 'Nombre del distribuidor',
        name_of_subsidiary: 'Nombre de la subsidiaria',
        contacts: 'Persona de contacto',
        contact_information: 'Información de contacto',
        training_certification: 'Certificación de capacitación',
        driving_mileage: 'Kilometraje',
        plate_number: 'Número de placa',
        material_no: 'Número de material',
        material_desc: 'Descripción del material',
        offline_date: 'Fecha de desactivación',
        model_platform: 'Plataforma del modelo de vehículo',
        engine_number: 'Número de motor',
        transmission_type: 'Número de transmisión',
        assembly_no: 'Número de ensamblaje',
        fault_info_desc: 'Descripción de la falla',
        fault_handle_process: 'Proceso de reparación de falla',
        solution: 'Solución',
        remark: 'Observaciones',
        caseRemark: 'Observaciones del caso',
        document_information: 'Información del documento',
        basic_information_of_service_center: 'Información básica del centro de servicio',
        basic_vehicle_information: 'Información básica del vehículo',
        problem_description: 'Descripción del problema',
        common_links: 'Enlaces frecuentes',
        claim_information: 'Información de reclamos',
        diagnostic_report_record: 'Registro de informes de diagnóstico',
        vehicle_history: 'Documentos históricos de consultas de vehículos',
        programming_history: 'Historial de programación',
        vehicle_sales_logistics_information: 'Información de ventas y logística del vehículo',
        new_vehicle_information: 'Información de vehículos nuevos agregados',
        attachment_upload: 'Carga de archivos adjuntos',
        diagnostic_report: 'Informe de diagnóstico',
        bulk_download: 'Descarga masiva',
        document_View: 'Vista de documentos',
        inquiry_content: 'Contenido de consulta',
        inquiry_entry: 'Respuesta a la consulta',
        subordinate_record: 'Registro de preguntas y respuestas de nivel inferior',
        superior_record: 'Registro de preguntas y respuestas de nivel superior',
        send_content: 'Contenido enviado',
        send_time: 'Fecha de envío',
        sent_username: 'Remitente',
        receive_name: 'Enviar a',
        dept_name: 'Nivel',
        enclosure: 'Adjunto',
        wind_case: 'Cerrar caso',
        close_order: 'Cerrar la mónada',
        case_reporting: 'Reportar caso',
        pdf_preview: 'Vista previa del archivo PDF',
        SIS_maintenance: 'Mantenimiento de accidentes graves',
        Please_fill_in_the_inquiry_content: 'Por favor ingrese el contenido de consulta',
        sending_an_email: 'Enviar correo electrónico',
        copy: 'Copiar',
        repeat: 'VIN duplicado',
        inconsistent: 'Plataforma del modelo de vehículo no coincidente',
        sendTo: 'Enviar a',
        reply: 'Ingrese el contenido de la consulta o respuesta',
        please_check_the_exported_document: 'Por favor seleccione los documentos para exportar',
        please_upload_diagnostic_report: 'Por favor cargue el informe de diagnóstico',
        please_enter_the_vin: 'Por favor ingrese el VIN',
        placeholderDTC: 'Ejemplo: P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1. Verificar el problema que le preocupa al cliente (solo para asegurarse de que haya un problema).',
        placeholderFaultHandleProcess:
            '2. Identificar los síntomas relacionados (buscar y registrar todos los síntomas que puedan estar relacionados con el problema).\n' +
            '3. Analizar los síntomas (observar detenidamente los síntomas relacionados y encontrar las posibles causas comunes).\n' +
            '4. Confirmar el problema (reducir el alcance de los resultados para asegurarse de que se está solucionando el problema correcto).',
        placeholderSolution:
            '5. Reparar el problema (realizar reparaciones).\n' +
            '6. Verificar el funcionamiento del vehículo (realizar una revisión para asegurarse de que las reparaciones realizadas hayan solucionado el problema).',
        placeholderRemark:
            'Por ejemplo: Dirección asistida -201806, Amortiguador frontal izquierdo -2010101',
        placeholderContact: 'Por favor ingrese el nombre del operador',
        close_time: 'Tiempo de cierre',
        one_touch: 'Reconstruir el documento',
        confirm_generating_new_case: '¿Desea generar un nuevo documento?',
        closing_statement: 'Explicación de cierre',
        number_of_cases: 'Cantidad de documentos',
        number_of_vehicles: 'Cantidad de vehículos',
        pleaseEnterContent: 'Por favor, introduzca el contenido',
        case_age: 'Edad del caso',
        last_response_time: 'Último tiempo de respuesta',
        time_since_last_response: 'Tiempo desde la última respuesta',
        warranty_start_date: 'Fecha de inicio de la garantía',
        submit_reason: 'Razones para presentar',
        synchronize_PQCP: 'PQCP simultáneo',
        FPR_export: 'FPR exportación',
        level_selection: 'Selección de niveles',
        category_selection: 'Selección de clasificación',
        brand_selection: 'Selección de marca',
        code: 'Código',
        description: 'Descripción',
        major_complaints: 'Quejas importantes',
        difficult_miscellaneous_diseases: 'Enfermedades difíciles y complicadas',
        emerging_issues: 'Nuevos problemas',
        breakpoint_failure: 'Falla del punto de interrupción',
        general_complaints: 'Quejas generales',
        other: 'Otros',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Grave',
        secondary: 'Secundario',
        generally: 'General',
        complete: 'Completado',
        please_select_level: 'Por favor, elija el nivel',
        please_select_category: 'Por favor, elija la clasificación',
        please_select_brand: 'Por favor, elija la marca',
        placeholder_auto: 'Sacar automáticamente',
        translate: 'Traducción',
        isTranslate: 'Si traducir',
        day: 'días',
        one_click_order: 'Un botón para cerrar la orden',
        greater_than: 'Mayor que',
        less_than: 'Menos',
        between: 'Entre',
        document_label: 'Etiqueta del documento',
        receipts_first_modification_time: 'Recibos primera modificación tiempo',
        Selection_of_Document_Owner: 'Selección de la Persona Responsable del Documento',
        responsiblePerson: 'Persona Responsable：',
        functional_Domain: 'Dominio Funcional',
        Category: 'Categoría',
        property: 'atributo',
        reRepair: 'Reparaciones repetidas',
        pullInNum: 'Número de entradas',
        perception: 'Clase de percepción',
        onSupport: 'Soporte en el lugar',
        batteryNo: 'Número de batería',
        normalStartDate: 'Fecha de garantía',
        qmotorNo: 'Número de motor delantero',
        hmotorNo: 'Número de motor trasero',
        fault_cause_type: 'Tipo de causa del fallo',
        measure_name: 'Nombre de la medida',
        part_name: 'Nombre de la pieza',
        close_order_notification: 'Cerrar el pedido requiere una respuesta del proponente.',
        TIS_Case_Data: 'Datos del caso TIS',
        case_File: 'Archivo del caso',
        responsibility_Data: 'Datos de responsabilidad',
        longterm_Measures: 'Medidas a largo plazo',

        tisCaseNo: 'Número de Caso TIS',
        vehGlobalFwVer: 'Número de Versión del Software del Vehículo',
        vehGlobalFwPn: 'Número de Parte del Software del Vehículo',
        archivesNo: 'Número de Archivo',
        archivesTitle: 'Título del Archivo',
        archivesSource: 'Fuente del Archivo',
        proposer: 'Proponente',
        proposedTime: 'Fecha Propuesta',
        archivesManagementPersonnel: 'Personal de Gestión',
        functionalMode: 'Módulo Funcional',
        supplier: 'Proveedor',
        accessoriesInvolved: 'Accesorios Involucrados',
        functionalDomain: 'Dominio Funcional',
        invalidPartId: 'ID de Parte Inválida',
        archivesType: 'Tipo de Archivo',
        archivesGrade: 'Grado de Archivo',
        problemDescription: 'Descripción del Problema',
        analysisProgress: 'Progreso del Análisis',
        qaRemark: 'Observación QA',
        tacRemark: 'Observación TAC',
        responsibleDepartment: 'Departamento Responsable',
        coordinator: 'Coordinador',
        principal: 'Principal',
        containmentAction: 'Acción de Contención',
        issueStatus: 'Estado del Problema',
        softwareProblems: 'Problemas de Software',
        vehGlobalFwVer_j: 'Número de Versión Base',
        vehGlobalFwPn_j: 'Número de Parte Base',
        ewo: 'Número EWO',
        rootCause: 'Causa Raíz',
        marketBehavior: 'Comportamiento del Mercado',
        shortTermMeasures: 'Medidas a Corto Plazo',
        shortTermBreakpointDescription: 'Descripción del Breakpoint a Corto Plazo',
        measuresResult: 'Resultado de Aprobación de Medidas',
        measuresConfirmation: 'Confirmación de Medidas',
        longTermMeasures: 'Medidas a Largo Plazo',
        longTermMeasuresBreak: 'Descripción Estimada del Breakpoint de Medidas a Largo Plazo',
        implementation: 'Implementación',
        longMeasuresTime: 'Fecha Real del Breakpoint de Medidas a Largo Plazo',
        vinBreak: 'VIN del Breakpoint de Medidas a Largo Plazo',
        breakpointImplementationPhase: 'Fase de Implementación del Breakpoint',
        archive_Information: 'Información del archivo',
        reason_incomplete_closure: 'Razón de cierre incompleto',
        entry_Date: 'Fecha de entrada',
        departure_date: 'Fecha de salida',
        work_order_number: 'Número de orden de trabajo',
        questionnaire_usage: 'Uso del cuestionario',
        classification_of_technical_enquiries: 'Clasificación de consultas técnicas',
    },
    // 葡萄牙
    ptBR: {
        vin: 'VIN',
        inquiry_no: 'Número da consulta',
        case_theme: 'Tema do caso',
        department_name: 'Nome do departamento',
        department_type: 'Tipo de departamento',
        document_status: 'Status do documento',
        inquiry_date: 'Data da consulta',
        organization_name: 'Nome da organização',
        name: 'Nome do proponente',
        sap_code: 'Código SAP',
        failed_part: 'Peça com falha',
        case_type: 'Tipo de caso',
        vehicle_status: 'Status da manutenção',
        case_owner: 'Responsável pelo caso',
        country: 'País',
        fault_cause: 'Causa da falha',
        symptoms: 'Sintomas',
        is_the_document_qualified: 'O documento está qualificado',
        is_the_document_replied_in_a_timely_manner: 'O documento foi respondido em tempo hábil',
        filter: 'Filtro',
        system_classification_I: 'Classificação do sistema I',
        system_classification_II: 'Classificação do sistema II',
        full_query: 'Consulta completa',
        fuzzy_query: 'Consulta fuzzy',
        whether_belongs_new_car_market: 'Pertence ao mercado de carros novos',
        caseStatus: 'Status do caso',
        correct: 'Sim',
        deny: 'Não',
        not_closed: 'Não fechado',
        no_reply: 'Sem resposta',
        replied: 'Respondido',
        closed: 'Fechado',
        drafts: 'Rascunhos',
        create_time: 'Tempo de criação',
        create_user: 'Criado por',
        parent_of_document: 'Documento superior',
        document_owner: 'Responsável pelo documento',
        case_subject: 'Assunto do caso',
        receipt_info: 'Informação do documento',
        superior_of_case: 'Superior do caso',
        inquiry_time: 'Tempo da consulta',
        status: 'Status',
        overseas_company: 'Nome da empresa no exterior',
        general_agent_name: 'Nome do agente geral',
        dealer_code: 'Código do revendedor',
        dealer_name: 'Nome do revendedor',
        name_of_subsidiary: 'Nome da subsidiária',
        contacts: 'Contatos',
        contact_information: 'Informação de contato',
        training_certification: 'Certificação de treinamento',
        driving_mileage: 'Quilometragem',
        plate_number: 'Placa',
        material_no: 'Número do material',
        material_desc: 'Descrição do material',
        offline_date: 'Data de offline',
        model_platform: 'Plataforma do veículo',
        engine_number: 'Número do motor',
        transmission_type: 'Tipo de transmissão',
        assembly_no: 'Número de montagem',
        fault_info_desc: 'Descrição da informação de falha',
        fault_handle_process: 'Processo de tratamento de falha',
        solution: 'Solução',
        remark: 'Observação',
        caseRemark: 'Observação do caso',
        document_information: 'Informação do documento',
        basic_information_of_service_center: 'Informação básica do centro de serviços',
        basic_vehicle_information: 'Informação básica do veículo',
        problem_description: 'Descrição do problema',
        common_links: 'Links comuns',
        claim_information: 'Informação de reivindicação',
        diagnostic_report_record: 'Registro do relatório de diagnóstico',
        vehicle_history: 'Histórico de consultas de veículos',
        programming_history: 'Histórico de programação',
        vehicle_sales_logistics_information: 'Informação de vendas e logística do veículo',
        new_vehicle_information: 'Informação do veículo novo',
        attachment_upload: 'Upload de anexo',
        diagnostic_report: 'Relatório de diagnóstico',
        bulk_download: 'Download em massa',
        document_View: 'Visualização de documentos',
        inquiry_content: 'Conteúdo da consulta',
        inquiry_entry: 'Resposta à consulta',
        subordinate_record: 'Registro de perguntas e respostas subordinado',
        superior_record: 'Registro de perguntas e respostas superior',
        send_content: 'Conteúdo enviado',
        send_time: 'Tempo de envio',
        sent_username: 'Remetente',
        receive_name: 'Destinatário',
        dept_name: 'Nível',
        enclosure: 'Anexo',
        wind_case: 'Encerrar caso',
        close_order: 'Fechar a mônada',
        case_reporting: 'Relatório de caso',
        pdf_preview: 'Visualização PDF',
        SIS_maintenance: 'Manutenção de acidentes graves',
        Please_fill_in_the_inquiry_content: 'Por favor, preencha o conteúdo da consulta',
        sending_an_email: 'Enviando um email',
        copy: 'Copiar',
        repeat: 'Vin repetido',
        inconsistent: 'Plataforma do veículo inconsistente',
        sendTo: 'Enviar para',
        reply: 'Digite o conteúdo para consulta/resposta',
        please_check_the_exported_document: 'Por favor, verifique o documento exportado',
        please_upload_diagnostic_report: 'Por favor, faça upload do relatório de diagnóstico',
        please_enter_the_vin: 'Por favor, insira o VIN',
        placeholderDTC: 'Exemplo: P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1. Verifique o problema do cliente (apenas para garantir que haja um problema)',
        placeholderFaultHandleProcess:
            '2. Determine os sintomas relacionados (encontre e registre todos os sintomas relacionados ao problema)\n' +
            '3. Analise os sintomas (observe cuidadosamente os sintomas relacionados para encontrar as causas comuns)\n' +
            '4. Confirme o problema (estreite o escopo de suas conclusões para garantir que você esteja resolvendo o problema correto)',
        placeholderSolution:
            '5. Reparar o problema (realizar manutenção)\n' +
            '6. Verificar o desempenho do veículo (verificar se a solução que você fez resolveu o problema)',
        placeholderRemark: 'Exemplo: Direção - 201806, amortecedor dianteiro esquerdo - 2010101',
        placeholderContact: 'Por favor, insira o nome do operador',
        close_time: 'Tempo de encerramento',
        one_touch: 'Reconstrução do caso',
        confirm_generating_new_case: 'Confirmar a criação de um novo caso?',
        closing_statement: 'Declaração de encerramento',
        number_of_cases: 'Número de casos',
        number_of_vehicles: 'Número de veículos',
        pleaseEnterContent: 'Por favor, insira o conteúdo',
        case_age: 'Idade do caso',
        last_response_time: 'Último tempo de resposta',
        time_since_last_response: 'Tempo desde a última resposta',
        warranty_start_date: 'Data de início da garantia',
        submit_reason: 'Motivo do envio',
        synchronize_PQCP: 'Sincronizar PQCP',
        FPR_export: 'Exportar FPR',
        level_selection: 'Seleção de nível',
        category_selection: 'Seleção de categoria',
        brand_selection: 'Seleção de marca',
        code: 'Código',
        description: 'Descrição',
        major_complaints: 'Principais reclamações',
        difficult_miscellaneous_diseases: 'Doenças miscelâneas difíceis',
        emerging_issues: 'Questões emergentes',
        breakpoint_failure: 'Falha em ponto de interrupção',
        general_complaints: 'Reclamações gerais',
        other: 'Outro',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Grave',
        secondary: 'Secundário',
        generally: 'Geralmente',
        complete: 'Completo',
        please_select_level: 'Por favor, selecione o nível',
        please_select_category: 'Por favor, selecione a categoria',
        please_select_brand: 'Por favor, selecione a marca',
        placeholder_auto: 'Preenchimento automático',
        translate: 'Traduzir',
        isTranslate: 'Traduzir',
        day: 'dia',
        one_click_order: 'Uma chave para fechar a ordem',
        greater_than: 'Maior que',
        less_than: 'Menos de',
        between: 'Entre',
        document_label: 'Rótulo do documento',
        receipts_first_modification_time: 'Recibos primeira hora de modificação',
        Selection_of_Document_Owner: 'Seleção da Pessoa Responsável pelo Documento',
        responsiblePerson: 'Pessoa Responsável：',
        functional_Domain: 'Domínio Funcional',
        Category: 'Categoria',
        property: 'atributo',
        reRepair: 'Reparações repetidas',
        pullInNum: 'Número de entradas',
        perception: 'Classe de percepção',
        onSupport: 'Suporte no local',
        batteryNo: 'Número da bateria',
        normalStartDate: 'Data de garantia',
        qmotorNo: 'Número do motor dianteiro',
        hmotorNo: 'Número do motor traseiro',
        fault_cause_type: 'Tipo de causa da falha',
        measure_name: 'Nome da medida',
        part_name: 'Nome da peça',
        close_order_notification: 'Fechar o pedido requer uma resposta do proponente.',
        TIS_Case_Data: 'Dados do caso TIS',
        case_File: 'Arquivo do caso',
        responsibility_Data: 'Dados de responsabilidade',
        longterm_Measures: 'Medidas a longo prazo',

        tisCaseNo: 'Número do Caso TIS',
        vehGlobalFwVer: 'Número da Versão do Software do Veículo',
        vehGlobalFwPn: 'Número da Peça do Software do Veículo',
        archivesNo: 'Número do Arquivo',
        archivesTitle: 'Título do Arquivo',
        archivesSource: 'Fonte do Arquivo',
        proposer: 'Proponente',
        proposedTime: 'Data Proposta',
        archivesManagementPersonnel: 'Pessoal de Gestão',
        functionalMode: 'Módulo Funcional',
        supplier: 'Fornecedor',
        accessoriesInvolved: 'Acessórios Envolvidos',
        functionalDomain: 'Domínio Funcional',
        invalidPartId: 'ID da Peça Inválida',
        archivesType: 'Tipo de Arquivo',
        archivesGrade: 'Grau do Arquivo',
        problemDescription: 'Descrição do Problema',
        analysisProgress: 'Progresso da Análise',
        qaRemark: 'Observação QA',
        tacRemark: 'Observação TAC',
        responsibleDepartment: 'Departamento Responsável',
        coordinator: 'Coordenador',
        principal: 'Principal',
        containmentAction: 'Ação de Contenção',
        issueStatus: 'Status do Problema',
        softwareProblems: 'Problemas de Software',
        vehGlobalFwVer_j: 'Número da Versão Base',
        vehGlobalFwPn_j: 'Número da Peça Base',
        ewo: 'Número EWO',
        rootCause: 'Causa Raiz',
        marketBehavior: 'Comportamento do Mercado',
        shortTermMeasures: 'Medidas de Curto Prazo',
        shortTermBreakpointDescription: 'Descrição do Breakpoint de Curto Prazo',
        measuresResult: 'Resultado da Aprovação das Medidas',
        measuresConfirmation: 'Confirmação das Medidas',
        longTermMeasures: 'Medidas de Longo Prazo',
        longTermMeasuresBreak: 'Descrição Estimada do Breakpoint das Medidas de Longo Prazo',
        implementation: 'Implementação',
        longMeasuresTime: 'Data Real do Breakpoint das Medidas de Longo Prazo',
        vinBreak: 'VIN do Breakpoint das Medidas de Longo Prazo',
        breakpointImplementationPhase: 'Fase de Implementação do Breakpoint',
        archive_Information: 'Informações do arquivo',
        reason_incomplete_closure: 'Razão para fechamento incompleto',
        entry_Date: 'Data de entrada',
        departure_date: 'Data de saída',
        work_order_number: 'Número da ordem de serviço',
        questionnaire_usage: 'Uso do questionário',
        classification_of_technical_enquiries: 'Classificação de consultas técnicas',
    },
    // 波兰
    plPL: {
        vin: 'VIN',
        inquiry_no: 'Numer zapytania',
        case_theme: 'Temat zapytania',
        department_name: 'Nazwa oddziału',
        department_type: 'Typ odziału',
        document_status: 'Stan dokumentów',
        inquiry_date: 'Data zapytania',
        organization_name: 'Nazwa organizacji',
        name: 'Nazwa wnioskodawcy',
        sap_code: 'Kod SAP',
        failed_part: 'Uszkodzona część',
        case_type: 'Typ sprawy',
        vehicle_status: 'Status pojazdu',
        case_owner: 'Osoba odpowiedzialna',
        country: 'Kraj',
        fault_cause: 'Przyczyna błędu',
        symptoms: 'zjawisko usterki',
        is_the_document_qualified: 'Czy sprawa się kwalifikuje',
        is_the_document_replied_in_a_timely_manner:
            'Czy odpowiedź została udzielona w odpowiednim czasie?',
        filter: 'Filtr',
        system_classification_I: 'System 1',
        system_classification_II: 'System 2',
        full_query: 'Pełne zapytanie',
        fuzzy_query: 'Rozmyte zapytanie',
        whether_belongs_new_car_market: 'Czy nowo wprowadzony pojazd?',
        caseStatus: 'Status sprawy',
        correct: 'Poprawny',
        deny: 'Odmowa',
        not_closed: 'Otwarta',
        no_reply: 'Brak odpowiedzi',
        replied: 'Udzielono odpowiedzi',
        closed: 'Zamknięta',
        drafts: 'Robocze',
        create_time: 'Czas założenia',
        create_user: 'Założona przez',
        parent_of_document: 'Właściciel dokumentów',
        document_owner: 'Właściciel sprawy',
        case_subject: 'Temat',
        receipt_info: 'Informacje o sprawie',
        superior_of_case: 'Przełożony',
        inquiry_time: 'Czas zapytania',
        status: 'Status',
        overseas_company: 'Nazwa firmy sprzedającej',
        general_agent_name: 'Nazwa dystrybutora',
        dealer_code: 'Kod dealera',
        dealer_name: 'Nazwa dealera',
        name_of_subsidiary: 'Nazwa spółki zależnej',
        contacts: 'Osoba kontaktowa',
        contact_information: 'Kontakt',
        training_certification: 'Certyfikat szkolenia',
        driving_mileage: 'Przebieg',
        plate_number: 'Numer rejestracyjny',
        material_no: 'Kod materiałowy pojazdu',
        material_desc: 'Opis materiału',
        offline_date: 'Data zejścia z linii',
        model_platform: 'Platforma',
        engine_number: 'Numer silnika',
        transmission_type: 'Skrzynia biegów',
        assembly_no: 'Numer CSN',
        fault_info_desc: 'Opis błędu',
        fault_handle_process: 'Podjęte działania ',
        solution: 'Rozwiązanie',
        remark: 'Uwagi',
        caseRemark: 'Uwagi do sprawy',
        document_information: 'Informacje o sprawie',
        basic_information_of_service_center: 'Informacje centrum serwisowego',
        basic_vehicle_information: 'Podstawowe informaje o pojeździe',
        problem_description: 'Opis problemu',
        common_links: 'Przydatne linki',
        claim_information: 'Informacje o reklamacji',
        diagnostic_report_record: 'Raport diagnostyczny',
        vehicle_history: 'Historia zapytań technicznych',
        programming_history: 'Historia programowania',
        vehicle_sales_logistics_information: 'Inpofmacje sprzedażowe i logistyczne',
        new_vehicle_information: 'Informacje o nowym pojeździe',
        attachment_upload: 'Prześlij załącznik',
        diagnostic_report: 'Raport diagnostyczny',
        bulk_download: 'Pobieranie zbiorcze',
        document_View: 'Przeglądanie dokumentów',
        inquiry_content: 'Szczegóły zapytania',
        inquiry_entry: 'Odpowiedź na zapytanie',
        subordinate_record: 'record',
        superior_record: 'Zapis podwładnego',
        send_content: 'Zapis przełożonegsuperior',
        send_time: 'Czas wysłania',
        sent_username: 'Nadawca',
        receive_name: 'Wysłać do',
        dept_name: 'Poziom',
        enclosure: 'Załącznik',
        wind_case: 'Zamknięta',
        close_order: 'Zamknij zamówienie',
        case_reporting: 'Raport sprawy',
        pdf_preview: 'Podgląd PDF',
        SIS_maintenance: 'Serwis po poważnym wypadku',
        Please_fill_in_the_inquiry_content: 'Proszę o uzupełnienie treści zapytania',
        sending_an_email: 'Wysyłanie wiadomości e-mail',
        copy: 'Skopiuj',
        repeat: 'Powtórzenie numeru VIN',
        inconsistent: 'Niespójna platforma pojazdu',
        sendTo: 'Wyślij do',
        reply: 'Wprowadź treść zapytania/odpowiedzi',
        please_check_the_exported_document: 'Proszę zaznaczyć eksportowany dokument',
        please_upload_diagnostic_report: 'Proszę przesłać raport diagnostyczny',
        please_enter_the_vin: 'Proszę wprowadzić nr VIN',
        placeholderDTC: 'Przykład: P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1. Zweryfikuj obawy klienta (aby upewnić się, że problemy się pojawiają)',
        placeholderFaultHandleProcess:
            '2. Zidentyfikuj symptomy (zidentyfikuj i zapisz wszystkie symptomy, które mogą być związane z problemem.\n' +
            '3. Zanalizuj symptomy (uważnie obserwuj symptomy, aby zidentykować typowe przyczyny)\n' +
            '4. Potwierdź problem (zawęż zakres wyników, aby mieć pewność, że rozwiązałeś właściwy problem.)',
        placeholderSolution:
            '5. Napraw problem (przeprowadź naprawę)\n' +
            '6. Sprawdź działanie pojazdu (sprawdź, czy naprawa rozwiązała problem)',
        placeholderRemark: 'Przykład: Przekładnia kierownicza-201806, Amortyzator FL-2010101',
        placeholderContact: 'Wpisz imię i nazwisko oepratora',
        close_time: 'Czas zakończenia',
        one_touch: 'Rekonstrukcja sprawy',
        confirm_generating_new_case: 'Potwierdź utworzenie nowej sprawy?',
        closing_statement: 'Wyajśnienie końcowe',
        number_of_cases: 'Liczba spraw',
        number_of_vehicles: 'Liczba pojazdów',
        pleaseEnterContent: 'Proszę wprowadzić treść',
        case_age: 'Wiek sprawy',
        last_response_time: 'Ostatni czas odpowiedzi',
        time_since_last_response: 'Czas od ostatniej odpowiedzi',
        warranty_start_date: 'Data rozpoczęcia gwarancji',
        submit_reason: 'Prześlij powód',
        synchronize_PQCP: 'Synchronizuj PQCP',
        FPR_export: 'Eksport FPR',
        level_selection: 'Wybór poziomu',
        category_selection: 'Wybór kategorii',
        brand_selection: 'Wybór marki',
        code: 'Kod',
        description: 'Opis',
        major_complaints: 'Główne skargi',
        difficult_miscellaneous_diseases: 'Trudne, różnorodne choroby',
        emerging_issues: 'Nowo pojawiające się problemy',
        breakpoint_failure: 'Awaria w punkcie zerowym',
        general_complaints: 'Ogólne skargi',
        other: 'Inne',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Poważny',
        secondary: 'Drugorzędny',
        generally: 'Zazwyczaj',
        complete: 'Zakończone',
        please_select_level: 'Proszę wybrać poziom',
        please_select_category: 'Proszę wybrać kategorię',
        please_select_brand: 'Proszę wybrać markę',
        placeholder_auto: 'Automatyczne wypełnienie',
        translate: 'Przetłumacz',
        isTranslate: 'Czy przetłumaczyć?',
        day: 'dzień',
        one_click_order: 'Odprawa celna jednym kliknięciem',
        greater_than: 'Większa niż',
        less_than: 'Mniej niż',
        between: 'Między',
        document_label: 'Etykieta dokumentu',
        receipts_first_modification_time: 'Czas pierwszej modyfikacji paragonów',
        Selection_of_Document_Owner: 'Wybór Osoby Odpowiedzialnej za Dokument',
        responsiblePerson: 'Osoba Odpowiedzialna：',
        functional_Domain: 'Obszar Funkcjonalny',
        Category: 'Kategoria',
        property: 'przyczynowość',
        reRepair: 'Powtarzające się naprawy',
        pullInNum: 'Liczba wpisów',
        perception: 'Klasa percepcji',
        onSupport: 'Wsparcie na miejscu',
        batteryNo: 'Numer baterii',
        normalStartDate: 'Data gwarancji',
        qmotorNo: 'Numer silnika przedniego',
        hmotorNo: 'Numer silnika tylnego',
        fault_cause_type: 'Typ przyczyny awarii',
        measure_name: 'Nazwa środka',
        part_name: 'Nazwa części',
        close_order_notification: 'Zamknięcie zamówienia wymaga odpowiedzi od wnioskodawcy.',
        TIS_Case_Data: 'Dane sprawy TIS',
        case_File: 'Plik sprawy',
        responsibility_Data: 'Dane dotyczące odpowiedzialności',
        longterm_Measures: 'Środki długoterminowe',

        tisCaseNo: 'Numer Przypadku TIS',
        vehGlobalFwVer: 'Numer Wersji Oprogramowania Pojazdu',
        vehGlobalFwPn: 'Numer Części Oprogramowania Pojazdu',
        archivesNo: 'Numer Archiwum',
        archivesTitle: 'Tytuł Archiwum',
        archivesSource: 'Źródło Archiwum',
        proposer: 'Wnioskodawca',
        proposedTime: 'Data Wniosku',
        archivesManagementPersonnel: 'Personel Zarządzający',
        functionalMode: 'Moduł Funkcjonalny',
        supplier: 'Dostawca',
        accessoriesInvolved: 'Zaangażowane Akcesoria',
        functionalDomain: 'Domena Funkcjonalna',
        invalidPartId: 'Nieprawidłowy ID Części',
        archivesType: 'Typ Archiwum',
        archivesGrade: 'Ocena Archiwum',
        problemDescription: 'Opis Problemu',
        analysisProgress: 'Postęp Analizy',
        qaRemark: 'Uwagi QA',
        tacRemark: 'Uwagi TAC',
        responsibleDepartment: 'Odpowiedzialny Dział',
        coordinator: 'Koordynator',
        principal: 'Główny',
        containmentAction: 'Działania Zabezpieczające',
        issueStatus: 'Status Problemu',
        softwareProblems: 'Problemy Oprogramowania',
        vehGlobalFwVer_j: 'Numer Wersji Bazowej',
        vehGlobalFwPn_j: 'Numer Części Bazowej',
        ewo: 'Numer EWO',
        rootCause: 'Przyczyna Główna',
        marketBehavior: 'Zachowanie Rynkowe',
        shortTermMeasures: 'Krótkoterminowe Środki',
        shortTermBreakpointDescription: 'Opis Krótkoterminowego Breakpointu',
        measuresResult: 'Wynik Zatwierdzenia Środków',
        measuresConfirmation: 'Potwierdzenie Środków',
        longTermMeasures: 'Długoterminowe Środki',
        longTermMeasuresBreak: 'Szacowany Opis Breakpointu Długoterminowych Środków',
        implementation: 'Wdrożenie',
        longMeasuresTime: 'Rzeczywista Data Breakpointu Długoterminowych Środków',
        vinBreak: 'VIN Breakpointu Długoterminowych Środków',
        breakpointImplementationPhase: 'Faza Wdrożenia Breakpointu',
        archive_Information: 'Informacje archiwalne',
        reason_incomplete_closure: 'Powód niekompletnego zamknięcia',
        entry_Date: 'Data przybycia',
        departure_date: 'Data wyjazdu',
        work_order_number: 'Numer zlecenia',
        questionnaire_usage: 'Zastosowanie ankiety',
        classification_of_technical_enquiries: 'Klasyfikacja zapytań technicznych',
    },
    // 土耳其
    trTR: {
        vin: 'VIN',
        inquiry_no: 'Dosya No.',
        case_theme: 'Dosya konusu',
        department_name: 'Departman adı',
        department_type: 'Departman türü',
        document_status: 'Belge durumu',
        inquiry_date: 'Sorgulama tarihi',
        organization_name: 'Organizasyon adı',
        name: 'Teklif adı',
        sap_code: 'SAP kodu',
        failed_part: 'Etkilendi',
        case_type: 'Bölge türü',
        vehicle_status: 'Araç durumu',
        case_owner: 'Sorumlu kişi',
        country: 'Ülke',
        fault_cause: 'Hata sebebi',
        symptoms: 'Hata semptomu',
        is_the_document_qualified: 'Belgeler nitelikli mi?',
        is_the_document_replied_in_a_timely_manner: 'Dosyaya zamanında geri dönüş yapıldı mı?',
        filter: 'Filtreleme',
        system_classification_I: 'Sistem 1',
        system_classification_II: 'Sistem 2',
        full_query: 'Tam soru',
        fuzzy_query: 'Bulanık soru',
        whether_belongs_new_car_market: 'Yeni araç tanıtımı',
        caseStatus: 'Dosya durumu',
        correct: 'Doğru',
        deny: 'Red et',
        not_closed: 'Aç',
        no_reply: 'Cevap yok',
        replied: 'Cevap verildi',
        closed: 'Kapatıldı',
        drafts: 'Taslak kutusu',
        create_time: 'Oluşturma zamanı',
        create_user: 'Oluşturan kişi',
        parent_of_document: 'Belgenin ait olduğu yönetici',
        document_owner: 'Dosyanın Sahibi',
        case_subject: 'Konu',
        receipt_info: 'Dosya bilgisi',
        superior_of_case: 'Dosya yöneticisi',
        inquiry_time: 'Sorgulama zamanı',
        status: 'Durum',
        overseas_company: 'Satış şirketi ismi',
        general_agent_name: 'Dağıtıcı adı',
        dealer_code: 'Bayi kodu',
        dealer_name: 'Bayi adı',
        name_of_subsidiary: 'Bağlı kuruluş adı',
        contacts: 'Kontakt kişi',
        contact_information: 'Kontakt bilgisi',
        training_certification: 'Eğitim sertifikası',
        driving_mileage: 'Km si',
        plate_number: 'Plaka no',
        material_no: 'Araç malzeme kodu',
        material_desc: 'Malzeme tanımı',
        offline_date: 'Devre-tışı tarih',
        model_platform: 'Platform',
        engine_number: 'Motor Numarası',
        transmission_type: 'Şanzıman',
        assembly_no: 'CSN Hayır',
        fault_info_desc: 'Arıza tanımı',
        fault_handle_process: 'Düzeltici Faaliyet',
        solution: 'Çözüm',
        remark: 'Yorum',
        caseRemark: 'Dosya Yorumu',
        document_information: 'Dosya bilgisi',
        basic_information_of_service_center: 'Servis merkezi bilgileri',
        basic_vehicle_information: 'Araç temel bilgi',
        problem_description: 'Sorun tarifi',
        common_links: 'Faydalı ağlar',
        claim_information: 'Kleym bilgisi',
        diagnostic_report_record: 'Diagnostik rapor',
        vehicle_history: 'Teknik sorgulama tarihi',
        programming_history: 'Programlama geçmişi',
        vehicle_sales_logistics_information: 'Araç satış ve lojik bilgileri',
        new_vehicle_information: 'Yeni araç bilgisi',
        attachment_upload: 'Ekleri  yükleme',
        diagnostic_report: 'Diagnostik rapor',
        bulk_download: 'Toplu indirme',
        document_View: 'Belge Görüntüsü',
        inquiry_content: 'Sorgulama detayları',
        inquiry_entry: 'Sorulara yanıtlar',
        subordinate_record: 'Kayıt',
        superior_record: 'Üst seviye kayıt',
        send_content: 'İçeri gönder',
        send_time: 'Gönderim zamanı',
        sent_username: 'Gönderici',
        receive_name: 'Gönderin',
        dept_name: 'Seviye',
        enclosure: 'Ekli dosya',
        wind_case: 'Kapalı',
        close_order: 'yakın sipariş',
        case_reporting: 'Dosya gönderimi',
        pdf_preview: 'PDF Önizlemesi',
        SIS_maintenance: 'Büyük kaza bakımı',
        Please_fill_in_the_inquiry_content: 'Lütfen istenen bilgileri doldurun',
        sending_an_email: 'E-posta gönderiyor',
        copy: 'Kopya',
        repeat: 'Vin tekrar',
        inconsistent: 'Tutarsız araç platformu',
        sendTo: 'Gönderin',
        reply: 'Sorgulama/cevap için girdi',
        please_check_the_exported_document: 'Lütfen dışarı aktarılmış belgeleri kontrol edin',
        please_upload_diagnostic_report: 'Lütfen arızacılık raporunu yükleyin',
        please_enter_the_vin: 'Lütfen VIN girin',
        placeholderDTC: 'Misal：P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1.Müşterilerin endişelerini kontrol edin (sadece sorun çıkmasını önlemek için)',
        placeholderFaultHandleProcess:
            '2.İlgili simptomları belirleyin (sorunla bağlı olabilecek bütün simptomları belirleyuin ve kaydedin)\n' +
            '3.Simptomları analiz edin (ortak sebepleri tanımlamak için ilgili simptomları dikkatli gözlemleyin)\n' +
            '4.Sorunu  teyit edin (sonuçlarınızın boyutunu azaltmak için doğru sorunu çözmenizi sağlayın)',
        placeholderSolution:
            '5.Problemi çözün (tamir edin)\n' +
            '6.Aracın çalışmasını kontrol edin (yapılan onarımın sorunu çözdüğünü kontrol edin)',
        placeholderRemark: 'Misal: Direksiyon kutusu-201806, FL Amortisör-2010101',
        placeholderContact: 'Lütfen operatör adını girin',
        close_time: 'Kapanış zamanı',
        one_touch: 'Dosyanın yeniden açılması',
        confirm_generating_new_case: 'Yeni  bir  dosyanın açıldığını onaylayın ?',
        closing_statement: 'Kapanış bildirimi',
        number_of_cases: 'Dosya sayısı',
        number_of_vehicles: 'Araç sayısı',
        pleaseEnterContent: 'Lütfen içerik girin',
        case_age: 'vaka yaşı',
        last_response_time: 'son yanıt süresi',
        time_since_last_response: 'Son yanıttan bu yana geçen süre',
        warranty_start_date: 'Garanti Başlangıç Tarihi',
        submit_reason: 'Başvuru nedeni',
        synchronize_PQCP: 'Senkronize PQCP',
        FPR_export: 'FPR İhracat',
        level_selection: 'Seviye Seçimi',
        category_selection: 'Kategori Seçimi',
        brand_selection: 'Marka Seçimi',
        code: 'kodu',
        description: 'tarif et',
        major_complaints: 'önemli̇ şi̇kayet',
        difficult_miscellaneous_diseases: 'şüpheli veya tedavisi zor vakalar',
        emerging_issues: 'ortaya çıkan sorunlar',
        breakpoint_failure: 'kesme noktası hatası',
        general_complaints: 'Genel şikayetler',
        other: 'diğer',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Ciddiyet',
        secondary: 'küçük',
        generally: 'olağan',
        complete: 'yerine getirmek',
        please_select_level: 'Lütfen bir seviye seçin',
        please_select_category: 'Lütfen bir kategori seçin',
        please_select_brand: 'Lütfen bir marka seçin',
        placeholder_auto: 'yer tutucu otomatik',
        translate: 'tercüme etmek',
        isTranslate: 'Çeviri ya da değil',
        day: 'gün',
        one_click_order: 'Seçili olanı kapatmak için tıklayın',
        greater_than: 'ağır basar',
        less_than: 'daha az',
        between: 'arasında',
        document_label: 'belge etiketi',
        receipts_first_modification_time: 'belge İlk değişiklik zamanı',
        Selection_of_Document_Owner: 'Belge Sorumlusu Seçimi',
        responsiblePerson: 'Sorumlu Kişi：',
        functional_Domain: 'Fonksiyonel Alan',
        Category: 'Kategori',
        property: 'nedensellik',
        reRepair: 'Tekrarlanan Onarımlar',
        pullInNum: 'Giriş Sayısı',
        perception: 'Algı Sınıfı',
        onSupport: 'Yerinde Destek',
        batteryNo: 'Batarya Numarası',
        normalStartDate: 'Garanti Tarihi',
        qmotorNo: 'Ön Motor Numarası',
        hmotorNo: 'Arka Motor Numarası',
        fault_cause_type: 'Arıza Neden Türü',
        measure_name: 'Tedbir Adı',
        part_name: 'Parça Adı',
        close_order_notification: 'Siparişi kapatmak, teklif sahibinden bir yanıt gerektirir.',
        TIS_Case_Data: 'TIS Vaka Verileri',
        case_File: 'Vaka Dosyası',
        responsibility_Data: 'Sorumluluk Verileri',
        longterm_Measures: 'Uzun Vadeli Önlemler',
        tisCaseNo: 'TIS Vaka Numarası',
        vehGlobalFwVer: 'Araç Yazılımı Sürüm Numarası',
        vehGlobalFwPn: 'Araç Yazılımı Parça Numarası',
        archivesNo: 'Dosya Numarası',
        archivesTitle: 'Dosya Başlığı',
        archivesSource: 'Dosya Kaynağı',
        proposer: 'Teklif Eden',
        proposedTime: 'Teklif Tarihi',
        archivesManagementPersonnel: 'Yönetim Personeli',
        functionalMode: 'Fonksiyonel Modül',
        supplier: 'Tedarikçi',
        accessoriesInvolved: 'Aksesuarlar Dahil',
        functionalDomain: 'Fonksiyonel Alan',
        invalidPartId: 'Geçersiz Parça Kimliği',
        archivesType: 'Dosya Türü',
        archivesGrade: 'Dosya Derecesi',
        problemDescription: 'Problem Açıklaması',
        analysisProgress: 'Analiz İlerlemesi',
        qaRemark: 'QA Açıklaması',
        tacRemark: 'TAC Açıklaması',
        responsibleDepartment: 'Sorumlu Departman',
        coordinator: 'Koordinatör',
        principal: 'Yetkili',
        containmentAction: 'Sınırlandırma Eylemi',
        issueStatus: 'Sorun Durumu',
        softwareProblems: 'Yazılım Problemleri',
        vehGlobalFwVer_j: 'Temel Sürüm Numarası',
        vehGlobalFwPn_j: 'Temel Parça Numarası',
        ewo: 'EWO Numarası',
        rootCause: 'Temel Sebep',
        marketBehavior: 'Pazar Davranışı',
        shortTermMeasures: 'Kısa Vadeli Önlemler',
        shortTermBreakpointDescription: 'Kısa Vadeli Kesilme Noktası Açıklaması',
        measuresResult: 'Önlemler Onay Sonucu',
        measuresConfirmation: 'Önlemler Onayı',
        longTermMeasures: 'Uzun Vadeli Önlemler',
        longTermMeasuresBreak: 'Uzun Vadeli Önlemler Tahmini Kesilme Noktası Açıklaması',
        implementation: 'Uygulama',
        longMeasuresTime: 'Uzun Vadeli Önlemler Gerçek Kesilme Tarihi',
        vinBreak: 'Uzun Vadeli Önlemler Kesilme Noktası VIN',
        breakpointImplementationPhase: 'Kesilme Noktası Uygulama Aşaması',
        archive_Information: 'Arşiv Bilgisi',
        reason_incomplete_closure: 'Tamamlanmamış kapanma nedeni',
        entry_Date: 'Giriş tarihi',
        departure_date: 'Çıkış tarihi',
        work_order_number: 'İş emri numarası',
        questionnaire_usage: 'Anket kullanımı',
        classification_of_technical_enquiries: 'Teknik soruların sınıflandırılması',
    },
    // 捷克语
    csCZ: {
        vin: 'VIN',
        inquiry_no: 'Případ č.',
        case_theme: 'Předmět případu',
        department_name: 'Název oddělení',
        department_type: 'Typ oddělení',
        document_status: 'Stav dokumentu',
        inquiry_date: 'Datum dotazu',
        organization_name: 'Název organizace',
        name: 'Jméno navrhovatele',
        sap_code: 'SAP kód',
        failed_part: 'Systémová část',
        case_type: 'Typ případu:',
        vehicle_status: 'Stav opravy',
        case_owner: 'Zodpovědná osoba',
        country: 'Země',
        fault_cause: 'Příčina závady',
        symptoms: 'Fenomén závady',
        is_the_document_qualified: 'Je případ kvalifikovaný',
        is_the_document_replied_in_a_timely_manner: 'Je případ zodpovězen včas',
        filter: 'Filtr',
        system_classification_I: 'Systémová skupina',
        system_classification_II: 'Sestava systému',
        full_query: 'Úplný dotaz',
        fuzzy_query: 'Nejasný dotaz',
        whether_belongs_new_car_market: 'Nový model na trhu',
        caseStatus: 'Stav případu',
        correct: 'Upravit',
        deny: 'Odmítnout',
        not_closed: 'OTEVŘENO',
        no_reply: 'Čeká na odpověď',
        replied: 'Odpovězeno',
        closed: 'ZAVŘENO',
        drafts: 'Návrh/Koncept',
        create_time: 'Čas vytvoření',
        create_user: 'Vytvořil',
        parent_of_document: 'Vlastník dokumentu',
        document_owner: 'Vlastník případu',
        case_subject: 'Předmět',
        receipt_info: 'Informace o případu',
        superior_of_case: 'Dohled případu',
        inquiry_time: 'Čas dotazu',
        status: 'Status',
        overseas_company: 'Název společnosti prodejce',
        general_agent_name: 'Jméno distributora',
        dealer_code: 'Kód dealera',
        dealer_name: 'Jméno dealera',
        name_of_subsidiary: 'Název pobočky',
        contacts: 'Kontaktní osoba',
        contact_information: 'Kontaktní informace',
        training_certification: 'Certifikace školení',
        driving_mileage: 'Počet najetých kilometrů',
        plate_number: 'Poznávací značka #',
        material_no: 'Kód materiálu vozidla',
        material_desc: 'Popis materiálu',
        offline_date: 'Datum off-line',
        model_platform: 'Platforma',
        engine_number: 'Číslo motoru',
        transmission_type: 'Převodovka',
        assembly_no: 'ČSN č',
        fault_info_desc: 'Stížnost zákazníka',
        fault_handle_process: 'Diagnostika/Provedené práce',
        solution: 'Řešení',
        remark: 'Poznámka',
        caseRemark: 'Poznámka k případu',
        document_information: 'Informace o případu',
        basic_information_of_service_center: 'Informace o servisním středisku',
        basic_vehicle_information: 'Základní informace o vozidle',
        problem_description: 'Popis problému',
        common_links: 'Užitečný odkaz',
        claim_information: 'Informace o nároku',
        diagnostic_report_record: 'Diagnostický report',
        vehicle_history: 'Historie technických dotazů',
        programming_history: 'Historie programování',
        vehicle_sales_logistics_information: 'Informace o prodeji vozidel a logistice',
        new_vehicle_information: 'Nové informace o vozidle',
        attachment_upload: 'Nahrání přílohy',
        diagnostic_report: 'Diagnostický report',
        bulk_download: 'Hromadné stahování',
        document_View: 'Zobrazení dokumentu',
        inquiry_content: 'Detail odpovědi případu',
        inquiry_entry: 'Zadání odpovědi případu',
        subordinate_record: 'Podřízený záznam',
        superior_record: 'Hlavní záznam',
        send_content: 'Odeslat obsah',
        send_time: 'Čas odeslání',
        sent_username: 'Odesílatel',
        receive_name: 'Poslat komu',
        dept_name: 'Úroveň',
        enclosure: 'Příloha',
        wind_case: 'ZAVŘENO',
        close_order: 'Uzavřít případ',
        case_reporting: 'Odeslat případ',
        pdf_preview: 'Náhled PDF',
        SIS_maintenance: 'Údržba při závažných haváriích',
        Please_fill_in_the_inquiry_content: 'Vyplňte prosím obsah dotazu',
        sending_an_email: 'Odeslání emailu',
        copy: 'kopírovat',
        repeat: 'Opakovat VIN',
        inconsistent: 'Nekonzistentní platforma vozidla',
        sendTo: 'Odeslat na',
        reply: 'Vstup pro dotaz/odpověď',
        please_check_the_exported_document: 'Zkontrolujte exportovaný dokument',
        please_upload_diagnostic_report: 'Nahrajte prosím diagnostický report',
        please_enter_the_vin: 'Zadejte VIN',
        placeholderDTC: 'Příklad：P080800_P087D00_------',
        placeholderFaultInfoDesc: '1. Stížnost zákazníka (zjištění / potvrzení projevu stížnosti)',
        placeholderFaultHandleProcess:
            '2. Identifikace relevantních příznaků (identifikujte a zaznamenejte všechny příznaky, které mohou souviset s problémem).\n' +
            '3. Analyzujte symptomy (pečlivě sledujte příslušné symptomy, abyste identifikovali společné příčiny)\n' +
            '4. Potvrzení problému (zúžení rozsahu výsledků, abyste se ujistili, že jste vyřešili správný problém)',
        placeholderSolution:
            '5.Odstranění problému (provedení opravy)\n' +
            '6. Ověřte provoz vozidla (zkontrolujte, zda vaše opravy problém vyřešily)',
        placeholderRemark: 'Příklad: Převodka řízení-201806, Tlumič FL-2010101',
        placeholderContact: 'Zadejte jméno operátora',
        close_time: 'Čas uzavření',
        one_touch: 'Obnovení případu',
        confirm_generating_new_case: 'Potvrdit generování nového případu?',
        closing_statement: 'Závěrečná zpráva',
        number_of_cases: 'Počet případů',
        number_of_vehicles: 'Počet vozidel',
        pleaseEnterContent: 'Prosím zadejte obsah',
        case_age: 'Stáří případu',
        last_response_time: 'Čas poslední reakce',
        time_since_last_response: 'Čas od poslední odpovědi',
        warranty_start_date: 'Datum začátku záruky',
        submit_reason: 'Důvod podání žádosti',
        synchronize_PQCP: 'Synchronizujte PQCP',
        FPR_export: 'FPR export',
        level_selection: 'Volba úrovně',
        category_selection: 'Volba kategorie',
        brand_selection: 'Výběr značky',
        code: 'Kód',
        description: 'Popis',
        major_complaints: 'Hlavní stížnost',
        difficult_miscellaneous_diseases: 'Obtížné případy',
        emerging_issues: 'Nové případy',
        breakpoint_failure: 'Breakpoint závady',
        general_complaints: 'Obecné stížnosti',
        other: 'jiný',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Vážně',
        secondary: 'Vedlejší',
        generally: 'Obecně',
        complete: 'dokončeno',
        please_select_level: 'Vyberte úroveň',
        please_select_category: 'Vyberte kategorii',
        please_select_brand: 'Vyberte značku',
        placeholder_auto: 'Automaticky vyplněné',
        translate: 'Přeložit',
        isTranslate: 'Překládá se',
        day: 'den',
        one_click_order: 'Objednávka jedním kliknutím',
        greater_than: 'Větší než',
        less_than: 'Méně než',
        between: 'Mezi',
        document_label: 'Označení dokumentu',
        receipts_first_modification_time: 'Čas první úpravy',
        Selection_of_Document_Owner: 'Výběr odpovědné osoby za dokument',
        responsiblePerson: 'odpovědná osoba',
        functional_Domain: 'Funkční doména',
        Category: 'Kategorie',
        property: 'vlastnictví',
        reRepair: 'Opakovaná oprava',
        pullInNum: 'Počet vstupů',
        perception: 'Třída vnímání',
        onSupport: 'Podpora na místě',
        batteryNo: 'Číslo baterie',
        normalStartDate: 'Datum záruky',
        qmotorNo: 'Číslo předního motoru',
        hmotorNo: 'Číslo zadního motoru',
        fault_cause_type: 'typ příčiny závady',
        measure_name: 'Měření',
        part_name: 'Název dílu',
        close_order_notification: 'Uzavření objednávky vyžaduje odpověď navrhovatele.',

        TIS_Case_Data: 'Údaje o případu TIS',
        case_File: 'Soubor případu',
        responsibility_Data: 'Údaje o odpovědnosti',
        longterm_Measures: 'Dlouhodobá opatření',
        tisCaseNo: 'Číslo případu TIS',
        vehGlobalFwVer: 'Číslo verze softwaru vozidla',
        vehGlobalFwPn: 'Číslo dílu softwaru vozidla',
        archivesNo: 'Číslo souboru',
        archivesTitle: 'Název souboru',
        archivesSource: 'Zdroj souboru',
        proposer: 'Navrhovatel',
        proposedTime: 'Navrhované datum',
        archivesManagementPersonnel: 'Personál správy',
        functionalMode: 'Funkční modul',
        supplier: 'Dodavatel',
        accessoriesInvolved: 'Zahrnuté příslušenství',
        functionalDomain: 'Funkční doména',
        invalidPartId: 'Neplatné ID dílu',
        archivesType: 'Typ souboru',
        archivesGrade: 'Hodnocení souboru',
        problemDescription: 'Popis problému',
        analysisProgress: 'Pokrok analýzy',
        qaRemark: 'Poznámka QA',
        tacRemark: 'Poznámka TAC',
        responsibleDepartment: 'Odpovědné oddělení',
        coordinator: 'Koordinátor',
        principal: 'Vedoucí',
        containmentAction: 'Zajišťovací opatření',
        issueStatus: 'Stav problému',
        softwareProblems: 'Problémy softwaru',
        vehGlobalFwVer_j: 'Číslo základní verze',
        vehGlobalFwPn_j: 'Číslo základního dílu',
        ewo: 'Číslo EWO',
        rootCause: 'Kořenová příčina',
        marketBehavior: 'Tržní chování',
        shortTermMeasures: 'Krátkodobá opatření',
        shortTermBreakpointDescription: 'Popis krátkodobého bodu zlomu',
        measuresResult: 'Výsledek schválení opatření',
        measuresConfirmation: 'Potvrzení opatření',
        longTermMeasures: 'Dlouhodobá opatření',
        longTermMeasuresBreak: 'Odhadovaný popis bodu zlomu dlouhodobých opatření',
        implementation: 'Implementace',
        longMeasuresTime: 'Skutečné datum bodu zlomu dlouhodobých opatření',
        vinBreak: 'VIN bodu zlomu dlouhodobých opatření',
        breakpointImplementationPhase: 'Fáze implementace bodu zlomu',
        archive_Information: 'Informace o archivu',
        reason_incomplete_closure: 'Důvod neúplného uzavření',
        entry_Date: 'Datum vstupu',
        departure_date: 'Datum odjezdu',
        work_order_number: 'Číslo pracovního příkazu',
        questionnaire_usage: 'Použití dotazníku',
        classification_of_technical_enquiries: 'Klasifikace technických dotazů',
    },
    // 匈牙利语翻译
    huHU: {
        vin: 'Alvázszám',
        inquiry_no: 'Eset sz.',
        case_theme: 'Eset tárgya',
        department_name: 'Részleg neve',
        department_type: 'Részleg típusa',
        document_status: 'Dokumentum állapota',
        inquiry_date: 'Érdeklődés dátuma',
        organization_name: 'Szervezet neve',
        name: 'Ajánlattevő neve',
        sap_code: 'SAP-kód',
        failed_part: 'Rendszer része',
        case_type: 'Eset típusa',
        vehicle_status: 'Javítás állapota',
        case_owner: 'Felelős személy',
        country: 'Ország',
        fault_cause: 'Hiba oka',
        symptoms: 'Hibajelenség',
        is_the_document_qualified: 'Minősített eset-e',
        is_the_document_replied_in_a_timely_manner: 'Időben reagáltak-e az esetre',
        filter: 'Szűrő',
        system_classification_I: 'Rendszercsoport',
        system_classification_II: 'Rendszeregység',
        full_query: 'Teljes lekérdezés',
        fuzzy_query: 'Részleges lekérdezés',
        whether_belongs_new_car_market: 'Új jármű bevezetése',
        caseStatus: 'Eset állapota',
        correct: 'Helyes',
        deny: 'Elutasítás',
        not_closed: 'Megnyitás',
        no_reply: 'Válaszra vár',
        replied: 'Megválaszolva',
        closed: 'Lezárva',
        drafts: 'Vázlatmező',
        create_time: 'Létrehozás ideje',
        create_user: 'Létrehozta',
        parent_of_document: 'Dokumentum szülője',
        document_owner: 'Eset tulajdonosa',
        case_subject: 'Tárgy',
        receipt_info: 'Esetinformáció',
        superior_of_case: 'Eset felettes',
        inquiry_time: 'Érdeklődés ideje',
        status: 'Állapot',
        overseas_company: 'Értékesítő vállalat neve',
        general_agent_name: 'Forgalmazó neve',
        dealer_code: 'Kereskedői kód',
        dealer_name: 'Kereskedő neve',
        name_of_subsidiary: 'Alvállalkozó neve',
        contacts: 'Kapcsolattartó',
        contact_information: 'Elérhetőségek',
        training_certification: 'Képzési bizonyítvány',
        driving_mileage: 'Futásteljesítmény',
        plate_number: 'Rendszám',
        material_no: 'Jármű anyagkód',
        material_desc: 'Anyagleírás',
        offline_date: 'Offline dátum',
        model_platform: 'Platform',
        engine_number: 'Motorszám',
        transmission_type: 'Váltómű',
        assembly_no: 'CSN sz.',
        fault_info_desc: 'Ügyfél által jelentett panasz',
        fault_handle_process: 'Elvégzett diagnosztika/munka',
        solution: 'Megoldás',
        remark: 'Megjegyzés',
        caseRemark: 'Eset megjegyzés',
        document_information: 'Esetinformáció',
        basic_information_of_service_center: 'Szervizközpont információ',
        basic_vehicle_information: 'Jármű alapvető információi',
        problem_description: 'Probléma leírása',
        common_links: 'Hasznos hivatkozás',
        claim_information: 'Igényinformáció',
        diagnostic_report_record: 'Diagnosztikai jelentés',
        vehicle_history: 'Műszaki érdeklődés előzménye',
        programming_history: 'Programozási előzmények',
        vehicle_sales_logistics_information: 'Járműértékesítési és -logisztikai információ',
        new_vehicle_information: 'Újjármű-információ',
        attachment_upload: 'Csatolmány feltöltése',
        diagnostic_report: 'Diagnosztikai jelentés',
        bulk_download: 'Kötegelt letöltés',
        document_View: 'Dokumentumnézet',
        inquiry_content: 'Esetreakció-részlet',
        inquiry_entry: 'Esetreakció-bejegyzés',
        subordinate_record: 'Beosztotti rekord',
        superior_record: 'Felettesi rekord',
        send_content: 'Tartalom küldése',
        send_time: 'Küldési idő',
        sent_username: 'Küldő',
        receive_name: 'Címzett',
        dept_name: 'Szint',
        enclosure: 'Csatolmány',
        wind_case: 'Lezárva',
        close_order: 'Rendelés lezárása',
        case_reporting: 'Eset beküldése',
        pdf_preview: 'PDF előnézet',
        SIS_maintenance: 'Nagy baleseti karbantartás',
        Please_fill_in_the_inquiry_content: 'Töltse ki az érdeklődés tartalmát',
        sending_an_email: 'E-mail küldése',
        copy: 'Másolás',
        repeat: 'Alvázszám újra',
        inconsistent: 'Nem következetes járműplatform',
        sendTo: 'Címzett',
        reply: 'Érdeklődés/válasz bevitele',
        please_check_the_exported_document: 'Ellenőrizze az exportált dokumentumot',
        please_upload_diagnostic_report: 'Töltse fel a diagnosztikai jelentést',
        please_enter_the_vin: 'Adja meg az alvázszámot',
        placeholderDTC: 'Példa：P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1. Ellenőrizze az ügyfélpanaszt (hogy meggyőződjön a fellépő problémáról)',
        placeholderFaultHandleProcess:
            '2. Azonosítsa a vonatkozó tüneteket (azonosítsa és rögzítse az összes tünetet, ami a problémához kapcsolódhat)\n' +
            '3. Elemezze a tüneteket (gondosan figyelje meg a vonatkozó tüneteket, hogy azonosítsa a gyakori okokat)\n' +
            '4. Erősítse meg a problémát (szűkítse le az eredmények körét, hogy biztosan a megfelelő problémát oldotta-e meg)',
        placeholderSolution:
            '5. Javítsa meg a problémát (végezze el a javításokat)\n' +
            '6. Ellenőrizze a jármű működését (ellenőrizze, hogy a javítások biztosan megoldották-e a problémát)',
        placeholderRemark: 'Példa: Kormánymű–201806, BE lengéscsillapító–2010101',
        placeholderContact: 'Adja meg az operátor nevét',
        close_time: 'Lezárás ideje',
        one_touch: 'Eset újraépítése',
        confirm_generating_new_case: 'Biztosan új esetet hoz létre?',
        closing_statement: 'Lezárási nyilatkozat',
        number_of_cases: 'Esetek száma',
        number_of_vehicles: 'Járművek száma',
        pleaseEnterContent: 'Adja meg a tartalmat',
        case_age: 'Eset kora',
        last_response_time: 'Utolsó reakció ideje',
        time_since_last_response: 'Utolsó reakció óta eltelt idő',
        warranty_start_date: 'Garancia érvényességének kezdete',
        submit_reason: 'Beküldés oka',
        synchronize_PQCP: 'PQCP szinkronizálása',
        FPR_export: 'FPR exportálása',
        level_selection: 'Szintválasztás',
        category_selection: 'Kategóriaválasztás',
        brand_selection: 'Márkaválasztás',
        code: 'Kód',
        description: 'Leírás',
        major_complaints: 'Komoly panaszok',
        difficult_miscellaneous_diseases: 'Bonyolult egyéb tünetek',
        emerging_issues: 'Felmerülő problémák',
        breakpoint_failure: 'Lerobbanás',
        general_complaints: 'Általános panaszok',
        other: 'Egyéb',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Súlyos',
        secondary: 'Másodlagos',
        generally: 'Általános',
        complete: 'Kész',
        please_select_level: 'Válasszon szintet',
        please_select_category: 'Válasszon kategóriát',
        please_select_brand: 'Válasszon márkát',
        placeholder_auto: 'Automatikusan kitöltve',
        translate: 'Fordítás',
        isTranslate: 'Fordítsa-e',
        day: 'Nap',
        one_click_order: 'Egykattintásos rendelés',
        greater_than: 'Nagyobb, mint',
        less_than: 'Kisebb, mint',
        between: 'Között',
        document_label: 'Dokumentumcímke',
        receipts_first_modification_time: 'Számla első módosításának ideje',
        Selection_of_Document_Owner: 'Dokumentumért felelős személy kiválasztása',
        responsiblePerson: 'Felelős személy',
        functional_Domain: 'Funkcionális terület',
        Category: 'Kategória',
        property: 'Tulajdonság',
        reRepair: 'Javítások ismétlése',
        pullInNum: 'Bejegyzések száma',
        perception: 'Észlelési osztály',
        onSupport: 'Helyszíni támogatás',
        batteryNo: 'Akkumulátor száma',
        normalStartDate: 'Garancia dátuma',
        qmotorNo: 'Elülsőmotor száma',
        hmotorNo: 'Hátsómotor száma',
        fault_cause_type: 'Hiba okának típusa',
        measure_name: 'Intézkedés neve',
        part_name: 'Alkatrész neve',
        close_order_notification: 'A rendelés lezárásához az ajánló reakciójára van szükség.',
        TIS_Case_Data: 'TIS esetadatok',
        case_File: 'Eset fájl',
        responsibility_Data: 'Felelős adatok',
        longterm_Measures: 'Hosszú távú intézkedések',
        tisCaseNo: 'TIS eset száma',
        vehGlobalFwVer: 'Teljes jármű szoftververzió',
        vehGlobalFwPn: 'Teljes jármű szoftver alkatrész szám',
        archivesNo: 'Archívum szám',
        archivesTitle: 'Archívum címe',
        archivesSource: 'Archívum forrása',
        proposer: 'Javasló személy',
        proposedTime: 'Javaslat dátuma',
        archivesManagementPersonnel: 'Kezelő személy',
        functionalMode: 'Funkcionális mód',
        supplier: 'Szállító',
        accessoriesInvolved: 'Résztvevő alkatrészek',
        functionalDomain: 'Funkcionális terület',
        invalidPartId: 'Elégtelen alkatrész',
        archivesType: 'Archívum típusa',
        archivesGrade: 'Archívum szintje',
        problemDescription: 'Probléma leírása',
        analysisProgress: 'Elemzési előrehaladás',
        qaRemark: 'QA megjegyzés',
        tacRemark: 'TAC megjegyzés',
        responsibleDepartment: 'Felelős osztály',
        coordinator: 'Koordinátor',
        principal: 'Felelős személy',
        containmentAction: 'Felfogó intézkedés',
        issueStatus: 'Probléma állapota',
        softwareProblems: 'Szoftverproblémák',
        vehGlobalFwVer_j: 'Alapvonal verziószám',
        vehGlobalFwPn_j: 'Alapvonal alkatrész szám',
        ewo: 'EWO szám',
        rootCause: 'Gyökérok',
        marketBehavior: 'Piaci magatartás',
        shortTermMeasures: 'Rövid távú intézkedések',
        shortTermBreakpointDescription: 'Rövid távú törési leírás',
        measuresResult: 'Intézkedések jóváhagyási eredménye',
        measuresConfirmation: 'Intézkedések megerősítési nyilatkozat',
        longTermMeasures: 'Hosszú távú intézkedések',
        longTermMeasuresBreak: 'Hosszú távú várható törési leírás',
        implementation: 'Megvalósítás',
        longMeasuresTime: 'Hosszú távú tényleges törési dátum',
        vinBreak: 'Hosszú távú törési VIN',
        breakpointImplementationPhase: 'Törés végrehajtási szakasz',
        archive_Information: 'Archívum információ',
        reason_incomplete_closure: 'Hiányzó zárás oka',
        entry_Date: 'Belépési dátum',
        departure_date: 'Távozási dátum',
        work_order_number: 'Munkarend szám',
        questionnaire_usage: 'Kérdőív használata',
        classification_of_technical_enquiries: 'Technikai kérdések osztályozása',
    },
    // 俄罗斯语
    ruRU: {
        vin: 'VIN',
        inquiry_no: 'Номер случая',
        case_theme: 'Тема случая',
        department_name: 'Название отдела',
        department_type: 'Тип отдела',
        document_status: 'Статус документа',
        inquiry_date: 'Дата запроса',
        organization_name: 'Название организации',
        name: 'Имя предложителя',
        sap_code: 'Код SAP',
        failed_part: 'Неисправная деталь',
        case_type: 'Тип случая',
        vehicle_status: 'Статус ремонта',
        case_owner: 'Ответственный',
        country: 'Страна',
        fault_cause: 'Причина неисправности',
        symptoms: 'Симптом неисправности',
        is_the_document_qualified: 'Соответствие документа требованиям',
        is_the_document_replied_in_a_timely_manner: 'Своевременность ответа по документу',
        filter: 'Фильтр',
        system_classification_I: 'Системная группа',
        system_classification_II: 'Системная сборка',
        full_query: 'Полный запрос',
        fuzzy_query: 'Неопределенный запрос',
        whether_belongs_new_car_market: 'Новый автомобиль на рынке',
        caseStatus: 'Статус случая',
        correct: 'Да',
        deny: 'Нет',
        not_closed: 'Открыт',
        no_reply: 'Ожидание ответа',
        replied: 'Ответили',
        closed: 'Закрыто',
        drafts: 'Черновик',
        create_time: 'Время создания',
        create_user: 'Создатель',
        parent_of_document: 'Руководитель документа',
        document_owner: 'Ответственный за случай',
        case_subject: 'Тема',
        receipt_info: 'Информация о случае',
        superior_of_case: 'Руководитель случая',
        inquiry_time: 'Время запроса',
        status: 'Статус',
        overseas_company: 'Название компании-продавца',
        general_agent_name: 'Название дистрибьютора',
        dealer_code: 'Код дилера',
        dealer_name: 'Название дилера',
        name_of_subsidiary: 'Название дочернего предприятия',
        contacts: 'Контактное лицо',
        contact_information: 'Контактная информация',
        training_certification: 'Обучение и сертификация',
        driving_mileage: 'Пробег',
        plate_number: 'Регистрационный номер',
        material_no: 'Код материала',
        material_desc: 'Описание материала',
        offline_date: 'Дата выпуска',
        model_platform: 'Платформа',
        engine_number: 'Номер двигателя',
        transmission_type: 'Трансмиссия',
        assembly_no: 'Номер сборки',
        fault_info_desc: 'Сообщенные проблемы клиента',
        fault_handle_process: 'Диагностика/Проведенные работы',
        solution: 'Решение',
        remark: 'Примечание',
        caseRemark: 'Примечание к случаю',
        document_information: 'Информация о случае',
        basic_information_of_service_center: 'Информация о сервисном центре',
        basic_vehicle_information: 'Основная информация о транспортном средстве',
        problem_description: 'Описание проблемы',
        common_links: 'Полезные ссылки',
        claim_information: 'Информация о претензии',
        diagnostic_report_record: 'Диагностический отчет',
        vehicle_history: 'История технических запросов',
        programming_history: 'История программирования',
        vehicle_sales_logistics_information:
            'Информация о продаже и логистике транспортного средства',
        new_vehicle_information: 'Новая информация о транспортном средстве',
        attachment_upload: 'Загрузка вложений',
        diagnostic_report: 'Диагностический отчет',
        bulk_download: 'Пакетная загрузка',
        document_View: 'Просмотр документа',
        inquiry_content: 'Подробности ответа по случаю',
        inquiry_entry: 'Запись ответа по случаю',
        subordinate_record: 'Запись нижестоящих ответов',
        superior_record: 'Запись вышестоящих ответов',
        send_content: 'Содержание сообщения',
        send_time: 'Время отправки',
        sent_username: 'Отправитель',
        receive_name: 'Получатель',
        dept_name: 'Уровень',
        enclosure: 'Вложение',
        wind_case: 'Закрытие дела',
        close_order: 'Закрытие заявки',
        case_reporting: 'Подать случай',
        pdf_preview: 'Предварительный просмотр PDF',
        SIS_maintenance: 'Обслуживание крупных происшествий',
        Please_fill_in_the_inquiry_content: 'Пожалуйста, заполните содержание запроса',
        sending_an_email: 'Отправить по электронной почте',
        copy: 'Копировать',
        repeat: 'Повторный VIN',
        inconsistent: 'Несоответствие платформы автомобиля',
        sendTo: 'Получатель',
        reply: 'Введите содержимое для запроса/ответа',
        please_check_the_exported_document: 'Пожалуйста, выберите документы для экспорта',
        please_upload_diagnostic_report: 'Пожалуйста, загрузите диагностический отчет',
        please_enter_the_vin: 'Пожалуйста, введите VIN',
        placeholderDTC: 'Пример: P080800_P087D00_------',
        placeholderFaultInfoDesc:
            '1. Проверьте проблемы клиента (чтобы убедиться, что проблемы действительно возникают)',
        placeholderFaultHandleProcess:
            '2. Определите соответствующие симптомы (найдите и зафиксируйте все симптомы, которые могут быть связаны с проблемой)\n' +
            '3. Проанализируйте симптомы  (тщательно исследуйте соответствующие симптомы, чтобы выявить общие причины)\n' +
            '4. Подтвердите проблему  (сузьте область результатов, чтобы убедиться, что вы решаете правильную проблему)',
        placeholderSolution:
            '5. Исправьте проблему (проведите ремонт)\n' +
            '6. Проверьте работу автомобиля (убедитесь, что ваш ремонт решает проблему)',
        placeholderRemark: 'Пример: Рулевое управление-201806, Фронтальный амортизатор-2010101',
        placeholderContact: 'Пожалуйста, введите имя оператора',
        close_time: 'Время закрытия',
        one_touch: 'Перестройка дела',
        confirm_generating_new_case: 'Подтвердите создание нового дела?',
        closing_statement: 'Заключение по делу',
        number_of_cases: 'Количество дел',
        number_of_vehicles: 'Количество автомобилей',
        pleaseEnterContent: 'Пожалуйста, введите содержание',
        case_age: 'Возраст дела',
        last_response_time: 'Время последнего ответа',
        time_since_last_response: 'Время с последнего ответа',
        warranty_start_date: 'Дата начала гарантии',
        submit_reason: 'Причина подачи',
        synchronize_PQCP: 'Синхронизировать PQCP',
        FPR_export: 'Экспорт FPR',
        level_selection: 'Выбор уровня',
        category_selection: 'Выбор категории',
        brand_selection: 'Выбор бренда',
        code: 'Код',
        description: 'Описание',
        major_complaints: 'Основные жалобы',
        difficult_miscellaneous_diseases: 'Сложные редкие неисправности',
        emerging_issues: 'Новые проблемы',
        breakpoint_failure: 'Неисправность по прерыванию',
        general_complaints: 'Общие жалобы',
        other: 'Прочее',
        roewe: 'Roewe',
        mg: 'MG',
        serious: 'Серьезная',
        secondary: 'Второстепенная',
        generally: 'Обычная',
        complete: 'Завершено',
        please_select_level: 'Пожалуйста, выберите уровень',
        please_select_category: 'Пожалуйста, выберите категорию',
        please_select_brand: 'Пожалуйста, выберите бренд',
        placeholder_auto: 'Автоматически заполняется',
        translate: 'Перевод',
        isTranslate: 'Требуется перевод',
        day: 'День',
        one_click_order: 'Однократное закрытие заказа',
        greater_than: 'Больше чем',
        less_than: 'Меньше чем',
        between: 'Между',
        document_label: 'Метка документа',
        receipts_first_modification_time: 'Время первой модификации документа',
        Selection_of_Document_Owner: 'Выбор ответственного за документ',
        responsiblePerson: 'Ответственный',
        functional_Domain: 'Функциональная область',
        Category: 'Категория',
        property: 'Свойство',
        reRepair: 'Повторные ремонты',
        pullInNum: 'Количество записей',
        perception: 'Класс восприятия',
        onSupport: 'Поддержка на месте',
        batteryNo: 'Номер батареи',
        normalStartDate: 'Дата гарантии',
        qmotorNo: 'Номер переднего мотора',
        hmotorNo: 'Номер заднего мотора',
        fault_cause_type: 'Тип причины неисправности',
        measure_name: 'Название меры',
        part_name: 'Название детали',
        close_order_notification: 'Закрытие заказа требует ответа от предложителя',
        TIS_Case_Data: 'Данные TIS по делу',
        case_File: 'Файл дела',
        responsibility_Data: 'Данные по ответственности',
        longterm_Measures: 'Долгосрочные меры',
        tisCaseNo: 'Номер дела TIS',
        vehGlobalFwVer: 'Версия программного обеспечения автомобиля',
        vehGlobalFwPn: 'Номер детали программного обеспечения автомобиля',
        archivesNo: 'Номер файла',
        archivesTitle: 'Название файла',
        archivesSource: 'Источник файла',
        proposer: 'Предложитель',
        proposedTime: 'Дата предложения',
        archivesManagementPersonnel: 'Управляющий персонал',
        functionalMode: 'Функциональный модуль',
        supplier: 'Поставщик',
        accessoriesInvolved: 'Включает аксессуары',
        functionalDomain: 'Функциональная область',
        invalidPartId: 'Неверный идентификатор детали',
        archivesType: 'Тип файла',
        archivesGrade: 'Оценка файла',
        problemDescription: 'Описание проблемы',
        analysisProgress: 'Прогресс анализа',
        qaRemark: 'Примечание QA',
        tacRemark: 'Примечание TAC',
        responsibleDepartment: 'Ответственный отдел',
        coordinator: 'Координатор',
        principal: 'Ответственный',
        containmentAction: 'Меры по предотвращению',
        issueStatus: 'Статус проблемы',
        softwareProblems: 'Программные проблемы',
        vehGlobalFwVer_j: 'Базовая версия',
        vehGlobalFwPn_j: 'Базовая часть',
        ewo: 'Номер EWO',
        rootCause: 'Коренная причина',
        marketBehavior: 'Рыночное поведение',
        shortTermMeasures: 'Краткосрочные меры',
        shortTermBreakpointDescription: 'Описание краткосрочного прерывания',
        measuresResult: 'Результат утверждения мер',
        measuresConfirmation: 'Подтверждение мер',
        longTermMeasures: 'Долгосрочные меры',
        longTermMeasuresBreak: 'Описание долгосрочного прерывания',
        implementation: 'Реализация',
        longMeasuresTime: 'Фактическая дата прерывания долгосрочных мер',
        vinBreak: 'VIN прерывания',
        breakpointImplementationPhase: 'Фаза реализации прерывания',
        archive_Information: 'Архивная информация',
    },
};

import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
//
export default {
    license: {
        powered_by: 'napájený SMIL.',
    },
    auth: {
        login: 'Přihlásit se',
        username: 'Uživatelské jméno',
        username_placeholder: 'Zadejte uživatelské jméno',
        password: 'Heslo',
        password_placeholder: 'Zadejte heslo',
        code: 'Kontrolní kód',
        code_placeholder: 'Zadejte kontrolní kód',
        logout: 'Odhlásit se',
        logout_success: 'Odhlášení bylo úspěšné',
        day: 'Den',
        password_expiration: 'Heslo vypršelo, prosím změňte jej',
        expire: 'Zbývá do vypršení platnosti hesla',
        conseils:
            'Heslo musí obsahovat velká písmena, malá písmena, číslice a speciální znaky ({msg}), a délka hesla by měla být mezi 8 a 16 znaky.',
        brand_Selection: 'Výběr značky',
        unread_notices: 'Nepřečtené upozornění',
        new_cases: 'Nové případy',
        unanswered_cases: 'Nezodpovězené případy',
        overdue_cases: 'Po termínu',
    },
    button: {
        query: 'Dotaz',
        editing_position: 'Pozice úprav',
        show_theme_editor: 'Zobrazit/skrýt editor témat',
        change_password: 'Změnit heslo',
        old_password: 'Staré heslo',
        new_password: 'Nové heslo',
        confirm_password: 'Potvrdit heslo',
        button: 'Tlačítko',
        about: 'O nás',
        back: 'Zpět',
        go: 'JÍT',
        home: 'Domov',
        confirm: 'Potvrdit',
        modify: 'Upravit',
        edit: 'Upravit',
        new: 'Nový',
        cancel: 'Zrušit',
        close: 'Zavřít',
        search: 'Hledat',
        reset: 'Obnovit',
        delete: 'Smazat',
        add: 'Přidat',
        collapse: 'Skrýt',
        expand: 'Více',
        download_template: 'Stáhnout šablonu',
        batch_import: 'Hromadný import',
        import: 'Importovat',
        export: 'Exportovat',
        export_fail: 'Export se nezdařil',
        detailed: 'Detail',
        details: 'Detaily',
        download: 'Stáhnout',
        toView: 'Zkontrolovat',
        uploadAttachments: 'Nahrát přílohy',
        selectFile: 'Vybrat soubor',
        fillingInstructions: 'Instrukce k vyplnění',
        release: 'Uvolnit',
        release_recall: 'Uvolnění a stažení',
        save: 'Uložit',
        send: 'Odeslat',
        tips: 'Tipy',
        send_back: 'Vrátit zpět',
        examine: 'Zkontrolovat',
        void: 'Neplatné',
        more: 'Více',
        refresh: 'Obnovit',
        selects: 'Prosím vyberte',
        serial_number: 'Číslo',
        upload_progress: 'Pokrok nahrávání',
        invalid: 'Neplatné',
        daily_record: 'Denní záznam',
        operation_type: 'Typ operace',
        field_name: 'Název pole',
        edit_role: 'Upravit roli',
        user_role: 'Uživatelská role',
        assignment_role: 'Přiřadit roli',
        post: 'Příspěvek',
        sending: 'Odesílání',
        reset_password: 'Obnovit heslo',
        display_or_not: 'Zobrazit nebo ne',
        document: 'Dokument',
        specificTemplates: 'Specifické šablony',
        transferOrder: 'Převodní objednávka',
        forwarding: 'Předání',
        one_click_notification: 'Oznámení jedním kliknutím',
    },
    radio: {
        enabled: 'Povolené',
        disabled: 'Zakázané',
        catalogue: 'Katalog',
        individual: 'Jednotlivé',
        all: 'Vše',
        yes: 'Ano',
        no: 'Ne',
    },
    dropdown: {
        close_others: 'Zavřít ostatní',
        close_right: 'Zavřít napravo',
        close_left: 'Zavřít nalevo',
    },
    table: {
        action: 'Akce',
        explain: 'Vysvětlení',
        fileName: 'Název přílohy',
        size: 'Velikost (MB)',
        uploader: 'Nahrávač',
        uploadTime: 'Čas nahrání',
        system: 'Systém',
        forwarding_time: 'Čas předání',
        forwarder: 'Odesílatel',
        send_to: 'Odeslat na',
        mode: 'Móda',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('číslo')}/stránka`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('číslo')} data článku`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) => `${named('číslo')} Celkem vozidel`,
    },
    confirm: {
        confirm_deletion: 'Potvrdit smazání?',
        confirm_void: 'Potvrdit zrušení?',
        confirm_release: 'Potvrdit uvolnění?',
        ack: 'Potvrdit',
        cancel: 'Zrušit',
        non_records: 'Vyberte alespoň jeden záznam',
    },
    request: {
        failed_placeholder: 'Žádost selhala, chyba na serveru',
        success_placeholder: 'Žádost úspěšná',
        auth: {
            login_success: 'Přihlášení úspěšné',
        },
        error: {
            '404': 'URL požadavku nebyla nalezena',
            '403': 'Přístup odepřen',
        },
    },
    page: {
        global: {
            page_search: 'Vyhledávání na stránce',
        },
        error: {
            not_found: 'Nenalezeno',
            not_found_des: 'Tady není nic',
            auth_failed: 'Autentifikace selhala',
            auth_failed_des: 'Tato stránka není dostupná',
            file_format_error: 'Chyba formátu souboru!',
            preview_failed: 'Náhled selhal! Soubor je abnormální!',
        },
        homepage: {
            homepage: homepage.homepage.csCZ,
        },
        system: {
            menu: system.menu.csCZ,
            dict: system.dict.csCZ,
            dept: system.dept.csCZ,
            role: system.role.csCZ,
            user: system.user.csCZ,
        },
        info: {
            announcement: Info.announcement.announcement.csCZ,
            document: Info.document.document.csCZ,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.csCZ,
                recallActivity: tech.quality.recallActivity.csCZ,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.csCZ,
            },
            management: {
                inquiryOd: tech.management.inquiry.csCZ,
                permission: tech.management.permission.csCZ,
                onSiteSupportHq: tech.management.onSiteSupport.csCZ,
                progHistoryHq: tech.management.progHistory.csCZ,
                salesRecordHq: tech.management.salesRecord.csCZ,
                vehicleInfoHq: tech.management.vehicleInfo.csCZ,
                claimHq: tech.management.claim.csCZ,
                filterHq: tech.management.filter.csCZ,
                newCarlssue: tech.management.newCarIssue.csCZ,
                newCarDefinition: tech.management.newCarDefinition.csCZ,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.csCZ,
                programmingHistoryInformationQuery:
                    tech.management.programmingHistoryInformationQuery.csCZ,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.csCZ,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.csCZ,
        },
        statistics: statistics.inquiryStatistics.csCZ,
        systemClassifyA: statistics.systemClassifyA.csCZ,
        systemClassifyB: statistics.systemClassifyB.csCZ,
        engineerStatistics: statistics.engineerStatistics.csCZ,
        defectivePart: statistics.defectivePart.csCZ,
        replyInquiryStatistics: statistics.replyInquiryStatistics.csCZ,
        passRateStatistics: statistics.passRateStatistics.csCZ,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.csCZ,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.csCZ,
        customsClearance135Rate: statistics.customsClearance135Rate.csCZ,
        accumulatedClosureRate: statistics.accumulatedClosureRate.csCZ,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.csCZ,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.csCZ,
    },
    validation: {
        common: {
            required: 'Článek je povinný',
            numeric: 'Musí být celé číslo',
            decimal: 'Musí být číslo',
            positive_interger: 'Musí být kladné celé číslo',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Až do ${named('číslo')} Číslice`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Více než ${named('číslo')} Číslice`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Překročeno maximum bytů (${named('číslo')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Méně než${named('číslo')} Číslice`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Musí být ${named('číslo')} Číslice`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Větší než ${named('číslo')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) => `Méně než ${named('číslo')}`,
            up_to_2_decimal_places: 'Až 2 desetinná místa',
            retain_up: 'Zadejte až 10 kladných celých čísel a zachovejte až 4 desetinná místa',
            retain_ups: 'Zadejte až 11 kladných celých čísel a zachovejte až 4 desetinná místa',
            input_number: 'Zadejte číslo',
            only_number_a: 'Lze zadat pouze písmena nebo čísla',
            translate: 'Pouze písmena',
            positive_integer: 'Zadejte až 10 kladných celých čísel',
            at_least_one: 'Vyplňte alespoň jeden',
        },
        tip: {
            incomplete_information: 'Neúplné informace',
        },
    },
    import: {
        template_file: 'Šablona souboru',
        browse: 'Procházet',
        download_template: 'Stáhnout šablonu',
        no_file_selected: 'Žádný soubor nebyl vybrán',
        incorrect_file_type: 'Špatný typ souboru',
        exceed: 'Překročit',
        upload_success: 'Nahrávání úspěšné',
        upload_fail: 'Nahrávání selhalo',
        again_upload: 'Lze nahrávat pouze obrazové soubory ve formátu jpg, png, bmp nebo gif',
        attachmentUpload: 'Nahrát přílohu',
        supportedFormats: 'Podporované formáty',
        preview: 'Náhled',
    },
    export: {
        noDataToExport: 'Žádná data k exportu',
    },
    select: {
        all: 'Vše',
    },
    checkbox: {
        select_all: 'Vybrat vše',
    },
    dialog: {
        error_message: 'Chybová zpráva',
    },
    ymd: {
        day: 'Den',
        month: 'Měsíc',
        year: 'Rok',
        january: 'Led',
        february: 'Úno',
        march: 'Bře',
        april: 'Dub',
        may: 'Kvě',
        june: 'Čer',
        july: 'Čvc',
        august: 'Srp',
        september: 'Zář',
        october: 'Říj',
        november: 'Lis',
        december: 'Pro',
    },
};

<template>
    <n-config-provider
        :date-locale="localeObj[localeStr].date"
        :locale="localeObj[localeStr].lang"
        :theme-overrides="theme"
    >
        <n-dialog-provider>
            <n-message-provider>
                <main font-sans text="gray-700 dark:gray-200">
                    <component
                        :is="
                            showThemeEditor && localFlagShowThemeEditor
                                ? NThemeEditor
                                : 'div-container'
                        "
                    >
                        <n-loading-bar-provider>
                            <n-message-provider>
                                <n-dialog-provider>
                                    <router-view />
                                </n-dialog-provider>
                            </n-message-provider>
                        </n-loading-bar-provider>
                    </component>
                </main>
            </n-message-provider>
        </n-dialog-provider>
    </n-config-provider>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue';
import {
    NThemeEditor,
    csCZ,
    dateCsCZ,
    dateDeDE,
    dateEnUS,
    dateEsAR,
    dateFrFR,
    dateItIT,
    dateNlNL,
    datePlPL,
    datePtBR,
    dateRuRU,
    dateTrTR,
    dateViVN,
    dateZhCN,
    deDE,
    enUS,
    esAR,
    frFR,
    itIT,
    nlNL,
    plPL,
    ptBR,
    ruRU,
    trTR,
    viVN,
    zhCN,
} from 'naive-ui';
import huHU from '../locales/hu-HU';
import theme from './styles/naive-ui-theme-overrides.json';
import { useThemeditorVisible } from './composables/useThemeEditor';
import { Locales } from './composables/i18n';

const { locale } = useTypedI18n();
const localeStr = locale as Ref<Locales>;
const localeObj = shallowRef({
    [Locales.zhCN]: {
        lang: zhCN,
        date: dateZhCN,
    },
    [Locales.enUS]: {
        lang: enUS,
        date: dateEnUS,
    },
    [Locales.deDE]: {
        lang: deDE,
        date: dateDeDE,
    },
    [Locales.frFR]: {
        lang: frFR,
        date: dateFrFR,
    },
    [Locales.nlNL]: {
        lang: nlNL,
        date: dateNlNL,
    },
    [Locales.itIT]: {
        lang: itIT,
        date: dateItIT,
    },
    [Locales.viVN]: {
        lang: viVN,
        date: dateViVN,
    },
    [Locales.esES]: {
        lang: esAR,
        date: dateEsAR,
    },
    [Locales.plPL]: {
        lang: plPL,
        date: datePlPL,
    },
    [Locales.ptPT]: {
        lang: ptBR,
        date: datePtBR,
    },
    [Locales.trTR]: {
        lang: trTR,
        date: dateTrTR,
    },
    [Locales.csCZ]: {
        lang: csCZ,
        date: dateCsCZ,
    },
    [Locales.huHU]: {
        lang: huHU,
        date: dateEnUS,
    },
    [Locales.ruRU]: {
        lang: ruRU,
        date: dateRuRU,
    },
});

const { localFlagShowThemeEditor, showThemeEditor } = useThemeditorVisible();
</script>

<style scoped></style>

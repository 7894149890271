export default {
    // 中
    zhCN: {
        fill_in_at_least_one_query_condition: '至少填写一个查询条件',
        full_query: '全量查询',
        query_time: '查询时间',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        number_of_cases: '案例总数',
        waiting_for_reply_cases: '未答复案例数量',
        percentage_of_No_Responses: '未答复数量百分比',
        replied_cases: '已答复案例数量',
        percentage_of_replied_cases: '已答复案例百分比',
        closed_cases: '已结案案例数量',
        percentage_closed_cases: '已结案数量百分比',
    },
    // 英
    enUS: {
        fill_in_at_least_one_query_condition: 'Fill in at least one query condition',
        full_query: 'Full query',
        query_time: 'Query time',
        organization_code: 'Organization code',
        organization_name: 'Organization name',
        country: 'Country',
        number_of_cases: 'Number of cases',
        waiting_for_reply_cases: 'Waiting for reply cases',
        percentage_of_No_Responses: 'Percentage of No Responses',
        replied_cases: 'Replied cases',
        percentage_of_replied_cases: 'Percentage of replied cases',
        closed_cases: 'Closed cases',
        percentage_closed_cases: 'Closed cases(%)',
    },
    // 德
    deDE: {
        fill_in_at_least_one_query_condition: 'Füllen Sie mindestens eine Abfragebedingung aus',
        full_query: 'Vollständige Abfrage',
        query_time: 'Abfragezeit',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        number_of_cases: 'Gesamtzahl der Fälle',
        waiting_for_reply_cases: 'Fälle, die auf Antwort warten',
        percentage_of_No_Responses: 'Prozentsatz der fehlenden Antworten',
        replied_cases: 'Beantwortete Fälle',
        percentage_of_replied_cases: 'Prozentsatz der beanstandeten Fälle',
        closed_cases: 'Geschlossene Fälle',
        percentage_closed_cases: 'Geschlossene Fälle (%)',
    },
    // 法
    frFR: {
        fill_in_at_least_one_query_condition: 'Remplissez au moins une condition de demande',
        full_query: 'Requête complète',
        query_time: 'Temps de requête',
        organization_code: 'Code d\'organisation',
        organization_name: 'Nom d\'organisation',
        country: 'Pays',
        number_of_cases: 'Nombre total de cas',
        waiting_for_reply_cases: 'Cas en attente de réponse',
        percentage_of_No_Responses: 'Pourcentage du nombre de non - réponses',
        replied_cases: 'Cas auxquels on a répondu',
        percentage_of_replied_cases: 'Pourcentage de cas répondus',
        closed_cases: 'Cas fermés',
        percentage_closed_cases: 'Cas fermés(%)',
    },
    // 意大利
    itIT: {
        fill_in_at_least_one_query_condition: 'Compilare almeno una condizione di query',
        full_query: 'Query Completa',
        query_time: 'Tempo di Query',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        country: 'Paese',
        number_of_cases: 'Numero totale di casi',
        waiting_for_reply_cases: 'Numero di Casi in Attesa di Risposta',
        percentage_of_No_Responses: 'Percentuale di nessuna risposta',
        replied_cases: 'Numero di Casi ai Quali è Stata Fornita una Risposta',
        percentage_of_replied_cases: 'Percentuale di casi risolti',
        closed_cases: 'Numero di Casi Chiusi',
        percentage_closed_cases: 'Percentuale di Casi Chiusi',
    },
    // 荷兰
    nlNL: {
        fill_in_at_least_one_query_condition: 'Vul ten minste één query voorwaarde in',
        full_query: 'Volledige query',
        query_time: 'Query time',
        organization_code: 'Organisatie code',
        organization_name: 'Organisatie naam',
        country: 'Land',
        number_of_cases: 'Totaal aantal gevallen',
        waiting_for_reply_cases: 'Wachten op antwoord gevallen',
        percentage_of_No_Responses: 'Percentage van geen reacties',
        replied_cases: 'Beantwoorde gevallen',
        percentage_of_replied_cases: 'Percentage beantwoorde zaken',
        closed_cases: 'Gesloten gevallen',
        percentage_closed_cases: 'Gesloten gevallen(%)',
    },
    // 越南
    viVN: {
        fill_in_at_least_one_query_condition: 'Ít nhất điền một điều kiện tìm kiếm',
        full_query: 'Tra cứu toàn bộ',
        query_time: 'Thời gian truy vấn',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        country: 'Quốc gia',
        number_of_cases: 'Tổng số trường hợp',
        waiting_for_reply_cases: 'Số lượng trường hợp chờ phản hồi',
        percentage_of_No_Responses: 'Phần trăm trường hợp chưa phản hồi',
        replied_cases: 'Số lượng trường hợp đã phản hồi',
        percentage_of_replied_cases: 'Phần trăm trường hợp đã phản hồi',
        closed_cases: 'Số lượng trường hợp đã đóng',
        percentage_closed_cases: 'Phần trăm trường hợp đã đóng',
    },
    // 西班牙
    esES: {
        fill_in_at_least_one_query_condition: 'Al menos complete un criterio de búsqueda',
        full_query: 'Nombre de la organización',
        query_time: 'Tiempo de consulta',
        organization_code: 'Código de la organización',
        organization_name: 'Nombre de la organización',
        country: 'País',
        number_of_cases: 'Número total de casos',
        waiting_for_reply_cases: 'Cantidad de casos no respondidos',
        percentage_of_No_Responses: 'Porcentaje de casos no respondidos',
        replied_cases: 'Cantidad de casos respondidos',
        percentage_of_replied_cases: 'Porcentaje de casos respondidos',
        closed_cases: 'Cantidad de casos cerrados',
        percentage_closed_cases: 'Porcentaje de casos cerrados',
    },
    // 葡萄牙
    ptBR: {
        fill_in_at_least_one_query_condition: 'Preencha pelo menos uma condição de consulta',
        full_query: 'Consulta completa',
        query_time: 'Tempo de consulta',
        organization_code: 'Código da organização',
        organization_name: 'Nome da organização',
        country: 'País',
        number_of_cases: 'Número total de casos',
        waiting_for_reply_cases: 'Número de casos aguardando resposta',
        percentage_of_No_Responses: 'Porcentagem de casos sem resposta',
        replied_cases: 'Número de casos respondidos',
        percentage_of_replied_cases: 'Porcentagem de casos respondidos',
        closed_cases: 'Número de casos fechados',
        percentage_closed_cases: 'Porcentagem de casos fechados',
    },
    // 波兰
    plPL: {
        fill_in_at_least_one_query_condition: 'Wypełnij co najmniej jeden warunek zapytania',
        full_query: 'Pełne zapytanie',
        query_time: 'Data zapytania',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        country: 'Kraj',
        number_of_cases: 'Łączna liczba spraw',
        waiting_for_reply_cases: 'Liczba spraw oczekujących na odpowiedź',
        percentage_of_No_Responses: 'Liczba spraw oczekujących na odpowiedź (%)',
        replied_cases: 'Liczba udzielonych odpowiedzi',
        percentage_of_replied_cases: 'Liczba udzielonych odpowiedzi (%)',
        closed_cases: 'Sprawy zamknięte',
        percentage_closed_cases: 'Sprawy zamknięte (%)',
    },
    // 土耳其
    trTR: {
        fill_in_at_least_one_query_condition: 'En azından bir soru durumunu doldur',
        full_query: 'Tam soru',
        query_time: 'Sorgulama zamanı',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        country: 'Ülke',
        number_of_cases: 'Toplam dosyalar',
        waiting_for_reply_cases: 'Dosyaların cevaplanması bekleniyor',
        percentage_of_No_Responses: 'Cevaplanmayı bekleyen dosyaların yüzdesi (%)',
        replied_cases: 'Cevaplanmış dosyalar',
        percentage_of_replied_cases: 'Cevaplanmış dosyaların yüzdesi %',
        closed_cases: 'Kapatılmış dosyalar',
        percentage_closed_cases: 'Kapatılmış dosyaların yüzdesi (%)',
    },
    // 捷克语
    csCZ: {
        fill_in_at_least_one_query_condition: 'Vyplňte alespoň jednu podmínku dotazu',
        full_query: 'Úplný dotaz',
        query_time: 'Čas dotazu',
        organization_code: 'Kód společnosti',
        organization_name: 'Název společnosti',
        country: 'Země',
        number_of_cases: 'Počet případů',
        waiting_for_reply_cases: 'Čeká na odpověď',
        percentage_of_No_Responses: 'Procento bez odpovědí',
        replied_cases: 'Odpovězené případy',
        percentage_of_replied_cases: 'Procento zodpovězených případů',
        closed_cases: 'Uzavřené případy',
        percentage_closed_cases: 'Uzavřené případy (%)',
    },
    // 匈牙利语
    huHU: {
        fill_in_at_least_one_query_condition: 'Töltsön ki legalább egy lekérdezési feltételt',
        full_query: 'Teljes lekérdezés',
        query_time: 'Lekérdezési idő',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        country: 'Ország',
        number_of_cases: 'Esetek száma',
        waiting_for_reply_cases: 'Reakcióra váró esetek',
        percentage_of_No_Responses: 'Reakció nélküli százalék',
        replied_cases: 'Megválaszolt esetek',
        percentage_of_replied_cases: 'Megválaszolt esetek százaléka',
        closed_cases: 'Lezárt esetek',
        percentage_closed_cases: 'Lezárt esetek (%)',
    },
    // 俄罗斯语
    ruRU: {
        fill_in_at_least_one_query_condition: 'Заполните хотя бы одно условие запроса',
        full_query: 'Полный запрос',
        query_time: 'Время запроса',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        country: 'Страна',
        number_of_cases: 'Общее количество случаев',
        waiting_for_reply_cases: 'Количество ожидающих ответа случаев',
        percentage_of_No_Responses: 'Процент неотвеченных случаев',
        replied_cases: 'Количество ответов на случаи',
        percentage_of_replied_cases: 'Процент ответов на случаи',
        closed_cases: 'Количество закрытых случаев',
        percentage_closed_cases: 'Процент закрытых случаев',
    },
};

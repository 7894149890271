export default {
    // 中
    zhCN: {
        fill_in_at_least_one_query_condition: '至少填写一个查询条件',
        reporting_organization_name: '上报组织名称',
        full_query: '全量查询',
        query_time: '查询时间',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        number_of_cases: '案例总数',
        failure_parts: '故障件名称',
        total_of_failure_parts: '故障件数量',
        of_organization: '组织占比',
        of_total: '全量占比',
    },
    // 英
    enUS: {
        fill_in_at_least_one_query_condition: 'Fill in at least one query condition',
        reporting_organization_name: 'Reporting organization name',
        full_query: 'Full query',
        query_time: 'Query time',
        organization_code: 'Organization code',
        organization_name: 'Organization name',
        country: 'Country',
        number_of_cases: 'Total cases',
        failure_parts: 'Failure parts',
        total_of_failure_parts: 'Total of failure parts',
        of_organization: '% of Organization',
        of_total: '% of Total',
    },
    // 德
    deDE: {
        fill_in_at_least_one_query_condition: 'Füllen Sie mindestens eine Abfragebedingung aus',
        reporting_organization_name: 'Name der meldenden Organisation',
        full_query: 'Vollständige Abfrage',
        query_time: 'Abfragezeit',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        number_of_cases: 'Gesamtzahl der Fälle',
        failure_parts: 'Defekte Teile',
        total_of_failure_parts: 'Anzahl der defekten Teile',
        of_organization: '% der Organisation',
        of_total: '% vom Gesamtwert',
    },
    // 法
    frFR: {
        fill_in_at_least_one_query_condition: 'Remplissez au moins une condition de demande',
        reporting_organization_name: 'Nom de l\'organisation de rapport',
        full_query: 'Requête complète',
        query_time: 'Temps de requête',
        organization_code: 'Code organisation',
        organization_name: 'Nom organisation',
        country: 'Pays',
        number_of_cases: 'Total cas',
        failure_parts: 'Pièces défectueuses',
        total_of_failure_parts: 'Total pièces défectueuses',
        of_organization: '% de l\'oganisation',
        of_total: '% du total',
    },
    // 意大利
    itIT: {
        fill_in_at_least_one_query_condition: 'Compilare almeno una condizione di query',
        reporting_organization_name: 'Nome Organizzazione Segnalante',
        full_query: 'Query Completa',
        query_time: 'Tempo di Query',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        country: 'Paese',
        number_of_cases: 'Numero totale di casi',
        failure_parts: 'Nome Pezzi Guasti',
        total_of_failure_parts: 'Numero Totale di Pezzi Guasti',
        of_organization: 'Percentuale per Organizzazione',
        of_total: 'Percentuale Totale',
    },
    // 荷兰
    nlNL: {
        fill_in_at_least_one_query_condition: 'Vul ten minste één query voorwaarde in',
        reporting_organization_name: 'Rapporterende organisatienaam',
        full_query: 'Volledige query',
        query_time: 'Query time',
        organization_code: 'Organisatie code',
        organization_name: 'Organisatie naam',
        country: 'Land',
        number_of_cases: 'Totaal aantal gevallen',
        failure_parts: 'Falende onderdelen',
        total_of_failure_parts: 'Totaal van falende onderdelen',
        of_organization: '% van organisatie',
        of_total: '% van totaal',
    },
    // 越南
    viVN: {
        fill_in_at_least_one_query_condition: 'Ít nhất điền một điều kiện tìm kiếm',
        reporting_organization_name: 'Tên tổ chức báo cáo',
        full_query: 'Tra cứu toàn bộ',
        query_time: 'Thời gian truy vấn',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        country: 'Quốc gia',
        number_of_cases: 'Tổng số trường hợp',
        failure_parts: 'Phụ tùng bị lỗi',
        total_of_failure_parts: 'Tổng số phụ tùng bị lỗi',
        of_organization: 'Phần trăm của tổ chức',
        of_total: 'Phần trăm của tổng số',
    },
    // 西班牙
    esES: {
        fill_in_at_least_one_query_condition: 'Al menos complete un criterio de búsqueda',
        reporting_organization_name: 'Nombre de la organización de informes',
        full_query: 'Consulta completa',
        query_time: 'Tiempo de consulta',
        organization_code: 'Código de la organización',
        organization_name: 'Nombre de la organización',
        country: 'País',
        number_of_cases: 'Número total de casos',
        failure_parts: 'Nombre de la pieza defectuosa',
        total_of_failure_parts: 'Cantidad de piezas defectuosas',
        of_organization: 'Porcentaje de la organización',
        of_total: 'Porcentaje completo',
    },
    // 葡萄牙
    ptBR: {
        fill_in_at_least_one_query_condition: 'Preencha pelo menos uma condição de consulta',
        reporting_organization_name: 'Nome da organização de relatório',
        full_query: 'Consulta completa',
        query_time: 'Tempo de consulta',
        organization_code: 'Código da organização',
        organization_name: 'Nome da organização',
        country: 'País',
        number_of_cases: 'Total de casos',
        failure_parts: 'Nome das peças com defeito',
        total_of_failure_parts: 'Total de peças com defeito',
        of_organization: 'Porcentagem da organização',
        of_total: 'Porcentagem total',
    },
    // 波兰
    plPL: {
        fill_in_at_least_one_query_condition: 'Wypełnij co najmniej jeden warunek zapytania',
        reporting_organization_name: 'Nazwa organizacji raportującej',
        full_query: 'Pełne zapytanie',
        query_time: 'Data zapytania',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        country: 'Kraj',
        number_of_cases: 'Łączna liczba spraw',
        failure_parts: 'Uszkodzona część',
        total_of_failure_parts: 'Liczba uszkodzonych części',
        of_organization: '% organizacji',
        of_total: '% ogółu',
    },
    // 土耳其
    trTR: {
        fill_in_at_least_one_query_condition: 'En azından bir soru durumunu doldur',
        reporting_organization_name: 'Bildirim organizasyonu adı',
        full_query: 'Tam sorgulama',
        query_time: 'Sorgulama zamanı',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        country: 'Ülke',
        number_of_cases: 'Toplam  dosyalar',
        failure_parts: 'Arızalı parçalar',
        total_of_failure_parts: 'Toplam arızalı parçalar',
        of_organization: 'Organizasyon %',
        of_total: 'Toplam %',
    },
    // 捷克语
    csCZ: {
        fill_in_at_least_one_query_condition: 'Vyplňte alespoň jednu podmínku dotazu',
        reporting_organization_name: 'Název ohlašující společnosti',
        full_query: 'Úplný dotaz',
        query_time: 'Čas dotazu',
        organization_code: 'Kód společnosti',
        organization_name: 'Název společnosti',
        country: 'Země',
        number_of_cases: 'Celkový počet případů',
        failure_parts: 'Poruchové díly',
        total_of_failure_parts: 'Celkový počet vadných dílů',
        of_organization: '% organizace',
        of_total: '% celkem',
    },
    // 匈牙利语
    huHU: {
        fill_in_at_least_one_query_condition: 'Töltsön ki legalább egy lekérdezési feltételt',
        reporting_organization_name: 'Jelentő szervezet neve',
        full_query: 'Teljes lekérdezés',
        query_time: 'Lekérdezési idő',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        country: 'Ország',
        number_of_cases: 'Összes eset',
        failure_parts: 'Meghibásodott alkatrészek',
        total_of_failure_parts: 'Összes meghibásodott alkatrész',
        of_organization: 'Szervezet %-a',
        of_total: 'Összes %-a',
    },
    // 俄罗斯语
    ruRU: {
        fill_in_at_least_one_query_condition: 'Заполните хотя бы одно условие запроса',
        reporting_organization_name: 'Название отчетной организации',
        full_query: 'Полный запрос',
        query_time: 'Время запроса',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        country: 'Страна',
        number_of_cases: 'Общее количество случаев',
        failure_parts: 'Наименование неисправных деталей',
        total_of_failure_parts: 'Общее количество неисправных деталей',
        of_organization: 'Доля организации',
        of_total: 'Доля от общего числа',
    },
};

export default {
    // 中
    zhCN: {
        region: '地区',
        vehicle_platform: '车型平台',
        code_name: '代码名称',
        abnormal_detection_time: '异常发现时间',
        return_shipment_status: '回运件状态',
        problem_status: '问题状态',
        abnormal_shutdown_time: '异常关闭时间',
        operation_code: '操作代码',
        country: '国家',
        exception_type: '异常类型',
        total_number_of_claims: '总索赔数',
        number_of_claims_after_breakpoint: '断点后索赔数',
        analyze_requirements: '分析需求',
        return_shipment_OA_time: '回运件抵达OA时间',
        function_block: '功能块',
        Supervisor: '主管人',
        abnormal_situation_description: '异常情况描述',
        known_breakpoint_information: '已知断点信息',
        overseas_information_feedback: '海外信息反馈',
        latest_progress: '最新进展(原因分析&改进断点)',
        abnormal_analysis_conclusion: '异常分析结论',
        marketing_strategy: '市场活动策略',
    },
    // 英
    enUS: {
        region: 'region',
        vehicle_platform: 'Vehicle Platform',
        code_name: 'Code Name',
        abnormal_detection_time: 'Abnormal Detection Time',
        return_shipment_status: 'Return Shipment Status',
        problem_status: 'Problem Status',
        abnormal_shutdown_time: 'Abnormal Shutdown Time',
        operation_code: 'Operation code',
        country: 'country',
        exception_type: 'Exception type',
        total_number_of_claims: 'Total number of claims',
        number_of_claims_after_breakpoint: 'Number of claims after breakpoint',
        analyze_requirements: 'Analyze requirements',
        return_shipment_OA_time: 'Return shipment arrival time at OA',
        function_block: 'Function block',
        Supervisor: 'Supervisor',
        abnormal_situation_description: 'Abnormal situation description',
        known_breakpoint_information: 'Known breakpoint information',
        overseas_information_feedback: 'Overseas information feedback',
        latest_progress: 'Latest Progress (Cause Analysis&Improvement Breakpoints)',
        abnormal_analysis_conclusion: 'Abnormal analysis conclusion',
        marketing_strategy: 'Marketing strategy',
    },
    // 德
    deDE: {
        region: 'Region',
        vehicle_platform: 'Fahrzeugplattform',
        code_name: 'Codename',
        abnormal_detection_time: 'Anomalieerkennungszeit',
        return_shipment_status: 'Rücksendestatus',
        problem_status: 'Problestatus',
        abnormal_shutdown_time: 'Anomalieabschaltzeit',
        operation_code: 'Betriebscode',
        country: 'Land',
        exception_type: 'Ausnahmetyp',
        total_number_of_claims: 'Gesamtzahl der Ansprüche',
        number_of_claims_after_breakpoint: 'Anzahl der Ansprüche nach dem Breakpoint',
        analyze_requirements: 'Analyseanforderungen',
        return_shipment_OA_time: 'Rücksendung OA Zeit',
        function_block: 'Funktionsblock',
        Supervisor: 'Aufsichtsperson',
        abnormal_situation_description: 'Beschreibung der Anomalie',
        known_breakpoint_information: 'Bekannte Breakpoint-Informationen',
        overseas_information_feedback: 'Feedback zu Überseeinformationen',
        latest_progress: 'Neueste Fortschritte (Ursachenanalyse & Verbesserungen)',
        abnormal_analysis_conclusion: 'Anomalieanalyse Schlussfolgerung',
        marketing_strategy: 'Marketingstrategie',
    },
    // 法
    frFR: {
        region: 'Région',
        vehicle_platform: 'Plateforme de véhicule',
        code_name: 'Nom de code',
        abnormal_detection_time: 'Heure de détection anormale',
        return_shipment_status: 'État de retour',
        problem_status: 'État du problème',
        abnormal_shutdown_time: 'Heure d\'arrêt anormal',
        operation_code: 'Code d\'opération',
        country: 'Pays',
        exception_type: 'Type d\'exception',
        total_number_of_claims: 'Nombre total de réclamations',
        number_of_claims_after_breakpoint: 'Nombre de réclamations après point de rupture',
        analyze_requirements: 'Exigences d\'analyse',
        return_shipment_OA_time: 'Temps de retour OA',
        function_block: 'Bloc fonctionnel',
        Supervisor: 'Superviseur',
        abnormal_situation_description: 'Description de la situation anormale',
        known_breakpoint_information: 'Informations de point de rupture connues',
        overseas_information_feedback: 'Retour d\'informations à l\'étranger',
        latest_progress: 'Derniers progrès (analyse des causes & améliorations)',
        abnormal_analysis_conclusion: 'Conclusion de l\'analyse anormale',
        marketing_strategy: 'Stratégie marketing',
    },
    // 意大利
    itIT: {
        region: 'Regione',
        vehicle_platform: 'Piattaforma del veicolo',
        code_name: 'Nome in codice',
        abnormal_detection_time: 'Tempo di rilevamento anomalo',
        return_shipment_status: 'Stato della spedizione di ritorno',
        problem_status: 'Stato del problema',
        abnormal_shutdown_time: 'Tempo di arresto anomalo',
        operation_code: 'Codice operazione',
        country: 'Paese',
        exception_type: 'Tipo di eccezione',
        total_number_of_claims: 'Numero totale di reclami',
        number_of_claims_after_breakpoint: 'Numero di reclami dopo il punto di interruzione',
        analyze_requirements: 'Requisiti di analisi',
        return_shipment_OA_time: 'Tempo di restituzione OA',
        function_block: 'Blocco funzionale',
        Supervisor: 'Supervisore',
        abnormal_situation_description: 'Descrizione della situazione anomala',
        known_breakpoint_information: 'Informazioni sul punto di interruzione conosciute',
        overseas_information_feedback: 'Feedback delle informazioni estere',
        latest_progress: 'Ultimi progressi (analisi delle cause & miglioramenti)',
        abnormal_analysis_conclusion: 'Conclusione dell\'analisi anomala',
        marketing_strategy: 'Strategia di marketing',
    },
    // 荷兰
    nlNL: {
        region: 'Regio',
        vehicle_platform: 'Voertuigplatform',
        code_name: 'Codenaam',
        abnormal_detection_time: 'Tijd van abnormale detectie',
        return_shipment_status: 'Retourverzendstatus',
        problem_status: 'Probleemstatus',
        abnormal_shutdown_time: 'Tijd van abnormale uitschakeling',
        operation_code: 'Operatiecode',
        country: 'Land',
        exception_type: 'Uitzonderingstype',
        total_number_of_claims: 'Totaal aantal claims',
        number_of_claims_after_breakpoint: 'Aantal claims na breekpunt',
        analyze_requirements: 'Analysevereisten',
        return_shipment_OA_time: 'Retourzending OA tijd',
        function_block: 'Functieblok',
        Supervisor: 'Toezichthouder',
        abnormal_situation_description: 'Beschrijving van de abnormaliteit',
        known_breakpoint_information: 'Bekende breekpuntinformatie',
        overseas_information_feedback: 'Feedback van buitenlandse informatie',
        latest_progress: 'Laatste voortgang (oorzaakanalyse & verbeteringen)',
        abnormal_analysis_conclusion: 'Conclusie van de anomalie-analyse',
        marketing_strategy: 'Marketingstrategie',
    },
    // 越南
    viVN: {
        region: 'Khu vực',
        vehicle_platform: 'Nền tảng xe',
        code_name: 'Tên mã',
        abnormal_detection_time: 'Thời gian phát hiện bất thường',
        return_shipment_status: 'Tình trạng hàng trả lại',
        problem_status: 'Tình trạng vấn đề',
        abnormal_shutdown_time: 'Thời gian tắt bất thường',
        operation_code: 'Mã hoạt động',
        country: 'Quốc gia',
        exception_type: 'Loại ngoại lệ',
        total_number_of_claims: 'Tổng số yêu cầu',
        number_of_claims_after_breakpoint: 'Số lượng yêu cầu sau điểm dừng',
        analyze_requirements: 'Yêu cầu phân tích',
        return_shipment_OA_time: 'Thời gian trả hàng OA',
        function_block: 'Khối chức năng',
        Supervisor: 'Người giám sát',
        abnormal_situation_description: 'Mô tả tình huống bất thường',
        known_breakpoint_information: 'Thông tin điểm dừng đã biết',
        overseas_information_feedback: 'Phản hồi thông tin nước ngoài',
        latest_progress: 'Tiến trình mới nhất (phân tích nguyên nhân & cải tiến)',
        abnormal_analysis_conclusion: 'Kết luận phân tích bất thường',
        marketing_strategy: 'Chiến lược tiếp thị',
    },
    // 西班牙
    esES: {
        region: 'Región',
        vehicle_platform: 'Plataforma del vehículo',
        code_name: 'Nombre en código',
        abnormal_detection_time: 'Tiempo de detección anormal',
        return_shipment_status: 'Estado del envío de devolución',
        problem_status: 'Estado del problema',
        abnormal_shutdown_time: 'Tiempo de apagado anormal',
        operation_code: 'Código de operación',
        country: 'País',
        exception_type: 'Tipo de excepción',
        total_number_of_claims: 'Número total de reclamaciones',
        number_of_claims_after_breakpoint: 'Número de reclamaciones después del punto de quiebre',
        analyze_requirements: 'Requisitos de análisis',
        return_shipment_OA_time: 'Tiempo de devolución OA',
        function_block: 'Bloque funcional',
        Supervisor: 'Supervisor',
        abnormal_situation_description: 'Descripción de la situación anormal',
        known_breakpoint_information: 'Información sobre puntos de ruptura conocidos',
        overseas_information_feedback: 'Retroalimentación de información en el extranjero',
        latest_progress: 'Últimos avances (análisis de causas & mejoras)',
        abnormal_analysis_conclusion: 'Conclusión del análisis anormal',
        marketing_strategy: 'Estrategia de marketing',
    },
    // 葡萄牙
    ptBR: {
        region: 'Região',
        vehicle_platform: 'Plataforma do veículo',
        code_name: 'Nome de código',
        abnormal_detection_time: 'Tempo de detecção anormal',
        return_shipment_status: 'Status de devolução',
        problem_status: 'Status do problema',
        abnormal_shutdown_time: 'Tempo de desligamento anormal',
        operation_code: 'Código de operação',
        country: 'País',
        exception_type: 'Tipo de exceção',
        total_number_of_claims: 'Número total de reclamações',
        number_of_claims_after_breakpoint: 'Número de reclamações após o ponto de interrupção',
        analyze_requirements: 'Requisitos de análise',
        return_shipment_OA_time: 'Tempo de devolução OA',
        function_block: 'Bloco funcional',
        Supervisor: 'Supervisor',
        abnormal_situation_description: 'Descrição da situação anormal',
        known_breakpoint_information: 'Informações sobre pontos de interrupção conhecidos',
        overseas_information_feedback: 'Feedback de informações no exterior',
        latest_progress: 'Últimos progressos (análise de causas & melhorias)',
        abnormal_analysis_conclusion: 'Conclusão da análise anormal',
        marketing_strategy: 'Estratégia de marketing',
    },
    // 波兰
    plPL: {
        region: 'Region',
        vehicle_platform: 'Platforma pojazdu',
        code_name: 'Nazwa kodowa',
        abnormal_detection_time: 'Czas wykrywania nieprawidłowości',
        return_shipment_status: 'Status zwrotu',
        problem_status: 'Status problemu',
        abnormal_shutdown_time: 'Czas anormalnego wyłączenia',
        operation_code: 'Kod operacji',
        country: 'Kraj',
        exception_type: 'Typ wyjątku',
        total_number_of_claims: 'Całkowita liczba roszczeń',
        number_of_claims_after_breakpoint: 'Liczba roszczeń po punkcie przerwania',
        analyze_requirements: 'Wymagania analizy',
        return_shipment_OA_time: 'Czas zwrotu OA',
        function_block: 'Blok funkcji',
        Supervisor: 'Nadzorca',
        abnormal_situation_description: 'Opis anomalii',
        known_breakpoint_information: 'Znane informacje o punkcie przerwania',
        overseas_information_feedback: 'Informacje zwrotne z zagranicy',
        latest_progress: 'Najnowsze postępy (analiza przyczyn & ulepszenia)',
        abnormal_analysis_conclusion: 'Wnioski z analizy anomalii',
        marketing_strategy: 'Strategia marketingowa',
    },
    // 土耳其
    trTR: {
        region: 'Bölge',
        vehicle_platform: 'Araç platformu',
        code_name: 'Kod adı',
        abnormal_detection_time: 'Anormal tespit zamanı',
        return_shipment_status: 'İade gönderim durumu',
        problem_status: 'Problem durumu',
        abnormal_shutdown_time: 'Anormal kapatma zamanı',
        operation_code: 'İşlem kodu',
        country: 'Ülke',
        exception_type: 'İstisna türü',
        total_number_of_claims: 'Toplam talep sayısı',
        number_of_claims_after_breakpoint: 'Kesme noktasından sonraki talep sayısı',
        analyze_requirements: 'Analiz gereksinimleri',
        return_shipment_OA_time: 'İade gönderim OA zamanı',
        function_block: 'Fonksiyon bloğu',
        Supervisor: 'Gözetmen',
        abnormal_situation_description: 'Anormal durum açıklaması',
        known_breakpoint_information: 'Bilinen kesme noktası bilgileri',
        overseas_information_feedback: 'Yurt dışı bilgi geri bildirimi',
        latest_progress: 'Son gelişmeler (neden analizi & iyileştirmeler)',
        abnormal_analysis_conclusion: 'Anormal analiz sonuçları',
        marketing_strategy: 'Pazarlama stratejisi',
    },
    // 捷克语
    csCZ: {
        region: 'region',
        vehicle_platform: 'Platforma vozidla',
        code_name: 'Název kódu',
        abnormal_detection_time: 'Abnormální doba detekce',
        return_shipment_status: 'Stav vrácení zásilky',
        problem_status: 'Stav problému',
        abnormal_shutdown_time: 'Neobvyklá doba vypnutí',
        operation_code: 'Kód operace',
        country: 'země',
        exception_type: 'Typ výjimky',
        total_number_of_claims: 'Celkový počet žádostí',
        number_of_claims_after_breakpoint: 'Počet nároků po bodu breakpointu',
        analyze_requirements: 'Analýza požadavků',
        return_shipment_OA_time: 'Return shipment arrival time at OA',
        function_block: 'Funkční blok',
        Supervisor: 'Vedoucí pracovník',
        abnormal_situation_description: 'Popis abnormální situace',
        known_breakpoint_information: 'Známé informace o bodu zlomu',
        overseas_information_feedback: 'Zpětná vazba informací ze zahraničí',
        latest_progress: 'Nejnovější pokrok (analýza příčin a zlomové body zlepšení)',
        abnormal_analysis_conclusion: 'Abnormální závěr analýzy',
        marketing_strategy: 'Marketingová strategie',
    },
    // 匈牙利语
    huHU: {
        region: 'Régió',
        vehicle_platform: 'Járműplatform',
        code_name: 'Kódnév',
        abnormal_detection_time: 'Rendellenes észlelés ideje',
        return_shipment_status: 'Visszaküldés állapota',
        problem_status: 'Probléma állapota',
        abnormal_shutdown_time: 'Rendellenes leállás ideje',
        operation_code: 'Műveletkód',
        country: 'Ország',
        exception_type: 'Kivétel típusa',
        total_number_of_claims: 'Összes igény száma',
        number_of_claims_after_breakpoint: 'Igények száma lerobbanás után',
        analyze_requirements: 'Elemzési követelmények',
        return_shipment_OA_time: 'Visszaküldött szállítmány érkezési ideje OA-hoz',
        function_block: 'Funkcióblokk',
        Supervisor: 'Felettes',
        abnormal_situation_description: 'Rendellenes helyzet leírása',
        known_breakpoint_information: 'Ismert lerobbanási adatok',
        overseas_information_feedback: 'Tengeren túli adatok visszajelzése',
        latest_progress: 'Legutóbbi előrelépések (lerobbanások okainak elemzése és fejlesztése)',
        abnormal_analysis_conclusion: 'Rendelleneség elemzésének eredménye',
        marketing_strategy: 'Marketingstratégia',
    },
    // 俄罗斯语
    ruRU: {
        region: 'Регион',
        vehicle_platform: 'Платформа автомобиля',
        code_name: 'Код Название',
        abnormal_detection_time: 'Время аномального обнаружения',
        return_shipment_status: 'Статус возвратной отправки',
        problem_status: 'Статус проблемы',
        abnormal_shutdown_time: 'Время аномального завершения',
        operation_code: 'Код операции',
        country: 'Страна',
        exception_type: 'Тип исключения',
        total_number_of_claims: 'Общее количество требований',
        number_of_claims_after_breakpoint: 'Количество требований после прерывания',
        analyze_requirements: 'Требования анализа',
        return_shipment_OA_time: 'Время прибытия возвратной отправки в OA',
        function_block: 'Блок функции',
        Supervisor: 'Руководитель',
        abnormal_situation_description: 'Описание аномальной ситуации',
        known_breakpoint_information: 'Известная информация о прерывании',
        overseas_information_feedback: 'Обратная связь о зарубежной информации',
        latest_progress: 'Последний прогресс (анализ причин и меры по улучшению прерываний)',
        abnormal_analysis_conclusion: 'Заключение по аномальному анализу',
        marketing_strategy: 'Маркетинговая стратегия',
    },
};

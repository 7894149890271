export default {
    // 中
    zhCN: {
        model: '车型',
        mileage: '里程数',
        functiontype: '功能类型',
        functiontypedesp_zh: '功能类型描述(中文)',
        functiontypedesp_en: '功能类型描述(英文)',
        channel: '通道',
        channeldesp_zh: '通道描述(中文)',
        channeldesp_en: '通道描述(英文)',
        functionstarttime: '功能开始时间',
        OS_version_beforere_freshing: '刷新前整车软件OS版本',
        part_number_before_refreshing: '刷新前整车软件零件号',
        vehicle_software_OS_version: '车软件OS版本',
        controller_name: '控制器名称',
        hardware_number: '硬件号',
        software_information_before_Refresh: '刷新前的软件信息',
        software_information_after_Refresh: '刷新后的软件信息',
        write_software_information: '写入软件信息',
        function_name: '功能名',
        minor_process_execution_time: '小流程执行时长',
        small_process_execution_result: '小流程执行结果',
        function_execution_time: '功能执行时长',
        function_execution_result: '功能执行结果',
        function_execution_result_desp_zh: '功能执行结果描述(中文)',
        function_execution_result_desp_en: '功能执行结果描述(英文)',
        log_start_time: 'Log开始时间',
        log_end_time: 'Log结束时间',
        vehicle_recognition_method: '车辆识别方式',
        vehicle_recognition_method_desp_zh: '车辆识别方式描述(中文)',
        vehicle_recognition_method_desp_en: '车辆识别方式描述(英文)',
        model_year: '年款',
        vehicle_configuration_information: '车辆配置信息',
        platform: '平台',
        login_account: '登录账号',
        user_name: '用户名称',
        service_center: '服务中心',
        SIPS_version: 'SIPS版本',
        VDI_version: 'VDI版本',
        VDI_connection_method: 'VDI连接方式',
        VDI_connection_method_desp_zh: 'VDI连接方式描述(中文)',
        VDI_connection_method_desp_en: 'VDI连接方式描述(英文)',
        operating_system: '操作系统',
        operating_system_zh: '操作系统(中文)',
        operating_system_en: '操作系统(英文)',
        brand: '品牌',
        country_region: '国家/地区',
    },
    // 英
    enUS: {
        model: 'Model',
        mileage: 'Mileage',
        functiontype: 'Function Type',
        functiontypedesp_zh: 'Function Type Description (Chinese)',
        functiontypedesp_en: 'Function Type Description (English)',
        channel: 'Channel',
        channeldesp_zh: 'Channel Description (Chinese)',
        channeldesp_en: 'Channel Description (English)',
        functionstarttime: 'Function Start Time',
        OS_version_beforere_freshing: 'Vehicle software OS version before refreshing',
        part_number_before_refreshing: 'Vehicle software part number before refreshing',
        vehicle_software_OS_version: 'Vehicle software OS version',
        controller_name: 'Controller Name',
        hardware_number: 'Hardware number',
        software_information_before_Refresh: 'Software Information Before Refresh',
        software_information_after_Refresh: 'Software information after refreshing',
        write_software_information: 'Write Software Information',
        function_name: 'Function name',
        minor_process_execution_time: 'Minor process execution time',
        small_process_execution_result: 'Small process execution result',
        function_execution_time: 'Function execution time',
        function_execution_result: 'Function Execution Result',
        function_execution_result_desp_zh: 'Function Execution Result Description (Chinese)',
        function_execution_result_desp_en: 'Function Execution Result Description (English)',
        log_start_time: 'Log start time',
        log_end_time: 'Log end time',
        vehicle_recognition_method: 'Vehicle Recognition Method',
        vehicle_recognition_method_desp_zh: 'Vehicle Recognition Method Description (Chinese)',
        vehicle_recognition_method_desp_en: 'Vehicle Recognition Method Description (English)',
        model_year: 'Model Year',
        vehicle_configuration_information: 'Vehicle Configuration Information',
        platform: 'Platform',
        login_account: 'Login Account',
        user_name: 'User Name',
        service_center: 'Service Center',
        SIPS_version: 'SIPS Version',
        VDI_version: 'VDI Version',
        VDI_connection_method: 'VDI Connection Method',
        VDI_connection_method_desp_zh: 'Description of VDI connection method (Chinese)',
        VDI_connection_method_desp_en: 'Description of VDI connection method (English)',
        operating_system: 'Operating System',
        operating_system_zh: 'Operating System(Chinese)',
        operating_system_en: 'Operating System(English)',
        brand: 'Brand',
        country_region: 'Country/Region',
    },
    // 德
    deDE: {
        model: 'Modell',
        mileage: 'Kilometerstand',
        functiontype: 'Funktion Typ',
        functiontypedesp_zh: 'Beschreibung des Funktionstyps (Chinesisch)',
        functiontypedesp_en: 'Funktionstyp Beschreibung (Englisch)',
        channel: 'Kanal',
        channeldesp_zh: 'Kanalbeschreibung (Chinesisch)',
        channeldesp_en: 'Kanalbeschreibung (Englisch)',
        functionstarttime: 'Funktion Startzeit',
        OS_version_beforere_freshing:
            'Version des Betriebssystems der Fahrzeugsoftware vor der Auffrischung',
        part_number_before_refreshing: 'Teilnummer der Fahrzeugsoftware vor der Aktualisierung',
        vehicle_software_OS_version: 'Version des Betriebssystems der Fahrzeugsoftware',
        controller_name: 'Name des Steuergeräts',
        hardware_number: 'Hardware-Nummer',
        software_information_before_Refresh: 'Software-Informationen vor der Auffrischung',
        software_information_after_Refresh: 'Software-Informationen nach der Auffrischung',
        write_software_information: 'Schreiben von Software-Informationen',
        function_name: 'Name der Funktion',
        minor_process_execution_time: 'Kleine Prozessausführungszeit',
        small_process_execution_result: 'Ergebnis der Ausführung eines kleinen Prozesses',
        function_execution_time: 'Ausführungszeit der Funktion',
        function_execution_result: 'Funktion Ausführungsergebnis',
        function_execution_result_desp_zh:
            'Beschreibung des Funktionsausführungsergebnisses (Chinesisch)',
        function_execution_result_desp_en: 'Funktion Ausführungsergebnis Beschreibung (Englisch)',
        log_start_time: 'Startzeit des Protokolls',
        log_end_time: 'Endzeit des Protokolls',
        vehicle_recognition_method: 'Fahrzeugerkennungsmethode',
        vehicle_recognition_method_desp_zh:
            'Beschreibung der Fahrzeugerkennungsmethode (Chinesisch)',
        vehicle_recognition_method_desp_en: 'Beschreibung der Fahrzeugerkennungsmethode (Englisch)',
        model_year: 'Modelljahr',
        vehicle_configuration_information: 'Informationen zur Fahrzeugkonfiguration',
        platform: 'Plattform',
        login_account: 'Login-Konto',
        user_name: 'Benutzer Name',
        service_center: 'Service-Zentrum',
        SIPS_version: 'SIPS-Version',
        VDI_version: 'VDI-Version',
        VDI_connection_method: 'VDI-Verbindung',
        VDI_connection_method_desp_zh: 'Beschreibung der VDI-Verbindungsmethode (Chinesisch)',
        VDI_connection_method_desp_en: 'Beschreibung der VDI-Verbindung (Englisch)',
        operating_system: 'Betriebssystem',
        operating_system_zh: 'Betriebssystem (Chinesisch)',
        operating_system_en: 'Betriebssystem (Englisch)',
        brand: 'Marke',
        country_region: 'Land/Region',
    },
    // 法
    frFR: {
        model: 'modèle',
        mileage: 'kilométrage',
        functiontype: 'Type de fonction',
        functiontypedesp_zh: 'Type de fonction Description (chinois)',
        functiontypedesp_en: 'Description du type de fonction (anglais)',
        channel: 'Canal',
        channeldesp_zh: 'Description du canal (chinois)',
        channeldesp_en: 'Description du canal (anglais)',
        functionstarttime: 'Heure de démarrage de la fonction',
        OS_version_beforere_freshing: 'Version du logiciel du véhicule avant actualisation',
        part_number_before_refreshing:
            'Numéro de pièce du logiciel du véhicule avant rafraîchissemen',
        vehicle_software_OS_version: 'Version du système d\'exploitation du logiciel du véhicule',
        controller_name: 'Nom du contrôleur',
        hardware_number: 'Numéro du matériel',
        software_information_before_Refresh: 'Informations sur le logiciel avant rafraîchissement',
        software_information_after_Refresh: 'Informations sur le logiciel après rafraîchissement',
        write_software_information: 'Écriture des informations sur le logiciel',
        function_name: 'Nom de la fonction',
        minor_process_execution_time: 'Temps d\'exécution du processus mineur',
        small_process_execution_result: 'Résultat de l\'exécution du processus mineur',
        function_execution_time: 'Temps d\'exécution de la fonction',
        function_execution_result: 'Résultat de l\'exécution de la fonction',
        function_execution_result_desp_zh:
            'Description du résultat de l\'exécution de la fonction (chinois)',
        function_execution_result_desp_en:
            'Description du résultat de l\'exécution de la fonction (anglais)',
        log_start_time: 'Heure de début du journal',
        log_end_time: 'Heure de fin du journal',
        vehicle_recognition_method: 'Méthode de reconnaissance des véhicules',
        vehicle_recognition_method_desp_zh:
            'Description de la méthode de reconnaissance des véhicules (chinois)',
        vehicle_recognition_method_desp_en:
            'Description de la méthode de reconnaissance des véhicules (anglais)',
        model_year: 'Année du modèle',
        vehicle_configuration_information: 'Informations sur la configuration du véhicule',
        platform: 'Plateforme',
        login_account: 'Compte de connexion',
        user_name: 'Nom d\'utilisateur',
        service_center: 'Centre de service',
        SIPS_version: 'Version SIPS',
        VDI_version: 'Version VDI',
        VDI_connection_method: 'Méthode de connexion VDI',
        VDI_connection_method_desp_zh: 'Description de la méthode de connexion VDI (chinois)',
        VDI_connection_method_desp_en: 'Description de la connexion VDI (anglais)',
        operating_system: 'Système d\'exploitation',
        operating_system_zh: 'Système d\'exploitation (chinois)',
        operating_system_en: 'Système d\'exploitation (anglais)',
        brand: 'Marque',
        country_region: 'Pays/Région',
    },
    // 意大利
    itIT: {
        model: 'modello',
        mileage: 'chilometraggio',
        functiontype: 'Tipo di funzione',
        functiontypedesp_zh: 'Descrizione del tipo di funzione (cinese)',
        functiontypedesp_en: 'Descrizione del tipo di funzione (inglese)',
        channel: 'Canale',
        channeldesp_zh: 'Descrizione del canale (cinese)',
        channeldesp_en: 'Descrizione del canale (inglese)',
        functionstarttime: 'Funzione Tempo di avvio',
        OS_version_beforere_freshing: 'Versione software OS del veicolo prima dell\'aggiornamento',
        part_number_before_refreshing:
            'Numero di parte del software del veicolo prima dell\'aggiornamento',
        vehicle_software_OS_version: 'Versione OS del software del veicolo',
        controller_name: 'Nome del controllore',
        hardware_number: 'Numero di hardware',
        software_information_before_Refresh: 'Informazioni sul software prima del refresh',
        software_information_after_Refresh: 'Informazioni sul software dopo l\'aggiornamento',
        write_software_information: 'Scrivi informazioni sul software',
        function_name: 'Nome della funzione',
        minor_process_execution_time: 'Tempo di esecuzione del processo minore',
        small_process_execution_result: 'Risultato dell\'esecuzione del processo minore',
        function_execution_time: 'Tempo di esecuzione della funzione',
        function_execution_result: 'Risultato dell\'esecuzione della funzione',
        function_execution_result_desp_zh:
            'Descrizione del risultato dell\'esecuzione della funzione (cinese)',
        function_execution_result_desp_en:
            'Descrizione del risultato dell\'esecuzione della funzione (inglese)',
        log_start_time: 'Ora di inizio del registro',
        log_end_time: 'Ora di fine registro',
        vehicle_recognition_method: 'Metodo di riconoscimento del veicolo',
        vehicle_recognition_method_desp_zh:
            'Descrizione del metodo di riconoscimento del veicolo (cinese)',
        vehicle_recognition_method_desp_en:
            'Descrizione del metodo di riconoscimento del veicolo (inglese)',
        model_year: 'Anno modello',
        vehicle_configuration_information: 'Informazioni sulla configurazione del veicolo',
        platform: 'Piattaforma',
        login_account: 'Account di accesso',
        user_name: 'Nome utente',
        service_center: 'Centro servizi',
        SIPS_version: 'Versione SIPS',
        VDI_version: 'Versione VDI',
        VDI_connection_method: 'Metodo di connessione VDI',
        VDI_connection_method_desp_zh: 'Descrizione del metodo di connessione VDI (cinese)',
        VDI_connection_method_desp_en: 'Descrizione della connessione VDI (inglese)',
        operating_system: 'Sistema operativo',
        operating_system_zh: 'Sistema operativo (cinese)',
        operating_system_en: 'Sistema operativo (inglese)',
        brand: 'Marchio',
        country_region: 'Paese/Regione',
    },
    // 荷兰
    nlNL: {
        model: 'Model',
        mileage: 'Kilometerstand',
        functiontype: 'Functietype',
        functiontypedesp_zh: 'Functietype Beschrijving (Chinees)',
        functiontypedesp_en: 'Functietype Beschrijving (Engels)',
        channel: 'Kanaal',
        channeldesp_zh: 'Beschrijving kanaal (Chinees)',
        channeldesp_en: 'Beschrijving kanaal (Engels)',
        functionstarttime: 'Functie starttijd',
        OS_version_beforere_freshing: 'OS-versie voertuigsoftware vóór vernieuwing',
        part_number_before_refreshing: 'Onderdeelnummer voertuigsoftware vóór vernieuwen',
        vehicle_software_OS_version: 'OS-versie voertuigsoftware',
        controller_name: 'Naam controller',
        hardware_number: 'Hardware nummer',
        software_information_before_Refresh: 'Software-informatie vóór verversen',
        software_information_after_Refresh: 'Software-informatie na verversen',
        write_software_information: 'Software-informatie schrijven',
        function_name: 'Naam functie',
        minor_process_execution_time: 'Kleine procesuitvoeringstijd',
        small_process_execution_result: 'Resultaat uitvoering klein proces',
        function_execution_time: 'Tijd uitvoering functie',
        function_execution_result: 'Resultaat van functie-uitvoering',
        function_execution_result_desp_zh:
            'Beschrijving van het resultaat van de functie-uitvoering (Chinees)',
        function_execution_result_desp_en:
            'Beschrijving van het functie-uitvoeringresultaat (Engels)',
        log_start_time: 'Begintijd log',
        log_end_time: 'Eindtijd log',
        vehicle_recognition_method: 'Methode voertuigherkenning',
        vehicle_recognition_method_desp_zh: 'Beschrijving voertuigherkenningsmethode (Chinees)',
        vehicle_recognition_method_desp_en: 'Beschrijving voertuigherkenningsmethode (Engels)',
        model_year: 'Modeljaar',
        vehicle_configuration_information: 'Informatie over voertuigconfiguratie',
        platform: 'Platform',
        login_account: 'Inlogaccount',
        user_name: 'Gebruikersnaam',
        service_center: 'Servicecentrum',
        SIPS_version: 'SIPS-versie',
        VDI_version: 'VDI-versie',
        VDI_connection_method: 'VDI-verbindingsmethode',
        VDI_connection_method_desp_zh: 'Beschrijving van VDI-verbindingsmethode (Chinees)',
        VDI_connection_method_desp_en: 'Beschrijving VDI-verbinding (Engels)',
        operating_system: 'Besturingssysteem',
        operating_system_zh: 'Besturingssysteem (Chinees)',
        operating_system_en: 'Besturingssysteem (Engels)',
        brand: 'Merk',
        country_region: 'Land/regio',
    },
    // 越南
    viVN: {
        model: 'Mô hình',
        mileage: 'Số dặm',
        functiontype: 'Loại chức năng',
        functiontypedesp_zh: 'Mô tả loại chức năng (Tiếng Trung)',
        functiontypedesp_en: 'Mô tả loại chức năng (Tiếng Anh)',
        channel: 'Kênh',
        channeldesp_zh: 'Mô tả kênh (Tiếng Trung)',
        channeldesp_en: 'Mô tả kênh (Tiếng Anh)',
        functionstarttime: 'Thời gian bắt đầu chức năng',
        OS_version_beforere_freshing: 'Phiên bản hệ điều hành phương tiện trước khi làm mới',
        part_number_before_refreshing: 'Số phần mềm phương tiện trước khi làm mới',
        vehicle_software_OS_version: 'Phiên bản hệ điều hành phần mềm phương tiện',
        controller_name: 'Tên bộ điều khiển',
        hardware_number: 'Số phần cứng',
        software_information_before_Refresh: 'Thông tin phần mềm trước khi làm mới',
        software_information_after_Refresh: 'Thông tin phần mềm sau khi làm mới',
        write_software_information: 'Viết thông tin phần mềm',
        function_name: 'Tên chức năng',
        minor_process_execution_time: 'Thời gian thực hiện quá trình nhỏ',
        small_process_execution_result: 'Kết quả thực hiện quá trình nhỏ',
        function_execution_time: 'Thời gian thực hiện chức năng',
        function_execution_result: 'Kết quả thực hiện chức năng',
        function_execution_result_desp_zh: 'Mô tả kết quả thực hiện chức năng (Tiếng Trung)',
        function_execution_result_desp_en: 'Mô tả kết quả thực hiện chức năng (Tiếng Anh)',
        log_start_time: 'Thời gian bắt đầu ghi nhật ký',
        log_end_time: 'Thời gian kết thúc ghi nhật ký',
        vehicle_recognition_method: 'Phương pháp nhận dạng phương tiện',
        vehicle_recognition_method_desp_zh: 'Mô tả phương pháp nhận dạng phương tiện (Tiếng Trung)',
        vehicle_recognition_method_desp_en: 'Mô tả phương pháp nhận dạng phương tiện (Tiếng Anh)',
        model_year: 'Năm sản xuất',
        vehicle_configuration_information: 'Thông tin cấu hình phương tiện',
        platform: 'Nền tảng',
        login_account: 'Tài khoản đăng nhập',
        user_name: 'Tên người dùng',
        service_center: 'Trung tâm dịch vụ',
        SIPS_version: 'Phiên bản SIPS',
        VDI_version: 'Phiên bản VDI',
        VDI_connection_method: 'Phương pháp kết nối VDI',
        VDI_connection_method_desp_zh: 'Mô tả phương pháp kết nối VDI (Tiếng Trung)',
        VDI_connection_method_desp_en: 'Mô tả phương pháp kết nối VDI (Tiếng Anh)',
        operating_system: 'Hệ điều hành',
        operating_system_zh: 'Hệ điều hành (Tiếng Trung)',
        operating_system_en: 'Hệ điều hành (Tiếng Anh)',
        brand: 'Thương hiệu',
        country_region: 'Quốc gia/Khu vực',
    },
    // 西班牙
    esES: {
        model: 'Modelo',
        mileage: 'Kilometraje',
        functiontype: 'Tipo de función',
        functiontypedesp_zh: 'Descripción del tipo de función (chino)',
        functiontypedesp_en: 'Descripción del tipo de función (inglés)',
        channel: 'Canal',
        channeldesp_zh: 'Descripción del canal (chino)',
        channeldesp_en: 'Descripción del canal (inglés)',
        functionstarttime: 'Hora de inicio de la función',
        OS_version_beforere_freshing:
            'Versión del SO del software del vehículo antes de la actualización',
        part_number_before_refreshing:
            'Número de pieza del software del vehículo antes de actualizar',
        vehicle_software_OS_version: 'Versión del SO del software del vehículo',
        controller_name: 'Nombre del controlador',
        hardware_number: 'Número de hardware',
        software_information_before_Refresh: 'Información del software antes de actualizar',
        software_information_after_Refresh: 'Información del software después de la actualización',
        write_software_information: 'Escribir información de software',
        function_name: 'Nombre de la función',
        minor_process_execution_time: 'Tiempo de ejecución de proceso menor',
        small_process_execution_result: 'Resultado de ejecución de proceso menor',
        function_execution_time: 'Tiempo de ejecución de la función',
        function_execution_result: 'Resultado de la ejecución de la función',
        function_execution_result_desp_zh:
            'Descripción del resultado de la ejecución de la función (chino)',
        function_execution_result_desp_en:
            'Descripción del resultado de la ejecución de la función (inglés)',
        log_start_time: 'Hora de inicio del registro',
        log_end_time: 'Hora de finalización del registro',
        vehicle_recognition_method: 'Método de reconocimiento de vehículos',
        vehicle_recognition_method_desp_zh:
            'Descripción del método de reconocimiento de vehículos (chino)',
        vehicle_recognition_method_desp_en:
            'Descripción del método de reconocimiento de vehículos (inglés)',
        model_year: 'Año del modelo',
        vehicle_configuration_information: 'Información sobre la configuración del vehículo',
        platform: 'Plataforma',
        login_account: 'Cuenta de inicio de sesión',
        user_name: 'Nombre de usuario',
        service_center: 'Centro de servicio',
        SIPS_version: 'Versión SIPS',
        VDI_version: 'Versión VDI',
        VDI_connection_method: 'Método de conexión VDI',
        VDI_connection_method_desp_zh: 'Descripción del método de conexión VDI (chino)',
        VDI_connection_method_desp_en: 'Descripción de la conexión VDI (inglés)',
        operating_system: 'Sistema operativo',
        operating_system_zh: 'Sistema operativo (chino)',
        operating_system_en: 'Sistema operativo (inglés)',
        brand: 'Marca',
        country_region: 'País/Región',
    },
    // 葡萄牙
    ptBR: {
        model: 'Modelo',
        mileage: 'Quilometragem',
        functiontype: 'Tipo de função',
        functiontypedesp_zh: 'Descrição do tipo de função (chinês)',
        functiontypedesp_en: 'Descrição do tipo de função (inglês)',
        channel: 'Canal',
        channeldesp_zh: 'Descrição do canal (chinês)',
        channeldesp_en: 'Descrição do canal (inglês)',
        functionstarttime: 'Função Hora de início',
        OS_version_beforere_freshing: 'Versão do SO do software do veículo antes da atualização',
        part_number_before_refreshing: 'Número de peça do software do veículo antes da atualização',
        vehicle_software_OS_version: 'Versão do SO do software do veículo',
        controller_name: 'Nome do controlador',
        hardware_number: 'Número de hardware',
        software_information_before_Refresh: 'Informações sobre o software antes da atualização',
        software_information_after_Refresh: 'Informações sobre o software após a atualização',
        write_software_information: 'Escrever informações sobre o software',
        function_name: 'Nome da função',
        minor_process_execution_time: 'Tempo de execução do processo menor',
        small_process_execution_result: 'Resultado da execução de um pequeno processo',
        function_execution_time: 'Tempo de execução da função',
        function_execution_result: 'Resultado da execução da função',
        function_execution_result_desp_zh: 'Descrição do resultado da execução da função (chinês)',
        function_execution_result_desp_en: 'Descrição do resultado da execução da função (inglês)',
        log_start_time: 'Hora de início do registo',
        log_end_time: 'Hora de fim do registo',
        vehicle_recognition_method: 'Método de reconhecimento do veículo',
        vehicle_recognition_method_desp_zh:
            'Descrição do método de reconhecimento do veículo (chinês)',
        vehicle_recognition_method_desp_en:
            'Descrição do método de reconhecimento do veículo (inglês)',
        model_year: 'Ano do modelo',
        vehicle_configuration_information: 'Informações de configuração do veículo',
        platform: 'Plataforma',
        login_account: 'Conta de início de sessão',
        user_name: 'Nome de utilizador',
        service_center: 'Centro de serviço',
        SIPS_version: 'Versão SIPS',
        VDI_version: 'Versão VDI',
        VDI_connection_method: 'Método de ligação VDI',
        VDI_connection_method_desp_zh: 'Descrição do método de ligação VDI (chinês)',
        VDI_connection_method_desp_en: 'Descrição da ligação VDI (inglês)',
        operating_system: 'Sistema operativo',
        operating_system_zh: 'Sistema operativo (chinês)',
        operating_system_en: 'Sistema operativo (inglês)',
        brand: 'Marca',
        country_region: 'País/Região',
    },
    // 波兰
    plPL: {
        model: 'Model',
        mileage: 'Przebieg',
        functiontype: 'Typ funkcji',
        functiontypedesp_zh: 'Opis typu funkcji (chiński)',
        functiontypedesp_en: 'Opis typu funkcji (angielski)',
        channel: 'Kanał',
        channeldesp_zh: 'Opis kanału (chiński)',
        channeldesp_en: 'Opis kanału (angielski)',
        functionstarttime: 'Czas rozpoczęcia funkcji',
        OS_version_beforere_freshing:
            'Wersja systemu operacyjnego oprogramowania pojazdu przed odświeżeniem',
        part_number_before_refreshing: 'Numer części oprogramowania pojazdu przed odświeżeniem',
        vehicle_software_OS_version: 'Wersja systemu operacyjnego oprogramowania pojazdu',
        controller_name: 'Nazwa kontrolera',
        hardware_number: 'Numer sprzętu',
        software_information_before_Refresh: 'Informacje o oprogramowaniu przed odświeżeniem',
        software_information_after_Refresh: 'Informacje o oprogramowaniu po odświeżeniu',
        write_software_information: 'Zapisywanie informacji o oprogramowaniu',
        function_name: 'Nazwa funkcji',
        minor_process_execution_time: 'Czas wykonania mniejszego procesu',
        small_process_execution_result: 'Wynik wykonania małego procesu',
        function_execution_time: 'Czas wykonania funkcji',
        function_execution_result: 'Wynik wykonania funkcji',
        function_execution_result_desp_zh: 'Opis wyniku wykonania funkcji (chiński)',
        function_execution_result_desp_en: 'Opis wyniku wykonania funkcji (angielski)',
        log_start_time: 'Czas rozpoczęcia dziennika',
        log_end_time: 'Czas zakończenia dziennika',
        vehicle_recognition_method: 'Metoda rozpoznawania pojazdu',
        vehicle_recognition_method_desp_zh: 'Opis metody rozpoznawania pojazdu (chiński)',
        vehicle_recognition_method_desp_en: 'Opis metody rozpoznawania pojazdu (angielski)',
        model_year: 'Rok modelowy',
        vehicle_configuration_information: 'Informacje o konfiguracji pojazdu',
        platform: 'Platforma',
        login_account: 'Konto logowania',
        user_name: 'Nazwa użytkownika',
        service_center: 'Centrum serwisowe',
        SIPS_version: 'Wersja SIPS',
        VDI_version: 'Wersja VDI',
        VDI_connection_method: 'Metoda połączenia VDI',
        VDI_connection_method_desp_zh: 'Opis metody połączenia VDI (chiński)',
        VDI_connection_method_desp_en: 'Opis połączenia VDI (angielski)',
        operating_system: 'System operacyjny',
        operating_system_zh: 'System operacyjny (chiński)',
        operating_system_en: 'System operacyjny (angielski)',
        brand: 'Marka',
        country_region: 'Kraj/region',
    },
    // 土耳其
    trTR: {
        model: 'Model',
        mileage: 'Kilometre',
        functiontype: 'Fonksiyon Tipi',
        functiontypedesp_zh: 'İşlev Türü Açıklama (Çince)',
        functiontypedesp_en: 'Fonksiyon Tipi Açıklaması (İngilizce)',
        channel: 'Kanal',
        channeldesp_zh: 'Kanal Açıklaması (Çince)',
        channeldesp_en: 'Kanal Açıklaması (İngilizce)',
        functionstarttime: 'Fonksiyon Başlangıç Zamanı',
        OS_version_beforere_freshing: 'Yenilemeden önce araç yazılımı işletim sistemi sürümü',
        part_number_before_refreshing: 'Yenilemeden önce araç yazılımı parça numarası',
        vehicle_software_OS_version: 'Araç yazılımı OS sürümü',
        controller_name: 'Denetleyici Adı',
        hardware_number: 'Donanım numarası',
        software_information_before_Refresh: 'Yenilemeden Önce Yazılım Bilgileri',
        software_information_after_Refresh: 'Yenileme sonrası yazılım bilgileri',
        write_software_information: 'Yazılım Bilgilerini Yazın',
        function_name: 'İşlev adı',
        minor_process_execution_time: 'Minör işlem yürütme süresi',
        small_process_execution_result: 'Küçük işlem yürütme sonucu',
        function_execution_time: 'Fonksiyon yürütme süresi',
        function_execution_result: 'Fonksiyon Yürütme Sonucu',
        function_execution_result_desp_zh: 'Fonksiyon Yürütme Sonucu Açıklaması (Çince)',
        function_execution_result_desp_en: 'Fonksiyon Yürütme Sonuç Açıklaması (İngilizce)',
        log_start_time: 'Günlük başlangıç zamanı',
        log_end_time: 'Günlük bitiş zamanı',
        vehicle_recognition_method: 'Araç Tanıma Yöntemi',
        vehicle_recognition_method_desp_zh: 'Araç Tanıma Yöntemi Açıklaması (Çince)',
        vehicle_recognition_method_desp_en: 'Araç Tanıma Yöntemi Açıklaması (İngilizce)',
        model_year: 'Model Yılı',
        vehicle_configuration_information: 'Araç Konfigürasyon Bilgileri',
        platform: 'Platform',
        login_account: 'Giriş hesabı',
        user_name: 'Kullanıcı Adı',
        service_center: 'Hizmet Merkezi',
        SIPS_version: 'SIPS Versiyonu',
        VDI_version: 'VDI Sürümü',
        VDI_connection_method: 'VDI Bağlantı yöntemi',
        VDI_connection_method_desp_zh: 'VDI bağlantı yönteminin açıklaması (Çince)',
        VDI_connection_method_desp_en: 'VDI Bağlantı Açıklaması (İngilizce)',
        operating_system: 'İşletim Sistemi',
        operating_system_zh: 'İşletim Sistemi (Çince)',
        operating_system_en: 'İşletim Sistemi (İngilizce)',
        brand: 'Marka',
        country_region: 'Ülke/Bölge',
    },
    // 捷克语
    csCZ: {
        model: 'Model',
        mileage: 'Počet najetých kilometrů',
        functiontype: 'Typ funkce',
        functiontypedesp_zh: 'Popis typu funkce (čínsky)',
        functiontypedesp_en: 'Popis typu funkce (anglicky)',
        channel: 'Kanál',
        channeldesp_zh: 'Popis kanálu (čínsky)',
        channeldesp_en: 'Popis kanálu (anglicky)',
        functionstarttime: 'Čas začátku funkce',
        OS_version_beforere_freshing: 'OS verze před obnovením',
        part_number_before_refreshing: 'Číslo dílu před obnovením',
        vehicle_software_OS_version: 'OS verze softwaru vozidla',
        controller_name: 'Název ovladače',
        hardware_number: 'Číslo hardwaru',
        software_information_before_Refresh: 'Informace o softwaru před obnovením',
        software_information_after_Refresh: 'Informace o softwaru po obnovení',
        write_software_information: 'Informace o napsaném softwaru',
        function_name: 'Název funkce',
        minor_process_execution_time: 'Doba provádění menšího procesu',
        small_process_execution_result: 'Výsledek provádění menšího procesu',
        function_execution_time: 'Doba provádění funkce',
        function_execution_result: 'Výsledek provádění funkce',
        function_execution_result_desp_zh: 'Popis výsledku provádění funkce (čínsky)',
        function_execution_result_desp_en: 'Popis výsledku provádění funkce (anglicky)',
        log_start_time: 'Čas začátku logu',
        log_end_time: 'Čas ukončení logu',
        vehicle_recognition_method: 'Metoda rozpoznání vozidla',
        vehicle_recognition_method_desp_zh: 'Popis metody rozpoznání vozidla (čínsky)',
        vehicle_recognition_method_desp_en: 'Popis metody rozpoznání vozidla (anglicky)',
        model_year: 'Rok modelu',
        vehicle_configuration_information: 'Informace o konfiguraci vozidla',
        platform: 'Platforma',
        login_account: 'Přihlašovací účet',
        user_name: 'Uživatelské jméno',
        service_center: 'Servisní centrum',
        SIPS_version: 'Verze SIPS',
        VDI_version: 'Verze VDI',
        VDI_connection_method: 'Metoda připojení VDI',
        VDI_connection_method_desp_zh: 'Popis metody připojení VDI (čínsky)',
        VDI_connection_method_desp_en: 'Popis metody připojení VDI (anglicky)',
        operating_system: 'Operační systém',
        operating_system_zh: 'Operační systém (čínsky)',
        operating_system_en: 'Operační systém (anglicky)',
        brand: 'Značka',
        country_region: 'Země/region',
    },
    // 匈牙利语
    huHU: {
        model: 'Modell',
        mileage: 'Futásteljesítmény',
        functiontype: 'Funkciótípus',
        functiontypedesp_zh: 'Funkciótípus leírása (kínai)',
        functiontypedesp_en: 'Funkciótípus leírása (angol)',
        channel: 'Csatorna',
        channeldesp_zh: 'Csatorna leírása (kínai)',
        channeldesp_en: 'Csatorna leírása (angol)',
        functionstarttime: 'Funkció kezdési ideje',
        OS_version_beforere_freshing: 'Frissítés előtti szoftver OS verzió',
        part_number_before_refreshing: 'Frissítés előtti szoftver alkatrész száma',
        vehicle_software_OS_version: 'Jármű szoftver OS verzió',
        controller_name: 'Vezérlő neve',
        hardware_number: 'Hardver száma',
        software_information_before_Refresh: 'Frissítés előtti szoftverinformáció',
        software_information_after_Refresh: 'Frissített szoftverinformáció',
        write_software_information: 'Írásos szoftverinformáció',
        function_name: 'Funkció neve',
        minor_process_execution_time: 'Kis folyamat végrehajtási idő',
        small_process_execution_result: 'Kis folyamat végrehajtási eredmény',
        function_execution_time: 'Funkció végrehajtási idő',
        function_execution_result: 'Funkció végrehajtási eredmény',
        function_execution_result_desp_zh: 'Funkció végrehajtási eredmény leírása (kínai)',
        function_execution_result_desp_en: 'Funkció végrehajtási eredmény leírása (angol)',
        log_start_time: 'Log kezdési idő',
        log_end_time: 'Log befejezési idő',
        vehicle_recognition_method: 'Jármű azonosítási módszer',
        vehicle_recognition_method_desp_zh: 'Jármű azonosítási módszer leírása (kínai)',
        vehicle_recognition_method_desp_en: 'Jármű azonosítási módszer leírása (angol)',
        model_year: 'Évjárat',
        vehicle_configuration_information: 'Jármű konfigurációs információ',
        platform: 'Platform',
        login_account: 'Bejelentkezési fiók',
        user_name: 'Felhasználónév',
        service_center: 'Szervizközpont',
        SIPS_version: 'SIPS verzió',
        VDI_version: 'VDI verzió',
        VDI_connection_method: 'VDI kapcsolódási mód',
        VDI_connection_method_desp_zh: 'VDI kapcsolódási mód leírása (kínai)',
        VDI_connection_method_desp_en: 'VDI kapcsolódási mód leírása (angol)',
        operating_system: 'Operációs rendszer',
        operating_system_zh: 'Operációs rendszer (kínai)',
        operating_system_en: 'Operációs rendszer (angol)',
        brand: 'Márka',
        country_region: 'Ország/Region',
    },
    // 俄罗斯语
    ruRU: {
        model: 'Модель',
        mileage: 'Пробег',
        functiontype: 'Тип функции',
        functiontypedesp_zh: 'Описание типа функции (китайский)',
        functiontypedesp_en: 'Описание типа функции (английский)',
        channel: 'Канал',
        channeldesp_zh: 'Описание канала (китайский)',
        channeldesp_en: 'Описание канала (английский)',
        functionstarttime: 'Время начала функции',
        OS_version_beforere_freshing: 'Версия ОС программного обеспечения автомобиля до обновления',
        part_number_before_refreshing: 'Номер детали программного обеспечения до обновления',
        vehicle_software_OS_version: 'Версия ОС программного обеспечения',
        controller_name: 'Имя контроллера',
        hardware_number: 'Номер оборудования',
        software_information_before_Refresh: 'Информация о программном обеспечении до обновления',
        software_information_after_Refresh: 'Информация о программном обеспечении после обновления',
        write_software_information: 'Информация о программировании',
        function_name: 'Название функции',
        minor_process_execution_time: 'Время выполнения мелкого процесса',
        small_process_execution_result: 'Результат выполнения мелкого процесса',
        function_execution_time: 'Время выполнения функции',
        function_execution_result: 'Результат выполнения функции',
        function_execution_result_desp_zh: 'Описание результата выполнения функции (китайский)',
        function_execution_result_desp_en: 'Описание результата выполнения функции (английский)',
        log_start_time: 'Время начала журнала',
        log_end_time: 'Время окончания журнала',
        vehicle_recognition_method: 'Метод распознавания автомобиля',
        vehicle_recognition_method_desp_zh: 'Описание метода распознавания автомобиля (китайский)',
        vehicle_recognition_method_desp_en: 'Описание метода распознавания автомобиля (английский)',
        model_year: 'Год модели',
        vehicle_configuration_information: 'Информация о конфигурации автомобиля',
        platform: 'Платформа',
        login_account: 'Логин',
        user_name: 'Имя пользователя',
        service_center: 'Центр обслуживания',
        SIPS_version: 'Версия SIPS',
        VDI_version: 'Версия VDI',
        VDI_connection_method: 'Метод подключения VDI',
        VDI_connection_method_desp_zh: 'Описание метода подключения VDI (китайский)',
        VDI_connection_method_desp_en: 'Описание метода подключения VDI (английский)',
        operating_system: 'Операционная система',
        operating_system_zh: 'Операционная система (китайский)',
        operating_system_en: 'Операционная система (английский)',
        brand: 'Бренд',
        country_region: 'Страна/Регион',
    },
};

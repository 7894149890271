export default {
    // 中
    zhCN: {
        vin: 'VIN',
        material: '物料',
        sales_order: '销售订单',
        vehicle_type: '车型',
        shipping_area: '发运区域',
        country_of_dispatch: '发运国家',
        down_date: '下线日期',
        port_of_destination: '目的港',
        scheduled_date_of_shipment: '计划发运日期',
        actual_date_of_shipment: '实际发运日期',
        current_position: '当前位置',
        actual_arrival_date: '实际到港日期',
        retail_date: '零售日期',
    },
    // 英
    enUS: {
        vin: 'VIN',
        material: 'Vehicle material code',
        sales_order: 'Sales order',
        vehicle_type: 'Model',
        shipping_area: ' Shipping area',
        country_of_dispatch: 'Shipping country',
        down_date: 'Off-line date',
        port_of_destination: 'Port of destination',
        scheduled_date_of_shipment: 'ETD',
        actual_date_of_shipment: 'ATD',
        current_position: 'Current position',
        actual_arrival_date: 'ATA',
        retail_date: ' Retail date',
    },
    // 德
    deDE: {
        vin: 'VIN',
        material: 'Material',
        sales_order: 'Verkaufsauftrag',
        vehicle_type: 'Modell',
        shipping_area: 'Versandgebiet',
        country_of_dispatch: 'Versandland',
        down_date: 'Offline-Datum',
        port_of_destination: 'Zielhafen',
        scheduled_date_of_shipment: 'Geplantes Versanddatum',
        actual_date_of_shipment: 'Tatsächliches Versanddatum',
        current_position: 'Aktuelle Position',
        actual_arrival_date: 'Tatsächliches Ankunftsdatum',
        retail_date: 'Einzelhandelsdatum',
    },
    // 法
    frFR: {
        vin: 'VIN',
        material: 'Code véhicule',
        sales_order: 'Numéro de commande',
        vehicle_type: 'Modèle',
        shipping_area: 'Zone d\'expédition',
        country_of_dispatch: 'Pays d\'expédition',
        down_date: 'Date off-line',
        port_of_destination: 'Port de destination',
        scheduled_date_of_shipment: 'Date d\'expédition prévue',
        actual_date_of_shipment: 'Date d\'expédition réelle',
        current_position: 'Position actuelle',
        actual_arrival_date: 'Date d\'arrivée réelle',
        retail_date: 'Date de livraison',
    },
    // 意大利
    itIT: {
        vin: 'VIN',
        material: 'Materiale',
        sales_order: 'Ordine di vendita',
        vehicle_type: 'Modello',
        shipping_area: 'Zona di spedizione',
        country_of_dispatch: 'Paese di spedizione',
        down_date: 'Data fuori produzione',
        port_of_destination: 'Porto di destinazione',
        scheduled_date_of_shipment: 'ETD',
        actual_date_of_shipment: 'ATD',
        current_position: 'Posizione attuale',
        actual_arrival_date: 'ATA',
        retail_date: 'Data di vendita al dettaglio',
    },
    // 荷兰
    nlNL: {
        vin: 'VIN',
        material: 'Materiaal',
        sales_order: 'Verkooporder',
        vehicle_type: 'Model',
        shipping_area: 'Verzendgebied',
        country_of_dispatch: 'Verzendland',
        down_date: 'Offline datum',
        port_of_destination: 'Bestemmingshaven',
        scheduled_date_of_shipment: 'ETD',
        actual_date_of_shipment: 'ATD',
        current_position: 'Huidige positie',
        actual_arrival_date: 'ATA',
        retail_date: 'Verkoopdatum',
    },
    // 越南
    viVN: {
        vin: 'Số khung',
        material: 'Mã vật liệu xe',
        sales_order: 'Đơn đặt hàng bán hàng',
        vehicle_type: 'Loại xe',
        shipping_area: 'Khu vực vận chuyển',
        country_of_dispatch: 'Quốc gia vận chuyển',
        down_date: 'Ngày off-line',
        port_of_destination: 'Cảng đến',
        scheduled_date_of_shipment: 'ETD (Ngày dự kiến rời cảng)',
        actual_date_of_shipment: 'ETA (Ngày dự kiến tới)',
        current_position: 'Vị trí hiện tại',
        actual_arrival_date: 'ATA (Ngày đến thực tế)',
        retail_date: 'Ngày bán lẻ',
    },
    // 西班牙
    esES: {
        vin: 'VIN',
        material: 'Material',
        sales_order: 'Orden de venta',
        vehicle_type: 'Modelo de vehículo',
        shipping_area: 'Región de envío',
        country_of_dispatch: 'País de envío',
        down_date: 'Fecha de finalización de producción',
        port_of_destination: 'Puerto de destino',
        scheduled_date_of_shipment: 'Fecha de envío planificada',
        actual_date_of_shipment: 'Fecha de envío real',
        current_position: 'Ubicación actual',
        actual_arrival_date: 'Fecha de llegada real',
        retail_date: 'Fecha de venta al por menor',
    },
    // 葡萄牙
    ptBR: {
        vin: 'VIN',
        material: 'Material',
        sales_order: 'Pedido de venda',
        vehicle_type: 'Tipo de veículo',
        shipping_area: 'Área de envio',
        country_of_dispatch: 'País de envio',
        down_date: 'Data de desativação',
        port_of_destination: 'Porto de destino',
        scheduled_date_of_shipment: 'Data programada de envio',
        actual_date_of_shipment: 'Data real de envio',
        current_position: 'Posição atual',
        actual_arrival_date: 'Data real de chegada',
        retail_date: 'Data de venda a retalho',
    },
    // 波兰
    plPL: {
        vin: 'VIN',
        material: 'Kod materiałowy pojazdu',
        sales_order: 'Zamówienie',
        vehicle_type: 'Model',
        shipping_area: 'Obszar wysyłki',
        country_of_dispatch: 'Kraj wysyłki',
        down_date: 'Data zejścia z linii',
        port_of_destination: 'Port przeznaczenia',
        scheduled_date_of_shipment: 'Spodziwana data wysłania',
        actual_date_of_shipment: 'Rzeczywista data wysłania',
        current_position: 'Obecna lokalizacja',
        actual_arrival_date: 'Rzeczywista data dostawy do portu',
        retail_date: 'Data sprzedaży detalicznej',
    },
    // 土耳其
    trTR: {
        vin: 'VIN',
        material: 'Araç mlzeme kodu',
        sales_order: 'Satış  emri',
        vehicle_type: 'Model',
        shipping_area: 'Gönderilen bölge',
        country_of_dispatch: 'Gönderilen ülke',
        down_date: 'Devre-tışı tarih',
        port_of_destination: 'Varış limanı',
        scheduled_date_of_shipment: 'ETD',
        actual_date_of_shipment: 'ATD',
        current_position: 'Mevcut pozisyon',
        actual_arrival_date: 'ATA',
        retail_date: 'Satış tarihi',
    },
    // 捷克语
    csCZ: {
        vin: 'VIN',
        material: 'Kód materiálu vozidla',
        sales_order: 'Prodejní objednávka',
        vehicle_type: 'Model',
        shipping_area: 'Oblast expedice',
        country_of_dispatch: 'Země odeslání',
        down_date: 'Datum off-line',
        port_of_destination: 'Cílový přístav',
        scheduled_date_of_shipment: 'ETD',
        actual_date_of_shipment: 'ATD',
        current_position: 'Aktuální pozice',
        actual_arrival_date: 'ATA',
        retail_date: 'Datum prodeje',
    },
    // 匈牙利语
    huHU: {
        vin: 'Alvázszám',
        material: 'Jármű anyagkód',
        sales_order: 'Értékesítési rendelés',
        vehicle_type: 'Modell',
        shipping_area: 'Kiszállítási terület',
        country_of_dispatch: 'Kiszállítás szerinti ország',
        down_date: 'Offline dátum',
        port_of_destination: 'Célállomás',
        scheduled_date_of_shipment: 'Tervezett szállítási dátum',
        actual_date_of_shipment: 'Tényleges szállítási dátum',
        current_position: 'Jelenlegi helyzet',
        actual_arrival_date: 'Tényleges érkezési idő',
        retail_date: 'Kiskereskedelmi dátum',
    },
    // 俄罗斯语
    ruRU: {
        vin: 'VIN',
        material: 'Код материала автомобиля',
        sales_order: 'Заказ на продажу',
        vehicle_type: 'Модель',
        shipping_area: 'Регион отгрузки',
        country_of_dispatch: 'Страна отгрузки',
        down_date: 'Дата снятия с производства',
        port_of_destination: 'Порт назначения',
        scheduled_date_of_shipment: 'ETD',
        actual_date_of_shipment: 'ATD',
        current_position: 'Текущее местоположение',
        actual_arrival_date: 'ATA',
        retail_date: 'Дата розничной продажи',
    },
};

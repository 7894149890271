export default {
    // 中
    zhCN: {
        newly_built: '新建',
        unpublished: '未发布',
        validity: '有效性',
        modelInvolved: '涉及车型',
        involvedArea: '涉及区域',
        state: '状态',
        new_car_reporting_level: '新车上报层级',
        confirm_release: '确认发布',
        maintenance: '维护',
        model_maintenance: '车型维护',
        area_maintenance: '区域维护',
        reporting_level: '上报层级',
    },
    // 英
    enUS: {
        newly_built: 'Add',
        unpublished: 'Unpublished',
        validity: 'Validity',
        modelInvolved: 'Model',
        involvedArea: 'Region',
        state: 'Status',
        new_car_reporting_level: 'New car reporting level',
        confirm_release: 'Confirm release',
        maintenance: 'Maintenance',
        model_maintenance: 'Model maintenance',
        area_maintenance: 'Area maintenance',
        reporting_level: 'Reporting level',
    },
    // 德
    deDE: {
        newly_built: 'Hinzugefügt',
        unpublished: 'Unveröffentlicht',
        validity: 'Gültigkeit',
        modelInvolved: 'Modell',
        involvedArea: 'Region',
        state: 'Status',
        new_car_reporting_level: 'Meldungsebene für Neuwagen',
        confirm_release: 'Freigabe bestätigen',
        maintenance: 'Wartung',
        model_maintenance: 'Model- Wartung',
        area_maintenance: 'Bereichswartung',
        reporting_level: 'Berichtsebene',
    },
    // 法
    frFR: {
        newly_built: 'Ajouter',
        unpublished: 'A communiquer',
        validity: 'Validité',
        modelInvolved: 'Modèle',
        involvedArea: 'Région',
        state: 'Statuts',
        new_car_reporting_level: 'Nouveau niveau de reporting des véhicules',
        confirm_release: 'Confirmer la publication',
        maintenance: 'Maintenir',
        model_maintenance: 'Maintenance du modèle',
        area_maintenance: 'Maintenance de la région',
        reporting_level: 'Niveau de reporting',
    },
    // 意大利
    itIT: {
        newly_built: 'Aggiungi',
        unpublished: 'Inédito',
        validity: 'Validità',
        modelInvolved: 'Modello',
        involvedArea: 'Regione',
        state: 'Stato',
        new_car_reporting_level: 'Livello di segnalazione del nuovo veicolo',
        confirm_release: 'Conferma rilascio',
        maintenance: 'Mantenimento',
        model_maintenance: 'Manutenzione del modello',
        area_maintenance: 'Manutenzione dell\'area\'',
        reporting_level: 'Livello di segnalazione',
    },
    // 荷兰
    nlNL: {
        newly_built: 'Toevoegen',
        unpublished: 'Niet gepubliceerd',
        validity: 'Geldigheid',
        modelInvolved: 'Model',
        involvedArea: 'Regio',
        state: 'Staat',
        new_car_reporting_level: 'Nieuw auto rapportageniveau',
        confirm_release: 'Bevestig vrijgave',
        maintenance: 'Onderhoud',
        model_maintenance: 'Model onderhoud',
        area_maintenance: 'Regio onderhoud',
        reporting_level: 'Rapportage niveau',
    },
    // 越南
    viVN: {
        newly_built: 'Mới tạo',
        unpublished: 'Chưa được xuất bản',
        validity: 'Hiệu lực',
        modelInvolved: 'Loại xe',
        involvedArea: 'Vùng',
        state: 'Trạng thái',
        new_car_reporting_level: 'Mức báo cáo xe mới',
        confirm_release: 'Xác nhận phát hành',
        maintenance: 'Bảo trì',
        model_maintenance: 'Bảo trì mô hình',
        area_maintenance: 'Bảo trì khu vực',
        reporting_level: 'Mức báo cáo',
    },
    // 西班牙
    esES: {
        newly_built: 'Nuevo',
        unpublished: 'No publicado',
        validity: 'Validez',
        modelInvolved: 'Modelo de vehículo relacionado',
        involvedArea: 'Región relacionada',
        state: 'Estado',
        new_car_reporting_level: 'Nivel de informe de vehículo nuevo',
        confirm_release: 'Confirmar publicación',
        maintenance: 'Mantenimiento',
        model_maintenance: 'Mantenimiento de modelo de vehículo',
        area_maintenance: 'Mantenimiento de región',
        reporting_level: 'Nivel de informe',
    },
    // 葡萄牙
    ptBR: {
        newly_built: 'Recém construído',
        unpublished: 'Não publicado',
        validity: 'Validade',
        modelInvolved: 'Modelo envolvido',
        involvedArea: 'Área envolvida',
        state: 'Estado',
        new_car_reporting_level: 'Novo nível de relatório de carro',
        confirm_release: 'Confirmar liberação',
        maintenance: 'Manutenção',
        model_maintenance: 'Manutenção de modelo',
        area_maintenance: 'Manutenção da área',
        reporting_level: 'Nível de relatório',
    },
    // 波兰
    plPL: {
        newly_built: 'Nowy',
        unpublished: 'Nieopublikowany',
        validity: 'Ważność',
        modelInvolved: 'Model',
        involvedArea: 'Region',
        state: 'Status',
        new_car_reporting_level: 'Poziom raportowania nowego pojazdu',
        confirm_release: 'Potwiedź wydanie',
        maintenance: 'Serwis',
        model_maintenance: 'Serwis modelu',
        area_maintenance: 'Serwis strefy',
        reporting_level: 'Poziom raportowania',
    },
    // 土耳其
    trTR: {
        newly_built: 'Ekle',
        unpublished: 'Yayınlanmamış',
        validity: 'Geçerlilik',
        modelInvolved: 'Model',
        involvedArea: 'Bölge',
        state: 'Durum',
        new_car_reporting_level: 'Yeni araba rapor seviyesi',
        confirm_release: 'Yayınlamayı doğrula',
        maintenance: 'Bakım',
        model_maintenance: 'Model  bakımı',
        area_maintenance: 'Bölge  bakımı',
        reporting_level: 'Rapor seviyesi',
    },
    // 捷克语
    csCZ: {
        newly_built: 'Přidat',
        unpublished: 'Nezveřejněný',
        validity: 'Platnost',
        modelInvolved: 'Model',
        involvedArea: 'Oblast',
        state: 'Stav',
        new_car_reporting_level: 'Úroveň hlášení nového vozidla',
        confirm_release: 'Potvrďte zveřejnění',
        maintenance: 'Údržba',
        model_maintenance: 'Údržba modelu',
        area_maintenance: 'Údržba areálu',
        reporting_level: 'Úroveň hlášení',
    },
    // 匈牙利语翻译
    huHU: {
        newly_built: 'Hozzáadás',
        unpublished: 'Kiadatlan',
        validity: 'Érvényesség',
        modelInvolved: 'Modell',
        involvedArea: 'Régió',
        state: 'Állapot',
        new_car_reporting_level: 'Új autó jelentési szint',
        confirm_release: 'Kiadás megerősítése',
        maintenance: 'Karbantartás',
        model_maintenance: 'Modell karbantartása',
        area_maintenance: 'Terület karbantartása',
        reporting_level: 'Jelentési szint',
    },
    // 俄罗斯语
    ruRU: {
        newly_built: 'Добавить',
        unpublished: 'Неопубликовано',
        validity: 'Срок действия',
        modelInvolved: 'Модель',
        involvedArea: 'Регион',
        state: 'Статус',
        new_car_reporting_level: 'Уровень отчета о новом автомобиле',
        confirm_release: 'Подтвердить выпуск',
        maintenance: 'Обслуживание',
        model_maintenance: 'Обслуживание модели',
        area_maintenance: 'Обслуживание области',
        reporting_level: 'Уровень отчета',
    },
};

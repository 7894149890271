export default {
    // 中
    zhCN: {
        system_selection: '系统选择',
        parent_dept: '上级部门',
        dept_name: '组织',
        leader: '负责人',
        email: '邮箱',
        create_by: '创建人',
        create_date: '创建时间',
        organization_editing: '组织编辑',
        phone: '电话',
        organizational_type: '组织类型',
    },
    // 英
    enUS: {
        system_selection: 'System selection',
        parent_dept: 'Parent dept',
        dept_name: 'Dept name',
        leader: 'Leader',
        email: 'Email',
        create_by: 'Create by',
        create_date: 'Create date',
        organization_editing: 'Organization editing',
        phone: 'Phone',
        organizational_type: 'Organizational type',
    },
    // 德
    deDE: {
        system_selection: 'Systemauswahl',
        parent_dept: 'Übergeordnete Abteilung',
        dept_name: 'Abteilungsname',
        leader: 'Leiter',
        email: 'E-Mail',
        create_by: 'Erstellt von',
        create_date: 'Erstellungsdatum',
        organization_editing: 'Organisation bearbeiten',
        phone: 'Telefon',
        organizational_type: 'Organisationstyp',
    },
    // 法
    frFR: {
        system_selection: 'Sélection du système',
        parent_dept: 'Département parent',
        dept_name: 'Nom du département',
        leader: 'Responsable',
        email: 'E-mail',
        create_by: 'Créé par',
        create_date: 'Date de création',
        organization_editing: 'Modification d\'organisation',
        phone: 'Téléphone',
        organizational_type: 'Type d\'organisation',
    },
    // 意大利
    itIT: {
        system_selection: 'Selezione del Sistema',
        parent_dept: 'Dipartimento Superiore',
        dept_name: 'Organizzazione',
        leader: 'Responsabile',
        email: 'Email',
        create_by: 'Creatore',
        create_date: 'Data di Creazione',
        organization_editing: 'Modifica Organizzazione',
        phone: 'Telefono',
        organizational_type: 'Tipo di Organizzazione',
    },
    // 荷兰
    nlNL: {
        system_selection: 'Systeemselectie',
        parent_dept: 'Bovenliggende afdeling',
        dept_name: 'Afdelingsnaam',
        leader: 'Leider',
        email: 'E-mail',
        create_by: 'Aangemaakt door',
        create_date: 'Aanmaakdatum',
        organization_editing: 'Organisatie bewerken',
        phone: 'Telefoon',
        organizational_type: 'Organisatietype',
    },
    // 越南
    viVN: {
        system_selection: 'Lựa chọn hệ thống',
        parent_dept: 'Bộ phận cha',
        dept_name: 'Tổ chức',
        leader: 'Người đứng đầu',
        email: 'Email',
        create_by: 'Tạo bởi',
        create_date: 'Ngày tạo',
        organization_editing: 'Chỉnh sửa tổ chức',
        phone: 'Điện thoại',
        organizational_type: 'Loại tổ chức',
    },
    // 西班牙
    esES: {
        system_selection: 'Selección del sistema',
        parent_dept: 'Departamento superior',
        dept_name: 'Organización',
        leader: 'Persona responsable',
        email: 'Correo electrónico',
        create_by: 'Creador',
        create_date: 'Fecha de creación',
        organization_editing: 'Edición de la organización',
        phone: 'Teléfono',
        organizational_type: 'Tipo de organización',
    },
    // 葡萄牙
    ptBR: {
        system_selection: 'Seleção do sistema',
        parent_dept: 'Departamento superior',
        dept_name: 'Organização',
        leader: 'Líder',
        email: 'E-mail',
        create_by: 'Criado por',
        create_date: 'Data de criação',
        organization_editing: 'Edição da organização',
        phone: 'Telefone',
        organizational_type: 'Tipo de organização',
    },
    // 波兰
    plPL: {
        system_selection: 'Wybór systemu',
        parent_dept: 'Odział nadzorujący',
        dept_name: 'Nazwa',
        leader: 'Lider',
        email: 'Email',
        create_by: 'Założone przez',
        create_date: 'Data założenia',
        organization_editing: 'Edycja organizacji',
        phone: 'Telefon',
        organizational_type: 'Typ organizacyjny',
    },
    // 土耳其
    trTR: {
        system_selection: 'Sistem seçimi',
        parent_dept: 'Bir üst departman',
        dept_name: 'Depot İsmi',
        leader: 'Yöneticisi',
        email: 'E-posta',
        create_by: 'Oluşturan kişi',
        create_date: 'Oluşturma tarihi',
        organization_editing: 'Organizasyon Düzenlemesi',
        phone: 'Telefon',
        organizational_type: 'Organizasyon türü',
    },
    // 捷克语
    csCZ: {
        system_selection: 'Výběr systému',
        parent_dept: 'Parent dept',
        dept_name: 'Název oddělení',
        leader: 'Vedoucí',
        email: 'E-mail',
        create_by: 'Vytvořil',
        create_date: 'Datum vytvoření',
        organization_editing: 'Editace organizace',
        phone: 'Telefon',
        organizational_type: 'Typ organizace',
    },
    // 匈牙利语
    huHU: {
        system_selection: 'Rendszerválasztás',
        parent_dept: 'Szülőrészleg',
        dept_name: 'Részlegnév',
        leader: 'Vezető',
        email: 'E-mail',
        create_by: 'Létrehozó',
        create_date: 'Létrehozás dátuma',
        organization_editing: 'Szervezet szerkesztése',
        phone: 'Telefon',
        organizational_type: 'Szervezeti típus',
    },
    // 俄罗斯语
    ruRU: {
        system_selection: 'Выбор системы',
        parent_dept: 'Вышестоящий отдел',
        dept_name: 'Название отдела',
        leader: 'Руководитель',
        email: 'Электронная почта',
        create_by: 'Создано',
        create_date: 'Дата создания',
        organization_editing: 'Редактирование организации',
        phone: 'Телефон',
        organizational_type: 'Тип организации',
    },
};

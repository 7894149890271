export default {
    // 中
    zhCN: {
        vin: 'VIN',
        name_of_maintenance_station: '维修站名称',
        mileage: '里程数',
        controller_name: '控制器名称',
        function_name: '功能',
        time: '时间',
        function_execution_result: '功能执行结果',
        channel: '通道',
        hardware_number: '硬件号',
        written_software_information: '写入的软件信息',
        software_information_before_refresh: '刷新前的软件信息',
        updated_software_information: '刷新后的软件信息',
    },
    // 英
    enUS: {
        vin: 'VIN',
        name_of_maintenance_station: 'Name of service station',
        mileage: 'Mileage',
        controller_name: 'Controller name',
        function_name: 'Function',
        time: 'Date',
        function_execution_result: 'Function execution result',
        channel: 'Channel',
        hardware_number: 'Hardware number',
        written_software_information: 'Written software information',
        software_information_before_refresh: 'Software information before refresh',
        updated_software_information: 'Updated software information',
    },
    // 德
    deDE: {
        vin: 'VIN',
        name_of_maintenance_station: 'Name der Service-Station',
        mileage: 'Kilometerstand',
        controller_name: 'Controller-Name',
        function_name: 'Funktionsname',
        time: 'Zeit',
        function_execution_result: 'Ergebnis der Funktionsausführung',
        channel: 'Kanal',
        hardware_number: 'Hardware-Nummer',
        written_software_information: 'Geschriebene Software-Informationen',
        software_information_before_refresh: 'Software-Informationen vor der Aktualisierung',
        updated_software_information: 'Aktualisierte Software-Informationen',
    },
    // 法
    frFR: {
        vin: 'VIN',
        name_of_maintenance_station: 'Nom de la station service',
        mileage: 'Kilométrage',
        controller_name: 'Nom du contrôleur',
        function_name: 'Fonction',
        time: 'Date',
        function_execution_result: 'Résultat',
        channel: 'Chaîne',
        hardware_number: 'Numéro de matériel',
        written_software_information: 'Informations écriture logiciel',
        software_information_before_refresh: 'Informations logiciel avant rafraichissement',
        updated_software_information: 'Informations mise à jour logiciel',
    },
    // 意大利
    itIT: {
        vin: 'VIN',
        name_of_maintenance_station: 'Nome stazione di assistenza',
        mileage: 'Chilometraggio',
        controller_name: 'Nome controllore',
        function_name: 'Funzione',
        time: 'Tempo',
        function_execution_result: 'Risultato esecuzione funzione',
        channel: 'Canale',
        hardware_number: 'Numero hardware',
        written_software_information: 'Informazioni software scritte',
        software_information_before_refresh: 'Informazioni software prima del refresh',
        updated_software_information: 'Informazioni software aggiornate',
    },
    // 荷兰
    nlNL: {
        vin: 'VIN',
        name_of_maintenance_station: 'Naam van de onderhoudsstation',
        mileage: 'Kilometerstand',
        controller_name: 'Controller naam',
        function_name: 'Functienaam',
        time: 'Tijd',
        function_execution_result: 'Functie uitvoeringsresultaat',
        channel: 'Kanaal',
        hardware_number: 'Hardware nummer',
        written_software_information: 'Geschreven software informatie',
        software_information_before_refresh: 'Software informatie voor update',
        updated_software_information: 'Geüpdatete software informatie',
    },
    // 越南
    viVN: {
        vin: 'Số khung',
        name_of_maintenance_station: 'Tên trạm dịch vụ',
        mileage: 'Số km đã đi',
        controller_name: 'Tên bộ phận điều khiển',
        function_name: 'Chức năng',
        time: 'Ngày',
        function_execution_result: 'Kết quả thực thi chức năng',
        channel: 'Kênh',
        hardware_number: 'Số phần cứng',
        written_software_information: 'Thông tin phần mềm đã viết',
        software_information_before_refresh: 'Thông tin phần mềm trước khi chạy lại',
        updated_software_information: 'Thông tin phần mềm được cập nhật',
    },
    // 西班牙
    esES: {
        vin: 'VIN',
        name_of_maintenance_station: 'Nombre de la estación de reparación',
        mileage: 'Kilometraje',
        controller_name: 'Nombre del controlador',
        function_name: 'Función',
        time: 'Tiempo',
        function_execution_result: 'Resultado de ejecución de la función',
        channel: 'Canal',
        hardware_number: 'Número de hardware',
        written_software_information: 'Información de software escrita',
        software_information_before_refresh: 'Información de software antes de la actualización',
        updated_software_information: 'Información de actualización de software',
    },
    // 葡萄牙
    ptBR: {
        vin: 'VIN',
        name_of_maintenance_station: 'Nome da estação de manutenção',
        mileage: 'Quilometragem',
        controller_name: 'Nome do controlador',
        function_name: 'Função',
        time: 'Tempo',
        function_execution_result: 'Resultado da execução da função',
        channel: 'Canal',
        hardware_number: 'Número do hardware',
        written_software_information: 'Informações do software gravado',
        software_information_before_refresh: 'Informações do software antes da atualização',
        updated_software_information: 'Informações do software atualizadas',
    },
    // 波兰
    plPL: {
        vin: 'VIN',
        name_of_maintenance_station: 'Nazwa stacji obsługi',
        mileage: 'Przebieg',
        controller_name: 'Nazwa kontrolera',
        function_name: 'Funkcja',
        time: 'Data',
        function_execution_result: 'Wynik wykonania funkcji',
        channel: 'Kanał',
        hardware_number: 'Sprzęt',
        written_software_information: 'Informacje o oprogramowaniu',
        software_information_before_refresh: 'Informacje o oprogramowaniu przed aktualizacją',
        updated_software_information: 'Informacje o oprogramowaniu po aktualizacji',
    },
    // 土耳其
    trTR: {
        vin: 'VIN',
        name_of_maintenance_station: 'Servis istasyonunun adı',
        mileage: 'Km',
        controller_name: 'Kontrol edn kişinin  ismi',
        function_name: 'Fonksiyon',
        time: 'Tarih',
        function_execution_result: 'Funksiyon çalışma sonuçları',
        channel: 'Kanal',
        hardware_number: 'Hardware numarası',
        written_software_information: 'Yazılmış yazılım bilgisi',
        software_information_before_refresh: 'Yeniden yenilenmeden önce yazılım bilgisi',
        updated_software_information: 'Yazılım bilgisi yenilenmiş',
    },
    // 捷克语
    csCZ: {
        vin: 'VIN',
        name_of_maintenance_station: 'Název servisu',
        mileage: 'Počet najetých kilometrů',
        controller_name: 'Název ovladače',
        function_name: 'Funkce',
        time: 'datum',
        function_execution_result: 'Výsledek provedení funkce',
        channel: 'Kanál',
        hardware_number: 'Číslo hardwaru',
        written_software_information: 'Psané informace o softwaru',
        software_information_before_refresh: 'Informace o softwaru před obnovením',
        updated_software_information: 'Aktualizované informace o softwaru',
    },
    // 匈牙利语
    huHU: {
        vin: 'Alvázszám',
        name_of_maintenance_station: 'Szervizállomás neve',
        mileage: 'Futásteljesítmény',
        controller_name: 'Vezérlő neve',
        function_name: 'Funkció',
        time: 'Dátum',
        function_execution_result: 'Funkcióvégrehajtási eredmény',
        channel: 'Csatorna',
        hardware_number: 'Hardver száma',
        written_software_information: 'Írásos szoftveradatok',
        software_information_before_refresh: 'Szoftveradatok frissítés előtt',
        updated_software_information: 'Frissített szoftveradatok',
    },
    // 俄罗斯语
    ruRU: {
        vin: 'VIN',
        name_of_maintenance_station: 'Название сервисной станции',
        mileage: 'Пробег',
        controller_name: 'Имя контроллера',
        function_name: 'Функция',
        time: 'Дата',
        function_execution_result: 'Результат выполнения функции',
        channel: 'Канал',
        hardware_number: 'Номер оборудования',
        written_software_information: 'Информация о программном обеспечении',
        software_information_before_refresh: 'Информация о программном обеспечении до обновления',
        updated_software_information: 'Обновленная информация о программном обеспечении',
    },
};

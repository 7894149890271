export default {
    // 中
    zhCN: {
        fill_in_at_least_one_query_condition: '至少填写一个查询条件',
        query_time: '查询时间',
        full_query: '全量查询',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        number_of_cases: '案例总数',
        number_of_case_responses: '24小时案件答复数量',
        response_rate: '24小时答复比率',
    },
    // 英
    enUS: {
        fill_in_at_least_one_query_condition: 'Fill in at least one query condition',
        query_time: 'Query time',
        full_query: 'Full query',
        organization_code: 'Organization code',
        organization_name: 'Organization name',
        country: 'Country',
        number_of_cases: 'Number of cases',
        number_of_case_responses: 'Number of 24-hour case responses',
        response_rate: '24-hour response rate',
    },
    // 德
    deDE: {
        fill_in_at_least_one_query_condition: 'Füllen Sie mindestens eine Abfragebedingung aus',
        query_time: 'Abfragezeit',
        full_query: 'Vollständige Abfrage',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        number_of_cases: 'Gesamtzahl der Fälle',
        number_of_case_responses: 'Anzahl der 24-Stunden-Fallantworten',
        response_rate: 'Reaktionsrate rund um die Uhr',
    },
    // 法
    frFR: {
        fill_in_at_least_one_query_condition: 'Remplissez au moins une condition de demande',
        query_time: 'Temps de requête',
        full_query: 'Requête complète',
        organization_code: 'Code organisation',
        organization_name: 'Nom organisation',
        country: 'Pays',
        number_of_cases: 'Nombre de cas',
        number_of_case_responses: 'Nombre de réponses de cas en 24 heures',
        response_rate: 'Taux de réponse 24 heures',
    },
    // 意大利
    itIT: {
        fill_in_at_least_one_query_condition: 'Compilare almeno una condizione di query',
        query_time: 'Tempo di Query',
        full_query: 'Query Completa',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        country: 'Paese',
        number_of_cases: 'Numero totale di casi',
        number_of_case_responses: 'Numero di risposte al caso per 24 ore',
        response_rate: 'Tasso di risposta 24 ore',
    },
    // 荷兰
    nlNL: {
        fill_in_at_least_one_query_condition: 'Vul ten minste één query voorwaarde in',
        query_time: 'Query time',
        full_query: 'Volledige query',
        organization_code: 'Organisatie code',
        organization_name: 'Organisatie naam',
        country: 'Land',
        number_of_cases: 'Totaal aantal gevallen',
        number_of_case_responses: 'Aantal 24-uurs antwoorden op gevallen',
        response_rate: '24-uursresponspercentage',
    },
    // 越南
    viVN: {
        fill_in_at_least_one_query_condition: 'Ít nhất điền một điều kiện tìm kiếm',
        query_time: 'Thời gian truy vấn',
        full_query: 'Tra cứu toàn bộ',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        country: 'Quốc gia',
        number_of_cases: 'Số lượng trường hợp',
        number_of_case_responses: 'Số lượng câu trả lời trong 24 giờ',
        response_rate: 'Tỷ lệ trả lời 24 giờ',
    },
    // 西班牙
    esES: {
        fill_in_at_least_one_query_condition: 'Al menos complete un criterio de búsqueda',
        query_time: 'Consulta de tiempo',
        full_query: 'Consulta completa',
        organization_code: 'Código de la organización',
        organization_name: 'Nombre de la organización',
        country: 'País',
        number_of_cases: 'Número total de casos',
        number_of_case_responses: 'Número de respuestas a casos en 24 horas',
        response_rate: 'Tasa de respuesta de 24 horas',
    },
    // 葡萄牙
    ptBR: {
        fill_in_at_least_one_query_condition: 'Preencha pelo menos uma condição de consulta',
        query_time: 'Tempo de consulta',
        full_query: 'Consulta completa',
        organization_code: 'Código da organização',
        organization_name: 'Nome da organização',
        country: 'País',
        number_of_cases: 'Número total de casos',
        number_of_case_responses: 'Número de respostas aos casos de 24 horas',
        response_rate: 'Taxa de resposta 24 horas',
    },
    // 波兰
    plPL: {
        fill_in_at_least_one_query_condition: 'Wypełnij co najmniej jeden warunek zapytania',
        query_time: 'Data zapytania',
        full_query: 'Pełne zapytanie',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        country: 'Kraj',
        number_of_cases: 'Łączna liczba spraw',
        number_of_case_responses: 'Liczba 24-godzinnych odpowiedzi na sprawy',
        response_rate: 'Wskaźnik reakcji całodobowej',
    },
    // 土耳其
    trTR: {
        fill_in_at_least_one_query_condition: 'En azından bir soru durumunu doldur',
        query_time: 'Sorgulama zamanı',
        full_query: 'Tam sorgulama',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        country: 'Ülke',
        number_of_cases: 'Toplam dosyalar',
        number_of_case_responses: '24H Vaka yanıtlarının sayısı',
        response_rate: '24H Yanıt oranı',
    },
    // 捷克语
    csCZ: {
        fill_in_at_least_one_query_condition: 'Vyplňte alespoň jednu podmínku dotazu',
        query_time: 'Čas dotazu',
        full_query: 'Úplný dotaz',
        organization_code: 'Kód společnosti',
        organization_name: 'Název společnosti',
        country: 'Země',
        number_of_cases: 'Počet případů',
        number_of_case_responses: 'Počet odpovědí případu za 24 hodin',
        response_rate: '24hodinová rychlost odezvy',
    },
    // 匈牙利语
    huHU: {
        fill_in_at_least_one_query_condition: 'Töltsön ki legalább egy lekérdezési feltételt',
        query_time: 'Lekérdezési idő',
        full_query: 'Teljes lekérdezés',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        country: 'Ország',
        number_of_cases: 'Esetek száma',
        number_of_case_responses: '24 órán belüli esetreakciók száma',
        response_rate: '24 órás reagálási arány',
    },
    // 俄罗斯语
    ruRU: {
        fill_in_at_least_one_query_condition: 'Заполните хотя бы одно условие запроса',
        query_time: 'Время запроса',
        full_query: 'Полный запрос',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        country: 'Страна',
        number_of_cases: 'Общее количество случаев',
        number_of_case_responses: 'Количество ответов по случаям в течение 24 часов',
        response_rate: 'Коэффициент ответов в течение 24 часов',
    },
};

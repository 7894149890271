export default {
    // 中
    zhCN: {
        query_time: '查询时间',
        number_of_closedCases: '关单数量',
        percentage_of_closedCases: '关单率',
        SAP: 'SAP',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        total_number_of_cases: '案件总数',
        statistical_days: '统计天数',
    },
    // 英
    enUS: {
        query_time: 'Query time',
        number_of_closedCases: 'Number of closedCases',
        percentage_of_closedCases: 'Percentage of closedCases',
        SAP: 'SAP',
        organization_code: 'Organization CODE',
        organization_name: 'Organization Name',
        country: 'Country',
        total_number_of_cases: 'Total Number Of Cases',
        statistical_days: 'Statistical Days',
    },
    // 德
    deDE: {
        query_time: 'Abfragezeit',
        number_of_closedCases: 'Anzahl abgeschlossener Fälle',
        percentage_of_closedCases: 'Prozentsatz der abgeschlossenen Fälle',
        SAP: 'SAP',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        total_number_of_cases: 'Gesamtzahl der Fälle',
    },
    // 法
    frFR: {
        query_time: 'Temps de requête',
        number_of_closedCases: 'Nombre de cas fermés',
        percentage_of_closedCases: 'Pourcentage de cas clos',
        SAP: 'SAP',
        organization_code: 'Code de l\'organisme',
        organization_name: 'Nom de l\'institution',
        country: 'Pays',
        total_number_of_cases: 'Nombre total de cas',
    },
    // 意大利
    itIT: {
        query_time: 'Tempo di Query',
        number_of_closedCases: 'Numero di casi chiusi',
        percentage_of_closedCases: 'Percentuale di casi chiusi',
        SAP: 'SAP',
        organization_code: 'Codice dell\'organizzazione',
        organization_name: 'Nome dell\'organizzazione',
        country: 'Paese',
        total_number_of_cases: 'Numero totale di casi',
    },
    // 荷兰
    nlNL: {
        query_time: 'Query time',
        number_of_closedCases: 'Aantal gesloten zaken',
        percentage_of_closedCases: 'Percentage gesloten zaken',
        SAP: 'SAP',
        organization_code: 'Organisatiecode',
        organization_name: 'Organisatienaam',
        country: 'Naties',
        total_number_of_cases: 'Totaal aantal gevallen',
    },
    // 越南
    viVN: {
        query_time: 'Thời gian truy vấn',
        number_of_closedCases: 'Số lượng case closed',
        percentage_of_closedCases: 'Phần trăm các trường hợp đóng',
        SAP: 'SAP',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        country: 'Quốc gia',
        total_number_of_cases: 'Tổng số trường hợp',
    },
    // 西班牙
    esES: {
        query_time: 'Consulta de tiempo',
        number_of_closedCases: 'Número de casos cerrados',
        percentage_of_closedCases: 'Porcentaje de casos cerrados',
        SAP: 'SAP',
        organization_code: 'Código de organización',
        organization_name: 'Nombre de la organización',
        country: 'País',
        total_number_of_cases: 'Número total de casos',
    },
    // 葡萄牙
    ptBR: {
        query_time: 'Tempo de consulta',
        number_of_closedCases: 'Número de casos encerrados',
        percentage_of_closedCases: 'Percentagem de casos encerrados',
        SAP: 'SAP',
        organization_code: 'Código de organização',
        organization_name: 'Nome da organização',
        country: 'País',
        total_number_of_cases: 'Número total de casos',
    },
    // 波兰
    plPL: {
        query_time: 'Data zapytania',
        number_of_closedCases: 'Liczba zamkniętych spraw',
        percentage_of_closedCases: 'Procent zamkniętych spraw',
        SAP: 'SAP',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        country: 'Kraj',
        total_number_of_cases: 'Łączna liczba spraw',
    },
    // 土耳其
    trTR: {
        query_time: 'Sorgulama zamanı',
        number_of_closedCases: 'Kapatma sayısı',
        percentage_of_closedCases: 'Kapat oranı',
        SAP: 'SAP',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        country: 'Ülke',
        total_number_of_cases: 'Toplam vaka sayısı',
    },
    // 捷克语
    csCZ: {
        query_time: 'Čas dotazu',
        number_of_closedCases: 'Počet uzavřených případů',
        percentage_of_closedCases: 'Procento uzavřených případů',
        SAP: 'SAP',
        organization_code: 'Kód společnosti',
        organization_name: 'Název organizace',
        country: 'Země',
        total_number_of_cases: 'Celkový počet případů',
        statistical_days: 'Statistické dny',
    },
    // 匈牙利语
    huHU: {
        query_time: 'Lekérdezési idő',
        number_of_closedCases: 'Lezárt esetek száma',
        percentage_of_closedCases: 'Lezárt esetek százaléka',
        SAP: 'SAP',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        country: 'Ország',
        total_number_of_cases: 'Összes eset száma',
        statistical_days: 'Statisztikai napok',
    },
    // 俄罗斯语
    ruRU: {
        query_time: 'Время запроса',
        number_of_closedCases: 'Количество закрытых дел',
        percentage_of_closedCases: 'Процент закрытых дел',
        SAP: 'SAP',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        country: 'Страна',
        total_number_of_cases: 'Общее количество случаев',
        statistical_days: 'Статистические дни',
    },
};

import { createI18n } from 'vue-i18n';
import type { Ref } from 'vue';

import zhCN from '../../locales/zh-CN';
import enUS from '../../locales/en-US';
import deDE from '../../locales/de-DE';
import frFR from '../../locales/fr-FR';
import nlNL from '../../locales/nl-NL';
import itIt from '../../locales/it-IT';
import plPL from '../../locales/pl-PL';
import ptPT from '../../locales/pt-BR';
import viVN from '../../locales/vi-VN';
import esES from '../../locales/es-ES';
import trTR from '../../locales/tr-TR';
import csCZ from '../../locales/cs-CZ';
import huHU from '../../locales/hu-HU';
import ruRU from '../../locales/ru-RU';
import { Locales } from '~/composables/i18n';
import { setDayjsLocale } from '~/composables/useDayjs';
import { commonRequests } from '~/requests/common/common.request';
import { resHandler } from '~/utils/http/handler';
import { useCommonStore } from '~/stores/common';

export const useStorageLocale = createGlobalState(() => {
    const defaultLocale = useStorage('locale', ref<Locales>(Locales.enUS), localStorage, {
        deep: true,
        writeDefaults: true,
        listenToStorageChanges: true,
    }) as Ref<Locales>;

    return {
        defaultLocale,
    };
});

export const i18n = createI18n({
    legacy: false,
    messages: {
        [Locales.zhCN]: zhCN,
        [Locales.enUS]: enUS,
        [Locales.deDE]: deDE,
        [Locales.frFR]: frFR,
        [Locales.nlNL]: nlNL,
        [Locales.itIT]: itIt,
        [Locales.viVN]: viVN,
        [Locales.esES]: esES,
        [Locales.plPL]: plPL,
        [Locales.ptPT]: ptPT,
        [Locales.trTR]: trTR,
        [Locales.csCZ]: csCZ,
        [Locales.huHU]: huHU,
        [Locales.ruRU]: ruRU,
    },
    allowComposition: true,
});

export const { global: i18nGlobal } = i18n;

const i18nScope = effectScope();

export const initI18nScope = () => {
    i18nScope.run(() => {
        const { defaultLocale: locale } = useStorageLocale();
        const store = useCommonStore();

        i18nGlobal.locale.value = locale.value;

        setDayjsLocale(locale.value);

        watch(i18nGlobal.locale, async (val) => {
            store.locale = val;
            setDayjsLocale(val);
            if (store.authToken) {
                const res = await commonRequests.switchLang();
                await resHandler(res);
                await store.reFetchRoutes(null);
            }
        });
    });
};

import inquiryStatistics from './inquiryStatistics';
import systemClassifyA from './systemClassifyA';
import systemClassifyB from './systemClassifyB';
import engineerStatistics from './engineerStatistics';
import defectivePart from './defectivePart';
import replyInquiryStatistics from './replyInquiryStatistics';
import passRateStatistics from './passRateStatistics';
import timelyResponseRateStatistics from './timelyResponseRateStatistics';
import responseRateStatistics24Hour from './24ResponseRateStatistics';
import customsClearance135Rate from './135CustomsClearanceRate';
import accumulatedClosureRate from './accumulatedClosureRate';
import engineerAffiliatedOrganization from './engineerAffiliatedOrganization';
import EngineerResponseRateReport from './5DayEngineerResponseRateReport'

export default {
    inquiryStatistics,
    systemClassifyA,
    systemClassifyB,
    engineerStatistics,
    defectivePart,
    replyInquiryStatistics,
    passRateStatistics,
    timelyResponseRateStatistics,
    responseRateStatistics24Hour,
    customsClearance135Rate,
    accumulatedClosureRate,
    engineerAffiliatedOrganization,
    EngineerResponseRateReport,
};

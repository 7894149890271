export default {
    // 中
    zhCN: {
        dictionary_name: '字典名称',
        creater: '创建人',
        dictionary_code: '字典代码',
        code_type: '类型代码',
        code_type_name: '类型名称',
        creation_time: '创建时间',
        dict_new: '字典新增',
        dict_edit: '字典编辑',
        lang: '语言',
        sort: '排序',
    },
    // 英
    enUS: {
        dictionary_name: 'Dictionary name',
        creater: 'Creater',
        dictionary_code: 'Dictionary code',
        code_type: 'Code type',
        code_type_name: 'Code type name',
        creation_time: 'Creation time',
        dict_new: 'Dict new',
        dict_edit: 'Dict edit',
        lang: 'Lang',
        sort: 'Sort',
    },
    // 德
    deDE: {
        dictionary_name: 'Wörterbuchname',
        creater: 'Ersteller',
        dictionary_code: 'Wörterbuchcode',
        code_type: 'Code Typ',
        code_type_name: 'Code Typ Name',
        creation_time: 'Erstellungszeit',
        dict_new: 'Neues Wörterbuch',
        dict_edit: 'Wörterbuch bearbeiten',
        lang: 'Sprache',
        sort: 'Sortieren',
    },
    // 法
    frFR: {
        dictionary_name: 'Nom du dictionnaire',
        creater: 'Créateur',
        dictionary_code: 'Code du dictionnaire',
        code_type: 'Type de code',
        code_type_name: 'Nom du type de code',
        creation_time: 'Heure de création',
        dict_new: 'Nouveau dictionnaire',
        dict_edit: 'Modifier le dictionnaire',
        lang: 'Langue',
        sort: 'Trier',
    },
    // 意大利
    itIT: {
        dictionary_name: 'Nome del Dizionario',
        creater: 'Creatore',
        dictionary_code: 'Codice del Dizionario',
        code_type: 'Tipo di Codice',
        code_type_name: 'Nome del Tipo di Codice',
        creation_time: 'Data di Creazione',
        dict_new: 'Nuovo Dizionario',
        dict_edit: 'Modifica Dizionario',
        lang: 'Lingua',
        sort: 'Ordinamento',
    },
    // 荷兰
    nlNL: {
        dictionary_name: 'Woordenboeknaam',
        creater: 'Maker',
        dictionary_code: 'Woordenboekcode',
        code_type: 'Code Type',
        code_type_name: 'Code Type Naam',
        creation_time: 'Aanmaaktijd',
        dict_new: 'Nieuw Woordenboek',
        dict_edit: 'Bewerk Woordenboek',
        lang: 'Taal',
        sort: 'Sorteren',
    },
    // 越南
    viVN: {
        dictionary_name: 'Tên từ điển',
        creater: 'Người tạo',
        dictionary_code: 'Mã từ điển',
        code_type: 'Mã loại',
        code_type_name: 'Tên loại mã',
        creation_time: 'Thời gian tạo',
        dict_new: 'Thêm mới từ điển',
        dict_edit: 'Chỉnh sửa từ điển',
        lang: 'Ngôn ngữ',
        sort: 'Sắp xếp',
    },
    // 西班牙
    esES: {
        dictionary_name: 'Nombre del diccionario',
        creater: 'Creador',
        dictionary_code: 'Código del diccionario',
        code_type: 'Código de tipo',
        code_type_name: 'Nombre de tipo',
        creation_time: 'Fecha de creación',
        dict_new: 'Añadir al diccionario',
        dict_edit: 'Editar el diccionario',
        lang: 'Idioma',
        sort: 'Ordenar',
    },
    // 葡萄牙
    ptBR: {
        dictionary_name: 'Nome do dicionário',
        creater: 'Criador',
        dictionary_code: 'Código do dicionário',
        code_type: 'Tipo de código',
        code_type_name: 'Nome do tipo de código',
        creation_time: 'Data de criação',
        dict_new: 'Novo dicionário',
        dict_edit: 'Edição do dicionário',
        lang: 'Idioma',
        sort: 'Classificação',
    },
    // 波兰
    plPL: {
        dictionary_name: 'Nazwa słownikowa',
        creater: 'Założone przez',
        dictionary_code: 'Kod słownika',
        code_type: 'Typ kodu',
        code_type_name: 'Nazwa typu kodu',
        creation_time: 'Czas założenia',
        dict_new: 'Nowy wpis',
        dict_edit: 'Edycja słownika',
        lang: 'Język',
        sort: 'Sortowanie',
    },
    // 土耳其
    trTR: {
        dictionary_name: 'Sözlük İsmi',
        creater: 'Oluşturucu',
        dictionary_code: 'Sözlük Kodu',
        code_type: 'Kod Türü',
        code_type_name: 'Kod Türü İsmi',
        creation_time: 'Oluşturma Zamanı',
        dict_new: 'Sözlük eklendi',
        dict_edit: 'Sözlük düzeltildi',
        lang: 'Dil',
        sort: 'Sırala',
    },
    // 捷克语
    csCZ: {
        dictionary_name: 'Název slovníku',
        creater: 'Tvůrce',
        dictionary_code: 'Kód slovníku',
        code_type: 'Typ kódu',
        code_type_name: 'Typu kódu',
        creation_time: 'Čas vytvoření',
        dict_new: 'Dict new',
        dict_edit: 'Dict edit',
        lang: 'Jazyk',
        sort: 'Řazení',
    },
    // 匈牙利语
    huHU: {
        dictionary_name: 'Könyvtár neve',
        creater: 'Létrehozó',
        dictionary_code: 'Könyvtárkód',
        code_type: 'Kódtípus',
        code_type_name: 'Kódtípus neve',
        creation_time: 'Létrehozás ideje',
        dict_new: 'Új könyvt.',
        dict_edit: 'Könyvt. szerk.',
        lang: 'Nyelv',
        sort: 'Rendezés',
    },
    // 俄罗斯语
    ruRU: {
        dictionary_name: 'Название словаря',
        creater: 'Создатель',
        dictionary_code: 'Код словаря',
        code_type: 'Тип кода',
        code_type_name: 'Название типа кода',
        creation_time: 'Дата создания',
        dict_new: 'Добавление словаря',
        dict_edit: 'Редактирование словаря',
        lang: 'Язык',
        sort: 'Сортировка',
    },
};

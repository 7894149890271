export default {
    // 中
    zhCN: {
        menu: '菜单',
        menu_name: '菜单名称',
        menu_type: '菜单类型',
        menu_new: '菜单新增',
        menu_edit: '菜单编辑',
        icon: '图标',
        parent: '父级菜单',
        order_number: '排序',
        path: '路由地址',
        status: '菜单状态',
    },
    // 英
    enUS: {
        menu: 'Menu',
        menu_name: 'Menu name',
        menu_type: 'Menu type',
        menu_new: 'New menu',
        menu_edit: 'Modify menu',
        icon: 'Icon',
        parent: 'Parent',
        order_number: 'Order number',
        path: 'Path',
        status: 'Status',
    },
    // 德
    deDE: {
        menu: 'Menü',
        menu_name: 'Menüname',
        menu_type: 'Menütyp',
        menu_new: 'Neues Menü',
        menu_edit: 'Menü bearbeiten',
        icon: 'Symbol',
        parent: 'Eltern',
        order_number: 'Reihenfolge',
        path: 'Pfad',
        status: 'Status',
    },
    // 法
    frFR: {
        menu: 'Menu',
        menu_name: 'Nom du menu',
        menu_type: 'Type de menu',
        menu_new: 'Nouveau menu',
        menu_edit: 'Modifier le menu',
        icon: 'Icône',
        parent: 'Parent',
        order_number: 'Numéro de commande',
        path: 'Chemin',
        status: 'Statut',
    },
    // 意大利
    itIT: {
        menu: 'Menu',
        menu_name: 'Nome del Menu',
        menu_type: 'Tipo di Menu',
        menu_new: 'Nuovo Menu',
        menu_edit: 'Modifica Menu',
        icon: 'Icona',
        parent: 'Menu Principale',
        order_number: 'Ordinamento',
        path: 'Indirizzo del Percorso',
        status: 'Stato del Menu',
    },
    // 荷兰
    nlNL: {
        menu: 'Menu',
        menu_name: 'Menunaam',
        menu_type: 'Menutype',
        menu_new: 'Nieuw menu',
        menu_edit: 'Menu wijzigen',
        icon: 'Icoon',
        parent: 'Ouder',
        order_number: 'Bestelnummer',
        path: 'Pad',
        status: 'Status',
    },
    // 越南
    viVN: {
        menu: 'Menu',
        menu_name: 'Tên menu',
        menu_type: 'Loại menu',
        menu_new: 'Thêm mới menu',
        menu_edit: 'Chỉnh sửa menu',
        icon: 'Biểu tượng',
        parent: 'Menu cha',
        order_number: 'Số thứ tự',
        path: 'Địa chỉ đường dẫn',
        status: 'Trạng thái menu',
    },
    // 西班牙
    esES: {
        menu: 'Menú',
        menu_name: 'Nombre del menú',
        menu_type: 'Tipo de menú',
        menu_new: 'Añadir menú',
        menu_edit: 'Editar menú',
        icon: 'Ícono',
        parent: 'Menú principal',
        order_number: 'Ordenar',
        path: 'Dirección de ruta',
        status: 'Estado del menú',
    },
    // 葡萄牙
    ptBR: {
        menu: 'Menu',
        menu_name: 'Nome do menu',
        menu_type: 'Tipo de menu',
        menu_new: 'Novo menu',
        menu_edit: 'Editar menu',
        icon: 'Ícone',
        parent: 'Menu pai',
        order_number: 'Ordenação',
        path: 'Endereço do roteamento',
        status: 'Status do menu',
    },
    // 波兰
    plPL: {
        menu: 'Menu',
        menu_name: 'Nazwa menu',
        menu_type: 'Typ menu',
        menu_new: 'Nowe menu',
        menu_edit: 'Edycja menu',
        icon: 'Ikona',
        parent: 'Menu nadrzędne',
        order_number: 'Numer porządkowy',
        path: 'Ścieżka',
        status: 'Status',
    },
    // 土耳其
    trTR: {
        menu: 'Menü',
        menu_name: 'Menü İsmi',
        menu_type: 'Menü Türü',
        menu_new: 'Yeni Menü',
        menu_edit: 'Menüyi değiştir',
        icon: 'İşaretçi',
        parent: 'Ana menü',
        order_number: 'Sıra Numarası',
        path: 'Yol',
        status: 'Durum',
    },
    // 捷克语
    csCZ: {
        menu: 'Menu',
        menu_name: 'Název nabídky',
        menu_type: 'Typ nabídky',
        menu_new: 'Nová nabídka',
        menu_edit: 'Upravit nabídku',
        icon: 'Ikona',
        parent: 'Parent',
        order_number: 'Číslo objednávky',
        path: 'Cesta',
        status: 'Status',
    },
    // 匈牙利语
    huHU: {
        menu: 'Menü',
        menu_name: 'Menünév',
        menu_type: 'Menütípus',
        menu_new: 'Új menü',
        menu_edit: 'Menü módosítása',
        icon: 'Ikon',
        parent: 'Szülő',
        order_number: 'Megrendelésszám',
        path: 'Útvonal',
        status: 'Állapot',
    },
    // 俄罗斯语
    ruRU: {
        menu: 'Меню',
        menu_name: 'Название меню',
        menu_type: 'Тип меню',
        menu_new: 'Добавление меню',
        menu_edit: 'Редактирование меню',
        icon: 'Иконка',
        parent: 'Родительское меню',
        order_number: 'Сортировка',
        path: 'Адрес маршрута',
        status: 'Статус меню',
    },
};

import camelcase from 'camelcase';
import Bookmark from '~icons/carbon/bookmark';
import * as icons from '~/icon';

export const getIcon = (name?: string) => {
    if (!name) return Bookmark;
    return Reflect.get(
        icons,
        camelcase(name, { pascalCase: true, preserveConsecutiveUppercase: true }),
    );
};

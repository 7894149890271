export default {
    // 中
    zhCN: {
        Create_Engineer_Management_Organization: '新建工程师管理组织',
        Edit_Engineer_Management_Organization: '编辑工程师管理组织',
        engineer: '工程师',
        management_organization: '管理组织',
    },
    // 英
    enUS: {
        Create_Engineer_Management_Organization: 'Create Engineer Management Organization',
        Edit_Engineer_Management_Organization: 'Edit Engineer Management Organization',
        engineer: 'Engineer',
        management_organization: 'Management Organization',
    },
    // 德
    deDE: {
        Create_Engineer_Management_Organization: 'Ingenieur-Management-Organisation erstellen',
        Edit_Engineer_Management_Organization: 'Ingenieur-Management-Organisation bearbeiten',
        engineer: 'Ingenieur',
        management_organization: 'Managementorganisation',
    },
    // 法
    frFR: {
        Create_Engineer_Management_Organization: 'Créer une organisation de gestion des ingénieurs',
        Edit_Engineer_Management_Organization: 'Modifier l\'organisation de gestion des ingénieurs',
        engineer: 'Ingénieur',
        management_organization: 'Organisation de gestion',
    },
    // 意大利
    itIT: {
        Create_Engineer_Management_Organization:
            'Creare un\'organizzazione di gestione degli ingegneri',
        Edit_Engineer_Management_Organization:
            'Modifica organizzazione di gestione degli ingegneri',
        engineer: 'Ingegnere',
        management_organization: 'Organizzazione di gestione',
    },
    // 荷兰
    nlNL: {
        Create_Engineer_Management_Organization: 'Ingenieursbeheerorganisatie aanmaken',
        Edit_Engineer_Management_Organization: 'Ingenieursbeheerorganisatie bewerken',
        engineer: 'Ingenieur',
        management_organization: 'Managementorganisatie',
    },
    // 越南
    viVN: {
        Create_Engineer_Management_Organization: 'Tạo Tổ chức Quản lý Kỹ sư',
        Edit_Engineer_Management_Organization: 'Chỉnh sửa Tổ chức Quản lý Kỹ sư',
        engineer: 'Kỹ sư',
        management_organization: 'Tổ chức quản lý',
    },
    // 西班牙
    esES: {
        Create_Engineer_Management_Organization: 'Crear organización de gestión de ingenieros',
        Edit_Engineer_Management_Organization: 'Editar organización de gestión de ingenieros',
        engineer: 'Ingeniero',
        management_organization: 'Organización de gestión',
    },
    // 葡萄牙
    ptBR: {
        Create_Engineer_Management_Organization: 'Criar Organização de Gestão de Engenheiros',
        Edit_Engineer_Management_Organization: 'Editar Organização de Gestão de Engenheiros',
        engineer: 'Engenheiro',
        management_organization: 'Organização de gestão',
    },
    // 波兰
    plPL: {
        Create_Engineer_Management_Organization: 'Utwórz organizację zarządzania inżynierami',
        Edit_Engineer_Management_Organization: 'Edytuj organizację zarządzania inżynierami',
        engineer: 'Inżynier',
        management_organization: 'Organizacja zarządzania',
    },
    // 土耳其
    trTR: {
        Create_Engineer_Management_Organization: 'Mühendis Yönetim Organizasyonu Oluştur',
        Edit_Engineer_Management_Organization: 'Mühendis Yönetim Organizasyonunu Düzenle',
        engineer: 'Mühendis',
        management_organization: 'Yönetim Organizasyonu',
    },
    // 捷克语
    csCZ: {
        Create_Engineer_Management_Organization: 'Vytvořte organizaci řízení techniků',
        Edit_Engineer_Management_Organization: 'Upravit organizaci řízení techniků',
        engineer: 'Technik',
        management_organization: 'Organizace řízení',
    },
    // 匈牙利语
    huHU: {
        Create_Engineer_Management_Organization: 'Szerelő felügyeleti szervezet létrehozása',
        Edit_Engineer_Management_Organization: 'Szerelő felügyeleti szervezet szerkesztése',
        engineer: 'Szerelő',
        management_organization: 'Felügyeleti szervezet',
    },
    // 俄罗斯语
    ruRU: {
        Create_Engineer_Management_Organization: 'Создать организацию управления инженерами',
        Edit_Engineer_Management_Organization: 'Редактировать организацию управления инженерами',
        engineer: 'Инженер',
        management_organization: 'Организация управления',
    },
};

export default {
    // 中
    zhCN: {
        fill_in_at_least_one_query_condition: '至少填写一个查询条件',
        organization_name: '组织名称',
        query_time: '查询时间',
        organization_code: '组织CODE',
        country: '国家',
        number_of_cases: '案例总数',
        average_replied_cases: '工程师单据平均答复单数',
        total_cases: '工程师案例总数',
        engineer_name: '工程师',
        waiting_for_reply_cases: '未答复案例数量',
        percentage_waiting_for_reply_cases: '未答复案例百分比',
        replied_cases: '已答复案例数量',
        percentage_replied_cases: '已答复数量百分比',
        closed_cases: '已结案案例数量',
        percentage_closed_cases: '已结案数量百分比',
    },
    // 英
    enUS: {
        fill_in_at_least_one_query_condition: 'Fill in at least one query condition',
        organization_name: 'Organization name',
        query_time: 'Query time',
        organization_code: 'Organization code',
        country: 'Country',
        number_of_cases: 'Number of cases',
        average_replied_cases: 'Average replied cases',
        total_cases: 'Total cases',
        engineer_name: 'Engineer name',
        waiting_for_reply_cases: 'Waiting for reply cases',
        percentage_waiting_for_reply_cases: 'Waiting for reply cases (%)',
        replied_cases: 'Replied cases',
        percentage_replied_cases: 'Replied cases (%)',
        closed_cases: 'Closed cases',
        percentage_closed_cases: 'Closed cases (%)',
    },
    // 德
    deDE: {
        fill_in_at_least_one_query_condition: 'Füllen Sie mindestens eine Abfragebedingung aus',
        organization_name: 'Organisationsname',
        query_time: 'Abfragezeit',
        organization_code: 'Organisationscode',
        country: 'Land',
        number_of_cases: 'Gesamtzahl der Fälle',
        average_replied_cases: 'Durchschnittlich beantwortete Fälle',
        total_cases: 'Gesamtzahl der Fälle',
        engineer_name: 'Name des Ingenieurs',
        waiting_for_reply_cases: 'Fälle, die auf Antwort warten',
        percentage_waiting_for_reply_cases: 'Fälle, die auf Antwort warten (%)',
        replied_cases: 'Beantwortete Fälle',
        percentage_replied_cases: 'Beantwortete Fälle (%)',
        closed_cases: 'Geschlossene Fälle',
        percentage_closed_cases: 'Geschlossene Fälle (%)',
    },
    // 法
    frFR: {
        fill_in_at_least_one_query_condition: 'Remplissez au moins une condition de demande',
        organization_name: 'Nom organisation',
        query_time: 'Temps de requête',
        organization_code: 'Code organisation',
        country: 'Pays',
        number_of_cases: 'Nombre de cas',
        average_replied_cases: 'Moyenne des cas répondus',
        total_cases: 'Total cas',
        engineer_name: 'Nom de l\'ingénieur',
        waiting_for_reply_cases: 'Cas en attente de retour',
        percentage_waiting_for_reply_cases: 'Cas en attente de retour (%)',
        replied_cases: 'Cas répondus',
        percentage_replied_cases: 'Cas répondus (%)',
        closed_cases: 'Cas clôturés',
        percentage_closed_cases: 'Cas clôturés (%)',
    },
    // 意大利
    itIT: {
        fill_in_at_least_one_query_condition: 'Compilare almeno una condizione di query',
        organization_name: 'Nome Organizzazione',
        query_time: 'Tempo di Query',
        organization_code: 'Codice Organizzazione',
        country: 'Paese',
        number_of_cases: 'Numero totale di casi',
        average_replied_cases: 'Numero Medio di Casi Risposti per Dipendente',
        total_cases: 'Numero Totale di Casi dei Dipendenti',
        engineer_name: 'Nome Dipendente',
        waiting_for_reply_cases: 'Numero di Casi in Attesa di Risposta',
        percentage_waiting_for_reply_cases: 'Percentuale di Casi in Attesa di Risposta',
        replied_cases: 'Numero di Casi ai Quali è Stata Fornita una Risposta',
        percentage_replied_cases: 'Percentuale di Casi ai Quali è Stata Fornita una Risposta',
        closed_cases: 'Numero di Casi Chiusi',
        percentage_closed_cases: 'Percentuale di Casi Chiusi',
    },
    // 荷兰
    nlNL: {
        fill_in_at_least_one_query_condition: 'Vul ten minste één query voorwaarde in',
        organization_name: 'Organisatie naam',
        query_time: 'Query time',
        organization_code: 'Organisatie code',
        country: 'Land',
        number_of_cases: 'Totaal aantal gevallen',
        average_replied_cases: 'Gemiddelde beantwoorde gevallen',
        total_cases: 'Totaal aantal gevallen',
        engineer_name: 'Ingenieursnaam',
        waiting_for_reply_cases: 'Wachten op antwoord gevallen',
        percentage_waiting_for_reply_cases: 'Wachten op antwoord gevallen (%)',
        replied_cases: 'Beantwoorde gevallen',
        percentage_replied_cases: 'Beantwoorde gevallen (%)',
        closed_cases: 'Gesloten gevallen',
        percentage_closed_cases: 'Gesloten gevallen(%)',
    },
    // 越南
    viVN: {
        fill_in_at_least_one_query_condition: 'Ít nhất điền một điều kiện tìm kiếm',
        organization_name: 'Tên tổ chức',
        query_time: 'Thời gian truy vấn',
        organization_code: 'Mã tổ chức',
        country: 'Quốc gia',
        number_of_cases: 'Số lượng trường hợp',
        average_replied_cases: 'Trung bình các trường hợp đã trả lời',
        total_cases: 'Tổng số trường hợp',
        engineer_name: 'Tên kỹ sư',
        waiting_for_reply_cases: 'Các trường hợp đang chờ trả lời',
        percentage_waiting_for_reply_cases: 'Tỉ lệ các trường hợp đang chờ trả lời (%)',
        replied_cases: 'Các trường hợp đã trả lời',
        percentage_replied_cases: 'Tỉ lệ các trường hợp đã trả lời (%)',
        closed_cases: 'Các trường hợp đã đóng',
        percentage_closed_cases: 'Tỉ lệ các trường hợp đã đóng (%)',
    },
    // 西班牙
    esES: {
        fill_in_at_least_one_query_condition: 'Al menos complete un criterio de búsqueda',
        organization_name: 'Nombre de la organización',
        query_time: 'Tiempo de consulta',
        organization_code: 'Código de la organización',
        country: 'País',
        number_of_cases: 'Número total de casos',
        average_replied_cases: 'Promedio de respuestas por ingeniero',
        total_cases: 'Número total de casos por ingeniero',
        engineer_name: 'Ingeniero',
        waiting_for_reply_cases: 'Cantidad de casos no respondidos',
        percentage_waiting_for_reply_cases: 'Porcentaje de casos no respondidos',
        replied_cases: 'Cantidad de casos respondidos',
        percentage_replied_cases: 'Porcentaje de casos respondidos',
        closed_cases: 'Cantidad de casos cerrados',
        percentage_closed_cases: 'Porcentaje de casos cerrados',
    },
    // 葡萄牙
    ptBR: {
        fill_in_at_least_one_query_condition: 'Preencha pelo menos uma condição de consulta',
        organization_name: 'Nome da organização',
        query_time: 'Tempo de consulta',
        organization_code: 'Código da organização',
        country: 'País',
        number_of_cases: 'Total de casos',
        average_replied_cases: 'Número médio de respostas por engenheiro',
        total_cases: 'Total de casos por engenheiro',
        engineer_name: 'Engenheiro',
        waiting_for_reply_cases: 'Número de casos aguardando resposta',
        percentage_waiting_for_reply_cases: 'Porcentagem de casos aguardando resposta',
        replied_cases: 'Número de casos respondidos',
        percentage_replied_cases: 'Porcentagem de casos respondidos',
        closed_cases: 'Número de casos encerrados',
        percentage_closed_cases: 'Porcentagem de casos encerrados',
    },
    // 波兰
    plPL: {
        fill_in_at_least_one_query_condition: 'Wypełnij co najmniej jeden warunek zapytania',
        organization_name: 'Nazwa organizacji',
        query_time: 'Data zapytania',
        organization_code: 'Kod organizacji',
        country: 'Kraj',
        number_of_cases: 'Łączna liczba spraw',
        average_replied_cases: 'Średnia liczba odpowiedzi',
        total_cases: 'Łączna liczba spraw',
        engineer_name: 'Nazwa inżyniera',
        waiting_for_reply_cases: 'Liczba spraw oczekujących na odpowiedź',
        percentage_waiting_for_reply_cases: 'Sprawy oczekujące na odpowiedź (%)',
        replied_cases: 'Udzielone odpowiedzi',
        percentage_replied_cases: 'Udzielone odpowiedzi (%)',
        closed_cases: 'Sprawy zamknięte',
        percentage_closed_cases: 'Sprawy zamknięte (%)',
    },
    // 土耳其
    trTR: {
        fill_in_at_least_one_query_condition: 'En azından bir soru durumunu doldur',
        organization_name: 'Organizasyon adı',
        query_time: 'Sorgulama zamanı',
        organization_code: 'Organizasyon CODE',
        country: 'Ülke',
        number_of_cases: 'Toplam vakalar',
        average_replied_cases: 'Ortalama cevaplanan  dosyalar',
        total_cases: 'Toplam  dosyalar',
        engineer_name: 'Mühendis adı',
        waiting_for_reply_cases: 'Dosya cevaplanmayı bekliyor',
        percentage_waiting_for_reply_cases: 'Cevap  bekleyen dosyalar (%)',
        replied_cases: 'Cevaplanmış  dosyalar',
        percentage_replied_cases: 'Cevaplanmış  dosyalar (%)',
        closed_cases: 'Kapatılmış  dosyalar',
        percentage_closed_cases: 'Kapatılmış  dosyalar (%)',
    },
    // 捷克语
    csCZ: {
        fill_in_at_least_one_query_condition: 'Vyplňte alespoň jednu podmínku dotazu',
        organization_name: 'Název společnosti',
        query_time: 'Čas dotazu',
        organization_code: 'Kód společnosti',
        country: 'Země',
        number_of_cases: 'Počet případů',
        average_replied_cases: 'Průměrný počet odpovědí',
        total_cases: 'Celkový počet případů',
        engineer_name: 'Jméno technika',
        waiting_for_reply_cases: 'Čeká na odpověď',
        percentage_waiting_for_reply_cases: 'Čeká na odpověď (%)',
        replied_cases: 'Odpovězené případy',
        percentage_replied_cases: 'Odpovězené případy (%)',
        closed_cases: 'Uzavřené případy',
        percentage_closed_cases: 'Uzavřené případy (%)',
    },
    // 匈牙利语
    huHU: {
        fill_in_at_least_one_query_condition: 'Töltsön ki legalább egy lekérdezési feltételt',
        organization_name: 'Szervezet neve',
        query_time: 'Lekérdezési idő',
        organization_code: 'Szervezet kódja',
        country: 'Ország',
        number_of_cases: 'Esetek száma',
        average_replied_cases: 'Átlagosan megválaszolt esetek',
        total_cases: 'Összes eset',
        engineer_name: 'Szerelő neve',
        waiting_for_reply_cases: 'Reakcióra váró esetek',
        percentage_waiting_for_reply_cases: 'Reakcióra váró esetek (%)',
        replied_cases: 'Megválaszolt esetek',
        percentage_replied_cases: 'Megválaszolt esetek (%)',
        closed_cases: 'Lezárt esetek',
        percentage_closed_cases: 'Lezárt esetek (%)',
    },
    // 俄罗斯语
    ruRU: {
        fill_in_at_least_one_query_condition: 'Заполните хотя бы одно условие запроса',
        organization_name: 'Название организации',
        query_time: 'Время запроса',
        organization_code: 'Код организации',
        country: 'Страна',
        number_of_cases: 'Общее количество случаев',
        average_replied_cases: 'Среднее количество ответов на заявки инженеров',
        total_cases: 'Общее количество случаев инженеров',
        engineer_name: 'Имя инженера',
        waiting_for_reply_cases: 'Количество ожидающих ответа случаев',
        percentage_waiting_for_reply_cases: 'Процент ожидающих ответа случаев',
        replied_cases: 'Количество ответов на случаи',
        percentage_replied_cases: 'Процент ответов на случаи',
        closed_cases: 'Количество закрытых случаев',
        percentage_closed_cases: 'Процент закрытых случаев',
    },
};

export default {
    // 中
    zhCN: {
        newly_built: '新建',
        fieldSupportNumber: '现场支持单号',
        informationSlipNumber: '问询单号',
        approvalStatus: '审批状态',
        state: '状态',
        selectApplicationDate: '选择申请日期',
        toBeProcessed: '待处理',
        processed: '已处理',
        initiated: '已发起',
        applicationDepartment: '申请部门',
        applicant: '申请人',
        reasonApplication: '申请原因',
        applicationTime: '申请时间',
        view: {
            application_department: '申请部门',
            application_person: '申请人',
            responsible_person: '负责人',
            associated_enquiry_number: '关联问询单号',
            reason_application: '申请原因',
            description: '说明(内部申请签字单)',
            file_name: '文件名',
            size: '大小(MB)',
            uploader: '上传人',
            upload_time: '上传时间',
            view: '查看',
            download: '下载',
            support_department: '支持部门',
            technical_personnel: '技术人员',
            contact_information: '联系方式',
            field_support_information: '现场支持信息',
            remarks: '备注',
            apply: '审批',
            application_information: '申请信息',
            field_support_personnel: '现场支持人员',
            approval_record: '审批记录',
            submit_an_application: '提交申请',
            refuse: '拒绝',
            agree: '同意',
            apply_for: '申请',
            field_support: '现场支持',
            reason_for_rejection: '拒绝原因',
        },
        dialog: {
            on_site_application_details: '现场申请详情',
            on_site_application: '现场申请',
            reason_for_rejection: '拒绝原因',
            field_support_application: '现场支持申请',
            technical_personnel: '技术人员',
            contact_information: '联系方式',
            field_support_time: '现场支持时间',
            note_information: '备注信息',
        },
    },
    // 英
    enUS: {
        newly_built: 'Add',
        fieldSupportNumber: 'Field support number',
        informationSlipNumber: 'Technical inquiry #',
        approvalStatus: 'Approval status',
        state: 'State',
        selectApplicationDate: 'Select the application date',
        toBeProcessed: 'To be processed',
        processed: 'Processed',
        initiated: 'Initiated',
        applicationDepartment: 'Application department',
        applicant: 'Applicant',
        reasonApplication: 'Reason for application',
        applicationTime: 'Application time',
        view: {
            application_department: 'Application department',
            responsible_person: 'Responsible person',
            application_person: 'Application person',
            associated_enquiry_number: 'Associated enquiry number',
            reason_application: 'Reason for application',
            description: 'Description(Internal application signature sheet)',
            file_name: 'File name',
            size: 'Size(MB)',
            uploader: 'Uploader',
            upload_time: 'Upload time',
            view: 'Check',
            download: 'Download',
            support_department: 'Support department',
            technical_personnel: 'Technical personnel',
            contact_information: 'Contact information',
            field_support_information: 'Field support information',
            remarks: 'Remarks',
            apply: 'Apply',
            application_information: 'Application information',
            field_support_personnel: 'On-site support personnel',
            approval_record: 'Approval history',
            submit_an_application: 'Submit an application',
            refuse: 'Refuse',
            agree: 'Agree',
            apply_for: 'Apply for',
            field_support: 'Field support',
            reason_for_rejection: 'Reason for rejection',
        },
        dialog: {
            on_site_application_details: 'On-site application details',
            on_site_application: 'On-site application',
            reason_for_rejection: 'Reason for rejection',
            field_support_application: 'apply for on-site technical support',
            technical_personnel: 'Technical personnel',
            contact_information: 'Contact Telephone',
            field_support_time: 'On-site support time',
            note_information: 'Note information',
        },
    },
    // 德
    deDE: {
        newly_built: 'Hinzugefügt',
        fieldSupportNumber: 'Feldunterstützungsnummer',
        informationSlipNumber: 'Informationszettelnummer',
        approvalStatus: 'Genehmigungsstatus',
        state: 'Zustand',
        selectApplicationDate: 'Wählen Sie das Anwendungsdatum aus',
        toBeProcessed: 'Zu bearbeiten',
        processed: 'Verarbeitet',
        initiated: 'Initiiert',
        applicationDepartment: 'Antragsabteilung',
        applicant: 'Antragsteller',
        reasonApplication: 'Grund für den Antrag',
        applicationTime: 'Antragszeit',
        view: {
            application_department: 'Antragsabteilung',
            responsible_person: 'Verantwortliche Person',
            application_person: 'Antragsteller',
            associated_enquiry_number: 'Zugehörige Anfragenummer',
            reason_application: 'Grund für den Antrag',
            description: 'Beschreibung (Interner Antragssignaturbogen)',
            file_name: 'Dateiname',
            size: 'Größe (MB)',
            uploader: 'Uploader',
            upload_time: 'Upload-Zeit',
            view: 'Prüfen',
            download: 'Herunterladen',
            support_department: 'Unterstützungsabteilung',
            technical_personnel: 'Technisches Personal',
            contact_information: 'Kontaktinformationen',
            field_support_information: 'Informationen zur Feldunterstützung',
            remarks: 'Bemerkungen',
            apply: 'Bewerben',
            application_information: 'Anwendungsinformationen',
            field_support_personnel: 'Vor-Ort-Supportpersonal',
            approval_record: 'Genehmigungsverlauf',
            submit_an_application: 'Ein Antrag stellen',
            refuse: 'Ablehnen',
            agree: 'Zustimmen ',
            apply_for: 'Antrag auf ',
            field_support: 'Feldunterstützung',
            reason_for_rejection: 'Grund für die Ablehnung',
        },
        dialog: {
            on_site_application_details: 'Details zur Vor-Ort-Anwendung',
            on_site_application: 'Vor-Ort-Anwendung',
            reason_for_rejection: 'Grund für die Ablehnung',
            field_support_application: 'Antrag auf Feldunterstützung',
            technical_personnel: 'Technisches Personal',
            contact_information: 'Kontakt Telefon',
            field_support_time: 'Zeitpunkt der Vor-Ort-Unterstützung',
            note_information: 'Notizinformationen',
        },
    },
    // 法
    frFR: {
        newly_built: 'Ajouter',
        fieldSupportNumber: 'Numéro de support sur le terrain',
        informationSlipNumber: 'Demande Technique #',
        approvalStatus: 'Statut d\'approbation',
        state: 'Statut',
        selectApplicationDate: 'Sélectionnez la date de demande',
        toBeProcessed: 'À traiter',
        processed: 'Traité',
        initiated: 'initié',
        applicationDepartment: 'Demandeur',
        applicant: 'Demandeur',
        reasonApplication: 'Justification de la demande',
        applicationTime: 'Heure de demande',
        view: {
            application_department: 'Service demandeur',
            responsible_person: 'Personne responsable',
            application_person: 'postulant',
            associated_enquiry_number: 'Numéro d\'enquête associé',
            reason_application: 'Raison de la demande',
            description: 'Description(Feuille de signature de demande interne)',
            file_name: 'Nom du fichier',
            size: 'Taille (Mo)',
            uploader: 'Téléchargé par',
            upload_time: 'Temps de Téléchargement ',
            view: 'Voir',
            download: 'Télécharger',
            support_department: 'Service de support',
            technical_personnel: 'Personnel technique',
            contact_information: 'Contact téléphonique',
            field_support_information: 'Informations de support sur le terrain',
            remarks: 'Remarque',
            apply: 'Soumettre',
            application_information: 'Informations de demande',
            field_support_personnel: 'Technicien sur site',
            approval_record: 'Historique d\'approbation',
            submit_an_application: 'Soumettre une demande',
            refuse: 'Refuser',
            agree: 'Accepter',
            apply_for: 'Demander',
            field_support: 'Support sur le terrain',
            reason_for_rejection: 'Raison du rejet',
        },
        dialog: {
            on_site_application_details: 'Demander une assistance technique sur site',
            on_site_application: 'Demande sur place',
            reason_for_rejection: 'Raison du rejet',
            field_support_application: 'Demande de support sur le terrain',
            technical_personnel: 'Personnel technique',
            contact_information: 'Contact téléphonique',
            field_support_time: 'Temps d\'assistance sur site',
            note_information: 'Remarque',
        },
    },
    // 意大利
    itIT: {
        newly_built: 'Aggiungi',
        fieldSupportNumber: 'Numero di supporto sul campo',
        informationSlipNumber: 'Numero del modulo informazioni',
        approvalStatus: 'Stato di approvazione',
        state: 'Stato',
        selectApplicationDate: 'Seleziona la data di richiesta',
        toBeProcessed: 'Da elaborare',
        processed: 'Elaborato',
        initiated: 'Iniziato',
        applicationDepartment: 'Dipartimento di applicazione',
        applicant: 'Richiedente',
        reasonApplication: 'Motivo della richiesta',
        applicationTime: 'Tempo di applicazione',
        view: {
            application_department: 'Dipartimento di applicazione',
            responsible_person: 'Persona responsabile',
            application_person: 'Persona che fa la domanda',
            associated_enquiry_number: 'Numero di indagine associato',
            reason_application: 'Motivo della richiesta',
            description: 'Descrizione (foglio firma interna della domanda)',
            file_name: 'Nome del file',
            size: 'Dimensione (MB)',
            uploader: 'Caricatore',
            upload_time: 'Tempo di caricamento',
            view: 'Verifica',
            download: 'Scarica',
            support_department: 'Dipartimento di supporto',
            technical_personnel: 'Personale tecnico',
            contact_information: 'Informazioni di contatto',
            field_support_information: 'Informazioni sul supporto sul campo',
            remarks: 'Osservazioni',
            apply: 'Applicare',
            application_information: 'Informazioni sulla richiesta',
            field_support_personnel: 'Personale di supporto sul sito',
            approval_record: 'Storia dell\'approvazione',
            submit_an_application: 'Presentare una domanda',
            refuse: 'Rifiutare',
            agree: 'Convenire',
            apply_for: 'Richiedere',
            field_support: 'Supporto sul campo',
            reason_for_rejection: 'Motivo del rifiuto',
        },
        dialog: {
            on_site_application_details: 'Dettagli della richiesta sul sito',
            on_site_application: 'Richiesta sul sito',
            reason_for_rejection: 'Motivo del rifiuto',
            field_support_application: 'Domanda di supporto sul campo',
            technical_personnel: 'Personale tecnico',
            contact_information: 'Telefono di contatto',
            field_support_time: 'Tempo di supporto sul campo',
            note_information: 'Informazioni sulla nota',
        },
    },
    // 荷兰
    nlNL: {
        newly_built: 'Toevoegen',
        fieldSupportNumber: 'Veldondersteuningsnummer',
        informationSlipNumber: 'Informatie slipnummer',
        approvalStatus: 'Goedkeuringsstatus',
        state: 'Staat',
        selectApplicationDate: 'Selecteer de aanvraagdatum',
        toBeProcessed: 'Te verwerken',
        processed: 'Verwerkt',
        initiated: 'Geïnitieerd',
        applicationDepartment: 'Afdeling aanvraag',
        applicant: 'Aanvrager',
        reasonApplication: 'Reden voor aanvraag',
        applicationTime: 'Aanvraagtijd',
        view: {
            application_department: 'Afdeling aanvraag',
            responsible_person: 'Verantwoordelijke persoon',
            application_person: 'Aanvraagpersoon',
            associated_enquiry_number: 'Bijbehorend vraagnummer',
            reason_application: 'Reden voor aanvraag',
            description: 'Beschrijving(Interne aanvraagondertekening)',
            file_name: 'Bestandsnaam',
            size: 'Grootte(MB)',
            uploader: 'Uploader',
            upload_time: 'Uploadtijd',
            view: 'Check',
            download: 'download',
            support_department: 'Ondersteuningsafdeling',
            technical_personnel: 'Technisch personeel',
            contact_information: 'Contactgegevens',
            field_support_information: 'Veldondersteuningsinformatie',
            remarks: 'Opmerkingen',
            apply: 'Solliciteren',
            application_information: 'Aanvraaginformatie',
            field_support_personnel: 'Ondersteuningspersoneel ter plaatse',
            approval_record: 'Goedkeuringsgeschiedenis',
            submit_an_application: 'Dien een aanvraag in',
            refuse: 'Weigeren',
            agree: 'Overeenkomen',
            apply_for: 'Toepassen voor',
            field_support: 'Veldondersteuning',
            reason_for_rejection: 'Reden van afwijzing',
        },
        dialog: {
            on_site_application_details: 'Details van de aanvraag op locatie',
            on_site_application: 'Aanvraag op locatie',
            reason_for_rejection: 'Reden van afwijzing',
            field_support_application: 'Veldondersteuningsaanvraag',
            technical_personnel: 'Technisch personeel',
            contact_information: 'Contact telefoon',
            field_support_time: 'Tijd van ondersteuning ter plaatse',
            note_information: 'Notitie informatie',
        },
    },
    // 越南
    viVN: {
        newly_built: 'Mới tạo',
        fieldSupportNumber: 'Số hỗ trợ trường hợp',
        informationSlipNumber: 'Yêu cầu kỹ thuật #',
        approvalStatus: 'Trạng thái phê duyệt',
        state: 'Trạng thái',
        selectApplicationDate: 'Chọn ngày ứng dụng',
        toBeProcessed: 'Đang chờ xử lý',
        processed: 'Đã xử lý',
        initiated: 'Đã khởi tạo',
        applicationDepartment: 'Người đăng ký',
        applicant: 'Người đăng ký',
        reasonApplication: 'Lý do đăng ký',
        applicationTime: 'Thời gian đăng ký',
        view: {
            application_department: 'Bộ phận ứng dụng',
            application_person: 'Người ứng dụng',
            responsible_person: 'Người chịu trách nhiệm',
            associated_enquiry_number: 'Số yêu cầu liên quan',
            reason_application: 'Lý do ứng dụng',
            description: 'Mô tả (Biểu mẫu đăng ký nội bộ)',
            file_name: 'Tên tập tin',
            size: 'Kích thước (MB)',
            uploader: 'Người tải lên',
            upload_time: 'Thời gian tải lên',
            view: 'Xem',
            download: 'Tải về',
            support_department: 'Bộ phận hỗ trợ',
            technical_personnel: 'Nhân viên kỹ thuật',
            contact_information: 'Thông tin liên hệ',
            field_support_information: 'Thông tin hỗ trợ trường hợp',
            remarks: 'Ghi chú',
            apply: 'Áp dụng',
            application_information: 'Thông tin ứng dụng',
            field_support_personnel: 'Nhân viên hỗ trợ tại chổ',
            approval_record: 'Lịch sử phê duyệt',
            submit_an_application: 'Gửi một đơn đăng ký',
            refuse: 'Từ chối',
            agree: 'Đồng ý',
            apply_for: 'Yêu cầu',
            field_support: 'Hỗ trợ trường hợp',
            reason_for_rejection: 'Lý do từ chối',
        },
        dialog: {
            on_site_application_details: 'Chi tiết đơn đăng ký trường hợp',
            on_site_application: 'Đơn đăng ký trường hợp',
            reason_for_rejection: 'Lý do từ chối',
            field_support_application: 'Đăng ký cho trường hợp hỗ trợ kỹ thuật tại chổ',
            technical_personnel: 'Nhân viên kỹ thuật',
            contact_information: 'Số điện thoại liên lạc',
            field_support_time: 'Thời gian hỗ trợ tại chổ',
            note_information: 'Thông tin ghi chú',
        },
    },
    // 西班牙
    esES: {
        newly_built: 'Nuevo',
        fieldSupportNumber: 'Número de soporte en el sitio',
        informationSlipNumber: 'Número de consulta',
        approvalStatus: 'Estado de aprobación',
        state: 'Estado',
        selectApplicationDate: 'Seleccione la fecha de solicitud',
        toBeProcessed: 'Pendiente',
        processed: 'Procesado',
        initiated: 'Iniciado',
        applicationDepartment: 'Departamento solicitante',
        applicant: 'Solicitante',
        reasonApplication: 'Motivo de la solicitud',
        applicationTime: 'Fecha de solicitud',
        view: {
            application_department: 'Departamento solicitante',
            application_person: 'Solicitante',
            responsible_person: 'Responsable',
            associated_enquiry_number: 'Número de consulta relacionada',
            reason_application: 'Motivo de la solicitud',
            description: 'Descripción (formulario de firma de solicitud interna)',
            file_name: 'Nombre del archivo',
            size: 'Tamaño (MB)',
            uploader: 'Persona que subió',
            upload_time: 'Fecha de carga',
            view: 'Ver',
            download: 'Descargar',
            support_department: 'Departamento de soporte',
            technical_personnel: 'Personal técnico',
            contact_information: 'Información de contacto',
            field_support_information: 'Información de soporte en el sitio',
            remarks: 'Observaciones',
            apply: 'Aprobación',
            application_information: 'Información de solicitud',
            field_support_personnel: 'Personal de soporte en el sitio',
            approval_record: 'Registro de aprobación',
            submit_an_application: 'Enviar solicitud',
            refuse: 'Rechazar',
            agree: 'Aceptar',
            apply_for: 'Solicitud',
            field_support: 'Soporte en terreno',
            reason_for_rejection: 'Motivo de rechazo',
        },
        dialog: {
            on_site_application_details: 'Detalles de la solicitud en el sitio',
            on_site_application: 'Solicitud en el sitio',
            reason_for_rejection: 'Motivo de rechazo',
            field_support_application: 'Solicitud de soporte en el sitio',
            technical_personnel: 'Personal técnico',
            contact_information: 'Información de contacto',
            field_support_time: 'Fecha de soporte en el sitio',
            note_information: 'Información adicional',
        },
    },
    // 葡萄牙
    ptBR: {
        newly_built: 'Nova construção',
        fieldSupportNumber: 'Número de Suporte de Campo',
        informationSlipNumber: 'Número de Consulta',
        approvalStatus: 'Status de Aprovação',
        state: 'Estado',
        selectApplicationDate: 'Selecionar Data de Aplicação',
        toBeProcessed: 'A Ser Processado',
        processed: 'Processado',
        initiated: 'Iniciado',
        applicationDepartment: 'Departamento de Aplicação',
        applicant: 'Solicitante',
        reasonApplication: 'Motivo da Aplicação',
        applicationTime: 'Tempo de Aplicação',
        view: {
            application_department: 'Departamento de Aplicação',
            application_person: 'Solicitante',
            responsible_person: 'Pessoa Responsável',
            associated_enquiry_number: 'Número de Consulta Associado',
            reason_application: 'Motivo da Aplicação',
            description: 'Descrição (Formulário de Assinatura Interna de Aplicação)',
            file_name: 'Nome do Arquivo',
            size: 'Tamanho (MB)',
            uploader: 'Uploader',
            upload_time: 'Tempo de Upload',
            view: 'Visualizar',
            download: 'Download',
            support_department: 'Departamento de Suporte',
            technical_personnel: 'Pessoal Técnico',
            contact_information: 'Informações de Contato',
            field_support_information: 'Informações de Suporte de Campo',
            remarks: 'Observações',
            apply: 'Aplicar',
            application_information: 'Informações da Aplicação',
            field_support_personnel: 'Pessoal de Suporte de Campo',
            approval_record: 'Registro de Aprovação',
            submit_an_application: 'Enviar uma Aplicação',
            refuse: 'Recusar',
            agree: 'Concordar',
            apply_for: 'Aplicar',
            field_support: 'Suporte de Campo',
            reason_for_rejection: 'Motivo da Rejeição',
        },
        dialog: {
            on_site_application_details: 'Detalhes da Aplicação no Local',
            on_site_application: 'Aplicação no Local',
            reason_for_rejection: 'Motivo da Rejeição',
            field_support_application: 'Aplicação de Suporte de Campo',
            technical_personnel: 'Pessoal Técnico',
            contact_information: 'Informações de Contato',
            field_support_time: 'Tempo de Suporte de Campo',
            note_information: 'Informações da Nota',
        },
    },
    // 波兰
    plPL: {
        newly_built: 'Nowy',
        fieldSupportNumber: 'Numer wsparcia lokalnego',
        informationSlipNumber: 'Numer zapytania',
        approvalStatus: 'Status zatwierdzenia',
        state: 'Status',
        selectApplicationDate: 'Wybierz datę złożenia wniosku',
        toBeProcessed: 'Oczekuje ',
        processed: 'W trakcie',
        initiated: 'Rozpoczęte',
        applicationDepartment: 'Dział aplikujący',
        applicant: 'Aplikant',
        reasonApplication: 'Powód wniosku',
        applicationTime: 'Czas złożenia wniosku',
        view: {
            application_department: 'Dział aplikujący',
            application_person: 'Aplikant',
            responsible_person: 'Osoba odpowiedzialna',
            associated_enquiry_number: 'Powiązany numer zapytania',
            reason_application: 'Powód wniosku',
            description: 'Wyjaśnienie (arkusz podpisów wniosków wewnętrznych)',
            file_name: 'Nazwa pliku',
            size: 'Rozmiar (MB)',
            uploader: 'Przesyłający',
            upload_time: 'Czas przesłania',
            view: 'Sprawdź',
            download: 'Ściągnij',
            support_department: 'Odzsiał wspierający',
            technical_personnel: 'Personel techniczny',
            contact_information: 'Informacje kontaktowe',
            field_support_information: 'Informacje o wsparciu terenowym',
            remarks: 'Uwagi',
            apply: 'Zatwierdź',
            application_information: 'Informacje dot. Wniosku',
            field_support_personnel: 'Personel techniczny na miejscu',
            approval_record: 'Historia zatwierdzeń',
            submit_an_application: 'Przekaż wniosek',
            refuse: 'Odmowa',
            agree: 'Zgoda',
            apply_for: 'Wnioskować o',
            field_support: 'Wsparcie terenowe',
            reason_for_rejection: 'Powód odmowy',
        },
        dialog: {
            on_site_application_details: 'Informacje o wniosku lokalnym',
            on_site_application: 'Wniosek lokalny',
            reason_for_rejection: 'Powód odmowy',
            field_support_application: 'Wnioskój o wsparcie techniczne na miejscu',
            technical_personnel: 'Personel techniczny',
            contact_information: 'Kontakt',
            field_support_time: 'Data wsparcia',
            note_information: 'Uwagi',
        },
    },

    // 土耳其
    trTR: {
        newly_built: 'Ekle',
        fieldSupportNumber: 'Alan destek numarası',
        informationSlipNumber: 'Teknik  sorgulama',
        approvalStatus: 'onaylama durumu',
        state: 'Durum',
        selectApplicationDate: 'Uygulama tarihini seçin',
        toBeProcessed: 'İşlenecek',
        processed: 'İşlenmiş',
        initiated: 'Başlatılmış',
        applicationDepartment: 'Müracat departmanı',
        applicant: 'Müracat eden',
        reasonApplication: 'Müracat etme sebebi',
        applicationTime: 'Müracat zamanı',
        view: {
            application_department: 'Müracat  departmanı',
            application_person: 'Sorumlu kişi',
            responsible_person: 'Uygulama kişi',
            associated_enquiry_number: 'Ortak sorgulama numarası',
            reason_application: 'Uygulama sebebi',
            description: 'Açıklama(Dahili uygulama imzalama sayfası)',
            file_name: 'Dosya adı',
            size: 'Ölçü( MB)',
            uploader: 'Yükleyici',
            upload_time: 'Yükleme vakti',
            view: 'Kontrol',
            download: 'İndir',
            support_department: 'Destek departmanı',
            technical_personnel: 'Tehnik personel',
            contact_information: 'Kontakt bilgisi',
            field_support_information: 'Alan desteği bilgileri',
            remarks: 'Yorumlar',
            apply: 'Uygula',
            application_information: 'Uygulama bilgisi',
            field_support_personnel: 'İnternet destek personeli',
            approval_record: 'Onay geçmişi',
            submit_an_application: 'Müracat gönder',
            refuse: 'Geri çevirme',
            agree: 'Kabul etme',
            apply_for: 'Müracat etme',
            field_support: 'Alan desteği',
            reason_for_rejection: 'Geri çevirme nedeni',
        },
        dialog: {
            on_site_application_details: 'Yerde uygulama detayları',
            on_site_application: 'İnternet üstünden müracat etme',
            reason_for_rejection: 'Geri çevirme nedeni',
            field_support_application: 'İnternet  üstünden teknik destek talebi',
            technical_personnel: 'Tehnik personel',
            contact_information: 'Kontakt Telefonu',
            field_support_time: 'İnternet üstünden teknik destek zamanı',
            note_information: 'Not bilgisi',
        },
    },
    // 捷克语
    csCZ: {
        newly_built: 'Přidat',
        fieldSupportNumber: 'Číslo podpory',
        informationSlipNumber: 'Technický dotaz č.',
        approvalStatus: 'Stav schválení',
        state: 'Stav',
        selectApplicationDate: 'Vyberte datum aplikace',
        toBeProcessed: 'Ke zpracování',
        processed: 'Zpracováno',
        initiated: 'Zahájeno',
        applicationDepartment: 'Oddělení žádostí',
        applicant: 'Žadatel',
        reasonApplication: 'Důvod žádosti',
        applicationTime: 'Čas žádosti',
        view: {
            application_department: 'Oddělení žádostí',
            application_person: 'Odpovědná osoba',
            responsible_person: 'Aplikační osoba',
            associated_enquiry_number: 'Číslo souvisejícího dotazu',
            reason_application: 'Důvod aplikace',
            description: 'Popis (interní podpisový list aplikace)',
            file_name: 'Název souboru',
            size: 'Velikost (MB)',
            uploader: 'Uploader',
            upload_time: 'Čas nahrání',
            view: 'Kontrola',
            download: 'Stažení',
            support_department: 'Oddělení podpory',
            technical_personnel: 'Technický personál',
            contact_information: 'Kontaktní informace',
            field_support_information: 'Informace o podpoře v terénu',
            remarks: 'Poznámky',
            apply: 'Aplikovat',
            application_information: 'Informace o aplikaci',
            field_support_personnel: 'Personál podpory na místě',
            approval_record: 'Historie schválení',
            submit_an_application: 'Odešlete žádost',
            refuse: 'Odmítnout',
            agree: 'Souhlasit',
            apply_for: 'Žádat o',
            field_support: 'Podpora na místě',
            reason_for_rejection: 'Důvod zamítnutí',
        },
        dialog: {
            on_site_application_details: 'On-site application details',
            on_site_application: 'Žádost na místě',
            reason_for_rejection: 'Důvod zamítnutí',
            field_support_application: 'požádat o technickou podporu na místě',
            technical_personnel: 'Technický personál',
            contact_information: 'Kontaktní telefon',
            field_support_time: 'Doba podpory na místě',
            note_information: 'Informace o poznámkách',
        },
    },
    // 匈牙利语
    huHU: {
        newly_built: 'Hozzáadás',
        fieldSupportNumber: 'Helyszíni támogatás száma',
        informationSlipNumber: 'Műszaki érdeklődés sz.',
        approvalStatus: 'Jóváhagyás állapota',
        state: 'Állapot',
        selectApplicationDate: 'Válassza ki a kérvényezés dátumát',
        toBeProcessed: 'Feldolgozandó',
        processed: 'Feldolgozott',
        initiated: 'Kezdeményezett',
        applicationDepartment: 'Kérvényező részleg',
        applicant: 'Kérvényező',
        reasonApplication: 'Kérvényezés oka',
        applicationTime: 'Kérvényezés ideje',
        view: {
            application_department: 'Kérvényező részleg',
            application_person: 'Kérvényező',
            responsible_person: 'Felelős személy',
            associated_enquiry_number: 'Társított érdeklődés száma',
            reason_application: 'Kérvényezés oka',
            description: 'Leírás (belső kérvényezés aláíróív)',
            file_name: 'Fájlnév',
            size: 'Méret (MB)',
            uploader: 'Feltöltő',
            upload_time: 'Feltöltési idő',
            view: 'Ellenőrzés',
            download: 'Letöltés',
            support_department: 'Támogató részleg',
            technical_personnel: 'Műszaki személyzet',
            contact_information: 'Elérhetőségek',
            field_support_information: 'Helyszíni támogatási információ',
            remarks: 'Megjegyzések',
            apply: 'Alkalmazás',
            application_information: 'Kérvényezési információ',
            field_support_personnel: 'Helyszíni támogató személyzet',
            approval_record: 'Kérelmezési előzmények',
            submit_an_application: 'Kérelem beküldése',
            refuse: 'Elutasítás',
            agree: 'Beleegyezés',
            apply_for: 'Kérelem tárgya',
            field_support: 'Helyszíni támogatás',
            reason_for_rejection: 'Elutasítás oka',
        },
        dialog: {
            on_site_application_details: 'Helyszíni kérelem részletei',
            on_site_application: 'Helyszíni kérelem',
            reason_for_rejection: 'Elutasítás oka',
            field_support_application: 'Helyszíni műszaki támogatás kérelmezése',
            technical_personnel: 'Műszaki személyzet',
            contact_information: 'Kapcsolattartó telefonszám',
            field_support_time: 'Helyszíni támogatás időpontja',
            note_information: 'Megjegyzés adatai',
        },
    },
    // 俄罗斯语
    ruRU: {
        newly_built: 'Добавить',
        fieldSupportNumber: 'Номер поддержки на месте',
        informationSlipNumber: 'Технический запрос #',
        approvalStatus: 'Статус одобрения',
        state: 'Состояние',
        selectApplicationDate: 'Выберите дату подачи заявки',
        toBeProcessed: 'Обработано',
        processed: 'Обработано',
        initiated: 'Инициировано',
        applicationDepartment: 'Отдел заявки',
        applicant: 'Заявитель',
        reasonApplication: 'Причина заявки',
        applicationTime: 'Время подачи заявки',
        view: {
            application_department: 'Отдел заявки',
            application_person: 'Ответственное лицо',
            responsible_person: 'Заявитель',
            associated_enquiry_number: 'Связанный номер запроса',
            reason_application: 'Причина заявки',
            description: 'Описание (внутренний лист подписей заявки)',
            file_name: 'Название файла',
            size: 'Размер (МБ)',
            uploader: 'Загрузил',
            upload_time: 'Время загрузки',
            view: 'Проверка',
            download: 'Скачать',
            support_department: 'Поддерживающий отдел',
            technical_personnel: 'Технический персонал',
            contact_information: 'Контактная информация',
            field_support_information: 'Информация о поддержке на месте',
            remarks: 'Примечания',
            apply: 'Подать заявку',
            application_information: 'Информация о заявке',
            field_support_personnel: 'Персонал поддержки на месте',
            approval_record: 'История одобрений',
            submit_an_application: 'Подать заявку',
            refuse: 'Отказать',
            agree: 'Согласовать',
            apply_for: 'Подать заявку на',
            field_support: 'Поддержка на месте',
            reason_for_rejection: 'Причина отказа',
        },
        dialog: {
            on_site_application_details: 'Детали заявки на месте',
            on_site_application: 'Заявка на место',
            reason_for_rejection: 'Причина отказа',
            field_support_application: 'Подать заявку на техническую поддержку на месте',
            technical_personnel: 'Технический персонал',
            contact_information: 'Телефон для связи',
            field_support_time: 'Время поддержки на месте',
            note_information: 'Примечание',
        },
    },
};

import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 葡萄牙
export default {
    license: {
        powered_by: 'Alimentado por SMIL',
    },
    auth: {
        login: 'Login',
        username: 'Nome de usuário',
        username_placeholder: 'Digite o nome de usuário',
        password: 'Senha',
        password_placeholder: 'Digite a senha',
        code: 'Código',
        code_placeholder: 'Digite o código',
        logout: 'Sair',
        logout_success: 'Logout bem-sucedido',
        day: 'Dia',
        password_expiration: 'A senha expirou, por favor, altere-a',
        expire: 'Resta até expirar a senha',
        conseils: 'Deve conter letras maiúsculas, letras minúsculas, números, caracteres especiais ({msg}), e ter entre 8-16 caracteres',
        brand_Selection: 'Seleção de marca',
        unread_notices: 'Notificações não lidas',
        new_cases: 'Novos casos',
        unanswered_cases: 'Casos não respondidos',
        overdue_cases: 'Casos em atraso',
    },
    button: {
        query: 'Consulta',
        editing_position: 'Editar cargo',
        show_theme_editor: 'Mostrar/Ocultar editor de tema',
        change_password: 'Alterar senha',
        old_password: 'Senha antiga',
        new_password: 'Nova senha',
        confirm_password: 'Confirmar senha',
        button: 'Botão',
        about: 'Sobre',
        back: 'Voltar',
        go: 'Ir',
        home: 'Página inicial',
        confirm: 'Confirmar',
        modify: 'Modificar',
        edit: 'Editar',
        new: 'Novo',
        cancel: 'Cancelar',
        close: 'Fechar',
        search: 'Buscar',
        reset: 'Redefinir',
        delete: 'Excluir',
        add: 'Adicionar',
        collapse: 'Recolher',
        expand: 'Expandir',
        download_template: 'Baixar modelo',
        batch_import: 'Importar em lote',
        import: 'Importar',
        export: 'Exportar',
        export_fail: 'Falha na exportação',
        detailed: 'Detalhado',
        details: 'Detalhes',
        download: 'Baixar',
        toView: 'Ver',
        toViews: 'Ver imagem',
        uploadAttachments: 'Enviar anexos',
        selectFile: 'Selecionar arquivo',
        fillingInstructions: 'Instruções de preenchimento',
        release: 'Publicar',
        release_recall: 'Recall de lançamento',
        save: 'Salvar',
        send: 'Enviar',
        tips: 'Dicas',
        send_back: 'Enviar de volta',
        examine: 'Examinar',
        void: 'Anular',
        more: 'Mais',
        refresh: 'Atualizar',
        selects: 'Por favor selecione',
        serial_number: 'Número de série',
        upload_progress: 'Progresso do upload',
        invalid: 'Inválido',
        confirm_number: 'Número de fatura confirmado:',
        empty_data: 'Limpar dados gerados',
        variance_details: 'Detalhes de variação',
        daily_record: 'Registro diário',
        operation_type: 'Tipo de operação',
        field_name: 'Nome do campo',
        edit_role: 'Editar função',
        user_role: 'Selecionar função',
        assignment_role: 'Função',
        post: 'Cargo',
        sending: 'Enviando',
        reset_password: 'Redefinir senha',
        display_or_not: 'Exibir ou não',
        document: 'Documento',
        specificTemplates: 'Modelos específicos',
        transferOrder: 'Ordem de transferência',
        forwarding: 'Encaminhamento',
        one_click_notification: 'PNotificação com um clique',
    },
    radio: {
        enabled: 'Habilitado',
        disabled: 'Desabilitado',
        catalogue: 'Catálogo',
        individual: 'Individual',
        all: 'Todos',
        yes: 'Sim',
        no: 'Não',
    },
    dropdown: {
        close_others: 'Fechar outros',
        close_right: 'Fechar à direita',
        close_left: 'Fechar à esquerda',
    },
    table: {
        action: 'Ação',
        explain: 'Explicação',
        fileName: 'Nome do anexo',
        size: 'Tamanho (MB)',
        uploader: 'Uploader',
        uploadTime: 'Data de envio',
        system: 'Sistema',
        forwarding_time: 'Tempo de Encaminhamento',
        forwarder: 'Forwarder',
        send_to: 'Enviar Para',
        mode: 'Modo',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/página`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} dados no total`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} veículos no total`,
    },
    confirm: {
        confirm_deletion: 'Confirmar exclusão?',
        confirm_void: 'Confirmar anulação?',
        confirm_release: 'Confirmar publicação?',
        ack: 'Confirmar',
        cancel: 'Cancelar',
        non_records: 'Por favor, selecione pelo menos um registro',
    },
    request: {
        failed_placeholder: 'Falha na solicitação, erro no servidor',
        success_placeholder: 'Solicitação bem sucedida',
        auth: {
            login_success: 'Login bem sucedido',
        },
        error: {
            '404': 'Caminho da solicitação não existe',
            '403': 'Permissão insuficiente',
        },
    },
    page: {
        global: {
            page_search: 'Pesquisa na página',
        },
        error: {
            not_found: 'Página não encontrada',
            not_found_des: 'Não há nada aqui',
            auth_failed: 'Permissão insuficiente',
            auth_failed_des: 'O acesso a esta página é proibido',
            file_format_error: 'Formato de arquivo anormal!',
            preview_failed: 'Pré-visualização falhou! Arquivo anormal!',
        },
        homepage: {
            homepage: homepage.homepage.ptBR,
        },
        system: {
            menu: system.menu.ptBR,
            dict: system.dict.ptBR,
            dept: system.dept.ptBR,
            role: system.role.ptBR,
            user: system.user.ptBR,
        },
        info: {
            announcement: Info.announcement.announcement.ptBR,
            document: Info.document.document.ptBR,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.ptBR,
                recallActivity: tech.quality.recallActivity.ptBR,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.ptBR,
            },
            management: {
                inquiryOd: tech.management.inquiry.ptBR,
                permission: tech.management.permission.ptBR,
                onSiteSupportHq: tech.management.onSiteSupport.ptBR,
                progHistoryHq: tech.management.progHistory.ptBR,
                salesRecordHq: tech.management.salesRecord.ptBR,
                vehicleInfoHq: tech.management.vehicleInfo.ptBR,
                claimHq: tech.management.claim.ptBR,
                filterHq: tech.management.filter.ptBR,
                newCarlssue: tech.management.newCarIssue.ptBR,
                newCarDefinition: tech.management.newCarDefinition.ptBR,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.ptBR,
                programmingHistoryInformationQuery: tech.management.programmingHistoryInformationQuery.ptBR,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.ptBR,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.ptBR,
        },
        statistics: statistics.inquiryStatistics.ptBR,
        systemClassifyA: statistics.systemClassifyA.ptBR,
        systemClassifyB: statistics.systemClassifyB.ptBR,
        engineerStatistics: statistics.engineerStatistics.ptBR,
        defectivePart: statistics.defectivePart.ptBR,
        replyInquiryStatistics: statistics.replyInquiryStatistics.ptBR,
        passRateStatistics: statistics.passRateStatistics.ptBR,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.ptBR,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.ptBR,
        customsClearance135Rate: statistics.customsClearance135Rate.ptBR,
        accumulatedClosureRate: statistics.accumulatedClosureRate.ptBR,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.ptBR,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.ptBR,
    },
    validation: {
        common: {
            required: 'Este item é obrigatório',
            numeric: 'Deve ser um número inteiro',
            decimal: 'Deve ser um número',
            positive_interger: 'Deve ser um número inteiro positivo',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Até${named('number')}dígito`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Não é possível exceder${named('number')}palavra`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Exceder o número máximo de caracteres(${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Não inferior a${named('number')}palavra`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Deve ser${named('number')}palavra`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Não pode ser maior do que${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) => `Não pode ser inferior a${named('number')}`,
            up_to_2_decimal_places: 'Até 2 casas decimais',
            retain_up: 'Insira até 10 inteiros positivos e mantenha até 2 casas decimais',
            retain_ups: 'Insira até 11 inteiros positivos e mantenha até 4 casas decimais',
            input_number: 'Por favor indique um número',
            only_number_a: 'Só podem ser introduzidas letras ou números',
            translate: 'Só podem ser letras',
            positive_integer: 'Indique pelo menos um campo',
            at_least_one: 'Preencher pelo menos um',
        },
        tip: {
            incomplete_information: 'Informações incompletas preenchidas',
        },
    },
    import: {
        template_file: 'Arquivo de modelo',
        browse: 'Navegar',
        download_template: 'Baixar modelo',
        no_file_selected: 'Nenhum arquivo selecionado',
        incorrect_file_type: 'Tipo de arquivo incorreto',
        exceed: 'Exceder',
        upload_success: 'Upload bem sucedido',
        upload_fail: 'Falha no upload',
        again_upload: 'Apenas arquivos de imagem nos formatos jpg, png, bmp e gif são suportados. Por favor, faça o upload novamente',
        attachmentUpload: 'Upload de anexo',
        supportedFormats: 'Formatos suportados',
        preview: 'Visualizar',
    },
    export: {
        noDataToExport: 'Sem dados a exportar',
    },
    select: {
        all: 'Inteiro',
    },
    checkbox: {
        select_all: 'Seleccionar Tudo',
    },
    dialog: {
        error_message: 'Mensagem de erro',
    },
    ymd: {
        day: 'dia',
        month: 'mês',
        year: 'ano',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    },
};

export default {
    // 中
    zhCN: {
        activityNumber: '活动编号',
        activityName: '活动名称',
        activityStatus: '活动状态',
        activityDate: '选择活动日期',
        startDate: '活动开始日期',
        endDate: '活动结束日期',
        remarks: '备注',
        activity_details: '活动详情',
        activity_addition: '活动新增',
        confirm_enable: '确认启用',
        confirm_outage: '确认停用',
    },
    // 英
    enUS: {
        activityNumber: 'Campaign code',
        activityName: 'Campaign name',
        activityStatus: 'Status',
        activityDate: 'Activity date',
        startDate: 'Start date',
        endDate: 'End date',
        remarks: 'Remarks',
        activity_details: 'Activity details',
        activity_addition: 'Activity Addition',
        confirm_enable: 'Confirm enable',
        confirm_outage: 'Confirm outage',
    },
    // 德
    deDE: {
        activityNumber: 'Kampagnencode',
        activityName: 'Kampagnenname',
        activityStatus: 'Status',
        activityDate: 'Aktivitätsdatum',
        startDate: 'Startdatum',
        endDate: 'Enddatum',
        remarks: 'Bemerkungen',
        activity_details: 'Aktivitätsdetails',
        activity_addition: 'Aktivitätszusatz',
        confirm_enable: 'Bestätigung aktivieren',
        confirm_outage: 'Bestätigung der Störung',
    },
    // 法
    frFR: {
        activityNumber: 'Code de campagne',
        activityName: 'Nom de campagne',
        activityStatus: 'Statut',
        activityDate: 'Date d\'activité',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        remarks: 'Remarque',
        activity_details: 'Détails de l\'activité',
        activity_addition: 'Ajout d\'activité',
        confirm_enable: 'Confirmer l\'activation',
        confirm_outage: 'Confirmer la panne',
    },
    // 意大利
    itIT: {
        activityNumber: 'Codice campagna',
        activityName: 'Nome della campagna',
        activityStatus: 'Stato',
        activityDate: 'Data di attività',
        startDate: 'Data di inizio',
        endDate: 'Data di fine',
        remarks: 'Osservazioni',
        activity_details: 'Dettagli dell\'attività',
        activity_addition: 'Aggiunta di attività',
        confirm_enable: 'Conferma abilitazione',
        confirm_outage: 'Conferma interruzione',
    },
    // 荷兰
    nlNL: {
        activityNumber: 'Campagnecode',
        activityName: 'Campagnenaam',
        activityStatus: 'Status',
        activityDate: 'Activiteitsdatum',
        startDate: 'Startdatum',
        endDate: 'Einddatum',
        remarks: 'Opmerkingen',
        activity_details: 'Activiteitsdetails',
        activity_addition: 'Activiteit toevoeging',
        confirm_enable: 'Bevestig inschakelen',
        confirm_outage: 'Bevestig uitschakelen',
    },
    // 越南
    viVN: {
        activityNumber: 'Mã chiến dịch',
        activityName: 'Tên chiến dịch',
        activityStatus: 'Trạng thái',
        activityDate: 'Chọn ngày hoạt động',
        startDate: 'Ngày bắt đầu',
        endDate: 'Ngày kết thúc',
        remarks: 'Ghi chú',
        activity_details: 'Chi tiết hoạt động',
        activity_addition: 'Thêm hoạt động',
        confirm_enable: 'Xác nhận sử dụng',
        confirm_outage: 'Xác nhận tắt',
    },
    // 西班牙
    esES: {
        activityNumber: 'Número de actividad',
        activityName: 'Nombre de la actividad',
        activityStatus: 'Estado de la actividad',
        activityDate: 'Seleccione la fecha de la actividad',
        startDate: 'Fecha de inicio de la actividad',
        endDate: 'Fecha de finalización de la actividad',
        remarks: 'Observaciones',
        activity_details: 'Detalles de la actividad',
        activity_addition: 'Agregar actividad',
        confirm_enable: 'Confirmar habilitación',
        confirm_outage: 'Confirmar deshabilitación',
    },
    // 葡萄牙
    ptBR: {
        activityNumber: 'Número da atividade',
        activityName: 'Nome da atividade',
        activityStatus: 'Status da atividade',
        activityDate: 'Selecione a data da atividade',
        startDate: 'Data de início da atividade',
        endDate: 'Data de término da atividade',
        remarks: 'Observações',
        activity_details: 'Detalhes da atividade',
        activity_addition: 'Adição de atividade',
        confirm_enable: 'Confirmar habilitação',
        confirm_outage: 'Confirmar desativação',
    },
    // 波兰
    plPL: {
        activityNumber: 'Kod kampanii',
        activityName: 'Nazwa kampanii',
        activityStatus: 'Status kampanii',
        activityDate: 'Wybierz datę',
        startDate: 'Data rozpoczęcia',
        endDate: 'Data zakończenia',
        remarks: 'Uwagi',
        activity_details: 'Szczegóły wydarzenia',
        activity_addition: 'Dodatek do wydarzenia',
        confirm_enable: 'Potwierdź uruchomienie',
        confirm_outage: 'Potwierdź zatrzymanie',
    },
    // 土耳其
    trTR: {
        activityNumber: 'Kampanya kodu',
        activityName: 'Kampanya adı',
        activityStatus: 'Durum',
        activityDate: 'Etkinlik Zamanı',
        startDate: 'Başlangıç tarihi',
        endDate: 'Bitiş tarihi',
        remarks: 'Yorumlar',
        activity_details: 'Etkinlik detayları',
        activity_addition: 'Etkinlik Eklentisi',
        confirm_enable: 'Etkinleştirmeyi doğrula',
        confirm_outage: 'Devredışı bırakmayı onaylayın',
    },
    // 捷克语
    csCZ: {
        activityNumber: 'Kód kampaně',
        activityName: 'Název kampaně',
        activityStatus: 'Status',
        activityDate: 'Datum činnosti',
        startDate: 'Datum zahájení',
        endDate: 'Datum ukončení',
        remarks: 'Poznámky',
        activity_details: 'Podrobnosti o aktivitě',
        activity_addition: 'Přidání činnosti',
        confirm_enable: 'Potvrdit povolení',
        confirm_outage: 'Potvrzení výpadku',
    },
    // 匈牙利语翻译
    huHU: {
        activityNumber: 'Kampánykód',
        activityName: 'Kampánynév',
        activityStatus: 'Állapot',
        activityDate: 'Tevékenység dátuma',
        startDate: 'Kezdő dátum',
        endDate: 'Befejező dátum',
        remarks: 'Megjegyzések',
        activity_details: 'Tevékenység részletei',
        activity_addition: 'Tevékenység hozzáadása',
        confirm_enable: 'Engedélyezés megerősítése',
        confirm_outage: 'Hiány megerősítése',
    },
    // 俄罗斯语
    ruRU: {
        activityNumber: 'Код кампании',
        activityName: 'Название кампании',
        activityStatus: 'Статус',
        activityDate: 'Дата активности',
        startDate: 'Дата начала',
        endDate: 'Дата окончания',
        remarks: 'Примечания',
        activity_details: 'Детали активности',
        activity_addition: 'Добавление активности',
        confirm_enable: 'Подтвердить включение',
        confirm_outage: 'Подтвердить отключение',
    },
};

export default {
    // 中
    zhCN: {
        fill_in_at_least_one_query_condition: '至少填写一个查询条件',
        query_time: '查询时间',
        reporting_organization_name: '上报组织名称',
        full_query: '全量查询',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        number_of_cases: '案例总数',
        wait_for_superiors_to_reply_cases: '等待上级答复案例数量',
        percentage_wait_for_superiors_to_reply_cases: '等待上级答复案例百分比',
        superior_replied_casess: '上级已答复案例数量',
        percentage_superior_replied_casess: '上级已答复案例数量百分比',
        closed_cases: '已结案案例数量',
        percentage_closed_cases: '已结案数量百分比',
    },
    // 英
    enUS: {
        fill_in_at_least_one_query_condition: 'Fill in at least one query condition',
        query_time: 'Query time',
        reporting_organization_name: 'Reporting organization name',
        full_query: 'Full query',
        organization_code: 'Organization code',
        organization_name: 'Organization name',
        country: 'Country',
        number_of_cases: 'Number of cases',
        wait_for_superiors_to_reply_cases: 'Wait for superiors to reply cases',
        percentage_wait_for_superiors_to_reply_cases: 'Wait for superiors to reply cases (%)',
        superior_replied_casess: 'Superior replied cases',
        percentage_superior_replied_casess: 'Superior replied cases (%)',
        closed_cases: 'Closed cases',
        percentage_closed_cases: 'Closed cases (%)',
    },
    // 德
    deDE: {
        fill_in_at_least_one_query_condition: 'Füllen Sie mindestens eine Abfragebedingung aus',
        query_time: 'Abfragezeit',
        reporting_organization_name: 'Name der meldenden Organisation',
        full_query: 'Vollständige Abfrage',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        number_of_cases: 'Gesamtzahl der Fälle',
        wait_for_superiors_to_reply_cases: 'Fälle, auf Antwort der Vorgesetzten warten',
        percentage_wait_for_superiors_to_reply_cases:
            'Fälle, auf Antwort der Vorgesetzten warten (%)',
        superior_replied_casess: 'Vorgesetzte beantwortete Fälle',
        percentage_superior_replied_casess: 'Vorgesetzte beantwortete Fälle (%)',
        closed_cases: 'Geschlossene Fälle',
        percentage_closed_cases: 'Geschlossene Fälle (%)',
    },
    // 法
    frFR: {
        fill_in_at_least_one_query_condition: 'Remplissez au moins une condition de demande',
        query_time: 'Temps de requête',
        reporting_organization_name: 'Nom de l\'organisation de rapport',
        full_query: 'Requête complète',
        organization_code: 'Code organisation',
        organization_name: 'Nom organisation',
        country: 'Pays',
        number_of_cases: 'Nombre de cas',
        wait_for_superiors_to_reply_cases: 'Cas en attente de retour',
        percentage_wait_for_superiors_to_reply_cases: 'Cas en attente de retour (%)',
        superior_replied_casess: 'Cas répondus',
        percentage_superior_replied_casess: 'Cas répondus (%)',
        closed_cases: 'Cas clôturés',
        percentage_closed_cases: 'Cas clôturés (%)',
    },
    // 意大利
    itIT: {
        fill_in_at_least_one_query_condition: 'Compilare almeno una condizione di query',
        query_time: 'Tempo di Query',
        reporting_organization_name: 'Nome Organizzazione Segnalante',
        full_query: 'Query Completa',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        country: 'Paese',
        number_of_cases: 'Numero totale di casi',
        wait_for_superiors_to_reply_cases:
            'Numero di Casi in Attesa di Risposta da Parte dei Superiori',
        percentage_wait_for_superiors_to_reply_cases:
            'Percentuale di Casi in Attesa di Risposta da Parte dei Superiori',
        superior_replied_casess: 'Numero di Casi a cui i Superiori hanno Risposto',
        percentage_superior_replied_casess: 'Percentuale di Casi a cui i Superiori hanno Risposto',
        closed_cases: 'Numero di Casi Chiusi',
        percentage_closed_cases: 'Percentuale di Casi Chiusi',
    },
    // 荷兰
    nlNL: {
        fill_in_at_least_one_query_condition: 'Vul ten minste één query voorwaarde in',
        query_time: 'Query time',
        reporting_organization_name: 'Rapporterende organisatienaam',
        full_query: 'Volledige query',
        organization_code: 'Organisatie code',
        organization_name: 'Organisatie naam',
        country: 'Land',
        number_of_cases: 'Totaal aantal gevallen',
        wait_for_superiors_to_reply_cases: 'Wachten op antwoord van superieuren',
        percentage_wait_for_superiors_to_reply_cases: 'Wachten op antwoord van superieuren(%)',
        superior_replied_casess: 'Superieure beantwoorde gevallen',
        percentage_superior_replied_casess: 'Superieure beantwoorde gevallen(%)',
        closed_cases: 'Gesloten gevallen',
        percentage_closed_cases: 'Gesloten gevallen(%)',
    },
    // 越南
    viVN: {
        fill_in_at_least_one_query_condition: 'Ít nhất điền một điều kiện tìm kiếm',
        query_time: 'Thời gian truy vấn',
        reporting_organization_name: 'Tên tổ chức báo cáo',
        full_query: 'Tra cứu toàn bộ',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        country: 'Quốc gia',
        number_of_cases: 'Số lượng trường hợp',
        wait_for_superiors_to_reply_cases: 'Các trường hợp chờ cấp trên trả lời',
        percentage_wait_for_superiors_to_reply_cases:
            'Tỉ lệ các trường hợp chờ cấp trên trả lời (%)',
        superior_replied_casess: 'Các trường hợp cấp trên đã trả lời',
        percentage_superior_replied_casess: 'Tỉ lệ các trường hợp cấp trên đã trả lời (%)',
        closed_cases: 'Các trường hợp đã đóng',
        percentage_closed_cases: 'Tỉ lệ các trường hợp đã đóng (%)',
    },
    // 西班牙
    esES: {
        fill_in_at_least_one_query_condition: 'Al menos complete un criterio de búsqueda',
        query_time: 'Consulta de tiempo',
        reporting_organization_name: 'Nombre de la organización informante',
        full_query: 'Consulta completa',
        organization_code: 'Código de la organización',
        organization_name: 'Nombre de la organización',
        country: 'País',
        number_of_cases: 'Número total de casos',
        wait_for_superiors_to_reply_cases:
            'Número de casos pendientes de respuesta de los superiores',
        percentage_wait_for_superiors_to_reply_cases:
            'Porcentaje de casos pendientes de respuesta de los superiores',
        superior_replied_casess: 'Número de casos respondidos por los superiores',
        percentage_superior_replied_casess: 'Porcentaje de casos respondidos por los superiores',
        closed_cases: 'Número de casos cerrados',
        percentage_closed_cases: 'Porcentaje de casos cerrados',
    },
    // 葡萄牙
    ptBR: {
        fill_in_at_least_one_query_condition: 'Preencha pelo menos uma condição de consulta',
        query_time: 'Tempo de consulta',
        reporting_organization_name: 'Nome da organização de reportagem',
        full_query: 'Consulta completa',
        organization_code: 'Código da organização',
        organization_name: 'Nome da organização',
        country: 'País',
        number_of_cases: 'Número total de casos',
        wait_for_superiors_to_reply_cases: 'Número de casos aguardando resposta dos superiores',
        percentage_wait_for_superiors_to_reply_cases:
            'Porcentagem de casos aguardando resposta dos superiores',
        superior_replied_casess: 'Número de casos respondidos pelos superiores',
        percentage_superior_replied_casess: 'Porcentagem de casos respondidos pelos superiores',
        closed_cases: 'Número de casos encerrados',
        percentage_closed_cases: 'Porcentagem de casos encerrados',
    },
    // 波兰
    plPL: {
        fill_in_at_least_one_query_condition: 'Wypełnij co najmniej jeden warunek zapytania',
        query_time: 'Data zapytania',
        reporting_organization_name: 'Nazwa organizacji raportującej',
        full_query: 'Pełne zapytanie',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        country: 'Kraj',
        number_of_cases: 'Łączna liczba spraw',
        wait_for_superiors_to_reply_cases: 'Liczba spraw oczekujących na odpowiedź przełożonego',
        percentage_wait_for_superiors_to_reply_cases:
            'Liczba spraw oczekujących na odpowiedź przełożonego (%)',
        superior_replied_casess: 'Odpowiedzi udzielone przez przełożonego',
        percentage_superior_replied_casess: 'Odpowiedzi udzielone przez przełożonego (%)',
        closed_cases: 'Sprawy zamknięte',
        percentage_closed_cases: 'Sprawy zamknięte (%)',
    },
    // 土耳其
    trTR: {
        fill_in_at_least_one_query_condition: 'En azından bir soru durumunu doldur',
        query_time: 'Sorgulama zamanı',
        reporting_organization_name: 'Bildirim organizasyonu adı',
        full_query: 'Tam sorgulama',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        country: 'Ülke',
        number_of_cases: 'Dosya sayısı',
        wait_for_superiors_to_reply_cases: 'Uzman kişinin dosyayı cevaplaması bekleniyor',
        percentage_wait_for_superiors_to_reply_cases:
            'Uzman kişinin dosyayı cevaplaması bekleniyor (%)',
        superior_replied_casess: 'Uzmanın cevapladığı dosyalar',
        percentage_superior_replied_casess: 'Uzmanın cevapladığı dosyalar  (%)',
        closed_cases: 'Kapatılmış dosyalar',
        percentage_closed_cases: 'Kapatılmış davalar (%)',
    },
    // 捷克语
    csCZ: {
        fill_in_at_least_one_query_condition: 'Vyplňte alespoň jednu podmínku dotazu',
        query_time: 'Čas dotazu',
        reporting_organization_name: 'Název ohlašující společnosti',
        full_query: 'Úplný dotaz',
        organization_code: 'Kód společnosti',
        organization_name: 'Název společnosti',
        country: 'Země',
        number_of_cases: 'Počet případů',
        wait_for_superiors_to_reply_cases: 'Wait for superiors to reply cases',
        percentage_wait_for_superiors_to_reply_cases: 'Wait for superiors to reply cases (%)',
        superior_replied_cases: 'Nadřízený odpověděl případy',
        percentage_superior_replied_cases: 'Nadřízený odpověděl případy (%)',
        closed_cases: 'Uzavřené případy',
        percentage_closed_cases: 'Uzavřené případy (%)',
    },
    // 匈牙利语
    huHU: {
        fill_in_at_least_one_query_condition: 'Töltsön ki legalább egy lekérdezési feltételt',
        query_time: 'Lekérdezési idő',
        reporting_organization_name: 'Jelentő szervezet neve',
        full_query: 'Teljes lekérdezés',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        country: 'Ország',
        number_of_cases: 'Esetek száma',
        wait_for_superiors_to_reply_cases: 'Felettesek reakciójára váró esetek',
        percentage_wait_for_superiors_to_reply_cases: 'Felettesek reakciójára váró esetek (%)',
        superior_replied_casess: 'Felettes által megválaszolt esetek',
        percentage_superior_replied_casess: 'Felettes által megválaszolt esetek (%)',
        closed_cases: 'Lezárt esetek',
        percentage_closed_cases: 'Lezárt esetek (%)',
    },
    // 俄罗斯语
    ruRU: {
        fill_in_at_least_one_query_condition: 'Заполните хотя бы одно условие запроса',
        query_time: 'Время запроса',
        reporting_organization_name: 'Название отчетной организации',
        full_query: 'Полный запрос',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        country: 'Страна',
        number_of_cases: 'Общее количество случаев',
        wait_for_superiors_to_reply_cases: 'Количество случаев, ожидающих ответа от руководства',
        percentage_wait_for_superiors_to_reply_cases:
            'Процент случаев, ожидающих ответа от руководства',
        superior_replied_casess: 'Количество случаев, на которые ответил руководитель',
        percentage_superior_replied_casess: 'Процент случаев, на которые ответил руководитель',
        closed_cases: 'Количество закрытых случаев',
        percentage_closed_cases: 'Процент закрытых случаев',
    },
};

export default {
    // 中
    zhCN: {
        year: '年',
        month: '月',
        category_name: '分类名称',
        number_of_cases: '案例数量',
        percentage_of_cases: '案例占比',
        reporting_organization_name: '上报组织名称',
        full_query: '全量查询',
        day: '天',
    },
    // 英
    enUS: {
        year: 'Year',
        month: 'Month',
        category_name: 'Category name',
        number_of_cases: 'Total cases',
        percentage_of_cases: 'Percentage of cases',
        reporting_organization_name: 'Reporting organization name',
        full_query: 'Full query',
        day: 'Day',
    },
    // 德
    deDE: {
        year: 'Jahr',
        month: 'Monat',
        category_name: 'Kategoriename',
        number_of_cases: 'Anzahl der Fälle',
        percentage_of_cases: 'Prozentsatz der Fälle',
        reporting_organization_name: 'Name der meldenden Organisation',
        full_query: 'Vollständige Abfrage',
        day: 'Tag',
    },
    // 法
    frFR: {
        year: 'Année',
        month: 'Mois',
        category_name: 'Nom de catégorie',
        number_of_cases: 'Total cas',
        percentage_of_cases: 'Pourcentage de cas',
        reporting_organization_name: 'Nom de l\'organisation de signalement',
        full_query: 'Requête complète',
        day: 'Jours',
    },
    // 意大利
    itIT: {
        year: 'Anno',
        month: 'Mese',
        category_name: 'Nome Categoria',
        number_of_cases: 'Numero di Casi',
        percentage_of_cases: 'Percentuale di Casi',
        reporting_organization_name: 'Nome Organizzazione Segnalante',
        full_query: 'Query Completa',
        day: 'Giorno',
    },
    // 荷兰
    nlNL: {
        year: 'Jaar',
        month: 'Maand',
        category_name: 'Categorie naam',
        number_of_cases: 'Aantal gevallen',
        percentage_of_cases: 'Percentage van gevallen',
        reporting_organization_name: 'Meldende organisatie naam',
        full_query: 'Volledige query',
        day: 'Dag',
    },
    // 越南
    viVN: {
        year: 'Năm',
        month: 'Tháng',
        category_name: 'Tên danh mục',
        number_of_cases: 'Tổng số trường hợp',
        percentage_of_cases: 'Phần trăm số trường hợp',
        reporting_organization_name: 'Tên tổ chức báo cáo',
        full_query: 'Tra cứu toàn bộ',
        day: 'Ngày',
    },
    // 西班牙
    esES: {
        year: 'Año',
        month: 'Mes',
        category_name: 'Nombre de la categoría',
        number_of_cases: 'Número de casos',
        percentage_of_cases: 'Porcentaje de casos',
        reporting_organization_name: 'Nombre de la organización informante',
        full_query: 'Consulta completa',
        day: 'Días',
    },
    // 葡萄牙
    ptBR: {
        year: 'Ano',
        month: 'Mês',
        category_name: 'Nome da categoria',
        number_of_cases: 'Número de casos',
        percentage_of_cases: 'Percentagem de casos',
        reporting_organization_name: 'Nome da organização de relatório',
        full_query: 'Consulta completa',
        day: 'Dia',
    },
    // 波兰
    plPL: {
        year: 'Rok',
        month: 'Mesiąc',
        category_name: 'Nazwa kategorii',
        number_of_cases: 'Liczba spraw',
        percentage_of_cases: 'Odsetek spraw',
        reporting_organization_name: 'Nazwa organizacji raportującej',
        full_query: 'Pełne zapytanie',
        day: 'Dzień',
    },
    // 土耳其
    trTR: {
        year: 'Yıl',
        month: 'Ay',
        category_name: 'Kategori adı',
        number_of_cases: 'Toplam Dosyalar',
        percentage_of_cases: 'Dosyaların yüzdesi',
        reporting_organization_name: 'Bildirim organizasyonu adı',
        full_query: 'Tam sorgulama',
        day: 'gün',
    },
    // 捷克语
    csCZ: {
        year: 'Rok',
        month: 'Měsíc',
        category_name: 'Název Kategorie',
        number_of_cases: 'Celkový počet případů',
        percentage_of_cases: 'Procento případů',
        reporting_organization_name: 'Název ohlašující společnosti',
        full_query: 'Úplný dotaz',
        day: 'Den',
    },
    // 匈牙利语
    huHU: {
        year: 'Év',
        month: 'Hónap',
        category_name: 'Kategória neve',
        number_of_cases: 'Összes eset',
        percentage_of_cases: 'Esetek százaléka',
        reporting_organization_name: 'Jelentő szervezet neve',
        full_query: 'Teljes lekérdezés',
        day: 'Nap',
    },
    // 俄罗斯语
    ruRU: {
        year: 'Год',
        month: 'Месяц',
        category_name: 'Название категории',
        number_of_cases: 'Количество случаев',
        percentage_of_cases: 'Процент случаев',
        reporting_organization_name: 'Название отчетной организации',
        full_query: 'Полный запрос',
        day: 'День',
    },
};

export default {
    // 中
    zhCN: {
        sales_order: '销售单',
        pack_no: '箱单号',
        pack_status: '箱单状态',
        history: '历史',
        order_confirm: '订单确认',
        order_submit: '订单提交',
        order_cancel: '订单取消',
        order_reviewed_today: '今日已审核订单',
        anji_logistics: '安吉物流',
        fund_review: '资金审核',
        pack: '箱单包装',
        bo_cancel: 'BO取消',
        sale_orders_query: '销售单查询',
        notice_history_query: '通知历史查询',
        is_read: '已读/未读',
        is_read_y: '已读',
        is_read_n: '未读',
        notice: '通知',
        notice_type: '通知类型',
        notice_time: '通知日期',
        notice_msg: '通知消息',
        operation_change_review: '改运审核',
        review_returned: '审核退回',
        bo_cancel_to_review: 'BO取消待审核',
    },
    // 英
    enUS: {
        sales_order: 'Sales order',
        pack_no: 'Pack no',
        pack_status: 'Pack status',
        history: 'History',
        order_confirm: 'Order confirm',
        order_submit: 'Order submit',
        order_cancel: 'Order cancel',
        order_reviewed_today: 'Order reviewed today',
        anji_logistics: 'Anji logistics',
        fund_review: 'Fund review',
        pack: 'Pack',
        bo_cancel: 'BO cancel',
        sale_orders_query: 'Sales orders query',
        notice_history_query: 'Notice history query',
        is_read: 'Read/Unread',
        is_read_y: 'Read',
        is_read_n: 'Unread',
        notice: 'Notice',
        notice_type: 'Notice type',
        notice_time: 'Notice time',
        notice_msg: 'Notice message',
        operation_change_review: 'Operation change review',
        review_returned: 'Review returned',
        bo_cancel_to_review: 'BO cancel to review',
    },
    // 德
    deDE: {
        sales_order: 'Verkaufsauftrag',
        pack_no: 'Packnummer',
        pack_status: 'Packstatus',
        history: 'Geschichte',
        order_confirm: 'Bestellung bestätigen',
        order_submit: 'Bestellung abschließen',
        order_cancel: 'Bestellung stornieren',
        order_reviewed_today: 'Heute überprüfte Bestellungen',
        anji_logistics: 'Anji Logistik',
        fund_review: 'Fondsprüfung',
        pack: 'Packen',
        bo_cancel: 'BO-Stornierung',
        sale_orders_query: 'Abfrage der Verkaufsaufträge',
        notice_history_query: 'Abfrage des Verlaufes der Benachrichtigungen',
        is_read: 'Gelesen/Ungelesen',
        is_read_y: 'Gelesen',
        is_read_n: 'Ungelesen',
        notice: 'Notiz',
        notice_type: 'Benachrichtigungstyp',
        notice_time: 'Benachrichtigungszeit',
        notice_msg: 'Benachrichtigungsnachricht',
        operation_change_review: 'Überprüfung der Betriebsänderung',
        review_returned: 'Überprüfung zurückgesandt',
        bo_cancel_to_review: 'BO-Stornierung zur Überprüfung',
    },
    // 法
    frFR: {
        sales_order: 'Commande de vente',
        pack_no: 'N° de colis',
        pack_status: 'Statut du colis',
        history: 'Historique',
        order_confirm: 'Confirmation de commande',
        order_submit: 'Soumettre la commande',
        order_cancel: 'Annuler la commande',
        order_reviewed_today: 'Commande examinée aujourd\'hui',
        anji_logistics: 'Logistique Anji',
        fund_review: 'Examen des fonds',
        pack: 'Colis',
        bo_cancel: 'Annulation de BO',
        sale_orders_query: 'Recherche de commandes de vente',
        notice_history_query: 'Recherche d\'historique des notifications',
        is_read: 'Lu/Non-lu',
        is_read_y: 'Lu',
        is_read_n: 'Non-lu',
        notice: 'Notification',
        notice_type: 'Type de notification',
        notice_time: 'Heure de notification',
        notice_msg: 'Message de notification',
        operation_change_review: 'Modification de l\'opération d\'examen',
        review_returned: 'Examen retourné',
        bo_cancel_to_review: 'Annulation de BO à réviser',
    },
    // 意大利
    itIT: {
        sales_order: 'Ordine di Vendita',
        pack_no: 'Numero del Pacco',
        pack_status: 'Stato del Pacco',
        history: 'Storia',
        order_confirm: 'Conferma dell’Ordine',
        order_submit: 'Invio dell’Ordine',
        order_cancel: 'Annulla Ordine',
        order_reviewed_today: 'Ordini Recensiti Oggi',
        anji_logistics: 'Logistica Anji',
        fund_review: 'Revisione Fondi',
        pack: 'Pacco Imballaggio',
        bo_cancel: 'Annullamento BO',
        sale_orders_query: 'Query Ordini di Vendita',
        notice_history_query: 'Query Storia delle Notifiche',
        is_read: 'Letto/Non Letto',
        is_read_y: 'Letto',
        is_read_n: 'Non Letto',
        notice: 'Notifica',
        notice_type: 'Tipo di Notifica',
        notice_time: 'Data della Notifica',
        notice_msg: 'Messaggio della Notifica',
        operation_change_review: 'Revisione Cambio Operazione',
        review_returned: 'Recensioni in Ritorno',
        bo_cancel_to_review: 'Annullamento BO in Attesa di Revisione',
    },
    // 荷兰
    nlNL: {
        sales_order: 'Verkooporder',
        pack_no: 'Pakketnummer',
        pack_status: 'Pakketstatus',
        history: 'Geschiedenis',
        order_confirm: 'Order bevestigen',
        order_submit: 'Order indienen',
        order_cancel: 'Order annuleren',
        order_reviewed_today: 'Order beoordeeld vandaag',
        anji_logistics: 'Anji Logistiek',
        fund_review: 'Fondsherziening',
        pack: 'Pakket',
        bo_cancel: 'BO Annuleren',
        sale_orders_query: 'Query Verkooporders',
        notice_history_query: 'Query Kondig Geschiedenis',
        is_read: 'Lezen / Ongelezen',
        is_read_y: 'Gelezen',
        is_read_n: 'Ongelezen',
        notice: 'Kennisgeving',
        notice_type: 'Type Kennisgeving',
        notice_time: 'Kennisgeving Tijd',
        notice_msg: 'Kennisgeving Bericht',
        operation_change_review: 'Wijzigingsbeoordeling',
        review_returned: 'Beoordeling Teruggestuurd',
        bo_cancel_to_review: 'BO Annuleren naar Beoordeling',
    },
    // 越南
    viVN: {
        sales_order: 'Đơn hàng bán',
        pack_no: 'Số thùng',
        pack_status: 'Trạng thái thùng',
        history: 'Lịch sử',
        order_confirm: 'Xác nhận đơn hàng',
        order_submit: 'Gửi đơn hàng',
        order_cancel: 'Hủy đơn hàng',
        order_reviewed_today: 'Đơn hàng đã được duyệt hôm nay',
        anji_logistics: 'Vận chuyển Anji',
        fund_review: 'Duyệt quỹ',
        pack: 'Đóng gói thùng',
        bo_cancel: 'Hủy BO',
        sale_orders_query: 'Tra cứu đơn hàng bán',
        notice_history_query: 'Tra cứu lịch sử thông báo',
        is_read: 'Đã đọc / Chưa đọc',
        is_read_y: 'Đã đọc',
        is_read_n: 'Chưa đọc',
        notice: 'Thông báo',
        notice_type: 'Loại thông báo',
        notice_time: 'Ngày thông báo',
        notice_msg: 'Nội dung thông báo',
        operation_change_review: 'Xét duyệt thay đổi hoạt động',
        review_returned: 'Hoạt động xét duyệt đã trả lại',
        bo_cancel_to_review: 'Hủy BO đợi xét duyệt',
    },
    // 西班牙
    esES: {
        sales_order: 'Pedido de venta',
        pack_no: 'Número de paquete',
        pack_status: 'Estado del paquete',
        history: 'Historial',
        order_confirm: 'Confirmar pedido',
        order_submit: 'Enviar pedido',
        order_cancel: 'Cancelar pedido',
        order_reviewed_today: 'Pedidos revisados hoy',
        anji_logistics: 'Logística de Anji',
        fund_review: 'Revisión de fondos',
        pack: 'Empaquetar',
        bo_cancel: 'Cancelar BO',
        sale_orders_query: 'Consulta de pedidos de venta',
        notice_history_query: 'Consulta de historial de notificaciones',
        is_read: 'Leído/No leído',
        is_read_y: 'Leído',
        is_read_n: 'No leído',
        notice: 'Notificación',
        notice_type: 'Tipo de notificación',
        notice_time: 'Fecha de notificación',
        notice_msg: 'Mensaje de notificación',
        operation_change_review: 'Revisión de cambio de operación',
        review_returned: 'Revisión devuelta',
        bo_cancel_to_review: 'Cancelación de BO pendiente de revisión',
    },
    // 葡萄牙
    ptBR: {
        sales_order: 'Ordem de venda',
        pack_no: 'Número do pacote',
        pack_status: 'Status do pacote',
        history: 'Histórico',
        order_confirm: 'Confirmação do pedido',
        order_submit: 'Enviar pedido',
        order_cancel: 'Cancelar pedido',
        order_reviewed_today: 'Pedidos revisados ​​hoje',
        anji_logistics: 'Logística Anji',
        fund_review: 'Revisão do fundo',
        pack: 'Embalagem de Pacote',
        bo_cancel: 'BO cancelado',
        sale_orders_query: 'Consulta de pedidos de venda',
        notice_history_query: 'Consulta de histórico de aviso',
        is_read: 'Lido/Não lido',
        is_read_y: 'Lido',
        is_read_n: 'Não lido',
        notice: 'Aviso',
        notice_type: 'Tipo de aviso',
        notice_time: 'Data do aviso',
        notice_msg: 'Mensagem do aviso',
        operation_change_review: 'Revisão da mudança de operação',
        review_returned: 'Revisão devolvida',
        bo_cancel_to_review: 'BO cancelado aguardando revisão',
    },
    // 波兰
    plPL: {
        sales_order: 'Zamówienie sprzedaży',
        pack_no: 'Numer paczki',
        pack_status: 'Status paczki',
        history: 'Historia',
        order_confirm: 'Potwierdzenie zamówienia',
        order_submit: 'Przesłanie zamówienia',
        order_cancel: 'Anulowanie zamówienia',
        order_reviewed_today: 'Dzisiejsze zatwierdzone zamówienia',
        anji_logistics: 'Logistyka Anji',
        fund_review: 'Przegląd funduszy',
        pack: 'Opakowanie paczek',
        bo_cancel: 'Anuluj BO',
        sale_orders_query: 'Zapytanie o zamówienia sprzedaży',
        notice_history_query: 'Zapytanie o historię powiadomień',
        is_read: 'Przeczytane/Nieprzeczytane',
        is_read_y: 'Przeczytane',
        is_read_n: 'Nieprzeczytane',
        notice: 'Powiadomienie',
        notice_type: 'Typ powiadomienia',
        notice_time: 'Data powiadomienia',
        notice_msg: 'Wiadomość powiadomienia',
        operation_change_review: 'Przegląd zmiany operacji',
        review_returned: 'Przegląd zwrócony',
        bo_cancel_to_review: 'Anulowanie BO do przeglądu',
    },
    // 土耳其语
    trTR: {
        sales_order: 'Satış Siparişi',
        pack_no: 'Kutu Numarası',
        pack_status: 'Kutu Durumu',
        history: 'Geçmiş',
        order_confirm: 'Sipariş Onayı',
        order_submit: 'Sipariş Gönderimi',
        order_cancel: 'Sipariş İptali',
        order_reviewed_today: 'Bugün İncelenen Siparişler',
        anji_logistics: 'Anji Lojistik',
        fund_review: 'Fon İnceleme',
        pack: 'Kutu Paketleme',
        bo_cancel: 'BO İptali',
        sale_orders_query: 'Satış Siparişleri Sorgusu',
        notice_history_query: 'Bildirim Geçmişi Sorgusu',
        is_read: 'Okundu/Okunmadı',
        is_read_y: 'Okundu',
        is_read_n: 'Okunmadı',
        notice: 'Bildirim',
        notice_type: 'Bildirim Türü',
        notice_time: 'Bildirim Tarihi',
        notice_msg: 'Bildirim Mesajı',
        operation_change_review: 'Operasyon Değişikliği İnceleme',
        review_returned: 'İnceleme İade Edildi',
        bo_cancel_to_review: 'BO İptali İnceleme Bekliyor',
    },
    // 捷克语
    csCZ: {
        sales_order: 'Prodejní objednávka',
        pack_no: 'Balení č',
        pack_status: 'Stav balíku',
        history: 'Historie',
        order_confirm: 'Potvrzení objednávky',
        order_submit: 'Odeslání objednávky',
        order_cancel: 'Zrušení objednávky',
        order_reviewed_today: 'Objednávka dnes zkontrolována',
        anji_logistics: 'Anji logistika',
        fund_review: 'Revize fondu',
        pack: 'Balíček',
        bo_cancel: 'Zrušení zpětné objednávky',
        sale_orders_query: 'Dotaz na prodejní objednávky',
        notice_history_query: 'Dotaz na historii oznámení',
        is_read: 'Přečteno/Nepřečteno',
        is_read_y: 'Číst',
        is_read_n: 'Nepřečtený',
        notice: 'Oznámení',
        notice_type: 'Typ oznámení',
        notice_time: 'Čas upozornění',
        notice_msg: 'Zpráva s upozorněním',
        operation_change_review: 'Přezkoumání změny operace',
        review_returned: 'Review returned',
        bo_cancel_to_review: 'Zrušená zpětné objednávky k přezkoumání',
    },
    // 匈牙利语
    huHU: {
        sales_order: 'Értékesítési rendelés',
        pack_no: 'Csomagsz.',
        pack_status: 'Csomagállapot',
        history: 'Előzmény',
        order_confirm: 'Rendelésmegerősítés',
        order_submit: 'Rendelésbeküldés',
        order_cancel: 'Rendeléstörlés',
        order_reviewed_today: 'Rendelés ma ellenőrizve',
        anji_logistics: 'Anji logisztika',
        fund_review: 'Tőkeellenőrzés',
        pack: 'Csomag',
        bo_cancel: 'FR törlése',
        sale_orders_query: 'Értékesítési rendelés lekérdezése',
        notice_history_query: 'Értesítési előzmények lekérdezése',
        is_read: 'Olvasott/Olvasatlan',
        is_read_y: 'Olvasott',
        is_read_n: 'Olvasatlan',
        notice: 'Értesítés',
        notice_type: 'Értesítéstípus',
        notice_time: 'Értesítési idő',
        notice_msg: 'Értesítési üzenet',
        operation_change_review: 'Műveletmódosítás ellenőrzése',
        review_returned: 'Visszaküldött ellenőrzése',
        bo_cancel_to_review: 'FR törlésének ellenőrzése',
    },
    // 俄罗斯
    ruRU: {
        sales_order: 'Продажа заказа',
        pack_no: 'Упаковать нет',
        pack_status: 'Статус упаковки',
        history: 'История',
        order_confirm: 'Подтверждение заказа',
        order_submit: 'Отправить заказ',
        order_cancel: 'Отмена заказа',
        order_reviewed_today: 'Заказ проверен сегодня',
        anji_logistics: 'Анжи логистика',
        fund_review: 'Обзор фонда',
        pack: 'Упаковать',
        bo_cancel: 'BO отменить',
        sale_orders_query: 'Запрос заказов на продажу',
        notice_history_query: 'Запрос истории уведомлений',
        is_read: 'Прочитано/не прочитано',
        is_read_y: 'Прочитано',
        is_read_n: 'Не прочитано',
        notice: 'Уведомление',
        notice_type: 'Тип уведомления',
        notice_time: 'Дата уведомления',
        notice_msg: 'Сообщение уведомления',
        operation_change_review: 'Изменение операции пересмотреть',
        review_returned: 'Проверка возвращена',
        bo_cancel_to_review: 'BO отменить для пересмотра',
    },
};

import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 越南
export default {
    license: {
        powered_by: 'SAGW International {\'|\'} Bản quyền thuộc về',
    },
    auth: {
        login: 'Đăng nhập',
        username: 'Tên người dùng',
        username_placeholder: 'Vui lòng nhập tên người dùng',
        password: 'Mật khẩu',
        password_placeholder: 'Vui lòng nhập mật khẩu',
        code: 'Mã xác nhận',
        code_placeholder: 'Vui lòng nhập mã xác nhận',
        logout: 'Đăng xuất',
        logout_success: 'Đăng xuất thành công',
        day: 'ngày',
        password_expiration: 'Mật khẩu đã hết hạn, vui lòng thay đổi',
        expire: 'Còn lại',
        conseils:
            'Phải chứa chữ cái viết hoa, chữ cái viết thường, số và ký tự đặc biệt ({msg}), độ dài mật khẩu từ 8-16 ký tự',
        brand_Selection: 'Lựa chọn thương hiệu',
        unread_notices: 'Thông báo chưa đọc',
        new_cases: 'Các trường hợp mới',
        unanswered_cases: 'Các trường hợp chưa trả lời',
        overdue_cases: 'Các trường hợp quá hạn',
    },
    button: {
        query: 'Tìm kiếm',
        editing_position: 'Chỉnh sửa Vị trí',
        show_theme_editor: 'Hiển thị/Ẩn Trình chỉnh sửa Chủ đề',
        change_password: 'Thay đổi Mật khẩu',
        old_password: 'Mật khẩu cũ',
        new_password: 'Mật khẩu mới',
        confirm_password: 'Xác nhận Mật khẩu',
        button: 'Nút',
        about: 'Giới thiệu',
        back: 'Quay lại',
        go: 'Đi',
        home: 'Trang chủ',
        confirm: 'Xác nhận',
        modify: 'Chỉnh sửa',
        edit: 'Chỉnh sửa',
        new: 'Thêm',
        cancel: 'Huỷ',
        close: 'Đóng',
        search: 'Tìm kiếm',
        reset: 'Thiết lập lại',
        delete: 'Xóa',
        add: 'Thêm',
        collapse: 'Thu gọn',
        expand: 'Mở rộng',
        download_template: 'Tải xuống Mẫu',
        batch_import: 'Nhập khẩu hàng loạt',
        import: 'Nhập dữ liệu',
        export: 'Xuất dữ liệu',
        export_fail: 'Xuất không thành công',
        detailed: 'Chi tiết',
        details: 'Chi tiết',
        download: 'Tải xuống',
        toView: 'Xem',
        toViews: 'Xem hình ảnh',
        uploadAttachments: 'Tải lên tệp tin đính kèm',
        selectFile: 'Chọn tệp tin',
        fillingInstructions: 'Hướng dẫn điền',
        release: 'Phát hành',
        release_recall: 'Thu hồi phát hành',
        save: 'Lưu trữ',
        send: 'Gửi',
        tips: 'Mẹo',
        send_back: 'Trả lại',
        examine: 'Xem xét',
        void: 'Hết hiệu lực',
        more: 'Thêm',
        refresh: 'Làm mới',
        selects: 'Vui lòng chọn',
        serial_number: 'Số sê-ri',
        upload_progress: 'Tiến trình tải lên',
        invalid: 'Vô hiệu hóa',
        daily_record: 'Nhật ký',
        operation_type: 'Loại hoạt động',
        field_name: 'Tên trường',
        edit_role: 'Chỉnh sửa vai trò',
        user_role: 'Chọn vai trò',
        assignment_role: 'Vai trò',
        post: 'Vị trí',
        sending: 'Đang gửi',
        reset_password: 'Đặt lại Mật khẩu',
        display_or_not: 'Hiển thị hoặc không hiển thị',
        document: 'Tài liệu',
        specificTemplates: 'mẫu cụ thể',
        transferOrder: 'Lệnh chuyển',
        forwarding: 'Chuyển tiếp',
        one_click_notification: 'Thông báo một cú nhấp chuột',
    },
    radio: {
        enabled: 'kích hoạt',
        disabled: 'Vô hiệu hoá',
        catalogue: 'Danh mục',
        individual: 'Cá nhân',
        all: 'Tất cả',
        yes: 'Có',
        no: 'Không',
    },
    dropdown: {
        close_others: 'Đóng tất cả khác',
        close_right: 'Đóng bên phải',
        close_left: 'Đóng bên trái',
    },
    table: {
        action: 'Thực hiện',
        explain: 'Mô tả',
        fileName: 'Tên tệp đính kèm',
        size: 'Dung lượng (MB)',
        uploader: 'Đăng tải bởi',
        uploadTime: 'Thời gian đăng tải',
        system: 'Hệ thống',
        forwarding_time: 'Thời gian chuyển tiếp',
        forwarder: 'Trang chủ',
        send_to: 'Gửi tới',
        mode: 'Chế độ',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/Trang`,
        total: ({ named }: Parameters<MessageFunction>[0]) =>
            `Tổng cộng ${named('number')} Dữ liệu thanh`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) => `Tổng cộng ${named('number')} xe`,
    },
    confirm: {
        confirm_deletion: 'Xác nhận xóa?',
        confirm_void: 'Xác nhận vô hiệu hóa?',
        confirm_release: 'Xác nhận phát hành?',
        ack: 'Xác nhận',
        cancel: 'Huỷ',
        non_records: 'Vui lòng chọn ít nhất một bản ghi',
    },
    request: {
        failed_placeholder: 'Yêu cầu thất bại, lỗi máy chủ',
        success_placeholder: 'Yêu cầu thành công',
        auth: {
            login_success: 'Đăng nhập thành công',
        },
        error: {
            '404': 'Đường dẫn yêu cầu không tồn tại',
            '403': 'Không đủ quyền',
        },
    },
    page: {
        global: {
            page_search: 'Tìm kiếm trang',
        },
        error: {
            not_found: 'Không tìm thấy trang',
            not_found_des: 'Không có gì ở đây',
            auth_failed: 'Không đủ quyền',
            auth_failed_des: 'Trang này bị cấm truy cập',
            file_format_error: 'Định dạng tệp không bình thường!',
            preview_failed: 'Xem trước thất bại! Tệp không bình thường!',
        },
        homepage: {
            homepage: homepage.homepage.viVN,
        },
        system: {
            menu: system.menu.viVN,
            dict: system.dict.viVN,
            dept: system.dept.viVN,
            role: system.role.viVN,
            user: system.user.viVN,
        },
        info: {
            announcement: Info.announcement.announcement.viVN,
            document: Info.document.document.viVN,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.viVN,
                recallActivity: tech.quality.recallActivity.viVN,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.viVN,
            },
            management: {
                inquiryOd: tech.management.inquiry.viVN,
                permission: tech.management.permission.viVN,
                onSiteSupportHq: tech.management.onSiteSupport.viVN,
                progHistoryHq: tech.management.progHistory.viVN,
                salesRecordHq: tech.management.salesRecord.viVN,
                vehicleInfoHq: tech.management.vehicleInfo.viVN,
                claimHq: tech.management.claim.viVN,
                filterHq: tech.management.filter.viVN,
                newCarlssue: tech.management.newCarIssue.viVN,
                newCarDefinition: tech.management.newCarDefinition.viVN,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.viVN,
                programmingHistoryInformationQuery: tech.management.programmingHistoryInformationQuery.viVN,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.viVN,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.viVN,
        },
        statistics: statistics.inquiryStatistics.viVN,
        systemClassifyA: statistics.systemClassifyA.viVN,
        systemClassifyB: statistics.systemClassifyB.viVN,
        engineerStatistics: statistics.engineerStatistics.viVN,
        defectivePart: statistics.defectivePart.viVN,
        replyInquiryStatistics: statistics.replyInquiryStatistics.viVN,
        passRateStatistics: statistics.passRateStatistics.viVN,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.viVN,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.viVN,
        customsClearance135Rate: statistics.customsClearance135Rate.viVN,
        accumulatedClosureRate: statistics.accumulatedClosureRate.viVN,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.viVN,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.viVN,
    },
    validation: {
        common: {
            required: 'Mục này bắt buộc',
            numeric: 'Phải là số nguyên',
            decimal: 'Phải là số',
            positive_interger: 'Phải là một số nguyên dương',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Nhiều nhất là${named('number')}Số bit`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Không được vượt quá${named('number')}Từ`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Vượt quá số ký tự tối đa(${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Không ít hơn${named('number')}Từ`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Phải là${named('number')}Từ`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Không thể lớn hơn${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Không thể nhỏ hơn${named('number')}`,
            up_to_2_decimal_places: 'Tối đa 2 số thập phân',
            retain_up: 'Nhập tối đa 10 số nguyên dương, giữ tối đa 2 số thập phân',
            retain_ups: 'Nhập tối đa 11 số nguyên dương, giữ tối đa 4 số thập phân',
            input_number: 'Vui lòng nhập số',
            only_number_a: 'Chỉ có thể nhập chữ cái hoặc số',
            translate: 'Chỉ có thể là chữ cái',
            positive_integer: 'Nhập ít nhất một Field',
            at_least_one: 'Điền ít nhất một',
        },
        tip: {
            incomplete_information: 'Thông tin chưa đầy đủ',
        },
    },
    import: {
        template_file: 'Tệp mẫu',
        browse: 'Duyệt',
        download_template: 'Tải xuống mẫu',
        no_file_selected: 'Không có tệp được chọn',
        incorrect_file_type: 'Kiểu tập tin không chính xác',
        exceed: 'Ngoài',
        upload_success: 'Upload thành công',
        upload_fail: 'Tải lên không thành công',
        again_upload:
            'Chỉ có thể tải lên các tập tin hình ảnh ở định dạng jpg, png, bmp, gif, vui lòng tải lên lại',
        attachmentUpload: 'Tải tệp đính kèm',
        supportedFormats: 'Định dạng được hỗ trợ',
        preview: 'Xem trước',
    },
    export: {
        noDataToExport: 'Không có dữ liệu có thể xuất',
    },
    select: {
        all: 'Tất cả',
    },
    checkbox: {
        select_all: 'Chọn tất cả',
    },
    dialog: {
        error_message: 'Thông tin sai',
    },
    ymd: {
        day: 'ngày',
        month: 'tháng',
        year: 'năm',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    },
};

import inquiry from './inquiry';
import permission from './permission';
import onSiteSupport from './onSiteSupport';
import progHistory from './progHistory';
import salesRecord from './salesRecord';
import vehicleInfo from './vehicleInfo';
import claim from './claim';
import filter from './filter';
import newCarIssue from './newCarIssue';
import newCarDefinition from './newCarDefinition';
import businessComponents from './businessComponents';
import vehicleProductionInfo from './vehicleProductionInfo';
import programmingHistoryInformationQuery from './programmingHistoryInformationQuery';
import ecuControllerInformationQuery from './ecuControllerInformationQuery';

export default {
    inquiry,
    permission,
    onSiteSupport,
    progHistory,
    salesRecord,
    vehicleInfo,
    claim,
    filter,
    newCarIssue,
    newCarDefinition,
    businessComponents,
    vehicleProductionInfo,
    programmingHistoryInformationQuery,
    ecuControllerInformationQuery,
};

export default {
    // 中
    zhCN: {
        fill_in_at_least_one_query_condition: '至少填写一个查询条件',
        reporting_organization_name: '上报组织名称',
        full_query: '全量查询',
        query_time: '查询时间',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        number_of_cases: '案例总数',
        timely_reply_to_the_number_of_cases: '及时回复案例数量',
        prompt_response_percentage: '及时回复百分比',
        the_number_of_cases_was_not_timely_replied: '未及时回复案例数量',
        percentage_not_responding_in_time: '未及时回复百分比',
    },
    // 英
    enUS: {
        fill_in_at_least_one_query_condition: 'Fill in at least one query condition',
        reporting_organization_name: 'Reporting organization name',
        full_query: 'Full query',
        query_time: 'Query time',
        organization_code: 'Organization code',
        organization_name: 'Organization name',
        country: 'Country',
        number_of_cases: 'Number of cases',
        timely_reply_to_the_number_of_cases: 'Timely reply to the number of cases',
        prompt_response_percentage: 'Prompt response percentage',
        the_number_of_cases_was_not_timely_replied: 'The number of cases was not timely replied',
        percentage_not_responding_in_time: 'Percentage not responding in time',
    },
    // 德
    deDE: {
        fill_in_at_least_one_query_condition: 'Füllen Sie mindestens eine Abfragebedingung aus',
        reporting_organization_name: 'Name der meldenden Organisation',
        full_query: 'Vollständige Abfrage',
        query_time: 'Abfragezeit',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        number_of_cases: 'Gesamtzahl der Fälle',
        timely_reply_to_the_number_of_cases: 'Rechtzeitige Reaktion auf die Anzahl der Fälle',
        prompt_response_percentage: 'Prozentwert rechtzeitig wiederhergestellt',
        the_number_of_cases_was_not_timely_replied:
            'Wird die anzahl der fälle nicht rechtzeitig gemeldet',
        percentage_not_responding_in_time: 'Wurde nicht rechtzeitig über die prozent berichtet',
    },
    // 法
    frFR: {
        fill_in_at_least_one_query_condition: 'Remplissez au moins une condition de demande',
        reporting_organization_name: 'Nom de l\'organisation de rapport',
        full_query: 'Requête complète',
        query_time: 'Temps de requête',
        organization_code: 'Code d\'organisation',
        organization_name: 'Nom d\'organisation',
        country: 'Pays',
        number_of_cases: 'Nombre total de cas',
        timely_reply_to_the_number_of_cases: 'Réponse rapide au nombre de cas',
        prompt_response_percentage: 'Pourcentage de réponses rapides',
        the_number_of_cases_was_not_timely_replied: 'Nombre de cas en retard de réponse',
        percentage_not_responding_in_time: 'Pourcentage de réponses en retard',
    },
    // 意大利
    itIT: {
        fill_in_at_least_one_query_condition: 'Compilare almeno una condizione di query',
        reporting_organization_name: 'Nome Organizzazione Segnalante',
        full_query: 'Query Completa',
        query_time: 'Tempo di Query',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        country: 'Paese',
        number_of_cases: 'Numero totale di casi',
        timely_reply_to_the_number_of_cases: 'Risposta tempestiva al numero di casi',
        prompt_response_percentage: 'Percentuale di risposta tempestiva',
        the_number_of_cases_was_not_timely_replied:
            'Numero di casi che non hanno risposto tempestivamente',
        percentage_not_responding_in_time: 'Percentuale di risposte ritardate',
    },
    // 荷兰
    nlNL: {
        fill_in_at_least_one_query_condition: 'Vul ten minste één query voorwaarde in',
        reporting_organization_name: 'Rapporterende organisatienaam',
        full_query: 'Volledige query',
        query_time: 'Query time',
        organization_code: 'Organisatie code',
        organization_name: 'Organisatie naam',
        country: 'Land',
        number_of_cases: 'Totaal aantal gevallen',
        timely_reply_to_the_number_of_cases: 'Aantal gevallen',
        prompt_response_percentage: 'Percentage tijdige antwoorden',
        the_number_of_cases_was_not_timely_replied:
            'Aantal gevallen waarin niet tijdig is gereageerd',
        percentage_not_responding_in_time: 'Percentage niet-tijdige antwoorden',
    },
    // 越南
    viVN: {
        fill_in_at_least_one_query_condition: 'Ít nhất điền một điều kiện tìm kiếm',
        reporting_organization_name: 'Tên tổ chức báo cáo',
        full_query: 'Tra cứu toàn bộ',
        query_time: 'Thời gian truy vấn',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        country: 'Quốc gia',
        number_of_cases: 'Tổng số trường hợp',
        timely_reply_to_the_number_of_cases: 'Số lượng trả lời kịp thời cho các trường hợp',
        prompt_response_percentage: 'Phần trăm phản hồi kịp thời',
        the_number_of_cases_was_not_timely_replied:
            'Số lượng trường hợp không được trả lời kịp thời',
        percentage_not_responding_in_time: 'Phần trăm không phản hồi kịp thời',
    },
    // 西班牙
    esES: {
        fill_in_at_least_one_query_condition: 'Al menos complete un criterio de búsqueda',
        reporting_organization_name: 'Nombre de la organización de informes',
        full_query: 'Consulta completa',
        query_time: 'Tiempo de consulta',
        organization_code: 'Código de la organización',
        organization_name: 'Nombre de la organización',
        country: 'País',
        number_of_cases: 'Número total de casos',
        timely_reply_to_the_number_of_cases: 'Número de casos respondidos a tiempo',
        prompt_response_percentage: 'Porcentaje de casos respondidos a tiempo',
        the_number_of_cases_was_not_timely_replied: 'Número de casos no respondidos a tiempo',
        percentage_not_responding_in_time: 'Porcentaje de casos no respondidos a tiempo',
    },
    // 葡萄牙
    ptBR: {
        fill_in_at_least_one_query_condition: 'Preencha pelo menos uma condição de consulta',
        reporting_organization_name: 'Nome da organização de relatório',
        full_query: 'Consulta completa',
        query_time: 'Tempo de consulta',
        organization_code: 'Código da organização',
        organization_name: 'Nome da organização',
        country: 'País',
        number_of_cases: 'Número total de casos',
        timely_reply_to_the_number_of_cases: 'Número de respostas pontuais aos casos',
        prompt_response_percentage: 'Porcentagem de respostas pontuais',
        the_number_of_cases_was_not_timely_replied: 'Número de casos não respondidos pontualmente',
        percentage_not_responding_in_time: 'Porcentagem de casos não respondidos pontualmente',
    },
    // 波兰
    plPL: {
        fill_in_at_least_one_query_condition: 'Wypełnij co najmniej jeden warunek zapytania',
        reporting_organization_name: 'Nazwa organizacji raportującej',
        full_query: 'Pełne zapytanie',
        query_time: 'Data zapytania',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        country: 'Kraj',
        number_of_cases: 'Łączna liczba spraw',
        timely_reply_to_the_number_of_cases: 'Liczba terminowych odpowiedzi',
        prompt_response_percentage: 'Liczba terminowych odpowiedzi (%)',
        the_number_of_cases_was_not_timely_replied: 'Liczba odpowiedzi nie udzielonych w terminie',
        percentage_not_responding_in_time: 'Liczba odpowiedzi nie udzielonych w terminie (%)',
    },
    // 土耳其
    trTR: {
        fill_in_at_least_one_query_condition: 'En azından bir soru durumunu doldur',
        reporting_organization_name: 'Bildirim organizasyonu adı',
        full_query: 'Tam sorgulama',
        query_time: 'Sorgulama zamanı',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        country: 'Ülke',
        number_of_cases: 'Toplam dosyalar',
        timely_reply_to_the_number_of_cases: 'Zamanında cevaplanmış dosyalar',
        prompt_response_percentage: 'Hızlı cevaplama yüzdesi',
        the_number_of_cases_was_not_timely_replied: 'Zamanında cevaplanmamış dosya sayısı',
        percentage_not_responding_in_time: 'Zamanında cevaplanmış dosya  yüzdesi',
    },
    // 捷克语
    csCZ: {
        fill_in_at_least_one_query_condition: 'Vyplňte alespoň jednu podmínku dotazu',
        reporting_organization_name: 'Název ohlašující společnosti',
        full_query: 'Úplný dotaz',
        query_time: 'Čas dotazu',
        organization_code: 'Kód společnosti',
        organization_name: 'Název společnosti',
        country: 'Země',
        number_of_cases: 'Počet případů',
        timely_reply_to_the_number_of_cases: 'Včasná odpověď na počet případů',
        prompt_response_percentage: 'Procento rychlé odezvy',
        the_number_of_cases_was_not_timely_replied: 'Počet případů které nebyly včas odpovězeny',
        percentage_not_responding_in_time: 'Procento neodpovídá včas',
    },
    // 匈牙利语
    huHU: {
        fill_in_at_least_one_query_condition: 'Töltsön ki legalább egy lekérdezési feltételt',
        reporting_organization_name: 'Jelentő szervezet neve',
        full_query: 'Teljes lekérdezés',
        query_time: 'Lekérdezési idő',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        country: 'Ország',
        number_of_cases: 'Esetek száma',
        timely_reply_to_the_number_of_cases: 'Időben megválaszolt esetek száma',
        prompt_response_percentage: 'Azonnali válaszok százaléka',
        the_number_of_cases_was_not_timely_replied: 'Nem időben megválaszolt esetek száma',
        percentage_not_responding_in_time: 'Nem időben történt válaszok százaléka',
    },
    // 俄罗斯语
    ruRU: {
        fill_in_at_least_one_query_condition: 'Заполните хотя бы одно условие запроса',
        reporting_organization_name: 'Название отчетной организации',
        full_query: 'Полный запрос',
        query_time: 'Время запроса',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        country: 'Страна',
        number_of_cases: 'Общее количество случаев',
        timely_reply_to_the_number_of_cases: 'Количество случаев с своевременным ответом',
        prompt_response_percentage: 'Процент своевременных ответов',
        the_number_of_cases_was_not_timely_replied:
            'Количество случаев, на которые не был дан своевременный ответ',
        percentage_not_responding_in_time:
            'Процент случаев, на которые не был дан своевременный ответ',
    },
};

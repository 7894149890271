export default {
    // 中
    zhCN: {
        fill_in_at_least_one_query_condition: '至少填写一个查询条件',
        reporting_organization_name: '上报组织名称',
        full_query: '全量查询',
        query_time: '查询时间',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        number_of_cases: '案例总数',
        number_of_qualified_cases: '合格案例数量',
        percentage_of_qualified_cases: '合格案例百分比',
        number_of_unqualified_cases: '不合格案例数量',
        percentage_of_unqualified_cases: '不合格案例百分比',
    },
    // 英
    enUS: {
        fill_in_at_least_one_query_condition: 'Fill in at least one query condition',
        reporting_organization_name: 'Reporting organization name',
        full_query: 'Full query',
        query_time: 'Query time',
        organization_code: 'Organization code',
        organization_name: 'Organization name',
        country: 'Country',
        number_of_cases: 'Number of cases',
        number_of_qualified_cases: 'Number of qualified cases',
        percentage_of_qualified_cases: 'Percentage of Qualified Cases',
        number_of_unqualified_cases: 'Number of unqualified cases',
        percentage_of_unqualified_cases: 'Percentage of unqualified cases',
    },
    // 德
    deDE: {
        fill_in_at_least_one_query_condition: 'Füllen Sie mindestens eine Abfragebedingung aus',
        reporting_organization_name: 'Name der meldenden Organisation',
        full_query: 'Vollständige Abfrage',
        query_time: 'Abfragezeit',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        number_of_cases: 'Gesamtzahl der Fälle',
        number_of_qualified_cases: 'Anzahl qualifizierter Fälle',
        percentage_of_qualified_cases: 'Prozentsatz qualifizierter Fälle',
        number_of_unqualified_cases: 'Anzahl der nicht konformen Fälle',
        percentage_of_unqualified_cases: 'Prozentsatz der nicht konformen Fälle',
    },
    // 法
    frFR: {
        fill_in_at_least_one_query_condition: 'Remplissez au moins une condition de demande',
        reporting_organization_name: 'Nom de l\'organisation de rapport',
        full_query: 'Requête complète',
        query_time: 'Temps de requête',
        organization_code: 'Code organisation',
        organization_name: 'Nom organisation',
        country: 'Pays',
        number_of_cases: 'Total cas',
        number_of_qualified_cases: 'Nombre de cas admissibles',
        percentage_of_qualified_cases: 'Pourcentage de cas admissibles',
        number_of_unqualified_cases: 'Nombre de cas de non - conformité',
        percentage_of_unqualified_cases: 'Pourcentage de cas de non - conformité',
    },
    // 意大利
    itIT: {
        fill_in_at_least_one_query_condition: 'Compilare almeno una condizione di query',
        reporting_organization_name: 'Nome Organizzazione Segnalante',
        full_query: 'Query Completa',
        query_time: 'Tempo di Query',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        country: 'Paese',
        number_of_cases: 'Numero totale di casi',
        number_of_qualified_cases: 'Numero di casi ammissibili',
        percentage_of_qualified_cases: 'Percentuale di casi qualificati',
        number_of_unqualified_cases: 'Numero di casi non conformi',
        percentage_of_unqualified_cases: 'Percentuale di casi non conformi',
    },
    // 荷兰
    nlNL: {
        fill_in_at_least_one_query_condition: 'Vul ten minste één query voorwaarde in',
        reporting_organization_name: 'Rapporterende organisatienaam',
        full_query: 'Volledige query',
        query_time: 'Query time',
        organization_code: 'Organisatie code',
        organization_name: 'Organisatie naam',
        country: 'Land',
        number_of_cases: 'Totaal aantal gevallen',
        number_of_qualified_cases: 'Aantal gekwalificeerde gevallen',
        percentage_of_qualified_cases: 'Percentage gekwalificeerde zaken',
        number_of_unqualified_cases: 'Aantal niet-conforme gevallen',
        percentage_of_unqualified_cases: 'Percentage niet-conforme gevallen',
    },
    // 越南
    viVN: {
        fill_in_at_least_one_query_condition: 'Ít nhất điền một điều kiện tìm kiếm',
        reporting_organization_name: 'Tên tổ chức báo cáo',
        full_query: 'Tra cứu toàn bộ',
        query_time: 'Thời gian truy vấn',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        country: 'Quốc gia',
        number_of_cases: 'Tổng số trường hợp',
        number_of_qualified_cases: 'Số lượng trường hợp đạt yêu cầu',
        percentage_of_qualified_cases: 'Phần trăm trường hợp đạt yêu cầu',
        number_of_unqualified_cases: 'Số lượng trường hợp không đạt yêu cầu',
        percentage_of_unqualified_cases: 'Phần trăm trường hợp không đạt yêu cầu',
    },
    // 西班牙
    esES: {
        fill_in_at_least_one_query_condition: 'Al menos complete un criterio de búsqueda',
        reporting_organization_name: 'Nombre de la organización de informes',
        full_query: 'Consulta completa',
        query_time: 'Tiempo de consulta',
        organization_code: 'Código de la organización',
        organization_name: 'Nombre de la organización',
        country: 'País',
        number_of_cases: 'Número total de casos',
        number_of_qualified_cases: 'Número de casos calificados como aptos',
        percentage_of_qualified_cases: 'Porcentaje de casos calificados como aptos',
        number_of_unqualified_cases: 'Número de casos calificados como no aptos',
        percentage_of_unqualified_cases: 'Porcentaje de casos calificados como no aptos',
    },
    // 葡萄牙
    ptBR: {
        fill_in_at_least_one_query_condition: 'Preencha pelo menos uma condição de consulta',
        reporting_organization_name: 'Nome da organização de relatório',
        full_query: 'Consulta completa',
        query_time: 'Tempo da consulta',
        organization_code: 'Código da organização',
        organization_name: 'Nome da organização',
        country: 'País',
        number_of_cases: 'Número total de casos',
        number_of_qualified_cases: 'Número de casos qualificados',
        percentage_of_qualified_cases: 'Porcentagem de casos qualificados',
        number_of_unqualified_cases: 'Número de casos não qualificados',
        percentage_of_unqualified_cases: 'Porcentagem de casos não qualificados',
    },
    // 波兰
    plPL: {
        fill_in_at_least_one_query_condition: 'Wypełnij co najmniej jeden warunek zapytania',
        reporting_organization_name: 'Nazwa organizacji raportującej',
        full_query: 'Pełne zapytanie',
        query_time: 'Data zapytania',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        country: 'Kraj',
        number_of_cases: 'Łączna liczba spraw',
        number_of_qualified_cases: 'Liczba spraw zakwalifikowanych',
        percentage_of_qualified_cases: 'Liczba spraw zakwalifikowanych (%)',
        number_of_unqualified_cases: 'Liczba spraw niezakwalifikowanych',
        percentage_of_unqualified_cases: 'Liczba spraw niezakwalifikowanych (%)',
    },
    // 土耳其语
    trTR: {
        fill_in_at_least_one_query_condition: 'En azından bir soru durumunu doldur',
        reporting_organization_name: 'Bildirim organizasyonu adı',
        full_query: 'Tam sorgulama',
        query_time: 'Sorgulama zamanı',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        country: 'Ülke',
        number_of_cases: 'Toplam dosyalar',
        number_of_qualified_cases: 'Onaylanmış dosya sayısı',
        percentage_of_qualified_cases: 'Onaylanmış dosya sayısının yüzdesi',
        number_of_unqualified_cases: 'Onaylanmamış dosya sayısı',
        percentage_of_unqualified_cases: 'Onaylanmamış dosya sayısının yüzdesi',
    },
    // 捷克语
    csCZ: {
        fill_in_at_least_one_query_condition: 'Vyplňte alespoň jednu podmínku dotazu',
        reporting_organization_name: 'Název ohlašující společnosti',
        full_query: 'Úplný dotaz',
        query_time: 'Čas dotazu',
        organization_code: 'Kód společnosti',
        organization_name: 'Název společnosti',
        country: 'Země',
        number_of_cases: 'Počet případů',
        number_of_qualified_cases: 'Počet kvalifikovaných případů',
        percentage_of_qualified_cases: 'Procento kvalifikovaných případů',
        number_of_unqualified_cases: 'Počet nekvalifikovaných případů',
        percentage_of_unqualified_cases: 'Procento nekvalifikovaných případů',
    },
    // 匈牙利语
    huHU: {
        fill_in_at_least_one_query_condition: 'Töltsön ki legalább egy lekérdezési feltételt',
        reporting_organization_name: 'Jelentő szervezet neve',
        full_query: 'Teljes lekérdezés',
        query_time: 'Lekérdezési idő',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        country: 'Ország',
        number_of_cases: 'Esetek száma',
        number_of_qualified_cases: 'Minősített esetek száma',
        percentage_of_qualified_cases: 'Minősített esetek százaléka',
        number_of_unqualified_cases: 'Nem minősített esetek száma',
        percentage_of_unqualified_cases: 'Nem minősített esetek százaléka',
    },
    // 俄罗斯语
    ruRU: {
        fill_in_at_least_one_query_condition: 'Заполните хотя бы одно условие запроса',
        reporting_organization_name: 'Название отчетной организации',
        full_query: 'Полный запрос',
        query_time: 'Время запроса',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        country: 'Страна',
        number_of_cases: 'Общее количество случаев',
        number_of_qualified_cases: 'Количество квалифицированных случаев',
        percentage_of_qualified_cases: 'Процент квалифицированных случаев',
        number_of_unqualified_cases: 'Количество неквалифицированных случаев',
        percentage_of_unqualified_cases: 'Процент неквалифицированных случаев',
    },
};

import { useVDR } from 'v-demi-request';
import type { SelectOption } from 'naive-ui';
import type { MaybeRefOrGetter } from '@vueuse/core';
import type { UserInfo, UserLangListItem } from '~/stores/user';
import { useUserStore } from '~/stores/user';
import { useResponseHandler } from '~/utils/http/handler';
import { baseUrl, getRequest, postRequest } from '~/utils/http/request';
import type { NavDataOrigin } from '~/utils/router';
import { normalizePath } from '~/utils/router';
import { downloadCallback } from '~/utils/toolFunction';
import { withLangConfig } from '~/utils/http/helper';
import type { Locales } from '~/composables/i18n';
// import { useAuthToken } from '~/utils/token';
import { useAuthToken } from '~/utils/token';

const { brand } = useAuthToken();

export const getExampleKey = '/api/user/ticket';
export const commonRequests = {
    getRoutes: (p: any) => {
        return getRequest<undefined, NavDataOrigin[]>(
            `${baseUrl}/auth/menu/getRouters`,
            p || { brand: brand.value },
        );
    },
    logout: () => postRequest(`${baseUrl}/auth/logout`),
    switchLang: () => postRequest(`${baseUrl}/auth/switchLang`),
    getUserInfo: () =>
        getRequest<
            undefined,
            {
                user: UserInfo;
                userLang: Locales;
                userLangList: UserLangListItem[];
                pwdRemindDay: string;
                loginFlag: string;
            }
        >(`${baseUrl}/auth/user/getUserInfo`),
    cdcAuth: () =>
        getRequest<undefined, undefined>(
            `${
                import.meta.env.VITE_CDC_AUTH_URL
            }/esc-sso/app/forward?ssoType=saml&appId=1908289244179217129&accountNo=CN000138`,
        ),
};

export type QueryDictResponse = {
    codeId?: number;
    code: string;
    codeName: string;
    codeType?: number;
    codeTypeName?: string;
    parentCode?: string;
    status?: number;
    lang?: string;
    sortOrder?: number;
    createBy?: string | null;
    createDate?: number | null;
    updateBy?: string | null;
    updateDate?: number | null;
};

export const useDict = () => {
    const { t, locale } = useTypedI18n();
    const { getRequest } = useApis();
    const { vdrHandler } = useResponseHandler();
    const allOption = () => ({
        code: '',
        codeName: '',
        value: '',
        label: t('select.all'),
    });

    return {
        queryDIct: (codeType: string | number, isSort = false) => {
            // isSort 是根据阿拉伯字母进行排序的api
            const listData = ref<(QueryDictResponse & SelectOption)[]>([]);
            const { loading, onSuccess, send } = useVDR(
                () =>
                    isSort
                        ? `${baseUrl}/auth/dict/queryDictByCodeTypeOrderByCodeName?lang=${locale.value}`
                        : `${baseUrl}/auth/dict/queryDictByCodeType?lang=${locale.value}`,
                (key: string) =>
                    getRequest<{ codeType: string }, QueryDictResponse[]>(key, {
                        codeType: codeType.toString(),
                    }),
                { requiredDeps: [locale] },
            );

            const { onSuccess: whenSuccess } = vdrHandler(onSuccess);
            whenSuccess(({ res }) => {
                listData.value = res;
            });

            return {
                loading,
                send,
                listData,
                /** 带全部项的下拉菜单数据 */
                optionsFormatList: computed(() => {
                    const res = listData.value.map((i) => {
                        return {
                            ...i,
                            value: i.code,
                            label: i.codeName,
                        };
                    });
                    res.unshift(resolveUnref(allOption));
                    return res;
                }),
                /** 不带全部项的下拉菜单数据 */
                optionsFormatListWithoutAll: computed(() => {
                    return listData.value.map((i) => {
                        return {
                            ...i,
                            value: i.code,
                            label: i.codeName,
                        };
                    });
                }),
                /** 当前登录账号-下拉菜单数据 */
                optionsFormatListFilter: computed(() => {
                    const userStore = useUserStore();
                    const deptType = computed(() => userStore.userInfo?.dept.deptType);
                    let newArr: any[] = [];
                    if (deptType.value === '10081004' || deptType.value === '10081005') {
                        newArr = listData.value.filter((item) => {
                            return (
                                item.code !== '10300003' &&
                                item.code !== '10300004' &&
                                item.code !== '10300005'
                            );
                        });
                    } else if (deptType.value === '10081003') {
                        newArr = listData.value.filter((item) => {
                            return item.code !== '10300003' && item.code !== '10300004';
                        });
                    } else if (deptType.value === '10081002') {
                        newArr = listData.value.filter((item) => {
                            return item.code !== '10300003';
                        });
                    } else {
                        newArr = listData.value;
                    }
                    return newArr.map((i) => {
                        return {
                            ...i,
                            value: i.code,
                            label: i.codeName,
                        };
                    });
                }),

                /** 不带全部项的下拉菜单数据 */
                optionsFormatListWithoutAllLink: computed(() => {
                    return listData.value.map((i) => {
                        return {
                            ...i,
                            value: i.code,
                            label: `${i.codeName}-${i.code}`,
                        };
                    });
                }),
            };
        },
        batchQueryDict: (codeTypeList: MaybeRefOrGetter<string | string[]>) => {
            const listMap = ref<Map<string, QueryDictResponse[]>>(new Map());
            const { loading, onSuccess, send } = useVDR(
                () => `${baseUrl}/auth/dict/queryDictByCodeType?lang=${locale.value}`,
                (key: string) => {
                    const unrefList = resolveUnref(codeTypeList);
                    let param: string;
                    if (typeof unrefList === 'string') param = unrefList;
                    else param = unrefList.join(',');
                    return getRequest<
                        { codeTypeList: string },
                        Record<string, QueryDictResponse[]>
                    >(key, {
                        codeTypeList: param,
                    });
                },
                { requiredDeps: [locale] },
            );

            const { onSuccess: whenSuccess } = vdrHandler(onSuccess);
            whenSuccess(({ res }) => {
                listMap.value.clear();
                const unrefList = resolveUnref(codeTypeList);
                let arr: string[];
                if (typeof unrefList === 'string') arr = unrefList.split(',');
                else arr = unrefList;
                arr.forEach((key) => {
                    listMap.value.set(key, res[key]);
                });
            });

            const get = (key: MaybeRefOrGetter<string>) => {
                const source = computed(() => listMap.value.get(resolveUnref(key)));

                return computed(() => {
                    if (!source.value) return [];
                    return source.value.map((i) => {
                        return {
                            ...i,
                            value: i.code,
                            label: i.codeName,
                        };
                    });
                });
            };

            const getWithAll = (key: MaybeRefOrGetter<string>) => {
                const source = computed(() => listMap.value.get(resolveUnref(key)));

                return computed(() => {
                    if (!source.value) return [];
                    const arr = source.value.map((i) => {
                        return {
                            ...i,
                            value: i.code,
                            label: i.codeName,
                        };
                    });
                    arr.unshift(resolveUnref(allOption));
                    return arr;
                });
            };

            const withAll = (getResult: ReturnType<typeof get>) => {
                return computed(() => {
                    const arr = [...getResult.value];
                    arr.unshift(resolveUnref(allOption));
                    return arr;
                });
            };

            return {
                loading,
                send,
                get,
                getWithAll,
                withAll,
            };
        },
    };
};

export const useDownloadTemplate = () => {
    const { getRequest } = useApis();
    const { vdrHandler } = useResponseHandler();

    return {
        downloadTemplate: (
            fileName: MaybeRefOrGetter<string | undefined>,
            downloadTemplateUrl: string,
        ) => {
            const depCnf = withLangConfig();
            const templateUrl = downloadTemplateUrl.startsWith(baseUrl)
                ? downloadTemplateUrl
                : `${baseUrl}${normalizePath(downloadTemplateUrl)}`;
            const { loading, onSuccess, send } = useVDR(
                () =>
                    downloadTemplateUrl ? `${templateUrl}` : `${baseUrl}/snw/downloadExcel/i18n`,
                (url: string) => {
                    const urlWithParam = downloadTemplateUrl
                        ? `${url}?fileName=${resolveUnref(fileName)}`
                        : `${url}/${resolveUnref(fileName)}`;
                    return getRequest(
                        urlWithParam,
                        {},
                        {
                            ...depCnf.value,
                            responseType: 'blob',
                        },
                    );
                },
                {
                    cache: false,
                    retry: false,
                    immediate: false,
                },
            );

            const { onSuccess: whenSuccess } = vdrHandler(onSuccess);
            whenSuccess(({ res }) => {
                downloadCallback(res, resolveUnref(fileName));
            });

            return {
                loading,
                download: send,
            };
        },
    };
};

export const useAttachment = () => {
    const { getRequest, postRequest } = useApis();
    const { handler } = useResponseHandler();

    return {
        // 获取uuid
        getUuid: () => getRequest(`${baseUrl}/snw/enclosure/getUuid`),
        // 根据uuid获取列表
        getListByUuid: (params: Record<string, unknown>) =>
            getRequest(`${baseUrl}/snw/enclosure/getListByUuid`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }),
        // 上传附件
        addAttachments: (params: any) => postRequest(`${baseUrl}/snw/enclosure/add`, params),
        // 附件列表
        getAttachments: (params: Record<string, unknown>) =>
            getRequest(`${baseUrl}/snw/enclosure/getList`, params),
        // 关联附件
        relationAttachments: (params: any) =>
            postRequest(`${baseUrl}/snw/enclosure/relation`, params),
        // 删除附件
        deleteAttachments: (id: number) => getRequest(`${baseUrl}/snw/enclosure/delete?id=${id}`),
    };
};

export default {
    // 中
    zhCN: {
        factory: '工厂',
        vehicle_platform: '车型平台',
        model_year: '车型年',
        order_no: '订单号',
        order_type: '订单类型',
        vehicle_material_no: '整车物料号',
        engine_no: '发动机序列号',
        certificate_no_14: '14位合格证号',
        certificate_no_15: '15位合格证号',
        certificate_date: '合格证打印日期',
        pn: 'PIN',
        uptime: '总装上线时间',
        end_time: '车辆生产结束时间',
        bomversion: 'BOMVERSION',
        product_code: '生产代码',
        industrial_division: '⽣产区分(CKD、SKD、CBU)',
        vehicle_material_number_description: '整车物料号描述',
        SAIC_order_number: '上汽国际订单号',
        station_code: '工厂代码',
        station_desc: '工厂描述',
        station_number: '工位编号',
        station_name: '工位名称',
        vehicle_arrival_time: '车辆到达时间',
        key_component_barcode: '关键件条码',
        scan_time: '扫描时间',
        key_component_type_code: '关键件类型编码',
        key_component_type_desc: '关键件类型描述(中文)',
        key_component_type_descen: '关键件类型描述(英文)',
        part_number: '零件号',
        supplier_name: '供应商号',
        traceability_number: '追溯号',
        subcritical_component_type: '子关键件类型',
        batch_number: '批次号',
        vehicle_base_info: '车辆基础信息',
        vehicle_crossing_data: '车辆过点数据',
        accurately_traceable_parts_list: '精确追溯零件清单',
        vehicle_configuration_information: '车辆配置信息',
        VIN: 'VIN',
        SAP_feature_ID: 'SAP 特征 ID',
        SAP_feature_Codes: 'SAP 特征码',
        SAP_feature_Description: 'SAP 特征描述',
        SAP_feature_value_Description: 'SAP 特征值描述',
        SAP_feature_Description_EN: 'SAP 特征描述(英文)',
        SAP_feature_value_Description_EN: 'SAP 特征值描述(英文)',
        batteryNo: '电池编号',
        qmotorNo: '前电机编号',
        hmotorNo: '后电机编号',
        feature_ID: '特征ID',
        feature_Description: '特征描述(中文)',
        feature_Description_EN: '特征描述(英文)',
        feature_Codes: '特征码',
        feature_value_Description: '特征值描述(中文)',
        feature_value_Description_EN: '特征值描述(英文)',
        description_zh: '中文描述',
        supplier: '供应商',
        barCoding: '条形编码',
        key_component_type_desc_En: '关键件类型描述(英文)',
        partName: '零件中文名称',
        partName_En: '零件英文名称',
        workstation_code: '工位代码',
        workstationDesc: '工位描述(中文)',
        workstationDesc_En: '工位描述(英文)',
        packageNo: '电池包编码',
        packageType: '电池包型号',
        moduleNo: '电池模组编码',
        moduleType: '模组型号',
        cellNo: '单体电池编码',
        cellModelId: '单体型号',
        uploadTime: '报送时间',
        vehicle_battery_data: '车辆精追电池数据',
    },
    // 英
    enUS: {
        factory: 'Factory',
        vehicle_platform: 'Vehicle platform',
        model_year: 'Model year',
        order_no: 'Order no',
        order_type: 'Order type',
        vehicle_material_no: 'Vehicle material no',
        engine_no: 'Engine no',
        certificate_no_14: 'Certificate no 14',
        certificate_no_15: 'Certificate no 15',
        certificate_date: 'Certificate date',
        pn: 'PIN',
        uptime: 'Final assembly launch time',
        end_time: 'Vehicle production end time',
        bomversion: 'BOMVERSION',
        product_code: 'Product code',
        industrial_division: 'Industrial division(CKD、SKD、CBU)',
        vehicle_material_number_description: 'Vehicle material number description',
        SAIC_order_number: 'SAIC International Order Number',
        station_code: 'Factory code',
        station_desc: 'Factory Description',
        station_number: 'Station number',
        station_name: 'Station name',
        vehicle_arrival_time: 'Vehicle arrival time',
        key_component_barcode: 'Key component barcode',
        scan_time: 'Scan time',
        key_component_type_code: 'Key component type code',
        key_component_type_desc: 'Key Component Type Description (Chinese)',
        key_component_type_descen: 'Key Component Type Description (English)',
        part_number: 'Part number',
        supplier_name: 'Supplier Name',
        traceability_number: 'Traceability number',
        subcritical_component_type: 'Subcritical component type',
        batch_number: 'Batch number',
        vehicle_base_info: 'Vehicle basic information',
        vehicle_crossing_data: 'Vehicle crossing data',
        accurately_traceable_parts_list: 'Accurately Traceable Parts List',
        vehicle_configuration_information: 'Vehicle configuration information',
        VIN: 'VIN',
        SAP_feature_ID: 'SAP feature ID',
        SAP_feature_Codes: 'SAP feature codes',
        SAP_feature_Description: 'SAP feature description',
        SAP_feature_value_Description: 'SAP feature value description',
        SAP_feature_Description_EN: 'SAP feature description(English)',
        SAP_feature_value_Description_EN: 'SAP feature value description(English)',
        batteryNo: 'Battery Number',
        qmotorNo: 'Front Motor Number',
        hmotorNo: 'Rear Motor Number',
        feature_ID: 'Feature ID',
        feature_Description: 'Feature Description (Chinese)',
        feature_Description_EN: 'Feature Description (English)',
        feature_Codes: 'Feature Codes',
        feature_value_Description: 'Feature Value Description (Chinese)',
        feature_value_Description_EN: 'Feature Value Description (English)',
        description_zh: 'Description (Chinese)',
        supplier: 'Supplier',
        barCoding: 'Bar Coding',
        key_component_type_desc_En: 'Key Component Type Description (English)',
        partName: 'Part Name (Chinese)',
        partName_En: 'Part Name (English)',
        workstation_code: 'Workstation Code',
        workstationDesc: 'Workstation Description (Chinese)',
        workstationDesc_En: 'Workstation Description (English)',
        packageNo: 'Battery Pack Code',
        packageType: 'Battery Pack Model',
        moduleNo: 'Battery Module Code',
        moduleType: 'Module Model',
        cellNo: 'Single Cell Code',
        cellModelId: 'Single Cell Model',
        uploadTime: 'Upload Time',
        vehicle_battery_data: 'Vehicle Battery Data',
    },
    // 德
    deDE: {
        factory: 'Fabrik',
        vehicle_platform: 'Fahrzeugplattform',
        model_year: 'Modelljahr',
        order_no: 'Bestellnummer',
        order_type: 'Auftragsart',
        vehicle_material_no: 'Fahrzeugmaterialnummer',
        engine_no: 'Seriennummer des Motors',
        certificate_no_14: '14-stellige Zertifikatsnummer',
        certificate_no_15: '15-stellige Zertifikatsnummer',
        certificate_date: 'Datum des Ausdrucks der Bescheinigung',
        pn: 'PIN',
        uptime: 'Startzeit der Endmontage',
        end_time: 'Endzeit der Fahrzeugproduktion',
        bomversion: 'BOMVERSION',
        product_code: 'Produktionscode',
        industrial_division: '⽣Industriebereich(CKD、SKD、CBU)',
        vehicle_material_number_description: 'Beschreibung der Fahrzeugmaterialnummer',
        SAIC_order_number: 'SAIC International Bestellnummer',
        station_code: 'Fabrikcode',
        station_desc: 'Fabrikbeschreibung',
        station_number: 'Bahnhofsnummer',
        station_name: 'Name der Station',
        vehicle_arrival_time: 'Ankunftszeit des Fahrzeugs',
        key_component_barcode: 'Barcode der Hauptkomponente',
        scan_time: 'Scanzeit',
        key_component_type_code: 'Schlüsselkomponententypcode',
        key_component_type_desc: 'Beschreibung des Schlüsselkomponententyps (Chinesisch)',
        key_component_type_descen: 'Beschreibung des Schlüsselkomponententyps (Englisch)',
        part_number: 'Teilenummer',
        supplier_name: 'Name des Lieferanten',
        traceability_number: 'Rückverfolgbarkeitsnummer',
        subcritical_component_type: 'Subkritischer Komponententyp',
        batch_number: 'Chargennummer',
        vehicle_base_info: 'Grundlegende Fahrzeuginformationen',
        vehicle_crossing_data: 'Daten zur Fahrzeugüberquerung',
        accurately_traceable_parts_list: 'Genau nachvollziehbare Teileliste',
        VIN: 'VIN',
        SAP_feature_ID: 'SAP Funktions-ID',
        SAP_feature_Codes: 'SAP Funktionscodes',
        SAP_feature_Description: 'SAP Funktionsbeschreibung',
        SAP_feature_value_Description: 'SAP Funktionswertbeschreibung',
        SAP_feature_Description_EN: 'SAP Funktionsbeschreibung(Englisch)',
        SAP_feature_value_Description_EN: 'SAP Funktionswertbeschreibung(Englisch)',
        batteryNo: 'Hintere Motornummer',
        qmotorNo: 'Front Motor Number',
        hmotorNo: 'Rear Motor Number',
        feature_ID: 'Merkmal-ID',
        feature_Description: 'Merkmalsbeschreibung (Chinesisch)',
        feature_Description_EN: 'Merkmalsbeschreibung (Englisch)',
        feature_Codes: 'Merkmalscodes',
        feature_value_Description: 'Merkmalswertbeschreibung (Chinesisch)',
        feature_value_Description_EN: 'Merkmalswertbeschreibung (Englisch)',
        description_zh: 'Beschreibung (Chinesisch)',
        supplier: 'Lieferant',
        barCoding: 'Strichkodierung',
        key_component_type_desc_En: 'Beschreibung des Schlüsselkomponenten-Typs (Englisch)',
        partName: 'Teilename (Chinesisch)',
        partName_En: 'Teilename (Englisch)',
        workstation_code: 'Arbeitsplatzcode',
        workstationDesc: 'Arbeitsplatzbeschreibung (Chinesisch)',
        workstationDesc_En: 'Arbeitsplatzbeschreibung (Englisch)',
        packageNo: 'Batteriepackcode',
        packageType: 'Batteriepackmodell',
        moduleNo: 'Batteriemodulcode',
        moduleType: 'Modell des Moduls',
        cellNo: 'Einzelzellen-Code',
        cellModelId: 'Einzelzellenmodell',
        uploadTime: 'Übermittlungszeit',
        vehicle_battery_data: 'Fahrzeugbatteriedaten',
    },
    // 法
    frFR: {
        factory: 'Usine',
        vehicle_platform: 'Plate - forme de modèle de voiture',
        model_year: 'Année modèle',
        order_no: 'Numéro de commande',
        order_type: 'Type d\'ordre',
        vehicle_material_no: 'Numéro de matériau du véhicule entier',
        engine_no: 'Numéro de série du moteur',
        certificate_no_14: 'Numéro de certificat de conformité à 14 Chiffres',
        certificate_no_15: 'Numéro de certificat de conformité à 15 Chiffres',
        certificate_date: 'Date d\'impression du certificat de conformité',
        pn: 'PIN',
        uptime: 'Temps de montage final en ligne',
        end_time: 'Temps de fin de production du véhicule',
        bomversion: 'BOMVERSION',
        product_code: 'Code de production',
        industrial_division: 'Différenciation de la production(CKD、SKD、CBU)',
        vehicle_material_number_description: 'Numéro de matériau du véhicule complet Description',
        SAIC_order_number: 'SAIC International numéro de commande',
        station_code: 'Code d\'usine',
        station_desc: 'Description de l\'usine',
        station_number: 'Numéro de station',
        station_name: 'Nom de la station',
        vehicle_arrival_time: 'Heure d\'arrivée du véhicule',
        key_component_barcode: 'Code à barres des pièces clés',
        scan_time: 'Temps de scan',
        key_component_type_code: 'Codage des types de pièces clés',
        key_component_type_desc: 'Description du type de composant clé (chinois)',
        key_component_type_descen: 'Description du type de composant clé (anglais)',
        part_number: 'Numéro de pièce',
        supplier_name: 'Nom du fournisseur',
        traceability_number: 'Numéro de rétroactivité',
        subcritical_component_type: 'Type de sous - clé',
        batch_number: 'Numéro de lot',
        vehicle_base_info: 'Informations de base sur le véhicule',
        vehicle_crossing_data: 'Données de dépassement de véhicule',
        accurately_traceable_parts_list: 'Liste de pièces de traçabilité précise',
        VIN: 'VIN',
        SAP_feature_ID: 'Identifiant de fonction SAP',
        SAP_feature_Codes: 'Codes de fonction SAP',
        SAP_feature_Description: 'Description de fonction SAP',
        SAP_feature_value_Description: 'Description de la valeur de fonction SAP',
        SAP_feature_Description_EN: 'Description de fonction SAP(anglais)',
        SAP_feature_value_Description_EN: 'Description de la valeur de fonction SAP(anglais)',
        batteryNo: 'Numéro de batterie',
        qmotorNo: 'Numéro de moteur avant',
        hmotorNo: 'Numéro de moteur arrière',
        feature_ID: 'ID de Caractéristique',
        feature_Description: 'Description de la Caractéristique (Chinois)',
        feature_Description_EN: 'Description de la Caractéristique (Anglais)',
        feature_Codes: 'Codes de Caractéristique',
        feature_value_Description: 'Description de la Valeur de la Caractéristique (Chinois)',
        feature_value_Description_EN: 'Description de la Valeur de la Caractéristique (Anglais)',
        description_zh: 'Description (Chinois)',
        supplier: 'Fournisseur',
        barCoding: 'Codage à Barres',
        key_component_type_desc_En: 'Description du Type de Composant Clé (Anglais)',
        partName: 'Nom de la Pièce (Chinois)',
        partName_En: 'Nom de la Pièce (Anglais)',
        workstation_code: 'Code du Poste de Travail',
        workstationDesc: 'Description du Poste de Travail (Chinois)',
        workstationDesc_En: 'Description du Poste de Travail (Anglais)',
        packageNo: 'Code du Pack de Batterie',
        packageType: 'Modèle de Pack de Batterie',
        moduleNo: 'Code du Module de Batterie',
        moduleType: 'Modèle de Module',
        cellNo: 'Code de la Cellule Unique',
        cellModelId: 'Modèle de Cellule Unique',
        uploadTime: 'Heure de Téléchargement',
        vehicle_battery_data: 'Données de Batterie de Véhicule',
    },
    // 意大利
    itIT: {
        factory: 'Fabbrica',
        vehicle_platform: 'Piattaforma del veicolo',
        model_year: 'Anno modello',
        order_no: 'Numero d\'ordine',
        order_type: 'Tipo di ordine',
        vehicle_material_no: 'Numero materiale del veicolo',
        engine_no: 'Numero di serie del motore',
        certificate_no_14: 'Numero di certificato a 14 cifre',
        certificate_no_15: 'Numero di certificato a 15 cifre',
        certificate_date: 'Data di stampa del certificato',
        pn: 'PIN',
        uptime: 'Tempo di lancio finale dell\'assemblaggio',
        end_time: 'Fine produzione del veicolo',
        bomversion: 'BOMVERSION',
        product_code: 'Codice di produzione',
        industrial_division: 'Differenziamento della produzione(CKD、SKD、CBU)',
        vehicle_material_number_description: 'Descrizione del numero del materiale del veicolo',
        SAIC_order_number: 'Numero d\'ordine internazionale SAIC',
        station_code: 'Codice azienda',
        station_desc: 'Descrizione dell\'azienda',
        station_number: 'Numero della stazione',
        station_name: 'Nome della stazione',
        vehicle_arrival_time: 'Orario di arrivo del veicolo',
        key_component_barcode: 'Codice a barre del componente chiave',
        scan_time: 'Tempo di scansione',
        key_component_type_code: 'Codice del tipo di componente chiave',
        key_component_type_desc: 'Descrizione del tipo di componente chiave (cinese)',
        key_component_type_descen: 'Descrizione del tipo di componente chiave (inglese)',
        part_number: 'Numero di parte',
        supplier_name: 'Nome fornitore',
        traceability_number: 'Numero di rintracciabilità',
        subcritical_component_type: 'Tipo di componente sottocritico',
        batch_number: 'Numero di lotto',
        vehicle_base_info: 'Informazioni di base del veicolo',
        vehicle_crossing_data: 'Dati di attraversamento del veicolo',
        accurately_traceable_parts_list: 'Elenco parti tracciabili con precisione',
        VIN: 'VIN',
        SAP_feature_ID: 'ID funzione SAP',
        SAP_feature_Codes: 'Codici funzione SAP',
        SAP_feature_Description: 'Descrizione funzione SAP',
        SAP_feature_value_Description: 'Descrizione del valore di funzione SAP',
        SAP_feature_Description_EN: 'Descrizione funzione SAP(inglese)',
        SAP_feature_value_Description_EN: 'Descrizione del valore di funzione SAP(inglese)',
        batteryNo: 'Numero della batteria',
        qmotorNo: 'Numero motore anteriore',
        hmotorNo: 'Numero motore posteriore',
        feature_ID: 'ID Caratteristica',
        feature_Description: 'Descrizione Caratteristica (Cinese)',
        feature_Description_EN: 'Descrizione Caratteristica (Inglese)',
        feature_Codes: 'Codici Caratteristica',
        feature_value_Description: 'Descrizione Valore Caratteristica (Cinese)',
        feature_value_Description_EN: 'Descrizione Valore Caratteristica (Inglese)',
        description_zh: 'Descrizione (Cinese)',
        supplier: 'Fornitore',
        barCoding: 'Codifica a Barre',
        key_component_type_desc_En: 'Descrizione Tipo Componente Chiave (Inglese)',
        partName: 'Nome Parte (Cinese)',
        partName_En: 'Nome Parte (Inglese)',
        workstation_code: 'Codice Postazione di Lavoro',
        workstationDesc: 'Descrizione Postazione di Lavoro (Cinese)',
        workstationDesc_En: 'Descrizione Postazione di Lavoro (Inglese)',
        packageNo: 'Codice Pacco Batteria',
        packageType: 'Modello Pacco Batteria',
        moduleNo: 'Codice Modulo Batteria',
        moduleType: 'Modello Modulo',
        cellNo: 'Codice Singola Cellula',
        cellModelId: 'Modello Singola Cellula',
        uploadTime: 'Orario di Caricamento',
        vehicle_battery_data: 'Dati della Batteria del Veicolo',
    },
    // 荷兰
    nlNL: {
        factory: 'Fabriek',
        vehicle_platform: 'Voertuigplatform',
        model_year: 'Modeljaar',
        order_no: 'Ordernummer',
        order_type: 'Ordertype',
        vehicle_material_no: 'Materiaal nummer van het voertuig',
        engine_no: 'Serienummer van de motor',
        certificate_no_14: '14-cijferig certificaatnummer',
        certificate_no_15: '15-cijferig certificaatnummer',
        certificate_date: 'Datum van afdrukken van het certificaat',
        pn: 'PIN',
        uptime: 'Eindmontagetijd',
        end_time: 'Eindtijd voertuigproductie',
        bomversion: 'BOMVERSION',
        product_code: 'Productiecode',
        industrial_division: 'Productieverschilling(CKD、SKD、CBU)',
        vehicle_material_number_description: 'Beschrijving van het voertuigmateriaalnummer',
        SAIC_order_number: 'SAIC International Order Number',
        station_code: 'Fabriekscode',
        station_desc: 'Fabrieksomschrijving',
        station_number: 'Stationummer',
        station_name: 'Stationaam',
        vehicle_arrival_time: 'Aankomst van het voertuig',
        key_component_barcode: 'Strijkcode voor sleutelcomponenten',
        scan_time: 'Scantijd',
        key_component_type_code: 'Type sleutelcomponent',
        key_component_type_desc: 'Beschrijving van het type sleutelcomponent (Chinees)',
        key_component_type_descen: 'Beschrijving van het type sleutelcomponent (Engels)',
        part_number: 'onderdeelnummer',
        supplier_name: 'Naam van de leverancier',
        traceability_number: 'Traceerbaarheidsnummer',
        subcritical_component_type: 'Subkritisch componenttype',
        batch_number: 'Batchnummer',
        vehicle_base_info: 'Basisinformatie over het voertuig',
        vehicle_crossing_data: 'Gegevens over het oversteken van voertuigen',
        accurately_traceable_parts_list: 'Nauwkeurig traceerbare onderdelenlijst',
        VIN: 'VIN',
        SAP_feature_ID: 'SAP-functie-ID',
        SAP_feature_Codes: 'SAP-functiecodes',
        SAP_feature_Description: 'SAP-functiebeschrijving',
        SAP_feature_value_Description: 'SAP-functiewaardebeschrijving',
        SAP_feature_Description_EN: 'SAP-functiebeschrijving(Engels)',
        SAP_feature_value_Description_EN: 'SAP-functiewaardebeschrijving(Engels)',
        batteryNo: 'Batterijnummer',
        qmotorNo: 'Voorste motornummer',
        hmotorNo: 'Achterste motornummer',
        feature_ID: 'Kenmerk-ID',
        feature_Description: 'Kenmerkbeschrijving (Chinees)',
        feature_Description_EN: 'Kenmerkbeschrijving (Engels)',
        feature_Codes: 'Kenmerkcodes',
        feature_value_Description: 'Kenmerkwaardebeschrijving (Chinees)',
        feature_value_Description_EN: 'Kenmerkwaardebeschrijving (Engels)',
        description_zh: 'Beschrijving (Chinees)',
        supplier: 'Leverancier',
        barCoding: 'Streepjescode',
        key_component_type_desc_En: 'Beschrijving van het Type Sleutelcomponent (Engels)',
        partName: 'Onderdeelnaam (Chinees)',
        partName_En: 'Onderdeelnaam (Engels)',
        workstation_code: 'Werkstationcode',
        workstationDesc: 'Beschrijving van het Werkstation (Chinees)',
        workstationDesc_En: 'Beschrijving van het Werkstation (Engels)',
        packageNo: 'Batterijpakketcode',
        packageType: 'Batterijpakketmodel',
        moduleNo: 'Batterijmodulecode',
        moduleType: 'Modulemodel',
        cellNo: 'Enkelvoudige Celcode',
        cellModelId: 'Enkelvoudig Celmodel',
        uploadTime: 'Uploadtijd',
        vehicle_battery_data: 'Voertuigbatterijgegevens',
    },
    // 越南
    viVN: {
        factory: 'Nhà máy',
        vehicle_platform: 'Nền tảng mô hình',
        model_year: 'Mô hình năm',
        order_no: 'Số thứ tự',
        order_type: 'Loại lệnh',
        vehicle_material_no: 'Số nguyên liệu xe',
        engine_no: 'Số sê-ri động cơ',
        certificate_no_14: '14 Số chứng nhận hợp lệ',
        certificate_no_15: '15 Số chứng nhận hợp lệ',
        certificate_date: 'Ngày in giấy chứng nhận hợp lệ',
        pn: 'PIN',
        uptime: 'Tổng thời gian cài đặt',
        end_time: 'Thời gian kết thúc sản xuất xe',
        bomversion: 'BOMVERSION',
        product_code: 'Mã sản xuất',
        industrial_division: 'Phân biệt sản xuất(CKD、SKD、CBU)',
        vehicle_material_number_description: 'Toàn bộ xe Vật liệu No Description',
        SAIC_order_number: 'Số đặt hàng quốc tế SAIC',
        station_code: 'Mã nhà máy',
        station_desc: 'Mô tả nhà máy',
        station_number: 'Số trạm',
        station_name: 'Tên trạm',
        vehicle_arrival_time: 'Thời gian xe đến',
        key_component_barcode: 'Mã vạch Key Piece',
        scan_time: 'Thời gian quét',
        key_component_type_code: 'Mã hóa kiểu phần chính',
        key_component_type_desc: 'Mô tả loại thành phần chính (Tiếng Trung)',
        key_component_type_descen: 'Mô tả loại thành phần chính (Tiếng Anh)',
        part_number: 'Phần số',
        supplier_name: 'Số nhà cung cấp',
        traceability_number: 'Số truy xuất',
        subcritical_component_type: 'Loại khóa con',
        batch_number: 'Số lô',
        vehicle_base_info: 'Thông tin cơ bản về xe',
        vehicle_crossing_data: 'Xe vượt qua dữ liệu',
        accurately_traceable_parts_list: 'Danh sách phần truy xuất chính xác',
        VIN: 'VIN',
        SAP_feature_ID: 'ID tính năng SAP',
        SAP_feature_Codes: 'Mã tính năng SAP',
        SAP_feature_Description: 'Mô tả tính năng SAP',
        SAP_feature_value_Description: 'Mô tả giá trị tính năng SAP',
        SAP_feature_Description_EN: 'Mô tả tính năng SAP(Tiếng Anh)',
        SAP_feature_value_Description_EN: 'Mô tả giá trị tính năng SAP(Tiếng Anh)',
        batteryNo: 'Số pin',
        qmotorNo: 'Số máy trước',
        hmotorNo: 'Số máy sau',
        feature_ID: 'ID Tính năng',
        feature_Description: 'Mô tả Tính năng (Tiếng Trung)',
        feature_Description_EN: 'Mô tả Tính năng (Tiếng Anh)',
        feature_Codes: 'Mã Tính năng',
        feature_value_Description: 'Mô tả Giá trị Tính năng (Tiếng Trung)',
        feature_value_Description_EN: 'Mô tả Giá trị Tính năng (Tiếng Anh)',
        description_zh: 'Mô tả (Tiếng Trung)',
        supplier: 'Nhà cung cấp',
        barCoding: 'Mã Vạch',
        key_component_type_desc_En: 'Mô tả Loại Thành phần Chính (Tiếng Anh)',
        partName: 'Tên Bộ phận (Tiếng Trung)',
        partName_En: 'Tên Bộ phận (Tiếng Anh)',
        workstation_code: 'Mã Trạm Làm việc',
        workstationDesc: 'Mô tả Trạm Làm việc (Tiếng Trung)',
        workstationDesc_En: 'Mô tả Trạm Làm việc (Tiếng Anh)',
        packageNo: 'Mã Gói Pin',
        packageType: 'Mẫu Gói Pin',
        moduleNo: 'Mã Mô-đun Pin',
        moduleType: 'Mẫu Mô-đun',
        cellNo: 'Mã Tế bào Đơn',
        cellModelId: 'Mẫu Tế bào Đơn',
        uploadTime: 'Thời gian Tải lên',
        vehicle_battery_data: 'Dữ Liệu Pin Xe',
    },
    // 西班牙
    esES: {
        factory: 'Fábrica',
        vehicle_platform: 'Plataforma de modelos',
        model_year: 'Año del modelo',
        order_no: 'Número de pedido',
        order_type: 'Tipo de pedido',
        vehicle_material_no: 'Número de material del vehículo',
        engine_no: 'Número de serie del motor',
        certificate_no_14: 'Número de certificado de 14 plazas',
        certificate_no_15: 'Número de certificado de 15 plazas',
        certificate_date: 'Fecha de impresión del certificado de conformidad',
        pn: 'PIN',
        uptime: 'Tiempo de lanzamiento del montaje final',
        end_time: 'Tiempo de finalización de la producción de vehículos',
        bomversion: 'BOMVERSION',
        product_code: 'Código de producción',
        industrial_division: 'Distinción de producción(CKD、SKD、CBU)',
        vehicle_material_number_description: 'Descripción del número de material del vehículo',
        SAIC_order_number: 'SAIC International Order No.',
        station_code: 'Código de fábrica',
        station_desc: 'Descripción de la fábrica',
        station_number: 'Número de estación',
        station_name: 'Nombre de la estación',
        vehicle_arrival_time: 'Hora de llegada del vehículo',
        key_component_barcode: 'Código de barras de piezas clave',
        scan_time: 'Tiempo de escaneo',
        key_component_type_code: 'Codificación del tipo de pieza clave',
        key_component_type_desc: 'Descripción del tipo de componente clave (chino)',
        key_component_type_descen: 'Descripción del tipo de componente clave (inglés)',
        part_number: 'Número de pieza',
        supplier_name: 'Nombre del proveedor',
        traceability_number: 'Número de trazabilidad',
        subcritical_component_type: 'Tipo de pieza subcrítica',
        batch_number: 'Número de lote',
        vehicle_base_info: 'Información básica del vehículo',
        vehicle_crossing_data: 'Datos del punto de paso del vehículo',
        accurately_traceable_parts_list: 'Trazabilidad precisa de la lista de piezas',
        VIN: 'VIN',
        SAP_feature_ID: 'ID de función SAP',
        SAP_feature_Codes: 'Códigos de función SAP',
        SAP_feature_Description: 'Descripción de función SAP',
        SAP_feature_value_Description: 'Descripción del valor de función SAP',
        SAP_feature_Description_EN: 'Descripción de función SAP(inglés)',
        SAP_feature_value_Description_EN: 'Descripción del valor de función SAP(inglés)',
        batteryNo: 'Número de batería',
        qmotorNo: 'Número de motor delantero',
        hmotorNo: 'Número de motor trasero',
        feature_ID: 'ID de Característica',
        feature_Description: 'Descripción de la Característica (Chino)',
        feature_Description_EN: 'Descripción de la Característica (Inglés)',
        feature_Codes: 'Códigos de Característica',
        feature_value_Description: 'Descripción del Valor de la Característica (Chino)',
        feature_value_Description_EN: 'Descripción del Valor de la Característica (Inglés)',
        description_zh: 'Descripción (Chino)',
        supplier: 'Proveedor',
        barCoding: 'Codificación de Barras',
        key_component_type_desc_En: 'Descripción del Tipo de Componente Clave (Inglés)',
        partName: 'Nombre de la Parte (Chino)',
        partName_En: 'Nombre de la Parte (Inglés)',
        workstation_code: 'Código de Estación de Trabajo',
        workstationDesc: 'Descripción de Estación de Trabajo (Chino)',
        workstationDesc_En: 'Descripción de Estación de Trabajo (Inglés)',
        packageNo: 'Código del Paquete de Batería',
        packageType: 'Modelo del Paquete de Batería',
        moduleNo: 'Código del Módulo de Batería',
        moduleType: 'Modelo del Módulo',
        cellNo: 'Código de la Celda Individual',
        cellModelId: 'Modelo de la Celda Individual',
        uploadTime: 'Hora de Carga',
        vehicle_battery_data: 'Datos de la Batería del Vehículo',
    },
    // 葡萄牙
    ptBR: {
        factory: 'Fábrica',
        vehicle_platform: 'Plataforma do Veículo',
        model_year: 'Ano do Modelo',
        order_no: 'Número do Pedido',
        order_type: 'Tipo de Pedido',
        vehicle_material_no: 'Número de Material do Veículo',
        engine_no: 'Número de Série do Motor',
        certificate_no_14: 'Número de Certificado de 14 dígitos',
        certificate_no_15: 'Número de Certificado de 15 dígitos',
        certificate_date: 'Data de Impressão do Certificado',
        pn: 'PIN',
        uptime: 'Tempo de Montagem Total',
        end_time: 'Hora de Término da Produção do Veículo',
        bomversion: 'Versão BOM',
        product_code: 'Código de Produção',
        industrial_division: 'Divisão Industrial (CKD, SKD, CBU)',
        vehicle_material_number_description: 'Descrição do Número de Material do Veículo',
        SAIC_order_number: 'Número do Pedido SAIC',
        station_code: 'Código da fábrica',
        station_desc: 'Descrição da fábrica',
        station_number: 'Número da Estação',
        station_name: 'Nome da Estação',
        vehicle_arrival_time: 'Hora de Chegada do Veículo',
        key_component_barcode: 'Código de Barras do Componente Chave',
        scan_time: 'Hora de Digitalização',
        key_component_type_code: 'Código do Tipo de Componente Chave',
        key_component_type_desc: 'Descrição do tipo de componente chave (chinês)',
        key_component_type_descen: 'Descrição do tipo de componente chave (inglês)',
        part_number: 'Número da Peça',
        supplier_name: 'Nome do Fornecedor',
        traceability_number: 'Número de Rastreabilidade',
        subcritical_component_type: 'Tipo de Componente Subcrítico',
        batch_number: 'Número do Lote',
        vehicle_base_info: 'Informações Básicas do Veículo',
        vehicle_crossing_data: 'Dados de Passagem do Veículo',
        accurately_traceable_parts_list: 'Lista de Peças Rastreáveis com Precisão',
        VIN: 'VIN',
        SAP_feature_ID: 'ID de recurso SAP',
        SAP_feature_Codes: 'Códigos de recurso SAP',
        SAP_feature_Description: 'Descrição de recurso SAP',
        SAP_feature_value_Description: 'Descrição do valor de recurso SAP',
        SAP_feature_Description_EN: 'Descrição de recurso SAP(inglês)',
        SAP_feature_value_Description_EN: 'Descrição do valor de recurso SAP(inglês)',
        batteryNo: 'Número da bateria',
        qmotorNo: 'Número do motor dianteiro',
        hmotorNo: 'Número do motor traseiro',
        feature_ID: 'ID da Característica',
        feature_Description: 'Descrição da Característica (Chinês)',
        feature_Description_EN: 'Descrição da Característica (Inglês)',
        feature_Codes: 'Códigos da Característica',
        feature_value_Description: 'Descrição do Valor da Característica (Chinês)',
        feature_value_Description_EN: 'Descrição do Valor da Característica (Inglês)',
        description_zh: 'Descrição (Chinês)',
        supplier: 'Fornecedor',
        barCoding: 'Codificação de Barras',
        key_component_type_desc_En: 'Descrição do Tipo de Componente Principal (Inglês)',
        partName: 'Nome da Peça (Chinês)',
        partName_En: 'Nome da Peça (Inglês)',
        workstation_code: 'Código da Estação de Trabalho',
        workstationDesc: 'Descrição da Estação de Trabalho (Chinês)',
        workstationDesc_En: 'Descrição da Estação de Trabalho (Inglês)',
        packageNo: 'Código do Pacote de Bateria',
        packageType: 'Modelo do Pacote de Bateria',
        moduleNo: 'Código do Módulo de Bateria',
        moduleType: 'Modelo do Módulo',
        cellNo: 'Código da Célula Individual',
        cellModelId: 'Modelo da Célula Individual',
        uploadTime: 'Hora de Envio',
        vehicle_battery_data: 'Dados da Bateria do Veículo',
    },
    // 波兰
    plPL: {
        factory: 'Fabryka',
        vehicle_platform: 'Platforma pojazdu',
        model_year: 'Rok modelu',
        order_no: 'Numer zamówienia',
        order_type: 'Typ zamówienia',
        vehicle_material_no: 'Numer materiału pojazdu',
        engine_no: 'Numer seryjny silnika',
        certificate_no_14: 'Numer certyfikatu 14 cyfr',
        certificate_no_15: 'Numer certyfikatu 15 cyfr',
        certificate_date: 'Data wydruku certyfikatu',
        pn: 'PIN',
        uptime: 'Całkowity czas montażu',
        end_time: 'Czas zakończenia produkcji pojazdu',
        bomversion: 'Wersja BOM',
        product_code: 'Kod produktu',
        industrial_division: 'Podział przemysłowy (CKD, SKD, CBU)',
        vehicle_material_number_description: 'Opis numeru materiału pojazdu',
        SAIC_order_number: 'Numer zamówienia SAIC',
        station_code: 'Kod fabryki',
        station_desc: 'Opis fabryki',
        station_number: 'Numer stanowiska',
        station_name: 'Nazwa stanowiska',
        vehicle_arrival_time: 'Czas przybycia pojazdu',
        key_component_barcode: 'Kod kreskowy kluczowego komponentu',
        scan_time: 'Czas skanowania',
        key_component_type_code: 'Kod typu kluczowego komponentu',
        key_component_type_desc: 'Opis typu kluczowego komponentu (chiński)',
        key_component_type_descen: 'Opis typu kluczowego komponentu (angielski)',
        part_number: 'Numer części',
        supplier_name: 'Nazwa dostawcy',
        traceability_number: 'Numer śledzenia',
        subcritical_component_type: 'Typ podkluczowego komponentu',
        batch_number: 'Numer partii',
        vehicle_base_info: 'Podstawowe informacje o pojeździe',
        vehicle_crossing_data: 'Dane przechodzenia pojazdu',
        accurately_traceable_parts_list: 'Lista dokładnie śledzonych części',
        VIN: 'VIN',
        SAP_feature_ID: 'ID funkcji SAP',
        SAP_feature_Codes: 'Kody funkcji SAP',
        SAP_feature_Description: 'Opis funkcji SAP',
        SAP_feature_value_Description: 'Opis wartości funkcji SAP',
        SAP_feature_Description_EN: 'Opis funkcji SAP(angielski)',
        SAP_feature_value_Description_EN: 'Opis wartości funkcji SAP(angielski)',
        batteryNo: 'Numer baterii',
        qmotorNo: 'Numer silnika przedniego',
        hmotorNo: 'Numer silnika tylnego',
        feature_ID: 'ID Cechy',
        feature_Description: 'Opis Cechy (Chiński)',
        feature_Description_EN: 'Opis Cechy (Angielski)',
        feature_Codes: 'Kody Cechy',
        feature_value_Description: 'Opis Wartości Cechy (Chiński)',
        feature_value_Description_EN: 'Opis Wartości Cechy (Angielski)',
        description_zh: 'Opis (Chiński)',
        supplier: 'Dostawca',
        barCoding: 'Kodowanie Kreskowe',
        key_component_type_desc_En: 'Opis Typu Kluczowego Komponentu (Angielski)',
        partName: 'Nazwa Części (Chiński)',
        partName_En: 'Nazwa Części (Angielski)',
        workstation_code: 'Kod Stanowiska Pracy',
        workstationDesc: 'Opis Stanowiska Pracy (Chiński)',
        workstationDesc_En: 'Opis Stanowiska Pracy (Angielski)',
        packageNo: 'Kod Pakietu Baterii',
        packageType: 'Model Pakietu Baterii',
        moduleNo: 'Kod Modułu Baterii',
        moduleType: 'Model Modułu',
        cellNo: 'Kod Pojedynczej Komórki',
        cellModelId: 'Model Pojedynczej Komórki',
        uploadTime: 'Czas Przesyłania',
        vehicle_battery_data: 'Dane Baterii Pojazdu',
    },
    // 土耳其语
    trTR: {
        factory: 'bitki',
        vehicle_platform: 'araç platformu',
        model_year: 'model yılı',
        order_no: 'sipariş numarası',
        order_type: 'Sipariş Türü',
        vehicle_material_no: 'araç malzeme numarası',
        engine_no: 'motor numarası',
        certificate_no_14: '14 rakam sertifikat numarası',
        certificate_no_15: '15 rakam sertifikat numarası',
        certificate_date: 'Sertifikat yazdırma tarihi',
        pn: 'PIN',
        uptime: 'çalışma süresi',
        end_time: 'Araç üretim bitiş zamanı',
        bomversion: 'BOMVERSION',
        product_code: 'ÜRÜN KODU',
        industrial_division: 'üretim ayrı(CKD、SKD、CBU)',
        vehicle_material_number_description: 'araç malzeme numarasi açiklama',
        SAIC_order_number: 'SAIC sipariş numarası',
        station_code: 'Fabrika Kodu',
        station_desc: 'Fabrika Açıklaması',
        station_number: 'İş İstasyonu No.',
        station_name: 'İş istasyonunun adı',
        vehicle_arrival_time: 'Araç geldiği zaman',
        key_component_barcode: 'anahtar bileşen barkodu',
        scan_time: 'tarama süresi',
        key_component_type_code: 'Anahtar bileşen tipi kodu',
        key_component_type_desc: 'Ana Bileşen Türü Açıklaması (Çince)',
        key_component_type_descen: 'Ana Bileşen Türü Açıklaması (İngilizce)',
        part_number: 'parça numarası',
        supplier_name: 'tedari̇kçi̇ numarasi',
        traceability_number: 'geriye dönük sayı',
        subcritical_component_type: 'Alt-kritik bileşen tipi',
        batch_number: 'parti numarası',
        vehicle_base_info: 'Temel Araç Bilgileri',
        vehicle_crossing_data: 'araç geçi̇ş veri̇leri̇',
        accurately_traceable_parts_list: 'doğru bir şekilde izlenebilir parça listesi',
        VIN: 'VIN',
        SAP_feature_ID: 'SAP özellik kimliği',
        SAP_feature_Codes: 'SAP özellik kodları',
        SAP_feature_Description: 'SAP özellik açıklaması',
        SAP_feature_value_Description: 'SAP özellik değeri açıklaması',
        SAP_feature_Description_EN: 'SAP özellik açıklaması(İngilizce)',
        SAP_feature_value_Description_EN: 'SAP özellik değeri açıklaması(İngilizce)',
        batteryNo: 'Batarya Numarası',
        qmotorNo: 'Ön Motor Numarası',
        hmotorNo: 'Arka Motor Numarası',
        feature_ID: 'Özellik Kimliği',
        feature_Description: 'Özellik Açıklaması (Çince)',
        feature_Description_EN: 'Özellik Açıklaması (İngilizce)',
        feature_Codes: 'Özellik Kodları',
        feature_value_Description: 'Özellik Değeri Açıklaması (Çince)',
        feature_value_Description_EN: 'Özellik Değeri Açıklaması (İngilizce)',
        description_zh: 'Açıklama (Çince)',
        supplier: 'Tedarikçi',
        barCoding: 'Barkodlama',
        key_component_type_desc_En: 'Ana Bileşen Türü Açıklaması (İngilizce)',
        partName: 'Parça Adı (Çince)',
        partName_En: 'Parça Adı (İngilizce)',
        workstation_code: 'Çalışma İstasyonu Kodu',
        workstationDesc: 'Çalışma İstasyonu Açıklaması (Çince)',
        workstationDesc_En: 'Çalışma İstasyonu Açıklaması (İngilizce)',
        packageNo: 'Pil Paketi Kodu',
        packageType: 'Pil Paketi Modeli',
        moduleNo: 'Pil Modülü Kodu',
        moduleType: 'Modül Modeli',
        cellNo: 'Tek Hücre Kodu',
        cellModelId: 'Tek Hücre Modeli',
        uploadTime: 'Yükleme Zamanı',
        vehicle_battery_data: 'Araç Batarya Verileri',
    },
    // 捷克语
    csCZ: {
        factory: 'Továrna',
        vehicle_platform: 'Platforma vozidla',
        model_year: 'Modelový rok',
        order_no: 'Objednávka číslo',
        order_type: 'Typ objednávky',
        vehicle_material_no: 'Materiál vozidla č',
        engine_no: 'číslo motoru',
        certificate_no_14: 'Certifikát číslo 14',
        certificate_no_15: 'Certifikát číslo 15',
        certificate_date: 'Datum certifikátu',
        pn: 'Pn',
        uptime: 'Konečný čas montáže',
        end_time: 'Čas ukončení výroby vozidla',
        bomversion: 'BOMVERSION',
        product_code: 'Kód produktu',
        industrial_division: 'Průmyslová divize (CKD, SKD, CBU)',
        vehicle_material_number_description: 'Popis materiálu vozidla',
        SAIC_order_number: 'Číslo mezinárodní objednávky SAIC',
        station_code: 'Kód pracovní stanice',
        station_desc: 'Popis stanice',
        station_number: 'Číslo stanice',
        station_name: 'Název pracovní stanice',
        vehicle_arrival_time: 'Čas příjezdu vozidla',
        key_component_barcode: 'Čárový kód klíčové součásti',
        scan_time: 'Čas skenování',
        key_component_type_code: 'Typový kód klíčové součásti',
        key_component_type_desc: 'Čínský popis typů klíčových komponent',
        key_component_type_descen: 'Anglický popis typů klíčových komponent',
        part_number: 'Číslo dílu',
        supplier_name: 'Jméno dodavatele',
        traceability_number: 'Číslo sledovatelnosti',
        subcritical_component_type: 'Typ dílčí kritické součásti',
        batch_number: 'Číslo série',
        vehicle_base_info: 'Základní informace o vozidle',
        vehicle_crossing_data: 'Data o průjezdu vozidla',
        accurately_traceable_parts_list: 'Přesně sledovatelný seznam dílů',
        vehicle_configuration_information: 'Informace o konfiguraci vozidla',
        VIN: 'VIN',
        SAP_feature_ID: 'ID funkce SAP',
        SAP_feature_Codes: 'Kódy funkcí SAP',
        SAP_feature_Description: 'Popis funkce SAP',
        SAP_feature_value_Description: 'Popis hodnoty funkce SAP',
        SAP_feature_Description_EN: 'Popis funkce SAP (anglicky)',
        SAP_feature_value_Description_EN: 'Popis hodnoty funkce SAP (anglicky)',
        batteryNo: 'Číslo baterie',
        qmotorNo: 'Číslo předního motoru',
        hmotorNo: 'Číslo zadního motoru',
        feature_ID: 'ID funkce',
        feature_Description: 'Popis funkce (čínsky)',
        feature_Description_EN: 'Popis funkce (anglicky)',
        feature_Codes: 'Kódy funkce',
        feature_value_Description: 'Popis hodnoty funkce (čínsky)',
        feature_value_Description_EN: 'Popis hodnoty funkce (anglicky)',
        description_zh: 'Čínský popis',
        supplier: 'Dodavatel',
        barCoding: 'Čárové kódování',
        key_component_type_desc_En: 'Anglický popis typů klíčových komponent',
        partName: 'Název dílu (čínsky)',
        partName_En: 'Název dílu (anglicky)',
        workstation_code: 'Kód pracovní stanice',
        workstationDesc: 'Popis stanice (čínsky)',
        workstationDesc_En: 'Popis stanice (anglicky)',
        packageNo: 'Číslo bateriového balíku',
        packageType: 'Typ bateriového balíku',
        moduleNo: 'Číslo bateriového modulu',
        moduleType: 'Typ modulu',
        cellNo: 'Číslo jednotlivé baterie',
        cellModelId: 'Model jednotlivé baterie',
        uploadTime: 'Čas nahrání',
        vehicle_battery_data: 'Data baterie vozidla',
    },
    // 匈牙利语
    huHU: {
        factory: 'Gyár',
        vehicle_platform: 'Járműplatform',
        model_year: 'Modellév',
        order_no: 'Rendeléssz.',
        order_type: 'Rendeléstípus',
        vehicle_material_no: 'Jármű anyagsz.',
        engine_no: 'Motorsz.',
        certificate_no_14: '14. sz. tanúsítvány',
        certificate_no_15: '15. sz. tanúsítvány',
        certificate_date: 'Tanúsítvány dátuma',
        pn: 'Cikksz.',
        uptime: 'Végső összeszerelés megkezdésének időpontja',
        end_time: 'Jármű gyártásának befejezési ideje',
        bomversion: 'BOM-VERZIÓ',
        product_code: 'Termékkód',
        industrial_division: 'Ipari részleg (CKD, SKD, CBU)',
        vehicle_material_number_description: 'Jármű anyagszám leírása',
        SAIC_order_number: 'SAIC International megrendelésszám',
        station_code: 'Állomáskód',
        station_desc: 'Állomásleírás',
        station_number: 'Állomásszám',
        station_name: 'Állomásnév',
        vehicle_arrival_time: 'Jármű érkezési ideje',
        key_component_barcode: 'Fődarab vonalkód',
        scan_time: 'Beolvasás ideje',
        key_component_type_code: 'Fődarab típuskódja',
        key_component_type_desc: 'Fődarabtípusok kínai leírása',
        key_component_type_descen: 'Fődarabtípusok angol leírása',
        part_number: 'Alkatrészszám',
        supplier_name: 'Beszállító neve',
        traceability_number: 'Nyomkövetési szám',
        subcritical_component_type: 'Alegység száma',
        batch_number: 'Kötegszám',
        vehicle_base_info: 'Jármű alapvető információi',
        vehicle_crossing_data: 'Járműátadási adatok',
        accurately_traceable_parts_list: 'Pontosan nyomon követhető alkatrészek listája',
        vehicle_configuration_information: 'Járműkonfigurációs adatok',
        VIN: 'VIN',
        SAP_feature_ID: 'SAP-funkcióazonosító',
        SAP_feature_Codes: 'SAP-funkciókódok',
        SAP_feature_Description: 'SAP-funkcióleírás',
        SAP_feature_value_Description: 'SAP-funkcióértékleírás',
        SAP_feature_Description_EN: 'SAP-funkcióleírás (angol)',
        SAP_feature_value_Description_EN: 'SAP-funkcióértékleírás (angol)',
        batteryNo: 'Akkumulátor száma',
        qmotorNo: 'Első motor száma',
        hmotorNo: 'Hátsó motor száma',
        feature_ID: 'Funkcióazonosító',
        feature_Description: 'Funkcióleírás (kínai)',
        feature_Description_EN: 'Funkcióleírás (angol)',
        feature_Codes: 'Funkciókódok',
        feature_value_Description: 'Funkcióérték leírása (kínai)',
        feature_value_Description_EN: 'Funkcióérték leírása (angol)',
        description_zh: 'Kínai leírás',
        supplier: 'Beszállító',
        barCoding: 'Vonalkód',
        key_component_type_desc_En: 'Fődarabtípusok angol leírása',
        partName: 'Alkatrész kínai neve',
        partName_En: 'Alkatrész angol neve',
        workstation_code: 'Munkapont kód',
        workstationDesc: 'Munkapont leírása (kínai)',
        workstationDesc_En: 'Munkapont leírása (angol)',
        packageNo: 'Akkumulátor csomag kódja',
        packageType: 'Akkumulátor csomag típusa',
        moduleNo: 'Akkumulátor modul kódja',
        moduleType: 'Modul típusa',
        cellNo: 'Cellás akkumulátor kód',
        cellModelId: 'Cellás típusa',
        uploadTime: 'Jelentési idő',
        vehicle_battery_data: 'Jármű pontos nyomkövetési akkumulátor adatok',
    },
    // 俄罗斯语
    ruRU: {
        factory: 'Завод',
        vehicle_platform: 'Платформа автомобиля',
        model_year: 'Год модели',
        order_no: 'Номер заказа',
        order_type: 'Тип заказа',
        vehicle_material_no: 'Номер материала автомобиля',
        engine_no: 'Номер двигателя',
        certificate_no_14: 'Сертификат № 14',
        certificate_no_15: 'Сертификат № 15',
        certificate_date: 'Дата сертификата',
        pn: 'Pn',
        uptime: 'Время запуска окончательной сборки',
        end_time: 'Время завершения производства автомобиля',
        bomversion: 'BOMVERSION',
        product_code: 'Код продукта',
        industrial_division: 'Промышленное подразделение (CKD, SKD, CBU)',
        vehicle_material_number_description: 'Описание номера материала автомобиля',
        SAIC_order_number: 'Номер международного заказа SAIC',
        station_code: 'Код станции',
        station_desc: 'Описание станции',
        station_number: 'Номер станции',
        station_name: 'Название станции',
        vehicle_arrival_time: 'Время прибытия автомобиля',
        key_component_barcode: 'Штрих-код ключевого компонента',
        scan_time: 'Время сканирования',
        key_component_type_code: 'Код типа ключевого компонента',
        key_component_type_desc: 'Описание типа ключевого компонента на китайском',
        key_component_type_descen: 'Описание типа ключевого компонента на английском',
        part_number: 'Номер детали',
        supplier_name: 'Имя поставщика',
        traceability_number: 'Номер отслеживания',
        subcritical_component_type: 'Подкритический компонент',
        batch_number: 'Номер партии',
        vehicle_base_info: 'Основная информация об автомобиле',
        vehicle_crossing_data: 'Данные о пересечении автомобиля',
        accurately_traceable_parts_list: 'Точно отслеживаемый список деталей',
        vehicle_configuration_information: 'Информация о конфигурации автомобиля',
        VIN: 'VIN',
        SAP_feature_ID: 'ID функции SAP',
        SAP_feature_Codes: 'Коды функций SAP',
        SAP_feature_Description: 'Описание функций SAP',
        SAP_feature_value_Description: 'Описание значений функций SAP',
        SAP_feature_Description_EN: 'Описание на английском',
        SAP_feature_value_Description_EN: 'Описание значений функций SAP на английском',
        batteryNo: 'Номер батареи',
        qmotorNo: 'Номер переднего мотора',
        hmotorNo: 'Номер заднего мотора',
        feature_ID: 'ID функции',
        feature_Description: 'Описание функции (китайский)',
        feature_Description_EN: 'Описание функции (английский)',
        feature_Codes: 'Коды функций',
        feature_value_Description: 'Описание значений функций (китайский)',
        feature_value_Description_EN: 'Описание значений функций (английский)',
        description_zh: 'Описание (китайский)',
        supplier: 'Поставщик',
        barCoding: 'Штрих-код',
        key_component_type_desc_En: 'Описание типа ключевого компонента (английский)',
        partName: 'Название детали (китайский)',
        partName_En: 'Название детали (английский)',
        workstation_code: 'Код рабочего места',
        workstationDesc: 'Описание рабочего места (китайский)',
        workstationDesc_En: 'Описание рабочего места (английский)',
        packageNo: 'Код упаковки батареи',
        packageType: 'Модель упаковки батареи',
        moduleNo: 'Код модуля батареи',
        moduleType: 'Модель модуля',
        cellNo: 'Код отдельной ячейки',
        cellModelId: 'Модель отдельной ячейки',
        uploadTime: 'Время загрузки',
        vehicle_battery_data: 'Данные о батарее автомобиля',
    },
};

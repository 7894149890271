export default {
    // 中
    zhCN: {
        five_day_engineer_response_rate_report: '5天工程师答复率报表',
        five_day_closure_rate_statistics: '5天关闭率统计',
        Number_of_cases_in: '案件数',
        Closed_cases_in: '已关闭',
        Number_of_closures_in_five_days_in: '5天关闭数',
    },
    // 英
    enUS: {
        five_day_engineer_response_rate_report: '5-Day Engineer Response Rate Report',
        five_day_closure_rate_statistics: '5-Day Closure Rate Statistics',
        Number_of_cases_in: 'Number of Cases in',
        Closed_cases_in: 'Closed Cases in',
        Number_of_closures_in_five_days_in: 'Number of Closures in 5 Days',
    },
    // 德
    deDE: {
        five_day_engineer_response_rate_report: 'Ingenieur-Antwortrate-Bericht für 5 Tage',
        five_day_closure_rate_statistics: 'Schließungsrate-Statistik für 5 Tage',
        Number_of_cases_in: 'Number of Cases in',
        Closed_cases_in: 'Closed Cases in',
        Number_of_closures_in_five_days_in: 'Number of Closures in 5 Days',
    },
    // 法
    frFR: {
        five_day_engineer_response_rate_report:
            'Rapport sur le taux de réponse des ingénieurs en 5 jours',
        five_day_closure_rate_statistics: 'Statistiques sur le taux de fermeture en 5 jours',
        Number_of_cases_in: 'Number of Cases in',
        Closed_cases_in: 'Closed Cases in',
        Number_of_closures_in_five_days_in: 'Number of Closures in 5 Days',
    },
    // 意大利
    itIT: {
        five_day_engineer_response_rate_report:
            'Rapporto sul tasso di risposta degli ingegneri in 5 giorni',
        five_day_closure_rate_statistics: 'Statistiche sul tasso di chiusura in 5 giorni',
        Number_of_cases_in: 'Number of Cases in',
        Closed_cases_in: 'Closed Cases in',
        Number_of_closures_in_five_days_in: 'Number of Closures in 5 Days',
    },
    // 荷兰
    nlNL: {
        five_day_engineer_response_rate_report:
            'Rapport over het reactiepercentage van ingenieurs gedurende 5 dagen',
        five_day_closure_rate_statistics: 'Statistieken over de sluitingsgraad in 5 dagen',
        Number_of_cases_in: 'Number of Cases in',
        Closed_cases_in: 'Closed Cases in',
        Number_of_closures_in_five_days_in: 'Number of Closures in 5 Days',
    },
    // 越南
    viVN: {
        five_day_engineer_response_rate_report: 'Báo cáo tỷ lệ phản hồi của kỹ sư trong 5 ngày',
        five_day_closure_rate_statistics: 'Thống kê tỷ lệ đóng cửa trong 5 ngày',
        Number_of_cases_in: 'Number of Cases in',
        Closed_cases_in: 'Closed Cases in',
        Number_of_closures_in_five_days_in: 'Number of Closures in 5 Days',
    },
    // 西班牙
    esES: {
        five_day_engineer_response_rate_report:
            'Informe sobre la tasa de respuesta de ingenieros en 5 días',
        five_day_closure_rate_statistics: 'Estadísticas de la tasa de cierre en 5 días',
        Number_of_cases_in: 'Number of Cases in',
        Closed_cases_in: 'Closed Cases in',
        Number_of_closures_in_five_days_in: 'Number of Closures in 5 Days',
    },
    // 葡萄牙
    ptBR: {
        five_day_engineer_response_rate_report:
            'Relatório de taxa de resposta de engenheiros em 5 dias',
        five_day_closure_rate_statistics: 'Estatísticas da taxa de fechamento em 5 dias',
        Number_of_cases_in: 'Number of Cases in',
        Closed_cases_in: 'Closed Cases in',
        Number_of_closures_in_five_days_in: 'Number of Closures in 5 Days',
    },
    // 波兰
    plPL: {
        five_day_engineer_response_rate_report:
            'Raport wskaźnika odpowiedzi inżynierów w ciągu 5 dni',
        five_day_closure_rate_statistics: 'Statystyki wskaźnika zamknięcia na przestrzeni 5 dni',
        Number_of_cases_in: 'Number of Cases in',
        Closed_cases_in: 'Closed Cases in',
        Number_of_closures_in_five_days_in: 'Number of Closures in 5 Days',
    },
    // 土耳其
    trTR: {
        five_day_engineer_response_rate_report: '5 Günlük Mühendis Yanıt Oranı Raporu',
        five_day_closure_rate_statistics: '5 Günlük Kapanış Oranı İstatistikleri',
        Number_of_cases_in: 'Number of Cases in',
        Closed_cases_in: 'Closed Cases in',
        Number_of_closures_in_five_days_in: 'Number of Closures in 5 Days',
    },
    // 捷克语
    csCZ: {
        five_day_engineer_response_rate_report: 'Statistika odpovědi technika za 5 dní',
        five_day_closure_rate_statistics: 'Statistika uzavřených případů za 5 dní',
        Number_of_cases_in: 'Počet případů v',
        Closed_cases_in: 'Uzavřené případy v',
        Number_of_closures_in_five_days_in: 'Počet uzavřených případů za 5 dní',
    },
    // 匈牙利语
    huHU: {
        five_day_engineer_response_rate_report: 'Kimutatás az 5 napos szerelői reagálási arányról',
        five_day_closure_rate_statistics: '5 napos esetlezárási aránystatisztikák',
        Number_of_cases_in: 'Esetek száma itt:',
        Closed_cases_in: 'Lezárt esetek itt:',
        Number_of_closures_in_five_days_in: '5 napon belüli lezárások',
    },
    // 俄罗斯
    ruRU: {
        five_day_engineer_response_rate_report: 'Отчет о скорости ответа инженера за 5 дней',
        five_day_closure_rate_statistics: 'Статистика закрытия за 5 дней',
        Number_of_cases_in: 'Количество дел в',
        Closed_cases_in: 'Закрытые дела в',
        Number_of_closures_in_five_days_in: 'Количество закрытий за 5 дней',
    },
};

export default {
    // 中
    zhCN: {
        activity_code: '活动代码',
        activity_name: '活动名称',
        responsible_party: '责任方',
        release_date: '发布日期',
        data_sources: '数据来源',
        recall_campaign_type: '服务活动类型',
        service_activity_code: '服务活动代码',
        service_activity_name: '服务活动名称',
        buyout_company: '买断公司',
        audit_company: '审核公司',
        currency: '币种',
        money: '金额',
        start_date: '开始日期',
        end_date: '结束日期',
        failure_mode: '失效模式',
        activity_programme: '活动方案',
        improve_handling: '改善措施',
        activity_information: '活动信息',
        vehicle_involved_list: '未完成车辆列表',
        claim_vehicles_list: '索赔发生车辆列表',
        claim_statistics: '索赔统计',
        technical_document_attachments: '技术文档附件',
        vin: 'VIN',
        is_complete: '是否完成',
        warranty_claims: '索赔单号',
        customer: '总代号',
        claim_proportion: '索赔完成率',
        claim_settled_amount: '索赔已结算金额',
        is_claim_again: '是否再索赔',
        cancel_succeed: '取消成功',
        number_of_vehicles_involved: '未完成车辆数量',
        number_of_vehicles_in_which_the_claim_occurred: '索赔发生车辆数量',
        series: '车系',
        platform: '车型平台',
        activity_region: '活动区域',
        activity_vehicleNum: '车辆数量',
        number_of_claims: '索赔数量',
        activity_vehicle: '活动车辆',
        vehicle_total_num: '车辆总数',
    },
    // 英
    enUS: {
        activity_code: 'Activity code',
        activity_name: 'Activity name',
        responsible_party: 'Responsible party',
        release_date: 'Release date',
        data_sources: 'Data sources',
        recall_campaign_type: 'Recall type',
        service_activity_code: 'Service activity code',
        service_activity_name: 'Service activity name',
        buyout_company: 'Buy-off company',
        audit_company: 'Approval company',
        currency: 'Currency',
        money: 'Amount',
        start_date: 'Start date',
        end_date: 'End date',
        failure_mode: 'Failure mode',
        activity_programme: 'Campaign plan',
        improve_handling: 'Improvement measures',
        activity_information: 'Campaign information',
        vehicle_involved_list: 'Incomplete vehicle list',
        claim_vehicles_list: 'Vehicles claim occurred',
        claim_statistics: 'Claim statistics',
        technical_document_attachments: 'Technical document attachments',
        vin: 'VIN',
        is_complete: 'Completed',
        warranty_claims: 'Warranty claim',
        customer: 'Customer',
        claim_proportion: 'Claim completion rate',
        claim_settled_amount: 'Claim settled amount',
        is_claim_again: 'Whether reclaim',
        cancel_succeed: 'Cancel succeed',
        number_of_vehicles_involved: 'Number of incomplete vehicles',
        number_of_vehicles_in_which_the_claim_occurred:
            'Number of vehicles in which the claim occurred',
        series: 'Series',
        platform: 'Vehicle platform',
        activity_region: 'Activity region',
        activity_vehicleNum: 'Activity vehicleNum',
        number_of_claims: 'Number of claims',
        activity_vehicle: 'Activity vehicle',
        vehicle_total_num: 'Total umber of vehicle',
    },
    // 德
    deDE: {
        activity_code: 'Aktivitätscode',
        activity_name: 'Aktivitätsname',
        responsible_party: 'verantwortliche Partei',
        release_date: 'Veröffentlichungsdatum',
        data_sources: 'Datenquellen',
        recall_campaign_type: 'Art der Dienstaktivität',
        service_activity_code: 'Code der Dienstaktivität',
        service_activity_name: 'Name der Dienstaktivität',
        buyout_company: 'Buy-off-Unternehmen',
        audit_company: 'Genehmigungsunternehmen',
        currency: 'Währung',
        money: 'Betrag',
        start_date: 'Startdatum',
        end_date: 'Enddatum',
        failure_mode: 'Fehlermodus',
        activity_programme: 'Kampagnenplan',
        improve_handling: 'Verbesserungsmaßnahmen',
        activity_information: 'Aktivitätsinformationen',
        vehicle_involved_list: 'Unvollständige Fahrzeugliste',
        claim_vehicles_list: 'Fahrzeuge, bei denen Ansprüche geltend gemacht wurden',
        claim_statistics: 'Anspruchsstatistik',
        technical_document_attachments: 'Anlagen technischer Dokumente',
        vin: 'VIN',
        is_complete: 'Abgeschlossen',
        warranty_claims: 'Forderungsnummer',
        customer: 'Allgemeiner Code',
        claim_proportion: 'Anspruchserfüllungsquote',
        claim_settled_amount: 'beglichene Ansprüche',
        is_claim_again: 'Ob Anspruch erneut geltend gemacht wird',
        cancel_succeed: 'Stornierung erfolgreich',
        number_of_vehicles_involved: 'Anzahl unvollständiger Fahrzeuge',
        number_of_vehicles_in_which_the_claim_occurred:
            'Zentrale. Classic meldet abfahrt der fahrzeuge',
        series: 'Fahrzeugserien',
        platform: 'Fahrzeugplattform',
        activity_region: 'Aktivität Region',
        activity_vehicleNum: 'Aktivität Fahrzeugnummer',
        number_of_claims: 'Anzahl der Forderungen',
        activity_vehicle: 'Aktivitätsfahrzeug',
        vehicle_total_num: 'Gesamtanzahl der Fahrzeuge',
    },
    // 法
    frFR: {
        activity_code: 'Code de l\'activité',
        activity_name: 'Nom de l\'activité',
        responsible_party: 'Partie responsable',
        release_date: 'Date de publication',
        data_sources: 'Sources de données',
        recall_campaign_type: 'Type de Rappel',
        service_activity_code: 'Code de la campagne de service',
        service_activity_name: 'Nom de la campagne de service',
        buyout_company: 'Société de rachat',
        audit_company: 'Société d\'approbation',
        currency: 'Monaie',
        money: 'Montant',
        start_date: 'Date de début',
        end_date: 'Date de fin',
        failure_mode: 'Défaillance',
        activity_programme: 'Plan de la campagne',
        improve_handling: 'Solutions',
        activity_information: 'Informations sur la campagnes',
        vehicle_involved_list: 'Liste des véhicules inachevés',
        claim_vehicles_list: 'Réclamation par véhicule',
        claim_statistics: 'Statistiques',
        technical_document_attachments: 'Pièces jointes de document technique',
        vin: 'VIN',
        is_complete: 'Terminé',
        warranty_claims: 'Numéro de réclamation',
        customer: 'Nom de code total',
        claim_proportion: 'Taux d\'achèvement des réclamations',
        claim_settled_amount: 'Montant réglé de la réclamation',
        is_claim_again: 'Récupération ou non',
        cancel_succeed: 'Annulation réussie',
        number_of_vehicles_involved: 'Nombre de véhicules inachevés',
        number_of_vehicles_in_which_the_claim_occurred:
            'Nombre de véhicules impliqués dans la réclamation',
        series: 'Système de voiture',
        platform: 'Plate - forme de modèle de voiture',
        activity_region: 'Activités Région',
        activity_vehicleNum: 'Activité vehicleNum',
        number_of_claims: 'Nombre de réclamations',
        activity_vehicle: 'Véhicule d\'activité',
        vehicle_total_num: 'Nombre total de véhicules',
    },
    // 意大利
    itIT: {
        activity_code: 'Codice attività',
        activity_name: 'Nome attività',
        responsible_party: 'Parte responsabile',
        release_date: 'Data di rilascio',
        data_sources: 'Fonti dati',
        recall_campaign_type: 'Tipo di attività di servizio',
        service_activity_code: 'Codice delle attività di servizio',
        service_activity_name: 'Nome dell’attività di servizio',
        buyout_company: 'Società di acquisto',
        audit_company: 'Società di approvazione',
        currency: 'Valuta',
        money: 'Importo',
        start_date: 'Data di inizio',
        end_date: 'Data di fine',
        failure_mode: 'Modalità di guasto',
        activity_programme: 'Piano della campagna',
        improve_handling: 'Miglioramento delle misure',
        activity_information: 'Informazioni sull\'attività',
        vehicle_involved_list: 'Elenco dei veicoli incompleto',
        claim_vehicles_list: 'Veicoli che hanno avuto un reclamo',
        claim_statistics: 'Statistiche sui reclami',
        technical_document_attachments: 'Allegati documento tecnico',
        vin: 'VIN',
        is_complete: 'Completato',
        warranty_claims: 'Numero della domanda',
        customer: 'Codice generale',
        claim_proportion: 'Tasso di completamento dei crediti',
        claim_settled_amount: 'Importo risolto del reclamo',
        is_claim_again: 'Se riclaimare',
        cancel_succeed: 'Annullamento riuscito',
        number_of_vehicles_involved: 'Numero di veicoli incompleti',
        number_of_vehicles_in_which_the_claim_occurred: 'Numero di veicoli dichiarati',
        series: 'Serie auto',
        platform: 'Piattaforma del veicolo',
        activity_region: 'Attività regione',
        activity_vehicleNum: 'Attività vehicleNum',
        number_of_claims: 'Numero di crediti',
        activity_vehicle: 'Veicolo di attività',
        vehicle_total_num: 'Numero totale di veicoli',
    },
    // 荷兰
    nlNL: {
        activity_code: 'Activiteit code',
        activity_name: 'Activiteit naam',
        responsible_party: 'Verantwoordelijke partij',
        release_date: 'Uitgiftedatum',
        data_sources: 'Gegevensbronnen',
        recall_campaign_type: 'Type dienstactiviteit',
        service_activity_code: 'Code dienstactiviteit',
        service_activity_name: 'Naam dienstactiviteit',
        buyout_company: 'Uitkoopbedrijf',
        audit_company: 'Goedkeuringsbedrijf',
        currency: 'Valuta',
        money: 'Bedrag',
        start_date: 'Startdatum',
        end_date: 'Einddatum',
        failure_mode: 'Foutmodus',
        activity_programme: 'Campagneplan',
        improve_handling: 'Verbeteringsmaatregelen',
        activity_information: 'Activiteitsinformatie',
        vehicle_involved_list: 'Onvolledige voertuiglijst',
        claim_vehicles_list: 'Voertuigen waarvoor een claim is ingediend',
        claim_statistics: 'Claimstatistieken',
        technical_document_attachments: 'Technische documentbijlagen',
        vin: 'VIN',
        is_complete: 'Voltooid',
        warranty_claims: 'Claimnummer',
        customer: 'Algemene code',
        claim_proportion: 'Afwikkelingspercentage',
        claim_settled_amount: 'Afgehandeld bedrag van claim',
        is_claim_again: 'Of opnieuw claimen',
        cancel_succeed: 'Annuleren gelukt',
        number_of_vehicles_involved: 'Aantal onvolledige voertuigen',
        number_of_vehicles_in_which_the_claim_occurred:
            'Het aantal voertuigen waarvoor de claim is ingediend',
        series: 'Autoserie',
        platform: 'Voertuigplatform',
        activity_region: 'Activiteit regio',
        activity_vehicleNum: 'Activiteit vehicleNum',
        number_of_claims: 'Aantal vorderingen',
        activity_vehicle: 'Activiteitsvoertuig',
        vehicle_total_num: 'Totaal aantal voertuigen',
    },
    // 越南
    viVN: {
        activity_code: 'Mã hoạt động',
        activity_name: 'Tên hoạt động',
        responsible_party: 'Bên chịu trách nhiệm',
        release_date: 'Ngày phát hành',
        data_sources: 'Nguồn dữ liệu',
        recall_campaign_type: 'Loại triệu hồi',
        service_activity_code: 'Mã chiến dịch dịch vụ',
        service_activity_name: 'Tên chiến dịch dịch vụ',
        buyout_company: 'Công ty mua lại',
        audit_company: 'Công ty phê duyệt',
        currency: 'Tiền tệ',
        money: 'Giá trị',
        start_date: 'Ngày bắt đầu',
        end_date: 'Ngày kết thúc',
        failure_mode: 'Chế độ lỗi',
        activity_programme: 'Kế hoạch chiến dịch',
        improve_handling: 'Biện pháp cải tiến',
        activity_information: 'Thông tin chiến dịch',
        vehicle_involved_list: 'Danh sách xe chưa hoàn thành',
        claim_vehicles_list: 'Xe yêu cầu đã xẩy ra',
        claim_statistics: 'Thống kê yêu cầu bồi thường',
        technical_document_attachments: 'Tệp đính kèm tài liệu kỹ thuật',
        vin: 'Số khung',
        is_complete: 'Đã hoàn thành',
        warranty_claims: 'Số yêu cầu bảo hành',
        customer: 'Khách hàng',
        claim_proportion: 'Tỷ lệ hoàn thành yêu cầu bồi thường',
        claim_settled_amount: 'Số tiền đã giải quyết yêu cầu bồi thường',
        is_claim_again: 'Có khiếu nại lại hay không',
        cancel_succeed: 'Hủy thành công',
        number_of_vehicles_involved: 'Số lượng xe chưa hoàn thành',
        number_of_vehicles_in_which_the_claim_occurred: 'Số lượng xe yêu cầu bồi thường',
        series: 'Hệ thống xe',
        platform: 'Nền tảng mô hình',
        activity_region: 'Khu vực hoạt động',
        activity_vehicleNum: 'Hoạt động vehicleNum',
        number_of_claims: 'Số lượng yêu cầu',
        activity_vehicle: 'Xe hoạt động',
        vehicle_total_num: 'Tổng số xe',
    },
    // 西班牙
    esES: {
        activity_code: 'Código de la actividad',
        activity_name: 'Nombre de la actividad',
        responsible_party: 'Parte responsable',
        release_date: 'Fecha de publicación',
        data_sources: 'Fuente de datos',
        recall_campaign_type: 'Tipo de RECALL',
        service_activity_code: 'Código de campaña de servicio',
        service_activity_name: 'Nombre de campaña de servicio',
        buyout_company: 'Empresa de compra',
        audit_company: 'Empresa de auditoría',
        currency: 'Moneda',
        money: 'Monto',
        start_date: 'Fecha de inicio',
        end_date: 'Fecha de finalización',
        failure_mode: 'Modo de falla',
        activity_programme: 'Plan de campaña',
        improve_handling: 'Medidas de mejora',
        activity_information: 'Información de campaña',
        vehicle_involved_list: 'Lista de vehículos incompletos',
        claim_vehicles_list: 'Lista de vehículos con reclamación',
        claim_statistics: 'Estadísticas de reclamación',
        technical_document_attachments: 'Adjunto de documento técnico',
        vin: 'VIN',
        is_complete: '¿Completado?',
        warranty_claims: 'Número de reclamación',
        customer: 'Código general',
        claim_proportion: 'Tasa de finalización de la reclamación',
        claim_settled_amount: 'Monto liquidado de reclamación',
        is_claim_again: '¿Reclamación adicional?',
        cancel_succeed: 'Cancelación exitosa',
        number_of_vehicles_involved: 'Cantidad de vehículos incompletos',
        number_of_vehicles_in_which_the_claim_occurred: 'Cantidad de vehículos con reclamación',
        series: 'Sistema de automóviles',
        platform: 'Plataforma de modelos',
        activity_region: 'Área de actividad',
        activity_vehicleNum: 'Actividad vehicleNum',
        number_of_claims: 'Número de reclamaciones',
        activity_vehicle: 'Vehículo de actividad',
        vehicle_total_num: 'Número total de vehículos',
    },
    // 葡萄牙
    ptBR: {
        activity_code: 'Código de atividade',
        activity_name: 'Nome da atividade',
        responsible_party: 'Parte responsável',
        release_date: 'Data de lançamento',
        data_sources: 'Fontes de dados',
        recall_campaign_type: 'Tipo de campanha de recall',
        service_activity_code: 'Código da atividade de serviço',
        service_activity_name: 'Nome da atividade de serviço',
        buyout_company: 'Empresa de recompra',
        audit_company: 'Empresa de auditoria',
        currency: 'Moeda',
        money: 'Valor',
        start_date: 'Data de início',
        end_date: 'Data de término',
        failure_mode: 'Modo de falha',
        activity_programme: 'Programa de atividade',
        improve_handling: 'Melhorar manuseio',
        activity_information: 'Informação da atividade',
        vehicle_involved_list: 'Lista de veículos envolvidos',
        claim_vehicles_list: 'Lista de veículos com reclamações',
        claim_statistics: 'Estatísticas de reclamações',
        technical_document_attachments: 'Anexos de documento técnico',
        vin: 'VIN',
        is_complete: 'Está completo',
        warranty_claims: 'Números de reclamação de garantia',
        customer: 'Cliente',
        claim_proportion: 'Taxa de conclusão do pedido',
        claim_settled_amount: 'Valor das reclamações resolvidas',
        is_claim_again: 'É uma reclamação novamente',
        cancel_succeed: 'Cancelamento bem-sucedido',
        number_of_vehicles_involved: 'Número de veículos envolvidos não concluídos',
        number_of_vehicles_in_which_the_claim_occurred:
            'Número de veículos em que ocorreram reclamações',
        series: 'Série automóvel',
        platform: 'Plataforma do veículo',
        activity_region: 'Zona de acção',
        activity_vehicleNum: 'Atividade vehicleNum',
        number_of_claims: 'Número de pedidos',
        activity_vehicle: 'Veículo de atividade',
        vehicle_total_num: 'Número total de veículos',
    },
    // 波兰
    plPL: {
        activity_code: 'Kod działania',
        activity_name: 'Nazwa działania',
        responsible_party: 'Strona odpowiedzialna',
        release_date: 'Data wydania',
        data_sources: 'Źródło danych',
        recall_campaign_type: 'Typ działania serwisowego / akcji serwisowej',
        service_activity_code: 'Kod akcji serwisowej',
        service_activity_name: 'Nazwa akcji serwisowej',
        buyout_company: 'Firma wykupu',
        audit_company: 'Firma zatwierdzająca',
        currency: 'Waluta',
        money: 'Kwota',
        start_date: 'Data rozpoczęcia',
        end_date: 'Data zakończenia',
        failure_mode: 'Tryb awaryjny',
        activity_programme: 'Plan działań',
        improve_handling: 'Ulepszenie',
        activity_information: 'Informacja na temat działania',
        vehicle_involved_list: 'Lista pojazdów niekompletnych',
        claim_vehicles_list: 'Lista pojazdów ze zgłoszoną reklamacją',
        claim_statistics: 'Statystyki reklamacji',
        technical_document_attachments: 'Załączone dokumenty techniczne',
        vin: 'VIN',
        is_complete: 'Zakończone',
        warranty_claims: 'Numer zgłoszenia reklamacyjnego',
        customer: 'Klient',
        claim_proportion: 'Wskaźnik realizacji roszczeń',
        claim_settled_amount: 'Kwota rozliczenia zgłoszenia',
        is_claim_again: 'Czy zgłoszone ponownie',
        cancel_succeed: 'Anulowanie zakończone pomyślnie',
        number_of_vehicles_involved: 'Liczba niekompletnych pojazdów',
        number_of_vehicles_in_which_the_claim_occurred: 'Liczba pojazdów ze zgłoszoną reklamacją',
        series: 'Seria samochodów',
        platform: 'Platforma pojazdu',
        activity_region: 'Strefa działania',
        activity_vehicleNum: 'Aktywność vehicleNum',
        number_of_claims: 'Liczba roszczeń',
        activity_vehicle: 'Activity vehicle: Pojazd aktywności',
        vehicle_total_num: 'Całkowita liczba pojazdów',
    },
    trTR: {
        activity_code: 'Etkinlik kodu',
        activity_name: 'Etkinlik adı',
        responsible_party: 'Sorumlu birim',
        release_date: 'Çıkış tarihi',
        data_sources: 'Veri kaynakları',
        recall_campaign_type: 'Geri çağırma tipi',
        service_activity_code: 'SERVICE kampanyası kodu',
        service_activity_name: 'SERVICE kampanyası adı',
        buyout_company: 'Satış Şirketi',
        audit_company: 'Onay Şirketi',
        currency: 'Para birimi',
        money: 'Tutar',
        start_date: 'Başlangıç tarihi',
        end_date: 'Bitiş tarihi',
        failure_mode: 'Arıza modu',
        activity_programme: 'Kampanya planı',
        improve_handling: 'Geliştirme ölçüleri',
        activity_information: 'Kampanya bilgisi',
        vehicle_involved_list: 'Tamamlanmamış Araç Listesi',
        claim_vehicles_list: 'Araç kleymi oluşturuldu',
        claim_statistics: 'Kleym istatistikleri',
        technical_document_attachments: 'Tehnik belge ekleri',
        vin: 'VIN',
        is_complete: 'Tamamlandı',
        warranty_claims: 'Garanti Kleymi',
        customer: 'Müşteri',
        claim_proportion: 'Tamamlama oranı',
        claim_settled_amount: 'Kleym tutarı',
        is_claim_again: 'Tekrar kleym edildi mi?',
        cancel_succeed: 'İptal başarılı',
        number_of_vehicles_involved: 'Tamamlanmamış araçların sayısı',
        number_of_vehicles_in_which_the_claim_occurred: 'Kleym yapılan araç sayısı',
        series: 'araç türleri̇',
        platform: 'Araç platformu',
        activity_region: 'eylem bölgesi',
        activity_vehicleNum: 'Etkinlik araçNumarası',
        number_of_claims: 'Talep sayısı',
        activity_vehicle: 'Etkinlik aracı',
        vehicle_total_num: 'Toplam araç sayısı',
    },
    // 捷克语
    csCZ: {
        activity_code: 'Kód aktivity',
        activity_name: 'Název aktivity',
        responsible_party: 'Odpovědná strana',
        release_date: 'Datum vydání',
        data_sources: 'Zdroje dat',
        recall_campaign_type: 'Typ svolávací akce',
        service_activity_code: 'Kód servisní činnosti',
        service_activity_name: 'Název aktivity služby',
        buyout_company: 'Výkupní společnost',
        audit_company: 'Schvalovací společnost',
        currency: 'Měna',
        money: 'Množství',
        start_date: 'Datum zahájení',
        end_date: 'Datum ukončení',
        failure_mode: 'Režim poruchy',
        activity_programme: 'Plán kampaně',
        improve_handling: 'Opatření ke zlepšení',
        activity_information: 'Informace o kampani',
        vehicle_involved_list: 'Neúplný seznam vozidel',
        claim_vehicles_list: 'Došlo k reklamaci vozidel',
        claim_statistics: 'Statistika nároků',
        technical_document_attachments: 'Přílohy technických dokumentů',
        vin: 'VIN',
        is_complete: 'Dokončeno',
        warranty_claims: 'Záruční reklamace',
        customer: 'Zákazník',
        claim_proportion: 'Míra dokončení reklamace',
        claim_settled_amount: 'Vyrovnaná částka pohledávky',
        is_claim_again: 'Zda se jedná o reklamaci',
        cancel_succeed: 'Zrušení bylo úspěšné',
        number_of_vehicles_involved: 'Počet nedokončených vozidel',
        number_of_vehicles_in_which_the_claim_occurred:
            'Počet vozidel, u kterých došlo k reklamaci',
        series: 'Série',
        platform: 'Platforma vozidla',
        activity_region: 'Oblast činnosti',
        activity_vehicleNum: 'Aktivita vozidla (číslo)',
        number_of_claims: 'Počet nároků',
        activity_vehicle: 'Aktivita vozidla',
        vehicle_total_num: 'Celkový počet vozidel',
    },
    // 匈牙利语
    huHU: {
        activity_code: 'Tevékenységkód',
        activity_name: 'Tevékenységnév',
        responsible_party: 'Felelős fél',
        release_date: 'Kiadási dátum',
        data_sources: 'Adatforrások',
        recall_campaign_type: 'Visszahívás típusa',
        service_activity_code: 'Szerviztevékenység kódja',
        service_activity_name: 'Szerviztevékenység neve',
        buyout_company: 'Vásárló vállalat',
        audit_company: 'Jóváhagyó vállalat',
        currency: 'Pénznem',
        money: 'Mennyiség',
        start_date: 'Kezdő dátum',
        end_date: 'Befejező dátum',
        failure_mode: 'Meghibásodási mód',
        activity_programme: 'Kampányterv',
        improve_handling: 'Fejlesztési intézkedések',
        activity_information: 'Kampányinformáció',
        vehicle_involved_list: 'Hiányos járműlista',
        claim_vehicles_list: 'Járműigény történt',
        claim_statistics: 'Igénystatisztikák',
        technical_document_attachments: 'Műszaki dokumentum mellékletek',
        vin: 'Alvázszám',
        is_complete: 'Kész',
        warranty_claims: 'Garanciális igény',
        customer: 'Ügyfél',
        claim_proportion: 'Igényteljesítési arány',
        claim_settled_amount: 'Igényrendezési összeg',
        is_claim_again: 'Újraigénylés szükséges-e',
        cancel_succeed: 'Sikeres megszakítás',
        number_of_vehicles_involved: 'Befejezetlen járművek száma',
        number_of_vehicles_in_which_the_claim_occurred:
            'Járművek száma, amelyeknél az igény felmerült',
        series: 'Sorozat',
        platform: 'Járműplatform',
        activity_region: 'Tevékenységi régió',
        activity_vehicleNum: 'Járművek száma',
        number_of_claims: 'Igények száma',
        activity_vehicle: 'Tevékenységben érintett jármű',
        vehicle_total_num: 'Összes jármű száma',
    },
    // 俄罗斯语
    ruRU: {
        activity_code: 'Код мероприятия',
        activity_name: 'Название мероприятия',
        responsible_party: 'Ответственный',
        release_date: 'Дата выпуска',
        data_sources: 'Источники данных',
        recall_campaign_type: 'Тип отзыва',
        service_activity_code: 'Код сервисной активности',
        service_activity_name: 'Название сервисной активности',
        buyout_company: 'Компания по одобрению',
        audit_company: 'Компания по утверждению',
        currency: 'Валюта',
        money: 'Сумма',
        start_date: 'Дата начала',
        end_date: 'Дата окончания',
        failure_mode: 'Режим отказа',
        activity_programme: 'План кампании',
        improve_handling: 'Меры по улучшению',
        activity_information: 'Информация о кампании',
        vehicle_involved_list: 'Список неполных автомобилей',
        claim_vehicles_list: 'Произошедшие требования',
        claim_statistics: 'Статистика требований',
        technical_document_attachments: 'Вложения технических документов',
        vin: 'VIN',
        is_complete: 'Завершено',
        warranty_claims: 'Гарантийное требование',
        customer: 'Клиент',
        claim_proportion: 'Процент завершения требований',
        claim_settled_amount: 'Сумма урегулированного требования',
        is_claim_again: 'Является ли возвратом',
        cancel_succeed: 'Отмена прошла успешно',
        number_of_vehicles_involved: 'Количество неполных автомобилей',
        number_of_vehicles_in_which_the_claim_occurred:
            'Количество автомобилей, в которых произошло требование',
        series: 'Серия',
        platform: 'Платформа автомобиля',
        activity_region: 'Регион мероприятия',
        activity_vehicleNum: 'Номер автомобиля мероприятия',
        number_of_claims: 'Количество требований',
        activity_vehicle: 'Автомобиль мероприятия',
        vehicle_total_num: 'Общее количество автомобилей',
    },
};

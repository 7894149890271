export default {
    // 中
    zhCN: {
        department_name: '部门名称',
        encoded: '编码',
        name: '名称',
        disable_time: '禁用时间',
        disabled_person: '禁用人',
    },
    // 英
    enUS: {
        department_name: 'Department name',
        encoded: 'Encoded',
        name: 'Name',
        disable_time: 'Disable time',
        disabled_person: 'Disabled person',
    },
    // 德
    deDE: {
        department_name: 'Abteilungsname',
        encoded: 'Codiert',
        name: 'Name',
        disable_time: 'Deaktivierungszeit',
        disabled_person: 'Deaktivierte Person',
    },
    // 法
    frFR: {
        department_name: 'Nom du département',
        encoded: 'Encodé',
        name: 'Nom',
        disable_time: 'Heure de désactivation',
        disabled_person: 'Personne désactivée',
    },
    // 意大利
    itIT: {
        department_name: 'Nome Dipartimento',
        encoded: 'Codifica',
        name: 'Nome',
        disable_time: 'Tempo Disabilitazione',
        disabled_person: 'Persona Disabilitata',
    },
    // 荷兰
    nlNL: {
        department_name: 'Afdelingsnaam',
        encoded: 'Gecodeerd',
        name: 'Naam',
        disable_time: 'Uitschakeltijd',
        disabled_person: 'Gehandicapte persoon',
    },
    // 越南
    viVN: {
        department_name: 'Tên phòng ban',
        encoded: 'Mã hóa',
        name: 'Tên',
        disable_time: 'Thời gian vô hiệu hóa',
        disabled_person: 'Người vô hiệu hóa',
    },
    // 西班牙
    esES: {
        department_name: 'Nombre del departamento',
        encoded: 'Código',
        name: 'Nombre',
        disable_time: 'Tiempo de desactivación',
        disabled_person: 'Persona que desactivó',
    },
    // 葡萄牙
    ptBR: {
        department_name: 'Nome do departamento',
        encoded: 'Codificado',
        name: 'Nome',
        disable_time: 'Tempo desabilitado',
        disabled_person: 'Pessoa desabilitada',
    },
    // 波兰
    plPL: {
        department_name: 'Nazwa oddziału',
        encoded: 'Kod',
        name: 'Nazwa',
        disable_time: 'Czas blokady',
        disabled_person: 'Osoba zablokowana',
    },
    // 土耳其
    trTR: {
        department_name: 'Departman adı',
        encoded: 'Kodlanmış',
        name: 'İsim',
        disable_time: 'Devre dışı bırakma zamanı',
        disabled_person: 'Engelleyen kişi',
    },
    // 捷克语
    csCZ: {
        department_name: 'Název oddělení',
        encoded: 'Kódované',
        name: 'název',
        disable_time: 'Čas vypnutí',
        disabled_person: 'Postižený člověk',
    },
    // 匈牙利语
    huHU: {
        department_name: 'Részleg neve',
        encoded: 'Kódolva',
        name: 'Név',
        disable_time: 'Kikapcsolás ideje',
        disabled_person: 'Mozgássérült személy',
    },
    // 俄罗斯语
    ruRU: {
        department_name: 'Название отдела',
        encoded: 'Код',
        name: 'Имя',
        disable_time: 'Время отключения',
        disabled_person: 'Отключивший',
    },
};

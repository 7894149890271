export default {
    // 中
    zhCN: {
        fill_in_at_least_one_query_condition: '至少填写一个查询条件',
        query_time: '查询时间',
        reporting_organization_name: '上报组织名称',
        full_query: '全量查询',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        total_number_of_cases: '案例总数',
        percentage_of_cases: '案例占比',
        number_of_cases: '案例数量',
    },
    // 英
    enUS: {
        fill_in_at_least_one_query_condition: 'Fill in at least one query condition',
        query_time: 'Query time',
        reporting_organization_name: 'Reporting organization name',
        full_query: 'Full query',
        organization_code: 'Organization code',
        organization_name: 'Organization name',
        country: 'Country',
        total_number_of_cases: 'Total cases',
        percentage_of_cases: 'Percentage of cases',
        number_of_cases: 'Number of cases',
    },
    // 德
    deDE: {
        fill_in_at_least_one_query_condition: 'Füllen Sie mindestens eine Abfragebedingung aus',
        query_time: 'Abfragezeit',
        reporting_organization_name: 'Name der meldenden Organisation',
        full_query: 'Vollständige Abfrage',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        total_number_of_cases: 'Gesamtzahl der Fälle',
        percentage_of_cases: 'Prozentsatz der Fälle',
        number_of_cases: 'Anzahl der Fälle',
    },
    // 法
    frFR: {
        fill_in_at_least_one_query_condition: 'Remplissez au moins une condition de demande',
        query_time: 'Heure de requête',
        reporting_organization_name: 'Nom de l\'organisation de signalement',
        full_query: 'Requête complète',
        organization_code: 'Code organisation',
        organization_name: 'Nom organisation',
        country: 'Pays',
        total_number_of_cases: 'Total cas',
        percentage_of_cases: 'Pourcentage de cas',
        number_of_cases: 'Cas System-2',
    },
    // 意大利
    itIT: {
        fill_in_at_least_one_query_condition: 'Compilare almeno una condizione di query',
        query_time: 'Tempo di Query',
        reporting_organization_name: 'Nome Organizzazione Segnalante',
        full_query: 'Query Completa',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        country: 'Paese',
        total_number_of_cases: 'Numero totale di casi',
        percentage_of_cases: 'Percentuale di Casi',
        number_of_cases: 'Numero di Casi',
    },
    // 荷兰
    nlNL: {
        fill_in_at_least_one_query_condition: 'Vul ten minste één query voorwaarde in',
        query_time: 'Query time',
        reporting_organization_name: 'Meldende organisatie naam',
        full_query: 'Volledige query',
        organization_code: 'Organisatiecode',
        organization_name: 'Organisatienaam',
        country: 'Land',
        total_number_of_cases: 'Totaal aantal gevallen',
        percentage_of_cases: 'Percentage van gevallen',
        number_of_cases: 'Aantal gevallen',
    },
    // 越南
    viVN: {
        fill_in_at_least_one_query_condition: 'Ít nhất điền một điều kiện tìm kiếm',
        query_time: 'Thời gian truy vấn',
        reporting_organization_name: 'Tên tổ chức báo cáo',
        full_query: 'Tra cứu toàn bộ',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        country: 'Quốc gia',
        total_number_of_cases: 'Tổng số trường hợp',
        percentage_of_cases: 'Phần trăm các trường hợp',
        number_of_cases: 'Các trường hợp hệ thống',
    },
    // 西班牙
    esES: {
        fill_in_at_least_one_query_condition: 'Al menos complete un criterio de búsqueda',
        query_time: 'Consulta de tiempo',
        reporting_organization_name: 'Nombre de la organización informante',
        full_query: 'Consulta completa',
        organization_code: 'Código de la organización',
        organization_name: 'Nombre de la organización',
        country: 'País',
        total_number_of_cases: 'Número total de casos',
        percentage_of_cases: 'Porcentaje de casos',
        number_of_cases: 'Cantidad de casos',
    },
    // 葡萄牙
    ptBR: {
        fill_in_at_least_one_query_condition: 'Preencha pelo menos uma condição de consulta',
        query_time: 'Tempo de consulta',
        reporting_organization_name: 'Nome da organização de relatório',
        full_query: 'Consulta completa',
        organization_code: 'Código da organização',
        organization_name: 'Nome da organização',
        country: 'País',
        total_number_of_cases: 'Número total de casos',
        percentage_of_cases: 'Porcentagem de casos',
        number_of_cases: 'Número de casos',
    },
    // 波兰
    plPL: {
        fill_in_at_least_one_query_condition: 'Wypełnij co najmniej jeden warunek zapytania',
        query_time: 'Data zapytania',
        reporting_organization_name: 'Nazwa organizacji raportującej',
        full_query: 'Pełne zapytanie',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        country: 'Kraj',
        total_number_of_cases: 'Łączna liczba spraw',
        percentage_of_cases: 'Odsetek spraw',
        number_of_cases: 'Liczba spraw',
    },
    // 土耳其
    trTR: {
        fill_in_at_least_one_query_condition: 'En azından bir soru durumunu doldur',
        query_time: 'Sorgulama zamanı',
        reporting_organization_name: 'Bildirim organizasyonu adı',
        full_query: 'Tam sorgulama',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        country: 'Ülke',
        total_number_of_cases: 'Dosyaların sayısı',
        percentage_of_cases: 'Davaların yüzdesi',
        number_of_cases: 'Davaların sayısı',
    },
    // 捷克语
    csCZ: {
        fill_in_at_least_one_query_condition: 'Vyplňte alespoň jednu podmínku dotazu',
        query_time: 'Čas dotazu',
        reporting_organization_name: 'Název ohlašující společnosti',
        full_query: 'Úplný dotaz',
        organization_code: 'Kód společnosti',
        organization_name: 'Název společnosti',
        country: 'Země',
        total_number_of_cases: 'Celkový počet případů',
        percentage_of_cases: 'Procento případů',
        number_of_cases: 'Počet případů',
    },
    // 匈牙利语
    huHU: {
        fill_in_at_least_one_query_condition: 'Töltsön ki legalább egy lekérdezési feltételt',
        query_time: 'Lekérdezési idő',
        reporting_organization_name: 'Jelentő szervezet neve',
        full_query: 'Teljes lekérdezés',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        country: 'Ország',
        total_number_of_cases: 'Összes eset',
        percentage_of_cases: 'Esetek százaléka',
        number_of_cases: 'Esetek száma',
    },
    // 俄罗斯语
    ruRU: {
        fill_in_at_least_one_query_condition: 'Заполните хотя бы одно условие запроса',
        query_time: 'Время запроса',
        reporting_organization_name: 'Название отчетной организации',
        full_query: 'Полный запрос',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        country: 'Страна',
        total_number_of_cases: 'Общее количество случаев',
        percentage_of_cases: 'Процент случаев',
        number_of_cases: 'Количество случаев',
    },
};

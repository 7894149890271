export default {
    // 中
    zhCN: {
        documentType: '单据类型',
        vehicleType: '车辆类型',
        dealerInformation: '经销商信息',
        reportType: '上报类型',
        dealerContact: '经销商联系人',
        contact: '联系电话',
        customer_information: '客户信息',
        occupation: '职业',
        vehicleInformation: '车辆信息',
        mileage: '行驶里程/km',
        licensePlate: '车牌号',
        platform: '车型平台',
        offLineDate: '下线日期',
        vehicleUse: '车辆用途',
        refited: '是否有加改装',
        insuranceSituation: '保险情况',
        nameplateNo: '铭牌编号',
        purchaseDate: '购买日期',
        accidentInformation: '事故信息',
        timeOfAccident: '事故发生时间',
        placeOfAccident: '事故发生地点',
        roadConditions: '道路状况',
        pavementType: '路面类型',
        weather: '天气',
        vehicleCondition: '车辆状况',
        vehicleOfTheAccident: '事故发生时车辆信息',
        vehicleCollisionPosition: '车辆碰撞位置',
        vehicleRunningStatus: '车辆行驶状态',
        ownSpeed: '己方车速',
        vehicleSpeedOfTheOtherSide: '对方车速',
        personnelInsideTheVehicle: '事发车内人员情况',
        numberOfPeopleInTheCar: '车内人数',
        personalInjury: '人员受伤情况',
        distributionOfPersonnelInTheVehicle: '受伤人员所坐位置',
        descriptionOfPersonalInjury: '人员受伤情况描述',
        airbagDeployment: '气囊展开情况',
        safetyBeltWearing: '安全带佩戴情况',
        brakeBeforeTheAccident: '事发前刹车',
        turnBeforeTheIncident: '事发前转向',
        whenCorrosionOccurs: '何时发生腐蚀',
        parkingTimeHours: '停车时间（小时）',
        parkingTimeMinutes: '停车时间（分钟）',
        drivingHours: '驾驶小时',
        drivingMinutes: '驾驶分钟',
        drivingDistance: '驾驶距离',
        drivingSpeed: '驾驶速度',
        vehicleState: '事故发生时车辆状态',
        fireFightingOrNot: '是否消防救火',
        numberOfInjured: '受伤人数',
        deathToll: '死亡人数',
        vehicleHelp: '车辆求助情况',
        vehiclePassingAccident: '车辆过往事故',
        pastAccidents: '过往事故',
        isTheWarningLampOnBeforeTheEvent: '事件发生前警告灯是否亮起',
        specificIconContent: '特定图标内容',
        didYouSmellFuelBeforeTheAccident: '事发前你闻到燃油味了吗',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident: '事发前有没有感觉到异常振动',
        abnormalSoundZone: '异响区',
        didYouHearAnyNoiseBeforeTheAccident: '事发前你听到什么噪音了吗',
        noiseDescription: '噪音描述',
        theAreaWhereTheVehicleSmokedBeforeTheAccident: '事发前车辆起烟位置',
        otherAreas: '其他区域',
        whetherTheInsuranceCompanyHasIssuedACertificate: '保险公司是否出具了证明',
        whetherTheAuthorityIssuesTheCertificate: '权威部门是否出具证明',
        isVDSReportAvailable: '是否可以获得VDS报告',
        accidentProcess: '事发过程',
        accidentProcessPlaceholder:
            '提报人_XXXX的转述。\n' +
            '20XX年XX月XX日凌晨XX点XX分左右，当事人XXXX驾驶车牌号为XXXXXX荣威RX50 1.5/AT的车辆沿国道由南向北方向行驶（车内共一人，驾驶员一位）。当行驶至XXX省XX市XX县XX镇XX村路段处，发现左侧驾驶舱内产生白烟。',
        accessoryMaterials: '附件材料',
        bulkDownload: '批量下载',
        materialUpload: '材料上传',
        userReadmeAttachment: '用户自述附件',
        sitePhotos: '现场照片',
        fireAccidentIdentificationLetter: '火灾事故认定书',
        insuranceRecordAttachment: '保险记录附件',
        diagnosticRecords: '诊断记录',
        temperature: '温度',
        please_select_the_reporting_type: '请选择上报类型',
        fire_accident_identification_letter_required: '火灾事故认定书必选',
        please_upload_the_fire_accident_identification_letter: '请上传火灾事故认定书',
        insurance_record_attachment_required: '保险记录附件必选',
        please_upload_the_insurance_record_attachment: '请上传保险记录附件',
        please_upload_the_diagnostic_record_attachment: '请上传诊断记录附件',
        please_upload_site_photos: '请上传现场照片',
    },
    // 英
    enUS: {
        documentType: 'Document type',
        vehicleType: 'Vehicle type',
        dealerInformation: 'Dealer information',
        reportType: 'Case type',
        dealerContact: 'Dealer contact',
        contact: 'Contact #',
        customer_information: 'Customer information',
        occupation: 'Occupation',
        vehicleInformation: 'Vehicle information',
        mileage: 'Mileage (/km)',
        licensePlate: 'License plate #',
        platform: 'Platform',
        offLineDate: 'Off-line date',
        vehicleUse: 'Vehicle use',
        refited: 'Refited',
        insuranceSituation: 'Insurance situation',
        nameplateNo: 'Nameplate no',
        purchaseDate: 'Purchase date',
        accidentInformation: 'Accident information',
        timeOfAccident: 'Time of accident',
        placeOfAccident: 'Place of accident',
        roadConditions: 'Road conditions',
        pavementType: 'Pavement type',
        weather: 'Weather',
        vehicleCondition: 'Vehicle condition',
        vehicleOfTheAccident: 'Vehicle information at the time of the accident',
        vehicleCollisionPosition: 'Vehicle collision position',
        vehicleRunningStatus: 'Vehicle running status',
        ownSpeed: 'Own speed',
        vehicleSpeedOfTheOtherSide: 'Vehicle speed of the other side',
        personnelInsideTheVehicle: 'Personnel inside the vehicle',
        numberOfPeopleInTheCar: 'Number of people in the car',
        personalInjury: 'Personal injury',
        distributionOfPersonnelInTheVehicle: 'Distribution of personnel in the vehicle',
        descriptionOfPersonalInjury: 'Description of personal injury',
        airbagDeployment: 'Airbag deployment',
        safetyBeltWearing: 'Safety belt wearing',
        brakeBeforeTheAccident: 'Brake before the accident',
        turnBeforeTheIncident: 'Turn before the incident',
        whenCorrosionOccurs: 'When corrosion occurs',
        parkingTimeHours: 'Parking time (hours)',
        parkingTimeMinutes: 'Parking time (minutes)',
        drivingHours: 'Driving hours',
        drivingMinutes: 'Driving minutes',
        drivingDistance: 'Driving distance',
        drivingSpeed: 'Driving speed',
        vehicleState: 'Vehicle state',
        fireFightingOrNot: 'Fire fighting or not',
        numberOfInjured: 'Number of injured',
        deathToll: 'Death toll',
        vehicleHelp: 'Vehicle help',
        vehiclePassingAccident: 'Vehicle passing accident',
        pastAccidents: 'Past accidents',
        isTheWarningLampOnBeforeTheEvent: 'Is the warning lamp on before the event',
        specificIconContent: 'Specific icon content',
        didYouSmellFuelBeforeTheAccident: 'Did you smell fuel before the accident',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Did you feel any abnormal vibration before the accident',
        abnormalSoundZone: 'Abnormal sound zone',
        didYouHearAnyNoiseBeforeTheAccident: 'Did you hear any noise before the accident',
        noiseDescription: 'Noise description',
        theAreaWhereTheVehicleSmokedBeforeTheAccident:
            'The location where the vehicle started smoking before the accident occurred',
        otherAreas: 'Other areas',
        whetherTheInsuranceCompanyHasIssuedACertificate:
            'Whether the insurance company has issued a certificate',
        whetherTheAuthorityIssuesTheCertificate: 'Whether the authority issues the certificate',
        isVDSReportAvailable: 'Is VDS report available',
        accidentProcess: 'Accident process',
        accidentProcessPlaceholder:
            'Reported by_ The paraphrase of XXXX.\n' +
            'At around XX a.m. on XX/XX/20XX, the party XXXX was driving a vehicle with license plate number XXXXXX Roewe RX50 1.5/AT along the national highway from south to north (with one person inside and one driver). When driving to XX Village, XX Town, XX County, XX City, XXX Province, it was found that white smoke was generated in the left cockpit.',
        accessoryMaterials: 'Attachment',
        bulkDownload: 'Bulk download',
        materialUpload: 'Material upload',
        userReadmeAttachment: 'User report attachment',
        sitePhotos: 'Photographs of the scene',
        fireAccidentIdentificationLetter: 'Fire accident confirmation',
        insuranceRecordAttachment: 'Insurance record attachment',
        diagnosticRecords: 'Diagnostic history',
        temperature: 'Temperature',
        please_select_the_reporting_type: 'Please select the reporting type',
        fire_accident_identification_letter_required:
            'Fire Accident Identification Letter Required',
        please_upload_the_fire_accident_identification_letter:
            'Please upload the fire accident identification Letter',
        insurance_record_attachment_required: 'Insurance Record Attachment Required',
        please_upload_the_insurance_record_attachment:
            'Please upload the insurance record attachment',
        please_upload_the_diagnostic_record_attachment:
            'Please upload the diagnostic record attachment',
        please_upload_site_photos: 'Please upload on-site photos',
    },
    // 德
    deDE: {
        documentType: 'Dokumenttyp',
        vehicleType: 'Fahrzeugtyp',
        dealerInformation: 'Händlerinformationen',
        reportType: 'Berichtstyp',
        dealerContact: 'Händlerkontakt',
        contact: 'Kontakt #',
        customer_information: 'Kundeninformationen',
        occupation: 'Besetzung',
        vehicleInformation: 'Fahrzeuginformationen',
        mileage: 'Laufleistung (/km)',
        licensePlate: 'Kennzeichen #',
        platform: 'Plattform',
        offLineDate: 'Off-line Datum',
        vehicleUse: 'Fahrzeugnutzung',
        refited: 'Umrüstung',
        insuranceSituation: 'Nachgefüllt',
        nameplateNo: 'Typschild Nr',
        purchaseDate: 'Kaufdatum',
        accidentInformation: 'Unfallinformationen',
        timeOfAccident: 'Zeit des Unfalls',
        placeOfAccident: 'Unfallort',
        roadConditions: 'Straßenverhältnisse',
        pavementType: 'Straßentyp',
        weather: 'Wetter',
        vehicleCondition: 'Fahrzeugzustand',
        vehicleOfTheAccident: 'Fahrzeuginformationen zum Zeitpunkt des Unfalls',
        vehicleCollisionPosition: 'Fahrzeugkollisionsposition',
        vehicleRunningStatus: 'Fahrzeugbetriebsstatus',
        ownSpeed: 'Eigene Geschwindigkeit',
        vehicleSpeedOfTheOtherSide: 'Fahrzeuggeschwindigkeit auf der anderen Seite',
        personnelInsideTheVehicle: 'Personal im Fahrzeug',
        numberOfPeopleInTheCar: 'Anzahl der Personen im Auto',
        personalInjury: 'Personenschaden',
        distributionOfPersonnelInTheVehicle: 'Verteilung des Personals im Fahrzeug',
        descriptionOfPersonalInjury: 'Beschreibung der Personenschäden',
        airbagDeployment: 'Airbag-Deployment',
        safetyBeltWearing: 'Sicherheitsgurt tragen',
        brakeBeforeTheAccident: 'Bremsen vor dem Unfall',
        turnBeforeTheIncident: 'Drehung vor dem Vorfall',
        whenCorrosionOccurs: 'Wenn Korrosion auftritt',
        parkingTimeHours: 'Parkzeit (Stunden)',
        parkingTimeMinutes: 'Parkzeit (Minuten)',
        drivingHours: 'Fahrstunden',
        drivingMinutes: 'Fahrminuten',
        drivingDistance: 'Fahrstrecke',
        drivingSpeed: 'Fahrgeschwindigkeit',
        vehicleState: 'Fahrzeugstatus',
        fireFightingOrNot: 'Feuerbekämpfung oder nicht',
        numberOfInjured: 'Anzahl der Verletzten',
        deathToll: 'Todesfälle',
        vehicleHelp: 'Fahrzeughilfe',
        vehiclePassingAccident: 'Fahrzeug überholen Unfall',
        pastAccidents: 'Vergangene Unfälle',
        isTheWarningLampOnBeforeTheEvent: 'Ist die Warnleuchte vor dem Ereignis eingeschaltet',
        specificIconContent: 'Spezifischer Symbolinhalt',
        didYouSmellFuelBeforeTheAccident: 'Hast du vor dem Unfall Benzin gerochen',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Haben Sie vor dem Unfall eine abnormale Vibration gespürt',
        abnormalSoundZone: 'Abnormale Klangzone',
        didYouHearAnyNoiseBeforeTheAccident: 'Haben Sie vor dem Unfall ein Geräusch gehört',
        noiseDescription: 'Geräuschbeschreibung',
        theAreaWhereTheVehicleSmokedBeforeTheAccident:
            'Der Ort, an dem das Fahrzeug vor dem Unfall zu rauchen begann',
        otherAreas: 'Andere Bereiche',
        whetherTheInsuranceCompanyHasIssuedACertificate:
            'Hat die Versicherungsgesellschaft eine Bescheinigung ausgestellt',
        whetherTheAuthorityIssuesTheCertificate: 'Ob die Behörde die Bescheinigung ausstellt',
        isVDSReportAvailable: 'Steht ein VDS-Bericht zur Verfügung',
        accidentProcess: 'Unfallprozess',
        accidentProcessPlaceholder:
            'Berichtet von_ Die Paraphrase von XXXX.\n' +
            'Um ca. XX Uhr auf XX/XX/20XX fuhr die Party XXXX ein Fahrzeug mit Kennzeichen XXX Roewe RX50 1.5/AT entlang der Bundesstraße von Süden nach Norden (mit einer Person im Fahrzeug und einem Fahrer). Bei der Fahrt nach XX Village, XX Town, XX County, XX City, XXX Province wurde festgestellt, dass weißer Rauch im linken Cockpit erzeugt wurde.',
        accessoryMaterials: 'Zubehörmaterialien',
        bulkDownload: 'Massendownload',
        materialUpload: 'Material-Upload',
        userReadmeAttachment: 'Benutzer-Leseanleitung Anhang',
        sitePhotos: 'Standortfotos',
        fireAccidentIdentificationLetter: 'Feuerunfall-Identifikationsbrief',
        insuranceRecordAttachment: 'Versicherungsrekord-Anhang',
        diagnosticRecords: 'Diagnostikunterlagen',
        temperature: 'Temperatur',
        please_select_the_reporting_type: 'Bitte wählen Sie die Art der Berichterstattung',
        fire_accident_identification_letter_required:
            'Kennzeichnungsschreiben für Brandunfälle erforderlich',
        please_upload_the_fire_accident_identification_letter:
            'Bitte laden Sie das Brandunfallidentifikationsformular hoch',
        insurance_record_attachment_required: 'Anhang der Versicherungsunterlagen erforderlich',
        please_upload_the_insurance_record_attachment:
            'Bitte laden Sie den Anhang der Versicherungsakte hoch',
        please_upload_the_diagnostic_record_attachment:
            'Bitte laden Sie den Anhang zur Diagnose hoch',
        please_upload_site_photos: 'Bitte laden Sie Fotos vor Ort hoch',
    },
    // 法
    frFR: {
        documentType: 'Type de document',
        vehicleType: 'Type de véhicule',
        dealerInformation: 'Informations sur le concessionnaire',
        reportType: 'Type de dossier',
        dealerContact: 'Contacts',
        contact: 'Contact #',
        customer_information: 'Informations sur le client',
        occupation: 'Occupation',
        vehicleInformation: 'Informations sur le véhicule',
        mileage: 'Kilométrage (/km)',
        licensePlate: 'Plaque d\'immatriculation',
        platform: 'Plateforme',
        offLineDate: 'Date de sortie d\'usine',
        vehicleUse: 'Utilisation du véhicule',
        refited: 'Aménagements',
        insuranceSituation: 'Etat de l\'assurance ',
        nameplateNo: 'Numéro de plaque signalétique',
        purchaseDate: 'Date d\'achat',
        accidentInformation: 'Informations sur l\'accident',
        timeOfAccident: 'Date de l\'accident',
        placeOfAccident: 'Lieu de l\'accident',
        roadConditions: 'Condition de route',
        pavementType: 'Type de chaussée',
        weather: 'Météo',
        vehicleCondition: 'Conditions du véhicule',
        vehicleOfTheAccident: 'Informations sur le véhicule au moment de l\'accident',
        vehicleCollisionPosition: 'Position du choc sur le véhicule',
        vehicleRunningStatus: 'Statut du véhicule pendant le roulage',
        ownSpeed: 'Vitesse',
        vehicleSpeedOfTheOtherSide: 'Vitesse de l\'autre véhicule',
        personnelInsideTheVehicle: 'Emplacement des personne dans le véhicule',
        numberOfPeopleInTheCar: 'Nombre de personnes dans le véhicule',
        personalInjury: 'Nombre de personnes blessées',
        distributionOfPersonnelInTheVehicle: 'Répartition du personnel dans le véhicule',
        descriptionOfPersonalInjury: 'Description de la blessure personnelle',
        airbagDeployment: 'Déploiement des airbags ',
        safetyBeltWearing: 'Port des ceintures de sécurité',
        brakeBeforeTheAccident: 'Freinage avant l\'accident',
        turnBeforeTheIncident: 'Virage avant l\'accident',
        whenCorrosionOccurs: 'Moment d\'apparition de la corrosion',
        parkingTimeHours: 'Temps de stationnement (heures)',
        parkingTimeMinutes: 'Temps de stationnement (minutes)',
        drivingHours: 'Heures de conduite',
        drivingMinutes: 'Minutes de conduite',
        drivingDistance: 'Distance de conduite',
        drivingSpeed: 'Vitesse de conduite',
        vehicleState: 'Etat du véhicule',
        fireFightingOrNot: 'lutte contre l\'incendie ou non ?',
        numberOfInjured: 'Nombre de blessés',
        deathToll: 'Nombre de décès',
        vehicleHelp: 'Service d\'urgence',
        vehiclePassingAccident: 'Accident avec un véhicule de passage',
        pastAccidents: 'Accidents passés',
        isTheWarningLampOnBeforeTheEvent: 'Voyant allumé avant l\'accident',
        specificIconContent: 'Contenu spécifique de l\'icône',
        didYouSmellFuelBeforeTheAccident:
            'Avez-vous senti une odeur de carburant avant l\'accident ?',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Avez-vous senti des vibrations avant l\'accident ?',
        abnormalSoundZone: 'Zone de bruit anormal',
        didYouHearAnyNoiseBeforeTheAccident: 'Avez-vous entendu un bruit avant l\'accident ?',
        noiseDescription: 'Description du bruit',
        theAreaWhereTheVehicleSmokedBeforeTheAccident: 'Une zone fumait avant l\'accident ?',
        otherAreas: 'Autres zones',
        whetherTheInsuranceCompanyHasIssuedACertificate: 'Certificat d\'assurance conforme',
        whetherTheAuthorityIssuesTheCertificate: 'Carte grise conforme',
        isVDSReportAvailable: 'Un rapport VDS est possible',
        accidentProcess: 'Etat du processus d\'accident',
        accidentProcessPlaceholder:
            'Déclarant Paraphrase de XXXX.\n' +
            '20XX le xx xx xx, vers 20hxx, la partie XXXX conduisait un véhicule immatriculé XXXXXX ronway rx50 1.5 / at sur la route nationale en direction du Sud et du Nord (une personne à l\'intérieur du véhicule, un conducteur). Lorsque vous conduisez jusqu\'à la Section du village XX, Canton XX, comté XX, municipalité XX, Province XXX, de la fumée blanche a été trouvée dans le cockpit gauche.',
        accessoryMaterials: 'Pièces-jointes',
        bulkDownload: 'Téléchargement en vrac',
        materialUpload: 'Téléchargement de matériel',
        userReadmeAttachment: 'Constat de l\'accident',
        sitePhotos: 'Photos',
        fireAccidentIdentificationLetter: 'Confirmation de l\'incendie',
        insuranceRecordAttachment: 'Pièces-jointes de l\'assurance',
        diagnosticRecords: 'Historique de d\'atelier',
        temperature: 'Température',
        please_select_the_reporting_type: 'Veuillez sélectionner le type d\'escalade',
        fire_accident_identification_letter_required:
            'Certificat d\'accident d\'incendie obligatoire',
        please_upload_the_fire_accident_identification_letter:
            'Veuillez télécharger une déclaration d\'accident d\'incendie',
        insurance_record_attachment_required: 'Pièce jointe au dossier d\'assurance obligatoire',
        please_upload_the_insurance_record_attachment:
            'Veuillez télécharger une pièce jointe au dossier d\'assurance',
        please_upload_the_diagnostic_record_attachment:
            'Veuillez télécharger une pièce jointe au dossier de diagnostic',
        please_upload_site_photos: 'Veuillez télécharger une photo du site',
    },
    // 意大利
    itIT: {
        documentType: 'Tipo di documento',
        vehicleType: 'Tipo di veicolo',
        dealerInformation: 'Informazioni sul concessionario',
        reportType: 'Tipo di rapporto',
        dealerContact: 'Contatto del concessionario',
        contact: 'Contatto telefonico',
        customer_information: 'Informazioni sul cliente',
        occupation: 'Occupazione',
        vehicleInformation: 'Informazioni sul veicolo',
        mileage: 'Chilometraggio/km',
        licensePlate: 'Targa',
        platform: 'Piattaforma del modello',
        offLineDate: 'Data di uscita di produzione',
        vehicleUse: 'Utilizzo del veicolo',
        refited: 'Modifiche eseguite',
        insuranceSituation: 'Situazione assicurativa',
        nameplateNo: 'Numero di targa',
        purchaseDate: 'Data di acquisto',
        accidentInformation: 'Informazioni sull\'incidente',
        timeOfAccident: 'Orario in cui si è verificato l\'incidente',
        placeOfAccident: 'Luogo dell\'incidente',
        roadConditions: 'Condizioni della strada',
        pavementType: 'Tipo di pavimentazione',
        weather: 'Condizioni meteorologiche',
        vehicleCondition: 'Condizioni del veicolo',
        vehicleOfTheAccident: 'Informazioni sul veicolo coinvolto nell\'incidente',
        vehicleCollisionPosition: 'Posizione di impatto del veicolo',
        vehicleRunningStatus: 'Stato di marcia del veicolo',
        ownSpeed: 'Velocità del proprio veicolo',
        vehicleSpeedOfTheOtherSide: 'Velocità del veicolo dell\'altro conducente',
        personnelInsideTheVehicle:
            'Situazione delle persone all\'interno del veicolo coinvolto nell\'incidente',
        numberOfPeopleInTheCar: 'Numero di persone a bordo del veicolo',
        personalInjury: 'Condizioni delle persone ferite',
        distributionOfPersonnelInTheVehicle:
            'Posizione delle persone ferite all\'interno del veicolo',
        descriptionOfPersonalInjury: 'Descrizione delle condizioni delle persone ferite',
        airbagDeployment: 'Attivazione dell\'airbag',
        safetyBeltWearing: 'Utilizzo delle cinture di sicurezza',
        brakeBeforeTheAccident: 'Utilizzo dei freni prima dell\'incidente',
        turnBeforeTheIncident: 'Svolta prima dell\'incidente',
        whenCorrosionOccurs: 'Quando si è verificata la corrosione',
        parkingTimeHours: 'Tempo di parcheggio (ore)',
        parkingTimeMinutes: 'Tempo di parcheggio (minuti)',
        drivingHours: 'Ore di guida',
        drivingMinutes: 'Minuti di guida',
        drivingDistance: 'Distanza percorsa',
        drivingSpeed: 'Velocità di guida',
        vehicleState: 'Condizioni del veicolo al momento dell\'incidente',
        fireFightingOrNot: 'Intervento antincendio',
        numberOfInjured: 'Numero di feriti',
        deathToll: 'Numero di morti',
        vehicleHelp: 'Richiesta di aiuto per il veicolo',
        vehiclePassingAccident: 'Veicolo coinvolto in incidenti precedenti',
        pastAccidents: 'Incidenti precedenti',
        isTheWarningLampOnBeforeTheEvent: 'Spia di avvertimento accesa prima dell\'incidente',
        specificIconContent: 'Contenuto degli icone specifici',
        didYouSmellFuelBeforeTheAccident: 'Hai percepito odore di carburante prima dell\'incidente',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Hai percepito vibrazioni anomale prima dell\'incidente',
        abnormalSoundZone: 'Zona di rumore anomalo',
        didYouHearAnyNoiseBeforeTheAccident: 'Hai sentito rumori prima dell\'incidente',
        noiseDescription: 'Descrizione del rumore',
        theAreaWhereTheVehicleSmokedBeforeTheAccident:
            'Il luogo in cui il veicolo ha iniziato a fumare prima dell\'incidente',
        otherAreas: 'Altre zone',
        whetherTheInsuranceCompanyHasIssuedACertificate:
            'L\'azienda di assicurazione ha emesso il certificato\'',
        whetherTheAuthorityIssuesTheCertificate: 'L\'autorità ha emesso il certificato',
        isVDSReportAvailable: 'Disponibilità del rapporto VDS',
        accidentProcess: 'Processo dell\'incidente',
        accidentProcessPlaceholder:
            'Segnalato da_ La parafrasi di XXXX.\n' +
            'Verso le ore 20.00 del XX/XX/20XX, la festa XXXX guidava un veicolo con targa XXX Roewe RX50 1.5/AT lungo l\'autostrada nazionale da sud a nord (con una persona nel veicolo e un autista). Durante la guida verso XX Village, XX Town, XX County, XX City, XXX Province, è stato scoperto che il fumo bianco era generato nella cabina di pilotaggio sinistra.',
        accessoryMaterials: 'Materiale accessorio',
        bulkDownload: 'Download in blocco',
        materialUpload: 'Caricamento materiale',
        userReadmeAttachment: 'Allegato di presentazione dell\'utente',
        sitePhotos: 'Foto del sito',
        fireAccidentIdentificationLetter: 'Lettera di identificazione dell\'incidente di incendio',
        insuranceRecordAttachment: 'Allegato record assicurativo',
        diagnosticRecords: 'Registri diagnostici',
        temperature: 'Temperatura',
        please_select_the_reporting_type: 'Seleziona il tipo di segnalazione',
        fire_accident_identification_letter_required:
            'Lettera di identificazione degli incidenti antincendio richiesta',
        please_upload_the_fire_accident_identification_letter:
            'Si prega di caricare il modulo di identificazione degli incidenti antincendio',
        insurance_record_attachment_required: 'Obbligo di allegare il registro assicurativo',
        please_upload_the_insurance_record_attachment:
            'Si prega di caricare l\'allegato della scheda assicurativa',
        please_upload_the_diagnostic_record_attachment:
            'Carica l\'allegato della scheda diagnostica',
        please_upload_site_photos: 'Si prega di caricare foto in loco',
    },
    // 荷兰
    nlNL: {
        documentType: 'Document type',
        vehicleType: 'Vehicle type',
        dealerInformation: 'Dealer informatie',
        reportType: 'Rapporttype',
        dealerContact: 'Dealer contact',
        contact: 'Contact #',
        customer_information: 'Klantgegevens',
        occupation: 'Beroep',
        vehicleInformation: 'Voertuiginformatie',
        mileage: 'Kilometerstand (/km)',
        licensePlate: 'Kenteken #',
        platform: 'Platform',
        offLineDate: 'Offline datum',
        vehicleUse: 'Voertuiggebruik',
        refited: 'Gerenoveerd',
        insuranceSituation: 'Nachgefüllt',
        nameplateNo: 'Typegoedkeuringnummer',
        purchaseDate: 'Aankoopdatum',
        accidentInformation: 'Ongevalsinformatie',
        timeOfAccident: 'Tijdstip van ongeval',
        placeOfAccident: 'Plaats van ongeval',
        roadConditions: 'Wegomstandigheden',
        pavementType: 'Wegdektype',
        weather: 'Weer',
        vehicleCondition: 'Voertuigconditie',
        vehicleOfTheAccident: 'Voertuiginformatie op het moment van het ongeval',
        vehicleCollisionPosition: 'Positie van de voertuigbotsing',
        vehicleRunningStatus: 'Voertuigrijstatus',
        ownSpeed: 'Eigen snelheid',
        vehicleSpeedOfTheOtherSide: 'Voertuigsnelheid van de andere kant',
        personnelInsideTheVehicle: 'Personeel in het voertuig',
        numberOfPeopleInTheCar: 'Aantal personen in de auto',
        personalInjury: 'Persoonlijk letsel',
        distributionOfPersonnelInTheVehicle: 'Verdeling van personeel in het voertuig',
        descriptionOfPersonalInjury: 'Beschrijving van persoonlijk letsel',
        airbagDeployment: 'Airbag-ontplooiing',
        safetyBeltWearing: 'Veiligheidsgordel dragen',
        brakeBeforeTheAccident: 'Remmen voor het ongeval',
        turnBeforeTheIncident: 'Bocht voor het incident',
        whenCorrosionOccurs: 'Wanneer corrosie optreedt',
        parkingTimeHours: 'Parkeertijd (uren)',
        parkingTimeMinutes: 'Parkeertijd (minuten)',
        drivingHours: 'Rijuren',
        drivingMinutes: 'Rijminuten',
        drivingDistance: 'Rijafstand',
        drivingSpeed: 'Rijsnelheid',
        vehicleState: 'Voertuigstatus',
        fireFightingOrNot: 'Brandbestrijding of niet',
        numberOfInjured: 'Aantal gewonden',
        deathToll: 'Doodstol',
        vehicleHelp: 'Voertuighulp',
        vehiclePassingAccident: 'Voertuig dat het ongeval passeert',
        pastAccidents: 'Eerdere ongevallen',
        isTheWarningLampOnBeforeTheEvent: 'Is het waarschuwingslampje aan voor het evenement',
        specificIconContent: 'Specifieke pictograminhoud',
        didYouSmellFuelBeforeTheAccident: 'Heb je brandstof geroken voor het ongeval',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Voelde u een abnormale trilling voor het ongeval',
        abnormalSoundZone: 'Zone met abnormaal geluid',
        didYouHearAnyNoiseBeforeTheAccident: 'Heeft u geluid gehoord voor het ongeval',
        noiseDescription: 'Beschrijving van het geluid',
        theAreaWhereTheVehicleSmokedBeforeTheAccident:
            'Locatie van de rook van het voertuig vóór het incident',
        otherAreas: 'Andere gebieden',
        whetherTheInsuranceCompanyHasIssuedACertificate:
            'Heeft de verzekeringsmaatschappij een certificaat afgegeven',
        whetherTheAuthorityIssuesTheCertificate: 'Of de autoriteit het certificaat afgeeft',
        isVDSReportAvailable: 'Is er een VDS-rapport beschikbaar',
        accidentProcess: 'Ongeval proces',
        accidentProcessPlaceholder:
            'Geregistreerd door_ De parafrase van XXXX.\n' +
            'Rond XX a.m. op XX/XX/20XX reed de partij XXXX met een voertuig met kentekennummer XXX Roewe RX50 1.5/AT over de nationale snelweg van zuid naar noord (met één persoon in het voertuig en één bestuurder). Bij het rijden naar XX Village, XX Town, XX County, XX City, XXX Province, bleek dat witte rook werd gegenereerd in de linkercockpit.',
        accessoryMaterials: 'Accessoire materialen',
        bulkDownload: 'Bulk download',
        materialUpload: 'Materiaal uploaden',
        userReadmeAttachment: 'Gebruikershandleiding bijlage',
        sitePhotos: 'Site foto\'s',
        fireAccidentIdentificationLetter: 'Identificatiebrief van brandongevallen',
        insuranceRecordAttachment: 'Verzekeringsregistratie bijlage',
        diagnosticRecords: 'Diagnostische gegevens',
        temperature: 'Temperatuur',
        please_select_the_reporting_type: 'Selecteer het type rapportage',
        fire_accident_identification_letter_required:
            'Noodzakelijke identificatiebrief voor brandongevallen',
        please_upload_the_fire_accident_identification_letter:
            'Upload het identificatieformulier voor brandongevallen',
        insurance_record_attachment_required: 'Vereist beslag op verzekeringsdocumenten',
        please_upload_the_insurance_record_attachment:
            'Upload de bijlage van het verzekeringsdossier',
        please_upload_the_diagnostic_record_attachment:
            'Upload de bijlage van de diagnostische record',
        please_upload_site_photos: 'Upload foto\'s ter plaatse',
    },
    // 越南
    viVN: {
        documentType: 'Loại tài liệu',
        vehicleType: 'Loại xe',
        dealerInformation: 'Thông tin nhà phân phối',
        reportType: 'Loại trường hợp',
        dealerContact: 'Người liên hệ nhà phân phối',
        contact: 'Số liên hệ',
        customer_information: 'Thông tin khách hàng',
        occupation: 'Nghề nghiệp',
        vehicleInformation: 'Thông tin xe',
        mileage: 'Số km đã đi (/km)',
        licensePlate: 'Biển số xe',
        platform: 'Nền tảng',
        offLineDate: 'Ngày off-line',
        vehicleUse: 'Sử dụng xe',
        refited: 'Được trang bị lại',
        insuranceSituation: 'Tình trạng bảo hiểm',
        nameplateNo: 'Số tem đóng lên xe',
        purchaseDate: 'Ngày mua',
        accidentInformation: 'Thông tin về tai nạn',
        timeOfAccident: 'Thời gian tai nạn',
        placeOfAccident: 'Điạ điểm xẩy ra tai nạn',
        roadConditions: 'Tình trạng đường',
        pavementType: 'Loại mặt đường',
        weather: 'Thời tiết',
        vehicleCondition: 'Tình trạng xe',
        vehicleOfTheAccident: 'Thông tin xe trong lúc xảy ra tai nạn',
        vehicleCollisionPosition: 'Vị trí va chạm trên xe',
        vehicleRunningStatus: 'Tình trạng hoạt động của xe',
        ownSpeed: 'Tốc độ',
        vehicleSpeedOfTheOtherSide: 'Tốc độ của phía bên kia',
        personnelInsideTheVehicle: 'Tình hình nhân viên trong xe xảy ra tai nạn',
        numberOfPeopleInTheCar: 'Số người trong xe',
        personalInjury: 'Chấn thương về người',
        distributionOfPersonnelInTheVehicle: 'Phân bổ người ngồi trong xe',
        descriptionOfPersonalInjury: 'Miêu tả tình trạng thương tật của người',
        airbagDeployment: 'Túi khí hoạt động',
        safetyBeltWearing: 'Thắt đai an toàn',
        brakeBeforeTheAccident: 'Thắng trước khi tai nạn',
        turnBeforeTheIncident: 'Rẻ trước khi tai nạn',
        whenCorrosionOccurs: 'Xẩy ra va chạm khi nào',
        parkingTimeHours: 'Thời gian đỗ xe (giờ)',
        parkingTimeMinutes: 'Thời gian đỗ xe (phút)',
        drivingHours: 'Thời gian lái xe (giờ)',
        drivingMinutes: 'Thời gian lái xe (phút)',
        drivingDistance: 'Khoảng cách đã lái xe',
        drivingSpeed: 'Tốc độ lái xe',
        vehicleState: 'Trạng thái xe',
        fireFightingOrNot: 'Chữa cháy hoặc không',
        numberOfInjured: 'Số người bị thương',
        deathToll: 'Số người tử vong',
        vehicleHelp: 'Xe hỗ trợ',
        vehiclePassingAccident: 'Xe đi qua vụ tai nạn',
        pastAccidents: 'Tai nạn trong quá khứ',
        isTheWarningLampOnBeforeTheEvent: 'Đèn cảnh báo có sáng trước khi xẩy ra',
        specificIconContent: 'Nội dung biểu tượng cụ thể',
        didYouSmellFuelBeforeTheAccident: 'Bạn có ngửi thấy mùi nhiên liệu trước khi tai nạn',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Bạn có cảm nhận bất kỳ rung động bất thường nào trước khi xẩy ra tai nạn',
        abnormalSoundZone: 'Khu vực tiếng ồn không bình thường',
        didYouHearAnyNoiseBeforeTheAccident: 'Bạn có nghe bất kỳ âm thanh gì trước khi tai nạn',
        noiseDescription: 'Miêu tả âm thanh',
        theAreaWhereTheVehicleSmokedBeforeTheAccident: 'Vị trí trên xe bốc khói trước khi tai nạn',
        otherAreas: 'Các khu vực khác',
        whetherTheInsuranceCompanyHasIssuedACertificate: 'Công ty bảo hiểm có cấp giấy chứng nhận',
        whetherTheAuthorityIssuesTheCertificate: 'Cơ quan nhà nước có cấp giấy chứng nhận',
        isVDSReportAvailable: 'Có báo cáo VDS không',
        accidentProcess: 'Diễn biến tai nạn',
        accidentProcessPlaceholder:
            'Báo cáo của người gửi_XXXX.\n' +
            'Vào khoảng XX giờ XX phút sáng ngày XX tháng XX năm 20XX, tại đoạn đường thuộc xã XX, huyện XX, tỉnh XXX, khi đang lái xe ô tô biển số XXXXXX dòng RX50 1.5/AT của hãng Roewe theo hướng từ phía Nam đi Bắc (trong xe có một người, là tài xế). Khi đi đến đoạn đường thuộc xã XX, huyện XX, tỉnh XXX, thì phát hiện có khói trắng bốc lên từ trong khoang lái.',
        accessoryMaterials: 'Tệp đính kèm',
        bulkDownload: 'Tải xuống tập tin hàng loạt',
        materialUpload: 'Tải lên tài liệu',
        userReadmeAttachment: 'Đính kèm tệp báo cáo của người sử dụng',
        sitePhotos: 'Ảnh hiện trường',
        fireAccidentIdentificationLetter: 'Xác nhận tai nạn cháy nổ',
        insuranceRecordAttachment: 'Báo cáo hồ sơ bảo hiểm',
        diagnosticRecords: 'Lịch sử đọc lỗi',
        temperature: 'Nhiệt độ',
        please_select_the_reporting_type: 'Vui lòng chọn loại báo cáo',
        fire_accident_identification_letter_required: 'Yêu cầu có thư xác định tai nạn cháy',
        please_upload_the_fire_accident_identification_letter:
            'Vui lòng tải lên thư xác định tai nạn cháy',
        insurance_record_attachment_required: 'Yêu cầu tệp ghi nhận bảo hiểm',
        please_upload_the_insurance_record_attachment: 'Vui lòng tải lên tệp ghi nhận bảo hiểm',
        please_upload_the_diagnostic_record_attachment: 'Vui lòng tải lên tệp ghi nhận chẩn đoán',
        please_upload_site_photos: 'Vui lòng tải lên ảnh hiện trường',
    },
    // 西班牙
    esES: {
        documentType: 'Tipo de documento',
        vehicleType: 'Tipo de vehículo',
        dealerInformation: 'Información del distribuidor',
        reportType: 'Tipo de informe',
        dealerContact: 'Persona de contacto del distribuidor',
        contact: 'Teléfono de contacto',
        customer_information: 'Información del cliente',
        occupation: 'Profesión',
        vehicleInformation: 'Información del vehículo',
        mileage: 'Kilometraje recorrido/km',
        licensePlate: 'Número de matrícula',
        platform: 'Plataforma del modelo del vehículo',
        offLineDate: 'Fecha de producción',
        vehicleUse: 'Uso del vehículo',
        refited: '¿Ha sido modificado o agregado algo?',
        insuranceSituation: 'Estado del seguro',
        nameplateNo: 'Número de placa',
        purchaseDate: 'Fecha de compra',
        accidentInformation: 'Información sobre el accidente',
        timeOfAccident: 'Fecha del accidente',
        placeOfAccident: 'Lugar del accidente',
        roadConditions: 'Estado de la carretera',
        pavementType: 'Tipo de pavimento',
        weather: 'Clima',
        vehicleCondition: 'Estado del vehículo',
        vehicleOfTheAccident: 'Información del vehículo en el momento del accidente',
        vehicleCollisionPosition: 'Ubicación del impacto en el vehículo',
        vehicleRunningStatus: 'Estado de conducción del vehículo',
        ownSpeed: 'Velocidad del vehículo propio',
        vehicleSpeedOfTheOtherSide: 'Velocidad del vehículo contrario',
        personnelInsideTheVehicle:
            'Información de los ocupantes del vehículo en el momento del accidente',
        numberOfPeopleInTheCar: 'Número de ocupantes',
        personalInjury: 'Lesiones de los ocupantes',
        distributionOfPersonnelInTheVehicle: 'Posición de los ocupantes lesionados',
        descriptionOfPersonalInjury: 'Descripción de las lesiones de los ocupantes',
        airbagDeployment: 'Activación de los airbags',
        safetyBeltWearing: 'Uso del cinturón de seguridad',
        brakeBeforeTheAccident: 'Frenado antes del accidente',
        turnBeforeTheIncident: 'Giro antes del accidente',
        whenCorrosionOccurs: 'Cuándo ocurrió la corrosión',
        parkingTimeHours: 'Tiempo de estacionamiento (horas)',
        parkingTimeMinutes: 'Tiempo de estacionamiento (minutos)',
        drivingHours: 'Horas de conducción',
        drivingMinutes: 'Minutos de conducción',
        drivingDistance: 'Distancia de conducción',
        drivingSpeed: 'Velocidad de conducción',
        vehicleState: 'Estado del vehículo en el momento del accidente',
        fireFightingOrNot: '¿Se utilizó equipo de extinción de incendios?',
        numberOfInjured: 'Número de personas lesionadas',
        deathToll: 'Número de personas fallecidas',
        vehicleHelp: 'Situación de ayuda del vehículo',
        vehiclePassingAccident: 'Historial de accidentes anteriores del vehículo',
        pastAccidents: 'Accidentes anteriores',
        isTheWarningLampOnBeforeTheEvent:
            '¿Se encendió alguna luz de advertencia antes del incidente?',
        specificIconContent: 'Contenido del icono específico',
        didYouSmellFuelBeforeTheAccident: '¿Olió a combustible antes del incidente?',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            '¿Sintió alguna vibración anormal antes del incidente?',
        abnormalSoundZone: 'Zona de ruidos extraños',
        didYouHearAnyNoiseBeforeTheAccident: '¿Escuchó algún ruido antes del incidente?',
        noiseDescription: 'Descripción del ruido',
        theAreaWhereTheVehicleSmokedBeforeTheAccident:
            'Ubicación del humo en el vehículo antes del incidente',
        otherAreas: 'Otra área',
        whetherTheInsuranceCompanyHasIssuedACertificate:
            '¿La compañía de seguros emitió una certificación?',
        whetherTheAuthorityIssuesTheCertificate:
            '¿Se emitió un informe por parte de una autoridad competente?',
        isVDSReportAvailable: '¿Es posible obtener un informe VDS?',
        accidentProcess: 'Proceso del incidente',
        accidentProcessPlaceholder:
            '"Declaración del usuario_XXXX.\n' +
            'El XX de XX de 20XX, aproximadamente a las XX:XX am, el usuario XXXX conducía el vehículo con matrícula XXXXXX, modelo Roewe RX50 1.5/AT, viajando de sur a norte por la carretera nacional (el conductor era el único ocupante). Cuando se encontraba en la localidad de XX, en el municipio de XX, provincia de XXXX, se observó humo blanco en la cabina del lado izquierdo.","',
        accessoryMaterials: 'Documentos adjuntos',
        bulkDownload: 'Descargar varios',
        materialUpload: 'Cargar documentos',
        userReadmeAttachment: 'Adjunto de declaración del usuario',
        sitePhotos: 'Fotografías del lugar del incidente',
        fireAccidentIdentificationLetter: 'Informe de determinación del incendio',
        insuranceRecordAttachment: 'Archivo de registro de seguro',
        diagnosticRecords: 'Registro de diagnóstico',
        temperature: 'Temperatura',
        please_select_the_reporting_type: 'Seleccione el tipo de informe',
        fire_accident_identification_letter_required:
            'Se requiere el informe de determinación del incendio',
        please_upload_the_fire_accident_identification_letter:
            'Cargue el informe de determinación del incendio',
        insurance_record_attachment_required: 'Se requiere el archivo de registro de seguro',
        please_upload_the_insurance_record_attachment: 'Cargue el archivo de registro de seguro',
        please_upload_the_diagnostic_record_attachment:
            'Cargue el archivo de registro de diagnóstico',
        please_upload_site_photos: 'Cargue las fotografías del lugar del incidente',
    },
    // 葡萄牙
    ptBR: {
        documentType: 'Tipo de documento',
        vehicleType: 'Tipo de veículo',
        dealerInformation: 'Informações do revendedor',
        reportType: 'Tipo de relatório',
        dealerContact: 'Contato do revendedor',
        contact: 'Telefone de contato',
        customer_information: 'Informações do cliente',
        occupation: 'Profissão',
        vehicleInformation: 'Informações do veículo',
        mileage: 'Quilometragem percorrida/km',
        licensePlate: 'Placa do veículo',
        platform: 'Plataforma do modelo',
        offLineDate: 'Data de desativação',
        vehicleUse: 'Uso do veículo',
        refited: 'Possui modificações',
        insuranceSituation: 'Situação do seguro',
        nameplateNo: 'Número da placa',
        purchaseDate: 'Data de compra',
        accidentInformation: 'Informações do acidente',
        timeOfAccident: 'Horário do acidente',
        placeOfAccident: 'Local do acidente',
        roadConditions: 'Condições da estrada',
        pavementType: 'Tipo de pavimento',
        weather: 'Clima',
        vehicleCondition: 'Condição do veículo',
        vehicleOfTheAccident: 'Informações do veículo no momento do acidente',
        vehicleCollisionPosition: 'Posição de colisão do veículo',
        vehicleRunningStatus: 'Estado de movimento do veículo',
        ownSpeed: 'Velocidade própria',
        vehicleSpeedOfTheOtherSide: 'Velocidade do outro veículo',
        personnelInsideTheVehicle: 'Situação dos ocupantes do veículo',
        numberOfPeopleInTheCar: 'Número de pessoas no veículo',
        personalInjury: 'Lesões pessoais',
        distributionOfPersonnelInTheVehicle: 'Distribuição dos ocupantes do veículo',
        descriptionOfPersonalInjury: 'Descrição das lesões pessoais',
        airbagDeployment: 'Ativação da bolsa de ar',
        safetyBeltWearing: 'Uso do cinto de segurança',
        brakeBeforeTheAccident: 'Freio antes do acidente',
        turnBeforeTheIncident: 'Manobra antes do acidente',
        whenCorrosionOccurs: 'Momento em que ocorreu a corrosão',
        parkingTimeHours: 'Tempo de estacionamento (horas)',
        parkingTimeMinutes: 'Tempo de estacionamento (minutos)',
        drivingHours: 'Tempo de condução (horas)',
        drivingMinutes: 'Tempo de condução (minutos)',
        drivingDistance: 'Distância percorrida',
        drivingSpeed: 'Velocidade de condução',
        vehicleState: 'Estado do veículo no momento do acidente',
        fireFightingOrNot: 'Presença de combate a incêndio',
        numberOfInjured: 'Número de feridos',
        deathToll: 'Número de mortos',
        vehicleHelp: 'Situação do veículo que solicitou ajuda',
        vehiclePassingAccident: 'Acidentes anteriores envolvendo o veículo',
        pastAccidents: 'Acidentes anteriores',
        isTheWarningLampOnBeforeTheEvent: 'A luz de advertência estava acesa antes do evento',
        specificIconContent: 'Conteúdo do ícone específico',
        didYouSmellFuelBeforeTheAccident: 'Você sentiu cheiro de combustível antes do acidente',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Você sentiu alguma vibração anormal antes do acidente',
        abnormalSoundZone: 'Zona de ruído anormal',
        didYouHearAnyNoiseBeforeTheAccident: 'Você ouviu algum barulho antes do acidente',
        noiseDescription: 'Descrição do ruído',
        theAreaWhereTheVehicleSmokedBeforeTheAccident:
            'Área onde o veículo emitia fumaça antes do acidente',
        otherAreas: 'Outras áreas',
        whetherTheInsuranceCompanyHasIssuedACertificate: 'A seguradora emitiu um certificado',
        whetherTheAuthorityIssuesTheCertificate: 'A autoridade emitiu um certificado',
        isVDSReportAvailable: 'É possível obter o relatório VDS',
        accidentProcess: 'Processo do acidente',
        accidentProcessPlaceholder:
            'Relato de XXXX Transmitido por XXXX.\n' +
            'Por volta das XXhXXmin da madrugada de XX de XXXX de 20XX, a pessoa envolvida XXXX dirigia o veículo XXXXXX, modelo do carro XXXXXXXXXX RX50 1.5/AT, ao longo da rodovia no sentido sul-norte (apenas o motorista estava no veículo). Ao passar pela localidade de XX, cidade de XX, condado de XX, vila de XX, ocorreu a emissão de fumaça branca dentro da cabine do lado esquerdo.',
        accessoryMaterials: 'Materiais complementares',
        bulkDownload: 'Download em massa',
        materialUpload: 'Upload de materiais',
        userReadmeAttachment: 'Anexo de descrição do usuário',
        sitePhotos: 'Fotos do local',
        fireAccidentIdentificationLetter: 'Carta de Identificação do Acidente de Incêndio',
        insuranceRecordAttachment: 'Anexo de registro de seguro',
        diagnosticRecords: 'Registros de diagnóstico',
        temperature: 'Temperatura',
        please_select_the_reporting_type: 'Por favor, selecione o tipo de relatório',
        fire_accident_identification_letter_required:
            'A carta de identificação do acidente de incêndio é obrigatória',
        please_upload_the_fire_accident_identification_letter:
            'Por favor, faça o upload da carta de identificação do acidente de incêndio',
        insurance_record_attachment_required: 'O anexo de registro de seguro é obrigatório',
        please_upload_the_insurance_record_attachment:
            'Por favor, faça o upload do anexo de registro de seguro',
        please_upload_the_diagnostic_record_attachment:
            'Por favor, faça o upload do anexo de registro de diagnóstico',
        please_upload_site_photos: 'Por favor, faça o upload das fotos do local',
    },
    // 波兰
    plPL: {
        documentType: 'Typ dokumentu',
        vehicleType: 'Typ pojazdu',
        dealerInformation: 'Informacje o dealerze',
        reportType: 'Typ sprawy',
        dealerContact: 'Kontakt  do dealera',
        contact: 'Kontakt',
        customer_information: 'Informacje o kliencie',
        occupation: 'wykonywany zawód',
        vehicleInformation: 'Informacje o pojeździe',
        mileage: 'Przebieg/km',
        licensePlate: 'Numer rejestracyjny',
        platform: 'Platforma',
        offLineDate: 'Data zejścia z linii',
        vehicleUse: 'Użytkowanie pojazdu',
        refited: 'Zmiana wyposażenia',
        insuranceSituation: 'Sytuacja ubezpieczenia',
        nameplateNo: 'Numer plakietki znamionowej',
        purchaseDate: 'Data zakupu',
        accidentInformation: 'Informacje o wypadkach',
        timeOfAccident: 'Data wypadku',
        placeOfAccident: 'Miejsce wypadku',
        roadConditions: 'Warunki drogowe',
        pavementType: 'Rodzaj nawierzchni',
        weather: 'Pogoda',
        vehicleCondition: 'Stan pojadu',
        vehicleOfTheAccident: 'Informacje o pojeździe w chwili wypadku',
        vehicleCollisionPosition: 'Miejsce kolizji pojazdu',
        vehicleRunningStatus: 'Stan jazdy',
        ownSpeed: 'Prędkość własna',
        vehicleSpeedOfTheOtherSide: 'Prędkość drugiego pojazdu',
        personnelInsideTheVehicle: 'Załoga pojazdu',
        numberOfPeopleInTheCar: 'Ilość osób w pojeździe',
        personalInjury: 'Obrażenia',
        distributionOfPersonnelInTheVehicle: 'Miejsce poszkodowanych',
        descriptionOfPersonalInjury: 'Opis obrażeń',
        airbagDeployment: 'Wyzwolenie poduszki powietrznej',
        safetyBeltWearing: 'Stan zapięcia pasów bezpieczeństwa',
        brakeBeforeTheAccident: 'Hamowanie przed kolizją',
        turnBeforeTheIncident: 'Skręcanie przed kolizją',
        whenCorrosionOccurs: 'Kiedy występuje korozja',
        parkingTimeHours: 'Czas parkowania (w godzinach)',
        parkingTimeMinutes: 'Czas parkowania (w minutach)',
        drivingHours: 'Godziny jazdy',
        drivingMinutes: 'Minuty jazdy',
        drivingDistance: 'Dystans',
        drivingSpeed: 'Prędkość jazdy',
        vehicleState: 'Stan pojazdu podczas kolizji',
        fireFightingOrNot: 'Czy straż pożarna gasiła ogień',
        numberOfInjured: 'Ilość osób poszkodowanych',
        deathToll: 'Liczba ofiar śmiertelnych',
        vehicleHelp: 'Pomoc pojazdu',
        vehiclePassingAccident: 'Wypadek przejeżdżającego pojazdu',
        pastAccidents: 'Wcześcniejsze kolizje',
        isTheWarningLampOnBeforeTheEvent: 'Czy światła awaryjne paliły się przed zdarzeniem',
        specificIconContent: 'Określona zawartość ikony',
        didYouSmellFuelBeforeTheAccident: 'Czy przed kolizją wyczuwalny był zapach paliwa',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Czy przed kolizją wyczuwalne były nietypowe wibracje',
        abnormalSoundZone: 'Strefa nietypowych dźwięków',
        didYouHearAnyNoiseBeforeTheAccident: 'Czy s luchać było jakieś dźwięki przed wypadkiem',
        noiseDescription: 'Opis dźwięków',
        theAreaWhereTheVehicleSmokedBeforeTheAccident:
            'Miejsce, w krórym pojawił się ogień przed wypadkiem',
        otherAreas: 'Inne obszary',
        whetherTheInsuranceCompanyHasIssuedACertificate: 'Czy ubezpieczyciel wydał certyfikat',
        whetherTheAuthorityIssuesTheCertificate: 'Czy ubezpieczyciel wydał certyfikat',
        isVDSReportAvailable: 'Czy dostępny jest raport VDS',
        accidentProcess: 'Przebieg wypadku',
        accidentProcessPlaceholder:
            'Opis przekazany przez XXXX.\\n + \'Około godziny XX przed południem, dnia XX/XX/20XX, uczestnik XXXX prowadził pojazd Roewe RX50 1.5T/AT o numerze rejestracyjnym XXXXXX po drodze krajowej jadąc z południa na północ (z jednym pasażerem i jeden kierowca). Podczas jazdy do miejscowości XX, miasta CC, województwa XX stwierdzono, że po lewej stronie kokpitu wydobywał się biały dym.',
        accessoryMaterials: 'Załącznik',
        bulkDownload: 'Pobieranie zbiorcze',
        materialUpload: 'Przesyłanie materiałów',
        userReadmeAttachment: 'Załącznik raportu użytkownika',
        sitePhotos: 'Zdjęcia miejsca kolizji',
        fireAccidentIdentificationLetter: 'Potwierdzenie wystąpienia pożaru podczas kolizji',
        insuranceRecordAttachment: 'Załącznik protokołu ubezpieczenia',
        diagnosticRecords: 'Historia diagnostyczna',
        temperature: 'Temperatura',
        please_select_the_reporting_type: 'Proszę wybrać typ raportu',
        fire_accident_identification_letter_required:
            'Wymagane pismo identyfikacji wypadku pożarowego',
        please_upload_the_fire_accident_identification_letter:
            'Prześlij pismo identyfikacji wypadku pożarowego',
        insurance_record_attachment_required: 'Wymagany załącznik protokołu ubezpieczenia',
        please_upload_the_insurance_record_attachment: 'Prześlij załącznik protokołu ubezpieczenia',
        please_upload_the_diagnostic_record_attachment: 'Prześlij historię diagnostyczną',
        please_upload_site_photos: 'Prześlij zdjęcia kolizji',
    },
    // 土耳其
    trTR: {
        documentType: 'Belge türü',
        vehicleType: 'Araç tipi',
        dealerInformation: 'Satış Bilgisi',
        reportType: 'Dosya tipi',
        dealerContact: 'Bayi  İletişimi',
        contact: 'Kontakt #',
        customer_information: 'Müşteril bilgisi',
        occupation: 'Mesleği',
        vehicleInformation: 'Araç bilgisi',
        mileage: 'Mileage (/km)',
        licensePlate: 'Plaka',
        platform: 'Platform',
        offLineDate: 'Devre-tışı tarih',
        vehicleUse: 'Araç kullanımı',
        refited: 'Refited',
        insuranceSituation: 'Sigorta durumu',
        nameplateNo: 'İsim Tablosu Numarası',
        purchaseDate: 'Alış tarihi',
        accidentInformation: 'Kaza bilgisi',
        timeOfAccident: 'Kaza zamanı',
        placeOfAccident: 'Kaza yeri',
        roadConditions: 'Yol koşulları',
        pavementType: 'Yol kaplama durumu',
        weather: 'Hava',
        vehicleCondition: 'Araç durumu',
        vehicleOfTheAccident: 'Kaza zamanında araba bilgileri',
        vehicleCollisionPosition: 'Araç çarpma pozisyonu',
        vehicleRunningStatus: 'Araç çalışma durumu',
        ownSpeed: 'Araç hızı',
        vehicleSpeedOfTheOtherSide: 'Diğer tarafın araba hızı',
        personnelInsideTheVehicle: 'Arabadaki kişi',
        numberOfPeopleInTheCar: 'Arabadaki insanların sayısı',
        personalInjury: 'Kişisel yaralanmalar',
        distributionOfPersonnelInTheVehicle: 'Arabadaki personel dağıtımı',
        descriptionOfPersonalInjury: 'Kişisel yaralanmanın açıklaması',
        airbagDeployment: 'Hava yastığı açılma durumu',
        safetyBeltWearing: 'emniyet kemeri takılma durumu',
        brakeBeforeTheAccident: 'Kazadan önce fren.',
        turnBeforeTheIncident: 'Olay öncesi dönüş',
        whenCorrosionOccurs: 'Korozyon oluştuğunda',
        parkingTimeHours: 'Park zamanı (saat)',
        parkingTimeMinutes: 'Park zamanı (dakika)',
        drivingHours: 'Sürüş Süresi (saat)',
        drivingMinutes: 'Sürüş Süresi (dakika)',
        drivingDistance: 'Sürüş mesafesi',
        drivingSpeed: 'Sürücü hızı',
        vehicleState: 'Araç durumu',
        fireFightingOrNot: 'Yangın söndürme işlemi oldu mu?',
        numberOfInjured: 'Yaralı sayısı',
        deathToll: 'Ölü sayısı',
        vehicleHelp: 'Araç yardımı',
        vehiclePassingAccident: 'Araç kazası',
        pastAccidents: 'Geçmiş kazalar',
        isTheWarningLampOnBeforeTheEvent: 'Kaza öncesi uyarı lambası yandı mı?',
        specificIconContent: 'Belirli simge içeriği',
        didYouSmellFuelBeforeTheAccident: 'Kazadan önce yakıt kokuyordun mu?',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Kazadan önce normal bir vibracyon hissettin mi?',
        abnormalSoundZone: 'Normal ses bölgesi',
        didYouHearAnyNoiseBeforeTheAccident: 'Kazadan önce bir ses duydun mu?',
        noiseDescription: 'Ses Açıklaması',
        theAreaWhereTheVehicleSmokedBeforeTheAccident:
            'Kaza gerçekleşmeden önce araçtan duman geliyormuydu?',
        otherAreas: 'Diğer bölgeler',
        whetherTheInsuranceCompanyHasIssuedACertificate:
            'Sigorta şirketinin bir rapor düzenledi mi?',
        whetherTheAuthorityIssuesTheCertificate: 'Otoritenin bir rapor düzenledi mi?',
        isVDSReportAvailable: 'VDS raporu mebvcut mu?',
        accidentProcess: 'Olay süreci',
        accidentProcessPlaceholder:
            'XXXX\' tarafından rapor edildi. \n' +
            'XX/XX/20XX\'de yaklaşık saat XXXX\'de, parti XXXX, güneyden kuzeye doğru ulusal otoyol üzerinde seyrederken,  XXXXXX plalalı  1.5/AT model bir araç sürüyordu (içerisindeki ve bir sürücü olan tek kişi var). XX Köyü, XX Şehir, XX Şehir, XX Şehir, XXX ülkesinden geçerken, aracın sol iç tarafından beyaz bir duman geldiği tespit edildi.',
        accessoryMaterials: 'Ekler',
        bulkDownload: 'Toplu indirme',
        materialUpload: 'Materiyal Yükleme',
        userReadmeAttachment: 'Kullanıcı rapor ekleri',
        sitePhotos: 'Olay yeri fotoğrafları',
        fireAccidentIdentificationLetter: 'Yangın kazası  doğrulaması',
        insuranceRecordAttachment: 'Sigorta kayıt ekleri',
        diagnosticRecords: 'Arızacılık geçmişi',
        temperature: 'Sıcaklık',
        please_select_the_reporting_type: 'Lütfen rapor türünü seçin',
        fire_accident_identification_letter_required: 'Yangın Kazası Tanımlama Raporu Gerekli',
        please_upload_the_fire_accident_identification_letter:
            'Lütfen Yangın Kazası Tanımlama Raporunu yükleyin',
        insurance_record_attachment_required: 'Sigorta Rapor Ekleri gerekli',
        please_upload_the_insurance_record_attachment: 'Sigorta Rapor Eklerini yükleyin.',
        please_upload_the_diagnostic_record_attachment: 'Arızacılık kayıtları eklerini yükleyin',
        please_upload_site_photos: 'Lütfen olay yeri fotoğraflarını yükleyin',
    },
    // 捷克语
    csCZ: {
        documentType: 'Typ dokumentu',
        vehicleType: 'Typ vozidla',
        dealerInformation: 'Informace o dealerovi',
        reportType: 'Typ případu',
        dealerContact: 'Kontakt na prodejce',
        contact: 'Kontakt #',
        customer_information: 'Informace o zákazníkovi',
        occupation: 'obsazení',
        vehicleInformation: 'Informace o vozidle',
        mileage: 'Najeto (/km)',
        licensePlate: 'Poznávací značka #',
        platform: 'Platforma',
        offLineDate: 'Datum off-line',
        vehicleUse: 'Používání vozidla',
        refited: 'Repasované',
        insuranceSituation: 'Pojistná situace',
        nameplateNo: 'Výrobní štítek č.',
        purchaseDate: 'Datum nákupu',
        accidentInformation: 'Informace o nehodě',
        timeOfAccident: 'Čas nehody',
        placeOfAccident: 'Místo nehody',
        roadConditions: 'Stav vozovky',
        pavementType: 'Typ povrchu',
        weather: 'Počasí',
        vehicleCondition: 'Stav vozidla',
        vehicleOfTheAccident: 'Informace o vozidle v době nehody',
        vehicleCollisionPosition: 'Poloha vozidla při nehodě',
        vehicleRunningStatus: 'Stav vozidla',
        ownSpeed: 'Vlastní rychlost',
        vehicleSpeedOfTheOtherSide: 'Rychlost protijedoucího vozidla',
        personnelInsideTheVehicle: 'Osoby uvnitř vozidla',
        numberOfPeopleInTheCar: 'Počet lidí ve vozidle',
        personalInjury: 'Újma na zdraví',
        distributionOfPersonnelInTheVehicle: 'Rozložení osob ve vozidle',
        descriptionOfPersonalInjury: 'Popis poranění osob',
        airbagDeployment: 'Aktivace modulu airbagu',
        safetyBeltWearing: 'Použití bezpečnostního pásu',
        brakeBeforeTheAccident: 'Brzdění před nehodou',
        turnBeforeTheIncident: 'Otáčení před nehodou',
        whenCorrosionOccurs: 'Když dojde ke korozi',
        parkingTimeHours: 'Doba parkování (hodiny)',
        parkingTimeMinutes: 'Doba parkování (minuty)',
        drivingHours: 'Doba jízdy hodiny',
        drivingMinutes: 'Doba jízdy minuty',
        drivingDistance: 'Ujetá vzdálenost',
        drivingSpeed: 'Rychlost jízdy',
        vehicleState: 'Stav vozidla',
        fireFightingOrNot: 'Hašení požáru nebo ne',
        numberOfInjured: 'Počet poraněných',
        deathToll: 'Počet obětí',
        vehicleHelp: 'Pomoc vozidla',
        vehiclePassingAccident: 'Projíždějícího vozidla kolem nehody',
        pastAccidents: 'Předchozí nehody',
        isTheWarningLampOnBeforeTheEvent: 'Svítila výstražná kontrolka před událostí',
        specificIconContent: 'Konkrétní obsah ikony',
        didYouSmellFuelBeforeTheAccident: 'Bylo před nehodou cítit palivo?',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Cítili jste před nehodou nějaké neobvyklé vibrace?',
        abnormalSoundZone: 'Zóna s abnormálním hlukem',
        didYouHearAnyNoiseBeforeTheAccident: 'Slyšeli jste před nehodou nějaký hluk?',
        noiseDescription: 'Popis hluku',
        theAreaWhereTheVehicleSmokedBeforeTheAccident:
            'Místo, kde se z vozidla před nehodou začalo kouřit',
        otherAreas: 'Ostatní oblasti',
        whetherTheInsuranceCompanyHasIssuedACertificate: 'Vydala pojišťovna osvědčení',
        whetherTheAuthorityIssuesTheCertificate: 'Vydala autorita osvědčení',
        isVDSReportAvailable: 'Je k dispozici report VDS',
        accidentProcess: 'Proces nehody',
        accidentProcessPlaceholder:
            'Reported by_XXXX\'s paraphrase.\n' +
            'Dne XX.XX.20XX kolem XXhod. řídil účastník nehody XXXX vozidlo značky Roewe RX50 1.5/AT s SPZ XXXXXX směrem na sever po státní silnici (ve vozidle byla pouze jedna osoba, řidič). Když dojel na úsek silnice v obci XX, okrese XX, městě XX, provincii XX, zjistil, že se z levé strany kabiny řidiče kouří.',
        accessoryMaterials: 'Příloha',
        bulkDownload: 'Hromadné stahování',
        materialUpload: 'Nahrání materiálů',
        userReadmeAttachment: 'Příloha zprávy uživatele',
        sitePhotos: 'Fotografie z místa události',
        fireAccidentIdentificationLetter: 'Potvrzení požáru při nehodě',
        insuranceRecordAttachment: 'Příloha záznamu o pojištění',
        diagnosticRecords: 'Historie diagnostiky',
        temperature: 'Teplota',
        please_select_the_reporting_type: 'Vyberte typ hlášení',
        fire_accident_identification_letter_required: 'Požadováno potvrzení o požáru',
        please_upload_the_fire_accident_identification_letter: 'Nahrajte prosím potvrzení o požáru',
        insurance_record_attachment_required: 'Povinná příloha záznamu o pojištění',
        please_upload_the_insurance_record_attachment:
            'Nahrajte prosím přílohu záznamu o pojištění',
        please_upload_the_diagnostic_record_attachment:
            'Nahrajte prosím přílohu diagnostického záznamu',
        please_upload_site_photos: 'Nahrajte prosím fotografie z místa',
    },
    // 匈牙利语
    huHU: {
        documentType: 'Dokumentumtípus',
        vehicleType: 'Járműtípus',
        dealerInformation: 'Kereskedői információ',
        reportType: 'Eset típusa',
        dealerContact: 'Kereskedői kapcsolattartó',
        contact: 'Kapcsolattartási sz.',
        customer_information: 'Ügyfélinformáció',
        occupation: 'Foglalkozás',
        vehicleInformation: 'Járműinformáció',
        mileage: 'Futásteljesítmény (km)',
        licensePlate: 'Rendszám',
        platform: 'Platform',
        offLineDate: 'Offline dátum',
        vehicleUse: 'Járműhasználat',
        refited: 'Átalakítás',
        insuranceSituation: 'Biztosítási állapot',
        nameplateNo: 'Névtábla sz.',
        purchaseDate: 'Vásárlás dátuma',
        accidentInformation: 'Baleseti információ',
        timeOfAccident: 'Baleset ideje',
        placeOfAccident: 'Baleset helye',
        roadConditions: 'Útviszonyok',
        pavementType: 'Útburkolat típusa',
        weather: 'Időjárás',
        vehicleCondition: 'Járműállapot',
        vehicleOfTheAccident: 'Járműinformáció a baleset időpontjában',
        vehicleCollisionPosition: 'Jármű ütközésének helyzete',
        vehicleRunningStatus: 'Jármű menetállapota',
        ownSpeed: 'Saját sebesség',
        vehicleSpeedOfTheOtherSide: 'Másik oldali járműsebesség',
        personnelInsideTheVehicle: 'Személyek a járműben',
        numberOfPeopleInTheCar: 'Autóban lévő személyek',
        personalInjury: 'Személyi sérülés',
        distributionOfPersonnelInTheVehicle: 'Személyek eloszlása a járműben',
        descriptionOfPersonalInjury: 'Személyi sérülés leírása',
        airbagDeployment: 'Légzsák felfúvódása',
        safetyBeltWearing: 'Biztonsági öv viselete',
        brakeBeforeTheAccident: 'Fékezés a baleset előtt',
        turnBeforeTheIncident: 'Kanyarodás a baleset előtt',
        whenCorrosionOccurs: 'Amikor korrózió történik',
        parkingTimeHours: 'Parkolási idő (óra)',
        parkingTimeMinutes: 'Parkolási idő (perc)',
        drivingHours: 'Haladási óra',
        drivingMinutes: 'Haladási perc',
        drivingDistance: 'Haladási távolság',
        drivingSpeed: 'Haladási sebesség',
        vehicleState: 'Járműállapot',
        fireFightingOrNot: 'Tűzoltóság volt-e',
        numberOfInjured: 'Sérültek száma',
        deathToll: 'Elhunytak száma',
        vehicleHelp: 'Segítség a járműnek',
        vehiclePassingAccident: 'Elhaladó jármű balesete',
        pastAccidents: 'Múltbéli balesetek',
        isTheWarningLampOnBeforeTheEvent: 'Világított-e figyelmeztető lámpa az esemény előtt',
        specificIconContent: 'Specifikus ikon tartalma',
        didYouSmellFuelBeforeTheAccident: 'Érezte-e üzemanyag szagát a baleset előtt',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Érzett-e rendellenes vibrációt a baleset előtt',
        abnormalSoundZone: 'Rendellenes hang területe',
        didYouHearAnyNoiseBeforeTheAccident: 'Hallott-e rendellenes zajt a baleset előtt',
        noiseDescription: 'Zaj leírása',
        theAreaWhereTheVehicleSmokedBeforeTheAccident:
            'A hely, ahol a jármű füstölni kezdett, mielőtt megtörtént a baleset',
        otherAreas: 'Egyéb területek',
        whetherTheInsuranceCompanyHasIssuedACertificate:
            'Adott-e ki tanúsítványt a biztosítótársaság',
        whetherTheAuthorityIssuesTheCertificate: 'Adott-e ki tanúsítványt hatóság',
        isVDSReportAvailable: 'Van-e elérhető VDS jelentés',
        accidentProcess: 'Baleset lefolyása',
        accidentProcessPlaceholder:
            '_ által jelentett. XXXX elmondása.\n' +
            '20XX/XX/XX. délelőtt XX óra körül XXXX az XXXXXX rendszámú járművet vezette Roewe RX50 1.5/AT felé az autópálya mentén délről északra (egy utas és egy járművezető tartózkodott a járműben). Amikor az XX falu, XX város, XX megye, XXX tartomány felé haladt, azt észlelte, hogy fehér füst száll fel a műszerfal bal oldalából.',
        accessoryMaterials: 'Csatolmány',
        bulkDownload: 'Kötegelt letöltés',
        materialUpload: 'Anyag feltöltése',
        userReadmeAttachment: 'Felhasználói jelentés csatolása',
        sitePhotos: 'Helyszíni fényképek',
        fireAccidentIdentificationLetter: 'Tűzeset megerősítése',
        insuranceRecordAttachment: 'Baleseti jegyzőkönyv csatolása',
        diagnosticRecords: 'Diagnosztikai előzmények',
        temperature: 'Hőmérséklet',
        please_select_the_reporting_type: 'Válassza ki a jelentéstípust',
        fire_accident_identification_letter_required: 'Tűzesetet azonosító levél szükséges',
        please_upload_the_fire_accident_identification_letter:
            'Töltse fel a tűzesetet azonosító levelet',
        insurance_record_attachment_required: 'Biztosítási jegyzőkönyv csatolása szükséges',
        please_upload_the_insurance_record_attachment:
            'Töltse fel csatolmányként a biztosítási jegyzőkönyvet',
        please_upload_the_diagnostic_record_attachment:
            'Töltse fel csatolmányként a diagnosztikai jegyzőkönyvet',
        please_upload_site_photos: 'Töltse fel a helyszíni fényképeket',
    },
    // 俄罗斯语
    ruRU: {
        documentType: 'Тип документа',
        vehicleType: 'Тип транспортного средства',
        dealerInformation: 'Информация о дилере',
        reportType: 'Тип отчета',
        dealerContact: 'Контактное лицо дилера',
        contact: 'Контактный телефон',
        customer_information: 'Информация о клиенте',
        occupation: 'Профессия',
        vehicleInformation: 'Информация о транспортном средстве',
        mileage: 'Пробег / км',
        licensePlate: 'Номерной знак',
        platform: 'Платформа модели',
        offLineDate: 'Дата снятия с эксплуатации',
        vehicleUse: 'Назначение транспортного средства',
        refited: 'Модификации или изменения',
        insuranceSituation: 'Страховая ситуация',
        nameplateNo: 'Номер на табличке',
        purchaseDate: 'Дата покупки',
        accidentInformation: 'Информация об аварии',
        timeOfAccident: 'Дата и время аварии',
        placeOfAccident: 'Место происшествия',
        roadConditions: 'Состояние дороги',
        pavementType: 'Тип покрытия',
        weather: 'Погода',
        vehicleCondition: 'Состояние транспортного средства',
        vehicleOfTheAccident: 'Информация о транспортном средстве в момент происшествия',
        vehicleCollisionPosition: 'Место столкновения транспортного средства',
        vehicleRunningStatus: 'Состояние транспортного средства во время движения',
        ownSpeed: 'Скорость вашего автомобиля',
        vehicleSpeedOfTheOtherSide: 'Скорость другого автомобиля',
        personnelInsideTheVehicle: 'Состояние людей в машине в момент происшествия',
        numberOfPeopleInTheCar: 'Количество людей в машине',
        personalInjury: 'Состояние пострадавших',
        distributionOfPersonnelInTheVehicle: 'Место сидения пострадавших',
        descriptionOfPersonalInjury: 'Описание травм пострадавших',
        airbagDeployment: 'Состояние подушек безопасности',
        safetyBeltWearing: 'Состояние ремней безопасности',
        brakeBeforeTheAccident: 'Торможение перед происшествием',
        turnBeforeTheIncident: 'Маневрирование перед происшествием',
        whenCorrosionOccurs: 'Когда произошло коррозия',
        parkingTimeHours: 'Время парковки (часы)',
        parkingTimeMinutes: 'Время парковки (минуты)',
        drivingHours: 'Часы вождения',
        drivingMinutes: 'Минуты вождения',
        drivingDistance: 'Расстояние вождения',
        drivingSpeed: 'Скорость вождения',
        vehicleState: 'Состояние транспортного средства во время происшествия',
        fireFightingOrNot: 'Использовалась ли противопожарная служба',
        numberOfInjured: 'Количество пострадавших',
        deathToll: 'Количество погибших',
        vehicleHelp: 'Состояние помощи автомобилю',
        vehiclePassingAccident: 'Предыдущие аварии автомобиля',
        pastAccidents: 'Предыдущие аварии',
        isTheWarningLampOnBeforeTheEvent:
            'Был ли включен индикатор предупреждения перед происшествием',
        specificIconContent: 'Содержимое специфической иконки',
        didYouSmellFuelBeforeTheAccident: 'Почувствовали ли вы запах топлива перед происшествием?',
        didYouFeelAnyAbnormalVibrationBeforeTheAccident:
            'Ощущали ли вы необычную вибрацию перед происшествием?',
        abnormalSoundZone: 'Место шумов',
        didYouHearAnyNoiseBeforeTheAccident: 'Слышали ли вы шум перед происшествием?',
        noiseDescription: 'Описание шума',
        theAreaWhereTheVehicleSmokedBeforeTheAccident: 'Место появления дыма перед происшествием',
        otherAreas: 'Другие зоны',
        whetherTheInsuranceCompanyHasIssuedACertificate: 'Выдала ли страховая компания сертификат?',
        whetherTheAuthorityIssuesTheCertificate: 'Выдала ли авторитетная организация сертификат?',
        isVDSReportAvailable: 'Может ли быть предоставлен отчет VDS?',
        accidentProcess: 'Ход происшествия',
        accidentProcessPlaceholder:
            'Передача информации от XXXX\n' +
            '20XX год, XX месяц, XX число, примерно в XX часов XX минут, водитель XXXX управлял автомобилем марки Roewe RX50 1.5/AT с номерным знаком XXXXXX, который двигался по национальной дороге в направлении с юга на север (в автомобиле был только один человек — водитель). Когда автомобиль двигался по участку дороги в XXX провинции, XX городе, XX уезде, XX посёлке, XX деревне, в левом переднем отсеке автомобиля появилась белая дымка.',
        accessoryMaterials: 'Прикрепленные материалы',
        bulkDownload: 'Пакетная загрузка',
        materialUpload: 'Загрузка материалов',
        userReadmeAttachment: 'Документы от пользователя',
        sitePhotos: 'Фотографии с места происшествия',
        fireAccidentIdentificationLetter: 'Сертификат о пожаре',
        insuranceRecordAttachment: 'Прикрепленные страховые записи',
        diagnosticRecords: 'Записи диагностики',
        temperature: 'Температура',
        please_select_the_reporting_type: 'Выберите тип отчета',
        fire_accident_identification_letter_required: 'Сертификат о пожаре обязателен',
        please_upload_the_fire_accident_identification_letter:
            'Пожалуйста, загрузите сертификат о пожаре',
        insurance_record_attachment_required: 'Прикрепленные страховые записи обязательны',
        please_upload_the_insurance_record_attachment:
            'Пожалуйста, загрузите прикрепленные страховые записи',
        please_upload_the_diagnostic_record_attachment: 'Пожалуйста, загрузите записи диагностики',
        please_upload_the_site_photos: 'Пожалуйста, загрузите фотографии с места происшествия',
    },
};

import menu from './menu';
import dict from './dict';
import dept from './dept';
import role from './role';
import user from './user';

export default {
    menu,
    dict,
    dept,
    role,
    user,
};
